import { Avatar, GlobalStyles, Icon } from '@andromeda'
import { IconButton, Stack, StackProps, Typography } from '@mui/material'
import { labelStyles } from './Overview'
import { ReactNode } from 'react'
import { capitalizeWordsFirstLetter, getInitials } from '@utils/functions/helperFunctions'

export const Section = ({
  SectionIcon,
  title,
  children,
  noAdd = false,
  onClickAdd,
  ...props
}: StackProps & {
  children: ReactNode
  SectionIcon: (props: any) => JSX.Element
  title: string
  noAdd?: boolean
  onClickAdd?: () => void
}) => (
  <Stack
    border={`1px solid ${GlobalStyles.SLATE_100}`}
    borderRadius="6px"
    padding="12px"
    role="list"
    gap="14px"
    aria-label={title}
    {...props}>
    <Stack direction="row" height="40px" justifyContent="space-between">
      <Stack direction="row" gap="12px" alignItems="center">
        {<SectionIcon color={GlobalStyles.SLATE_500} width={20} height={20} />}
        <Typography {...labelStyles} color={GlobalStyles.SLATE_500}>
          {title.toUpperCase()}
        </Typography>
      </Stack>
      {!noAdd && (
        <Stack direction="row" gap="12px" alignItems="center">
          <IconButton sx={{ padding: 0 }} onClick={onClickAdd}>
            <Icon.PlusCircle color={GlobalStyles.PRIMARY_500} size={16} />
          </IconButton>
          <IconButton sx={{ border: `1px solid ${GlobalStyles.SLATE_200}`, borderRadius: '100%', padding: 0 }} onClick={onClickAdd}>
            <Icon.User size={25} color={GlobalStyles.SLATE_200} strokeWidth={1} />
          </IconButton>
        </Stack>
      )}
    </Stack>
    {children}
  </Stack>
)

export const UserRow = ({
  name,
  subtext,
  profile_image,
  Actions,
  AvatarIcon,
}: {
  name: string
  subtext?: string
  profile_image?: string
  Actions: JSX.Element
  AvatarIcon?: JSX.Element
}) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    padding="6px 12px 6px 6px"
    border={`1px solid ${GlobalStyles.SLATE_100}`}
    borderRadius="6px">
    <Stack direction="row" columnGap="10px" alignItems="center">
      {AvatarIcon ?? (
        <Avatar
          image={profile_image}
          placeHolder={
            <Typography fontSize="18px" color="white" textAlign="center">
              {getInitials(name)}
            </Typography>
          }
        />
      )}
      <Stack>
        <Typography color={GlobalStyles.MONO_BLACK} fontSize="14px" fontWeight={500}>
          {name}
        </Typography>
        {subtext && (
          <Typography color={GlobalStyles.MONO_BLACK} fontSize="14px" fontWeight={400} lineHeight="24px">
            {capitalizeWordsFirstLetter(subtext, ' ')}
          </Typography>
        )}
      </Stack>
    </Stack>
    {Actions}
  </Stack>
)
