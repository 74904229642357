import { StyleSheet } from 'react-native'
import { GlobalStyles } from '../../globalStyles'

export const CalendarStyles = StyleSheet.create({
  eventComponentStack: {
    paddingHorizontal: 10,
    paddingVertical: 0,
    borderRadius: 2,
  },
  monthHeaderComponent: {
    backgroundColor: GlobalStyles.SLATE_700,
    paddingVertical: 8,
    paddingHorizontal: 12,
    flex: 1,
    alignItems: 'flex-start',
  },
  monthHeaderText: {
    color: GlobalStyles.MONO_WHITE,
  },
  monthDateHeaderText: {
    color: GlobalStyles.SLATE_500,
    fontWeight: '600',
    lineHeight: 24,
  },
})
