import { Orbit, Types } from '@orbit'
import { Draft, PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import WorkspaceSidebarDefaults from '@utils/constants/workspacesSidebarDefaults.json'
import { ApiResponseError } from '@utils/types/auth'
import { WorkspaceSidebar, WorkspaceType, iWorkspaceInitialState } from '@utils/types/workspace'
import { AppState } from '../store'
import axios from 'axios'
import { dataUrlToFile } from '@utils/functions/helperFunctions'

export enum Workspace {
  settings = 'settings',
  help = 'help',
  profile = 'profile',
  invite = 'invite',
  workbench = 'workbench',
  userSettings = 'userSettings',
}

const initialState: iWorkspaceInitialState = {
  workspace: null,
  sidebarMenu: WorkspaceSidebarDefaults.workspace as WorkspaceSidebar,
  workspaceList: null,
  status: 'idle',
  activeWorkspace: null,
  settings: null,
  userSettings: null,
}

export const fetchAllWorkspaces = createAsyncThunk<Draft<Array<Types.Workspaces.iWorkspace>>, undefined>(
  'workspaces/fetchAllWorkspace',
  async (): Promise<Array<Types.Workspaces.iWorkspace>> => {
    const response = await Orbit.Services.userService.getMyWorkspaces()
    return response.data
  }
)

export const getWorkspaceSettings = createAsyncThunk<Draft<Types.Workspaces.iWorkspaceSettings>, string | undefined>(
  'workspaces/getWorkspaceSettings',
  async (workspaceSlug?: string): Promise<Types.Workspaces.iWorkspaceSettings> => {
    const response = await Orbit.Services.workspaceService.getWorkspaceSettings(workspaceSlug)
    return response.data
  }
)

export const updateWorkspaceSettings = createAsyncThunk(
  'workspaces/updateWorkspaceSettings',
  async (
    payload: Partial<Types.Workspaces.iWorkspaceSettings>,
    { rejectWithValue }
  ): Promise<Types.Workspaces.iWorkspaceSettings | any> => {
    try {
      const response = await Orbit.Services.workspaceService.updateWorkspaceSettings(payload)
      return response.data
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const getUserWorkspaceSettings = createAsyncThunk<Draft<Types.Workspaces.iUserSettings>, undefined>(
  'workspaces/getUserWorkspaceSettings',
  async (): Promise<Types.Workspaces.iUserSettings> => {
    const response = await Orbit.Services.workspaceService.getUserSettings()
    return response.data
  }
)

export const updateUserWorkspaceSettings = createAsyncThunk(
  'workspaces/updateUserWorkspaceSettings',
  async (payload: Types.Workspaces.iUserSettings, { rejectWithValue }): Promise<Types.Workspaces.iUserSettings | any> => {
    try {
      const response = await Orbit.Services.workspaceService.updateUserSettings(payload)
      return response.data
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)
export const leaveWorkspace = createAsyncThunk(
  'workspaces/leaveWorkspace',
  async (workspaceEid: string, { rejectWithValue }): Promise<Types.Workspaces.iUserSettings | any> => {
    try {
      const response = await Orbit.Services.workspaceService.leaveWorkspace(workspaceEid)
      return response.data
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const updateWorkspacePicture = createAsyncThunk(
  'workspaces/updateWorkspacePicture',
  async (payload: { fileMeta: File; profilePicture: string }): Promise<Types.Workspaces.iWorkspaceSettings | any> => {
    const response = await Orbit.Services.workspaceService.generateUploadUrl({ file_name: payload.fileMeta.name })
    const file = await dataUrlToFile(payload.profilePicture, payload.fileMeta.name)
    await axios.put(response.data.upload_presign_url, file, {
      headers: {
        'Content-Type': payload.fileMeta.type,
      },
    })

    return { profile_image_eid: response.data.eid, full_url: response.data.full_url }
  }
)

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    resetWorkspaceSlice: () => initialState,
    setWorkspace: (state: iWorkspaceInitialState, action: PayloadAction<WorkspaceType>) => {
      state.workspace = action.payload
      const newWorkspace = state.workspaceList?.find((workspace) => workspace.slug === action.payload)
      if (newWorkspace && state.activeWorkspace?.slug !== newWorkspace.slug) {
        // console.log("set new wp: ", newWorkspace.slug)
        state.activeWorkspace = newWorkspace
        Orbit.Services.AxiosBaseInstance.defaults.headers.common['x-pickstar-workspace'] = `${newWorkspace.slug}`
      }
      state.sidebarMenu =
        action.payload === Workspace.settings
          ? (WorkspaceSidebarDefaults.settings as WorkspaceSidebar)
          : (WorkspaceSidebarDefaults.workspace as WorkspaceSidebar)
    },
    updateWorkspaceList: (state, { payload }) => {
      state.workspaceList = payload
      state.status = 'success'
    },
    updateActiveWorkspace: (state, { payload }) => {
      const activeWorkspace = state.activeWorkspace ? Object.assign(state.activeWorkspace, payload) : payload
      state.activeWorkspace = activeWorkspace
      state.workspaceList =
        state.workspaceList?.map((workspace) => {
          if (workspace.eid === activeWorkspace.eid) {
            return activeWorkspace
          }
          return workspace
        }) || []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllWorkspaces.fulfilled, (state, action) => {
        state.workspaceList = action.payload
        state.activeWorkspace = action.payload[0]
        state.status = 'success'
      })
      .addCase(fetchAllWorkspaces.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getWorkspaceSettings.fulfilled, (state, action) => {
        state.settings = action.payload
      })
      .addCase(updateWorkspaceSettings.fulfilled, (state, action) => {
        state.settings = action.payload
      })
      .addCase(getUserWorkspaceSettings.fulfilled, (state, { payload }) => {
        state.userSettings = payload
      })
      .addCase(updateUserWorkspaceSettings.fulfilled, (state, { payload }) => {
        state.userSettings = payload
      })
  },
})

export const { setWorkspace, updateActiveWorkspace, resetWorkspaceSlice, updateWorkspaceList } = workspaceSlice.actions
export const { reducer } = workspaceSlice
export const selectWorkspace = (state: AppState) => state.workspace
