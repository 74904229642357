import { GlobalStyles, Icon } from '@andromeda'
import { Stack } from '@mui/material'
import { useState } from 'react'

const HoverEditIcon = ({ children, isEdit }: { isEdit: boolean; children: React.ReactNode }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Stack
      className="relative inline-block flex flex-auto"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      columnGap="4px"
      direction="row"
      alignItems="center"
      style={{
        cursor: !isEdit ? 'pointer' : 'default',
      }}>
      {children}

      <Icon.Edit2 size={16} color={GlobalStyles.PRIMARY_500} style={{ visibility: isHovered && !isEdit ? 'visible' : 'hidden' }} />
    </Stack>
  )
}

export default HoverEditIcon
