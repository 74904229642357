import { StyleProp, StyleSheet } from 'react-native'
import { GlobalStyles } from '../globalStyles'

const DefaultStyles = {
  borderRadius: 4,
  borderWidth: 1,
  borderColor: 'transparent',
  alignItems: 'start',
  paddingTop: GlobalStyles.PADDING,
  paddingRight: GlobalStyles.PADDING_HORIZONTAL,
  paddingLeft: GlobalStyles.PADDING_HORIZONTAL,
  paddingBottom: GlobalStyles.PADDING,
  // marginBottom: 10,
} as StyleProp<object> as Record<string, number>

export const AlertStyles = StyleSheet.create({
  primary: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.PRIMARY_500,
  },
  secondary: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.PRIMARY_600,
    // borderColor: 'rgba(214, 216, 219, 0.9)',
  },
  success: {
    ...DefaultStyles,
    backgroundColor: 'rgba(212, 237, 218, 0.9)',
    borderColor: 'rgba(195, 230, 203, 0.9)',
  },
  danger: {
    ...DefaultStyles,
    backgroundColor: 'rgba(248, 215, 218, 0.9)',
    borderColor: 'rgba(245, 198, 203, 0.9)',
  },
  warning: {
    ...DefaultStyles,
    backgroundColor: 'rgba(255, 243, 205, 0.9)',
    borderColor: 'rgba(255, 238, 186, 0.9)',
  },
  info: {
    ...DefaultStyles,
    backgroundColor: 'rgba(209, 236, 241, 0.9)',
    borderColor: 'rgba(190, 229, 235, 0.9)',
  },
  light: {
    ...DefaultStyles,
    backgroundColor: 'rgba(254, 254, 254, 0.9)',
    borderColor: 'rgba(0, 0, 0, 0.2)',
  },
  dark: {
    ...DefaultStyles,
    backgroundColor: 'rgba(214, 216, 217, 0.9)',
    borderColor: 'rgba(198, 200, 202, 0.9)',
  },
})

export const AlertHoverStyles = StyleSheet.create({
  primary: {
    ...DefaultStyles,
    backgroundColor: 'rgba(204, 229, 255, 1)',
    borderColor: 'rgba(184, 218, 255, 1)',
  },
  secondary: {
    ...DefaultStyles,
    backgroundColor: 'rgba(226, 227, 229, 1)',
    borderColor: 'rgba(214, 216, 219, 1)',
  },
  success: {
    ...DefaultStyles,
    backgroundColor: 'rgba(40, 167, 69, 1)',
    borderColor: 'rgba(195, 230, 203, 1)',
  },
  danger: {
    ...DefaultStyles,
    backgroundColor: 'rgba(248, 215, 218, 1)',
    borderColor: 'rgba(245, 198, 203, 1)',
  },
  warning: {
    ...DefaultStyles,
    backgroundColor: 'rgba(255, 243, 205, 1)',
    borderColor: 'rgba(255, 238, 186, 1)',
  },
  info: {
    ...DefaultStyles,
    backgroundColor: 'rgba(209, 236, 241, 1)',
    borderColor: 'rgba(190, 229, 235, 1)',
  },
  light: {
    ...DefaultStyles,
    backgroundColor: 'rgba(254, 254, 254, 1)',
    borderColor: 'rgba(0, 0, 0, 0.6)',
  },
  dark: {
    ...DefaultStyles,
    backgroundColor: 'rgba(214, 216, 217, 1)',
    borderColor: 'rgba(198, 200, 202, 1)',
  },
})

export const AlertTextStyles = StyleSheet.create({
  primary: {
    color: 'rgba(0, 64, 133, 0.9)',
  },
  secondary: {
    color: 'rgba(56, 61, 65, 0.9)',
  },
  success: {
    color: 'rgba(21, 87, 36, 0.9)',
  },
  danger: {
    color: 'rgba(114, 28, 36, 0.9)',
  },
  warning: {
    color: 'rgba(133, 100, 4, 0.9)',
  },
  info: {
    color: 'rgba(12, 84, 96, 0.9)',
  },
  light: {
    color: 'rgba(129, 129, 130, 0.9)',
  },
  dark: {
    color: 'rgba(27, 30, 33, 0.9)',
  },
})

export const AlertHoverTextStyles = StyleSheet.create({
  primary: {
    color: 'rgba(0, 64, 133, 1)',
  },
  secondary: {
    color: 'rgba(56, 61, 65, 1)',
  },
  success: {
    color: 'rgba(255, 255, 255, 1)',
  },
  danger: {
    color: 'rgba(114, 28, 36, 1)',
  },
  warning: {
    color: 'rgba(133, 100, 4, 1)',
  },
  info: {
    color: 'rgba(12, 84, 96, 1)',
  },
  light: {
    color: 'rgba(129, 129, 130, 1)',
  },
  dark: {
    color: 'rgba(27, 30, 33, 1)',
  },
})
