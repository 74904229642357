import { Button, GlobalStyles, Input, Stack, Text } from '@andromeda'
import { useAppSelector } from '@redux/hooks'
import { selectRecovery } from '@redux/reducers/passwordRecovery'
import { AppStyles } from '@styles'
import { ModalContext } from '@utils/context/Modal.context'
import { useContext, useState } from 'react'

const SecurityCodeForm = ({ onSubmit }: { onSubmit: () => void }): JSX.Element => {
  const { closeModal } = useContext(ModalContext)

  const [loading, setLoading] = useState<boolean>(false)

  /**
   * Input field values
   */
  const [securityCode, setSecurityCode] = useState<string>('')

  /**
   * Custom Error message hooks
   */
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const { phoneNumber } = useAppSelector(selectRecovery)

  const doSubmit = () => {
    if (!securityCode) {
      setErrorMessage('Please enter the security code')
    } else {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        onSubmit && onSubmit()
      }, 1000)
    }
  } // For dispatching Actions

  return (
    <Stack
      direction="column"
      style={{ paddingHorizontal: AppStyles.MODAL_PADDING_HORIZONTAL, paddingBottom: AppStyles.MODAL_PADDING_HORIZONTAL }}
      align="flex-start">
      <Text type={'paragraph'}>Please check your phone for a text message with your code. Your code is 6 characters in length.</Text>

      <Text type={'paragraph'}>{` We sent your code to: *********${phoneNumber?.slice(-3)}`}</Text>
      <Text type={'paragraph'}>Enter Code</Text>
      <Input
        value={securityCode}
        errorMessage={errorMessage}
        placeholder="12345"
        style={{ backgroundColor: 'GlobalStyles.MONO_WHITE', borderColor: GlobalStyles.SLATE_100 }}
        wrapperStyle={{ height: 'auto' }}
        onChangeText={(value: string) => setSecurityCode(value)}
      />

      <Stack height={'auto'} justify={'space-between'}>
        <Button
          onPress={() => {
            closeModal('resetPasswordForm')
          }}
          style={{ padding: 0 }}
          transparent>
          <Text size="sm" style={{ color: GlobalStyles.PRIMARY_500 }}>
            Cancel
          </Text>
        </Button>
        <Button
          loading={loading}
          onPress={() => {
            doSubmit()
          }}
          style={{ borderRadius: 6 }}
          type="primary"
          text="Continue"
        />
      </Stack>
    </Stack>
  )
}

SecurityCodeForm.displayName = 'SecurityCodeForm'

export { SecurityCodeForm }
