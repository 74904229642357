import { API } from '../types'
import { IDP } from '../types/idp'
import { HttpClient } from './api.service'

export const idpService = {
  list: () => {
    return HttpClient.get<null, Array<IDP.iIDP>>({
      endpoint: API.IDPEndpoints.LIST,
      payload: null,
    })
  },
  callback: (idp: string, payload: IDP.iRedirectPayload) => {
    return HttpClient.post<IDP.iRedirectPayload, IDP.iRedirectResponse>({
      endpoint: API.IDPEndpoints.CALLBACK.replace(':slug', idp),
      payload,
    })
  },
  redirect: (idp: string) => {
    return HttpClient.get({
      endpoint: API.IDPEndpoints.REDIRECT.replace(':slug', idp),
    })
  },
}
