import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs, { TabsProps } from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { iTab } from './types'
import { useEffect } from 'react'

interface TabPanelProps {
  children?: JSX.Element
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ paddingTop: '30px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function TabsV2({
  tabs,
  initialIndex = 0,
  onSelectTab,
  tabsProps,
  paddingTop,
  pathname,
  ...props
}: {
  tabs: Array<iTab>
  initialIndex: number
  onSelectTab?: (tab: iTab) => void
  tabsProps?: TabsProps
  paddingTop?: string | number
  pathname?: string
  value?: number
}) {
  const [value, setValue] = React.useState(initialIndex)

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value)
    }
  }, [props.value])

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    updateSelectedTab(newValue)
  }

  const updateSelectedTab = (newValue: number) => {
    setValue(newValue)
    onSelectTab && onSelectTab(tabs[newValue])
  }

  // To update the selected tab while changing route
  useEffect(() => {
    if (pathname && tabs) {
      const activeTab = pathname.split('/').pop()
      const tabIndex = tabs.findIndex((tab) => tab.slug === activeTab)

      if (tabIndex > -1) {
        updateSelectedTab(tabIndex)
      }
    }
  }, [pathname])

  return (
    <Box sx={{ width: '100%', paddingTop: paddingTop !== undefined ? paddingTop : '22px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs {...tabsProps} variant="scrollable" value={value} onChange={handleChange} aria-label="job list tabs">
          {tabs.map((tab) => (
            <Tab key={tab.id} label={tab.title} {...a11yProps(tab.id)} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab) => (
        <CustomTabPanel key={tab.id} value={value} index={tab.id}>
          {tab.component}
        </CustomTabPanel>
      ))}
    </Box>
  )
}
