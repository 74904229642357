import { StyleProp, StyleSheet } from 'react-native'
import { GlobalStyles } from '../globalStyles'

export const BadgeSizes = {
  primary: GlobalStyles.BADGE_SIZES.PRIMARY,
  secondary: GlobalStyles.BADGE_SIZES.SECONDARY,
}

const DefaultStyles = {
  justifyContent: GlobalStyles.JUSTIFY_CONTENT,
} as StyleProp<object> as Record<string, number>

const DefaultTextStyles = {
  textAlign: 'center',
  color: GlobalStyles.MONO_WHITE,
  fontSize: GlobalStyles.FONT_SIZES.SMALL,
} as StyleProp<object> as Record<string, number>

export const BadgeStyles = StyleSheet.create({
  primary: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
    width: GlobalStyles.BADGE_SIZES.PRIMARY,
    height: GlobalStyles.BADGE_SIZES.PRIMARY,
  },
  secondary: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.SLATE_500,
    width: GlobalStyles.BADGE_SIZES.SECONDARY,
    height: GlobalStyles.BADGE_SIZES.SECONDARY,
  },
})

export const BadgeTextStyles = StyleSheet.create({
  primary: {
    ...DefaultTextStyles,
  },
  secondary: {
    ...DefaultTextStyles,
  },
})
