import { StackProps } from '@mui/material'
import { memo, useEffect, useRef, useState } from 'react'
import { Modal as NativeModal, TouchableOpacity as NativeTouchableOpacity, Platform, StyleSheet } from 'react-native'
import { useScreenDimensions } from '../hooks/dimensions'
import { Icon } from '../Icon'
import { List } from '../List'
import { StackV2 } from '../StackV2'
import { Text } from '../Text'
import { View } from '../View'
import { GlobalStyles } from '../globalStyles'
import { DropdownStyles, IconColors } from './styles'
import { DropdownDefaults, DropdownOption, DropdownPlacement, DropdownProps } from './types'

const Dropdown = ({
  options,
  optionLabel = DropdownDefaults.LABEL,
  dropdownPosition = DropdownDefaults.POSITION,
  type = DropdownDefaults.TYPE,
  hover = true,
  repositionAllowance = 2,
  ...props
}: DropdownProps) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [dropdownPos, setDropdownPos] = useState<DropdownPlacement>({ top: 0 })
  const [selectedItem, setSelectedItem] = useState<DropdownOption | undefined>(props.selectedItem)
  const [dropdownWidth, setDropdownWidth] = useState<string | number>('90%')
  const isWeb = useState<boolean>(Platform.OS === DropdownDefaults.PLATFORM)
  const dropdownBtn = useRef<NativeTouchableOpacity>(null)
  const { windowHeight, windowWidth } = useScreenDimensions()
  const [dropdownYPos, setDropdownYPos] = useState<number>(0)
  const [dropdownHeight, setDropdownHeight] = useState<number>(0)

  const _toggleSelect = () => {
    if (visible) {
      setVisible(false)
      props.onClose && props.onClose()
    } else {
      _openDropdown()
      props.onOpen && props.onOpen()
    }
  }

  const _openDropdown = async () => {
    const [x, y, width, height]: [number, number, number, number] = await new Promise((resolve) =>
      dropdownBtn.current?.measureInWindow((...args: any) => resolve(args))
    )
    setDropdownYPos(y)
    setDropdownHeight(height)
    const dropdownStyles = StyleSheet.flatten([props.dropdownStyle])
    const dwidth = dropdownStyles?.width ? dropdownStyles?.width : width
    setDropdownWidth(dwidth)

    if (dropdownPosition === 'right') {
      setDropdownPos({ top: y + height, right: windowWidth - (x + width) })
    } else {
      setDropdownPos({ top: y + height, left: x })
    }

    setVisible(true)
  }

  const _handleItemPress = (item: any) => {
    setSelectedItem(item)
    props.onSelect(item)
    _toggleSelect()
  }

  useEffect(() => {
    if (isWeb) {
      document.addEventListener('scroll', () => setVisible(false))
    }

    return () => {
      if (isWeb) {
        document.removeEventListener('scroll', () => setVisible(false))
      }
    }
  }, [])

  const _renderList = () => {
    return (
      <NativeModal visible={visible} transparent animationType="none">
        <View
          style={[
            DropdownStyles.select,
            { ...dropdownPos },
            type === 'shadow' && DropdownStyles.shadowedDropdown,
            props.dropdownStyle,
            { width: dropdownWidth },
          ]}
          onLayout={(e) => {
            const height = e.nativeEvent.layout.height
            const willOverlap = windowHeight - (dropdownYPos + dropdownHeight) < height
            if (willOverlap) {
              const newPos: DropdownPlacement = { bottom: repositionAllowance }
              if (dropdownPos.left) newPos.left = dropdownPos.left
              if (dropdownPos.right) newPos.right = dropdownPos.right
              setDropdownPos(newPos)
            }
          }}>
          <List.Group
            values={options}
            valuesLabel={optionLabel}
            onItemPress={_handleItemPress}
            hover={hover}
            style={DropdownStyles.item}
            listHoverStyle={props.listHoverStyle}
            disabledItem={props.disableSelected ? selectedItem : undefined}
            disabledItemStyle={props.listDisabledItemStyle}
          />
        </View>
      </NativeModal>
    )
  }

  const _renderLabelText = () => {
    if (props.mode === 'menu') {
      return props.menuText ? props.menuText : props.placeholder
    }

    return selectedItem ? selectedItem[optionLabel] : props.placeholder
  }

  useEffect(() => {
    setSelectedItem(props.selectedItem)
  }, [props.selectedItem])

  return (
    <View style={props.dropdownWrapperStyle}>
      <View style={props.containerStyle}>
        <NativeTouchableOpacity
          testID={props.testID}
          ref={dropdownBtn}
          style={[
            DropdownStyles.container,
            type === 'shadow' && DropdownStyles.borderless,
            type === 'shadow' && visible && GlobalStyles.INPUT_SHADOWED,
            props.dropdownContainerStyle,
          ]}
          onPress={_toggleSelect}
          activeOpacity={1}
          disabled={props.disabled}>
          <StackV2 direction="row" alignItems="center" justifyContent={'space-between'} spacing={2} {...(props.stackProps as StackProps)}>
            {props.leftElement && props.leftElement}

            <Text
              style={[
                selectedItem || props.mode === 'menu' ? DropdownStyles.selectedLabel : DropdownStyles.placeholder,
                props.labelStyle,
                props.disabled && { color: GlobalStyles.SLATE_200 },
              ]}
              numberOfLines={1}>
              {_renderLabelText()}
            </Text>
            {!props.hideChevron && (
              <View
                style={[DropdownStyles.chevronIcon, type === 'shadow' && DropdownStyles.shadowedIconContainer, props.iconContainerStyle]}>
                <Icon.ChevronDown
                  size={GlobalStyles.ICON_SIZE}
                  color={IconColors[props.disabled ? 'disabled' : type]}
                  {...props.iconStyle}
                />
              </View>
            )}

            {props.rightElement && props.rightElement}
          </StackV2>

          {_renderList()}
        </NativeTouchableOpacity>
      </View>
    </View>
  )
}

export default memo(Dropdown)
