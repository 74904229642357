import { StyleProp, StyleSheet, ViewProps, ViewStyle } from 'react-native'
import { GlobalStyles } from '../globalStyles'

export const RoundedBorder = {
  borderRadius: GlobalStyles.BUTTON_BORDER_RADIUS,
} as StyleProp<ViewStyle>

const DefaultStyles = {
  width: '100%',
  alignItems: GlobalStyles.ALIGN_ITEMS,
  padding: GlobalStyles.PADDING,
} as StyleProp<object> as Record<string, number>

const DefaultTextStyles = {
  fontWeight: GlobalStyles.FONT_WEIGHT_MEDIUM,
  fontSize: GlobalStyles.FONT_SIZES.SMALL,
} as StyleProp<object> as Record<string, number>

const DefaultSelectedStyles = {
  borderLeftWidth: 2,
  borderLeftColor: GlobalStyles.PRIMARY_600,
} as StyleProp<object> as Record<string, number>

const DefaultTransparentStyles = {
  backgroundColor: GlobalStyles.COLOR_TRANSPARENT,
} as StyleProp<object> as Record<string, number>

export const ButtonStyles = StyleSheet.create({
  primary: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.PRIMARY_500,
  },
  secondary: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.MONO_WHITE,
  },
  outlined: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.MONO_WHITE,
    borderWidth: 1,
    borderColor: GlobalStyles.PRIMARY_500,
  },
  shadowed: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.MONO_WHITE,
    borderRadius: GlobalStyles.BORDER_RADIUS,
    borderWidth: GlobalStyles.BORDER_WIDTH,
    borderColor: GlobalStyles.SLATE_100,
    ...GlobalStyles.BUTTON_SHADOWED,
  },
})

export const ButtonVariantStyles = StyleSheet.create({
  primary: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.PRIMARY_500,
  },
  secondary: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.MONO_WHITE,
  },
  danger: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
  },
  success: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_POSITIVE,
  },
})

export const ButtonHoverStyles = StyleSheet.create({
  primary: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.PRIMARY_600,
  },
  secondary: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.SLATE_50,
  },
  outlined: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.MONO_WHITE,
    borderColor: GlobalStyles.PRIMARY_500,
  },
  shadowed: {
    ...DefaultStyles,
    ...GlobalStyles.BUTTON_SHADOWED,
  },
})

export const ButtonDisabledStyles = StyleSheet.create({
  primary: {
    ...DefaultStyles,
    color: GlobalStyles.SLATE_300,
    backgroundColor: GlobalStyles.SLATE_100,
  },
  secondary: {
    ...DefaultStyles,
    color: GlobalStyles.SLATE_300,
    backgroundColor: GlobalStyles.SLATE_100,
  },
  outlined: {
    ...DefaultStyles,
    color: GlobalStyles.SLATE_300,
    backgroundColor: GlobalStyles.SLATE_100,
  },
  shadowed: {
    ...DefaultStyles,
    ...GlobalStyles.BUTTON_SHADOWED,
    color: GlobalStyles.SLATE_300,
    backgroundColor: GlobalStyles.SLATE_100,
  },
})

export const ButtonTransparentStyles = StyleSheet.create({
  primary: {
    ...DefaultStyles,
    ...DefaultTransparentStyles,
  },
  secondary: {
    ...DefaultStyles,
    ...DefaultTransparentStyles,
  },
  outlined: {
    ...DefaultStyles,
    ...DefaultTransparentStyles,
  },
  shadowed: {
    ...DefaultStyles,
    ...GlobalStyles.BUTTON_SHADOWED,
    ...DefaultTransparentStyles,
  },
})

export const ButtonSelectedStyles = StyleSheet.create({
  primary: {
    ...DefaultStyles,
    ...DefaultSelectedStyles,
  },
  secondary: {
    ...DefaultStyles,
    ...DefaultSelectedStyles,
  },
  outlined: {
    ...DefaultStyles,
    ...DefaultSelectedStyles,
  },
  shadowed: {
    ...DefaultStyles,
    ...DefaultSelectedStyles,
    ...GlobalStyles.BUTTON_SHADOWED,
  },
})

export const ButtonTextStyles = StyleSheet.create({
  primary: {
    ...DefaultTextStyles,
    color: GlobalStyles.MONO_WHITE,
  },
  secondary: {
    ...DefaultTextStyles,
    color: GlobalStyles.PRIMARY_500,
  },
  outlined: {
    ...DefaultTextStyles,
    color: GlobalStyles.PRIMARY_500,
  },
  shadowed: {
    color: GlobalStyles.PRIMARY_500,
  },
})

export const ButtonHoverTextStyles = StyleSheet.create({
  primary: {
    ...DefaultTextStyles,
    color: GlobalStyles.MONO_WHITE,
  },
  secondary: {
    ...DefaultTextStyles,
    color: GlobalStyles.PRIMARY_600,
  },
  outlined: {
    ...DefaultTextStyles,
    color: GlobalStyles.PRIMARY_500,
  },
  shadowed: {
    color: GlobalStyles.PRIMARY_500,
  },
})

export const ButtonDisabledTextStyles = StyleSheet.create({
  primary: {
    ...DefaultTextStyles,
    color: GlobalStyles.SLATE_200,
  },
  secondary: {
    ...DefaultTextStyles,
    color: GlobalStyles.SLATE_300,
  },
  outlined: {
    ...DefaultTextStyles,
    color: GlobalStyles.SLATE_300,
  },
  shadowed: {
    color: GlobalStyles.SLATE_300,
  },
})

export const ButtonTransparentTextStyles = StyleSheet.create({
  primary: {
    ...DefaultTextStyles,
    ...DefaultTransparentStyles,
  },
  secondary: {
    ...DefaultTextStyles,
    ...DefaultTransparentStyles,
  },
  outlined: {
    ...DefaultTextStyles,
    ...DefaultTransparentStyles,
  },
  shadowed: {
    ...DefaultStyles,
    ...DefaultTransparentStyles,
  },
})

export const ButtonSelectedTextStyles = StyleSheet.create({
  primary: {
    ...DefaultTextStyles,
    color: GlobalStyles.PRIMARY_600,
  },
  secondary: {
    ...DefaultTextStyles,
    color: GlobalStyles.PRIMARY_600,
  },
  outlined: {
    ...DefaultTextStyles,
    color: GlobalStyles.PRIMARY_500,
  },
  shadowed: {
    color: GlobalStyles.PRIMARY_500,
  },
})
