import { Orbit } from '@orbit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiResponseError } from '@utils/types/auth'
import { iWidgetList } from '@utils/types/dasboard'
import { AppState } from '../store'
import { Types } from '@orbit'

const initialState: { widgets: iWidgetList } = {
  widgets: null,
  // status: 'idle',
}
export const listWidgets = createAsyncThunk(
  'widgets/list',
  async (
    { role }: { role: string },
    { rejectWithValue }
  ): Promise<{ eid: string; type: string; children?: Array<{ eid: string; type: string }> } | any> => {
    try {
      const response = await Orbit.Services.dashboardService.listWidgets({ role })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)
export const listWidgetData = createAsyncThunk(
  'widget/fetch',
  async (params: Types.Dashboard.iDashboardQueryParms, { rejectWithValue }): Promise<any> => {
    try {
      const response = await Orbit.Services.dashboardService.fetchWidgetData(params)
      return response
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetDashboard: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(listWidgets.fulfilled, (state, action) => {
        state.widgets = action.payload
        // state.status = 'success'
      })
      .addCase(listWidgets.pending, (state) => {
        // state.status = 'loading'
      })
      .addCase(listWidgets.rejected, (state) => {
        // state.status = 'failed'
      })
      .addCase(listWidgetData.fulfilled, (state, action) => {
        // state.roles = action.payload
        // state.status = 'success'
      })
      .addCase(listWidgetData.pending, (state) => {
        // state.status = 'loading'
      })
      .addCase(listWidgetData.rejected, (state) => {
        // state.status = 'failed'
      })
  },
})

export const { resetDashboard } = dashboardSlice.actions
export const { reducer } = dashboardSlice
export const selectDashboard = (state: AppState) => state.dashboard
