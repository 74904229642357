import { GlobalStyles } from '@andromeda'
import { SxProps, paginationItemClasses } from '@mui/material'

const PaginationStyle: SxProps = {
  ul: {
    paddingLeft: '56px',
    paddingRight: '56px',
  },
  'ul > li:first-of-type': {
    position: 'absolute',
    left: 24,
  },
  'ul > li:last-of-type': {
    position: 'absolute',
    right: 24,
  },
  'button.Mui-selected': {
    position: 'relative',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    fontSize: 14,
  },
  'button.Mui-selected:hover': {
    backgroundColor: 'transparent',
  },
  'button.Mui-selected::after': {
    position: 'absolute',
    content: '""',
    display: 'block',
    left: 0,
    bottom: 0,
    height: 3,
    width: '100%',
    backgroundColor: GlobalStyles.PRIMARY_500,
    borderRadius: 5,
    zIndex: 2,
  },
  [`button.${paginationItemClasses.previousNext}`]: {
    border: `1px solid ${GlobalStyles.SLATE_100}`,
    borderRadius: '50%',
  },
  [`button.${paginationItemClasses.previousNext}:not(.Mui-disabled)`]: {
    color: GlobalStyles.PRIMARY_500,
  },
}

export { PaginationStyle }
