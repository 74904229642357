import { StyleProp, TextInputProps as NativeTextInputProps, TextStyle, ViewStyle } from 'react-native'
import { MutableRefObject } from 'react'
import { TextSizes } from '..'
import { SpaceType } from '..'

export enum InputDefaults {
  TYPE = 'primary',
  SECONDARY = 'secondary',
  SIZE = 'md',
}

export type InputTypes = 'primary' | 'secondary' | 'shadow'

export interface InputProps extends NativeTextInputProps {
  ref?: MutableRefObject<any>
  name?: string
  type?: InputTypes
  size?: TextSizes
  style?: StyleProp<TextStyle>
  wrapperStyle?: StyleProp<ViewStyle>
  label?: string
  disabled?: boolean
  labelStyle?: StyleProp<TextStyle>
  leftElement?: JSX.Element | JSX.Element[]
  rightElement?: JSX.Element | JSX.Element[]
  errorMessage?: string | undefined
  errorMessageStyle?: StyleProp<TextStyle>
  space?: SpaceType
  mainWrapperStyle?: StyleProp<ViewStyle>
}

export enum PhoneInputDefaults {
  PHONE_LABEL = 'Phone',
  PLACEHOLDER = '123 445 6789',
  DEFAULT_VALUE = '',
}

export const PhoneInputDefaultCountry = {
  id: 12,
  countryCode: 'AU',
  countryCallCode: 61,
  label: 'AU (+61)',
  value: 'Australia +61',
}

export type CountryType = {
  id: number
  countryCode: string
  countryCallCode: number
  value: string
}

export interface PhoneInputProps extends InputProps {
  phoneLabel?: string
  inputStyles?: StyleProp<TextStyle>
  inputWrapper?: StyleProp<ViewStyle>
  defaultValue?: string
  placeholder?: string
  onChange: (e: any) => void
  onCountrySelect: (e: CountryType) => void
  dropdownContainerStyle?: StyleProp<ViewStyle>
  dropdownStyle?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
  mainWrapperStyle?: StyleProp<ViewStyle>
}

export type InputComponentType = ((props: InputProps) => JSX.Element) & {
  Phone: React.MemoExoticComponent<(props: PhoneInputProps) => JSX.Element>
}
