import { Agreements, API, Deliverable, Job } from '../types'
import { HttpClient } from './api.service'

export const deliverableService = {
  create: ({ payload }: { payload: Deliverable.iDeliverablePayload }) => {
    return HttpClient.post<Deliverable.iDeliverablePayload, Deliverable.iDeliverable>({
      endpoint: `${API.DeliverableEndpoints.DELIVERABLE}`,
      payload,
    })
  },
  listTypes: () => {
    return HttpClient.get<null, Deliverable.iDeliverableCategory[]>({
      endpoint: `${API.DeliverableEndpoints.TYPES}`,
    })
  },
  get: ({ eid }: { eid: string }) => {
    return HttpClient.get<null, Deliverable.iDeliverable>({
      endpoint: `${API.DeliverableEndpoints.DELIVERABLE}/${eid}`,
      payload: null,
    })
  },
  update: ({ eid, payload }: { eid: string; payload: Deliverable.iDeliverablePayload }) => {
    return HttpClient.put<Deliverable.iDeliverablePayload, Deliverable.iDeliverable>({
      endpoint: `${API.DeliverableEndpoints.DELIVERABLE}/${eid}`,
      payload,
    })
  },
  delete: ({ eid }: { eid: string }) => {
    return HttpClient.delete<null, null>({
      endpoint: `${API.DeliverableEndpoints.DELIVERABLE}/${eid}`,
      payload: null,
    })
  },
  archive: ({ eid }: { eid: string }) => {
    return HttpClient.put<null, null>({
      endpoint: `${API.DeliverableEndpoints.DELIVERABLE}/${eid}/archive`,
      payload: null,
    })
  },
  createMultiple: ({ payload }: { payload: Deliverable.iMultipleDeliverablePayload }) => {
    return HttpClient.post<Deliverable.iMultipleDeliverablePayload, Deliverable.iDeliverable[]>({
      endpoint: `${API.DeliverableEndpoints.MULTIPLE}`,
      payload,
    })
  },
  markComplete: ({ payload }: { payload: Deliverable.iMarkCompleteDeliverablePayload }) => {
    const { eid } = payload

    const endpoint = API.DeliverableEndpoints.MARK_COMPLETED.replace(':deliverable', eid)

    return HttpClient.put<Deliverable.iMarkCompleteDeliverablePayload, Job.iJob>({
      endpoint,
      payload,
    })
  },
  publish: ({ eid }: { eid: string }) => {
    const endpoint = API.DeliverableEndpoints.PUBLISH.replace(':eid', eid)
    return HttpClient.put<null, Agreements.iJobAgreementList>({
      endpoint,
    })
  },
  assignTalent: ({ payload }: { payload: Deliverable.iAssignTalentToDeliverablePayload }) => {
    const endpoint = API.DeliverableEndpoints.ASSIGN_TALENT
    return HttpClient.post<Deliverable.iAssignTalentToDeliverablePayload, Agreements.iJobAgreementList>({
      endpoint,
      payload,
    })
  },
  generateFileUploadUrl: ({ eid, file_names }: { eid: string; file_names: string[] }) => {
    return HttpClient.post<{ file_names: string[] }, Job.iFile[]>({
      endpoint: API.DeliverableEndpoints.GENERATE_FILE_UPLOAD_URL.replace(':eid', eid),
      payload: { file_names },
    })
  },
  generateFileDownloadUrl: ({ eid, payload }: { eid: string; payload: Job.iAttachFilesPayload }) => {
    return HttpClient.post<Job.iAttachFilesPayload, Job.iFile[]>({
      endpoint: API.DeliverableEndpoints.GENERATE_FILE_DONWLOAD_URL.replace(':eid', eid),
      payload,
    })
  },
  attachFiles: ({ eid, payload }: { eid: string; payload: Job.iAttachFilesPayload }) => {
    return HttpClient.put<Job.iAttachFilesPayload, Deliverable.iDeliverable>({
      endpoint: API.DeliverableEndpoints.ATTACH_FILES.replace(':eid', eid),
      payload,
    })
  },
  detachFiles: ({ eid, payload }: { eid: string; payload: Job.iAttachFilesPayload }) => {
    return HttpClient.put<Job.iAttachFilesPayload, Deliverable.iDeliverable>({
      endpoint: API.DeliverableEndpoints.DETACH_FILES.replace(':eid', eid),
      payload,
    })
  },
}
