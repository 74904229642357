import { Text, View } from '@andromeda'
import { Stack } from '@mui/material'
import { motion } from 'framer-motion'
import Image from 'next/legacy/image'
import PickstarLogo from 'public/pickstar-logo.png'

export const Loader = () => {
  return (
    <Stack
      flex={1}
      justifyContent="center"
      minHeight="50vh"
      style={{
        backgroundColor: 'transparent',
      }}>
      <motion.div
        transition={{ repeat: Infinity, duration: 2 }}
        animate={{
          opacity: [1, 0, 0, 1, 1],
        }}>
        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
          }}>
          <View style={{ height: 50, width: 50 }}>
            <Image alt="WorkspaceLogo" src={PickstarLogo} layout="responsive" />
          </View>
          <Text>Loading content...</Text>
        </View>
      </motion.div>
    </Stack>
  )
}
