import { Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectJobs, setDraftJobUserPayload, leaveJob, syncJobUsers } from '@redux/reducers/jobsReducer'
import { selectRoles } from '@redux/reducers/rolesReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { JobPermission } from '@utils/enum/permissions'
import { hasPermission } from '@utils/functions/permission'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { AddUsersModalContentProps } from './AddUsersModal.context'
import AddUsersModalContent from './add-users-content'
import { useRouter } from 'next/router'
import { useQueryClient } from '@tanstack/react-query'

const AddUserToJobModal = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const router = useRouter()

  const { roles } = useAppSelector(selectRoles)
  const { viewedJobUsers, syncJobUsersStatus } = useAppSelector(selectJobs)

  const { options, closeModal } = useContext(ModalContext)
  const { workspace, activeWorkspace } = useAppSelector(selectWorkspace)
  const [job, setJob] = useState<Types.Job.iJob | undefined>(undefined)
  const modalProps = options?.modalProps?.[ModalPages.ADD_USERS_TO_JOB] || {}

  const { requestId } = router.query
  const guestRole = useMemo(() => (roles ? (roles.find((role) => role.name === 'guest') as Types.Roles.iRole) : undefined), [roles])

  const [newInvitedUsers, setNewInvitedUsers] = useState<Array<Types.Invite.iInviteCreatePayload>>([])

  /**
   * Permissions
   */
  const hasUpdatePermissions = hasPermission({
    permissions: job?.permissions,
    allowedPermissions: [JobPermission.UPDATE],
    checkMethod: 'some',
  })

  const hasAddUserPermissions = hasPermission({
    permissions: job?.permissions,
    allowedPermissions: [JobPermission.ADD_USER],
    checkMethod: 'some',
  })

  const _saveUsers = (draftSelection: Types.Job.iDeliverableUsersResponse) => {
    if (!hasUpdatePermissions || !hasAddUserPermissions) return false
    // doSaveUsers
    const jobSyncPayload: Types.Job.iJobSyncPayload = {
      viewers: draftSelection?.users?.filter((user) => user.ownership_type === 'VIEWER').map((user) => user.eid),
      owners: draftSelection?.users?.filter((user) => user.ownership_type === 'OWNER').map((user) => user.eid),
    }

    if (requestId) {
      toast
        .promise(dispatch(syncJobUsers({ eid: requestId as string, payload: jobSyncPayload })), {
          pending: 'Saving user selection...',
        })
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            if (requestId) {
              queryClient.invalidateQueries({ queryKey: ['jobUsers', activeWorkspace?.slug, requestId], type: 'active' })
              setNewInvitedUsers([])
              toast.success('Users saved successfully.', { autoClose: 500 })
            }
            closeModal(ModalPages.ADD_USERS_TO_JOB)
          }
          if (response.meta.requestStatus === 'rejected') {
            toast.error('An error occured saving the users. Please try again.', { autoClose: 1000 })
          }
        })
    } else {
      dispatch(setDraftJobUserPayload(draftSelection))
      closeModal(ModalPages.ADD_USERS_TO_JOB)
    }
  }

  const _handleUserInvite = (
    draftSelection: Types.Job.iDeliverableUsersResponse,
    inviteData: Types.Invite.iInviteCreatePayload,
    onFulfilled: (data: Types.Job.iDeliverableUsersResponse) => void
  ) => {
    if (guestRole && !inviteData?.roles?.some((roleId) => roleId === guestRole?.eid)) {
      inviteData.roles = [...(inviteData.roles ?? []), guestRole?.eid]
    }

    const jobSyncPayload: Types.Job.iJobSyncPayload = {
      owners: draftSelection?.users?.filter((user) => user.ownership_type === 'OWNER').map((user) => user.eid),
      viewers: draftSelection?.users?.filter((user) => user.ownership_type === 'VIEWER').map((user) => user.eid),
      // @ts-expect-error
      invites: [inviteData],
    }

    if (requestId) {
      toast
        .promise(dispatch(syncJobUsers({ eid: requestId as string, payload: jobSyncPayload })), {
          pending: 'Creating invite..',

          error: 'Failed to select users.',
        })
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            toast.success('Users saved successfully.')
            queryClient.invalidateQueries({ queryKey: ['jobUsers', activeWorkspace?.slug, requestId], type: 'active' })
            setNewInvitedUsers((prevData) => [...prevData, inviteData])
            onFulfilled && onFulfilled(response.payload)
          } else {
            toast.error(response.payload.message)
          }
        })
    }
  }

  const _handleLeave: AddUsersModalContentProps['handleLeave'] = (user, drafts, setDrafts) => {
    if (requestId) {
      toast
        .promise(dispatch(leaveJob({ eid: requestId as string })), {
          pending: 'Leaving job...',
          success: 'Left job successfully!',
          error: 'Error leaving job.',
        })
        .then((res) => {
          closeModal(ModalPages.ADD_USERS_TO_JOB)
          setDrafts({
            ...drafts,
            users: drafts.users.filter((dUser) => dUser.eid !== user.eid),
          })
          router.replace({
            pathname: '/[workspace]/jobs',
            query: { workspace },
          })
        })
    } else {
      toast.error('You cannot leave job while creating...')
    }
  }

  return (
    <AddUsersModalContent
      enableCreateUser
      requestId={requestId as string}
      newInvitedUsers={newInvitedUsers}
      modalContext={ModalPages.ADD_USERS_TO_JOB}
      redirectRoute={`/${workspace}/directory/users`}
      currentSelectedUsers={viewedJobUsers!}
      hasUpdatePermissions={hasUpdatePermissions}
      hasAddUserPermissions={hasUpdatePermissions}
      saveUsersStatus={syncJobUsersStatus}
      showInviteRoleSelection={false}
      eventTypes={{
        invite: TrackingEventEnums.Invite.INVITE_USER_TO_JOB,
        inviteConfirmed: TrackingEventEnums.Invite.INVITE_USER_TO_JOB_CONFIRM,
      }}
      inviteAdditionalInfo={`
        You will receive an alert when this new user has completed the sign up process.
        They will be added automatically to this job as well.
      `}
      handleSaveUsers={_saveUsers}
      handleInvite={_handleUserInvite}
      handleLeave={_handleLeave}
    />
  )
}

export default AddUserToJobModal
