import { GlobalStyles, Tooltip } from '@andromeda'
import { Stack, Typography, StackProps } from '@mui/material'

export const Row = ({
  label,
  tooltipText,
  noBorder = false,
  containerProps,
  labelContainerProps,
  ...props
}: StackProps & {
  label: string
  tooltipText?: string
  noBorder?: boolean
  containerProps?: StackProps
  labelContainerProps?: StackProps
}) => (
  <Stack
    direction="row"
    className="items-center"
    columnGap="8px"
    padding="12px 24px"
    borderBottom={noBorder ? '' : `1px solid ${GlobalStyles.SLATE_100}`}
    {...containerProps}>
    <Stack direction="row" width="130px" columnGap="4px" {...labelContainerProps}>
      <Typography fontSize="14px" fontWeight="500" color={GlobalStyles.MONO_BLACK}>
        {label}
      </Typography>
      <Tooltip text={tooltipText ?? ''} />
    </Stack>
    <Stack flex={1} maxWidth="calc(100% - 142px)" {...props} />
  </Stack>
)
