import { TextProps as NativeTextProps } from 'react-native'

export enum TextDefaults {
  TYPE = 'paragraph',
  SIZE = 'base',
}

export type TextTypes = 'heading' | 'paragraph'

export type TextSizes = 'lg' | 'base' | 'sm' | 'md' | 'tiny' | 'xl'

export interface TextProps extends NativeTextProps {
  type?: TextTypes
  text?: string
  size?: TextSizes
  bold?: boolean
  active?: boolean
  activeColor?: string
}
