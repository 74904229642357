import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { useCallback, useEffect } from 'react'

export const useEscape = (callbackFn: () => void, isEditable: boolean, context: string | undefined = 'job') => {
  const { workspace } = useAppSelector(selectWorkspace)

  const cancelEdit = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        callbackFn()

        trackEvent({
          event: TrackingEventEnums.Others.CANCEL_EDIT,
          eventProperties: {
            trigger: 'Esc',
            workspace,
            context,
          },
        })
      }
    },
    [callbackFn, context, workspace]
  )

  useEffect(() => {
    if (isEditable)
      trackEvent({
        event: TrackingEventEnums.Others.EDIT_MODE,
        eventProperties: { workspace, context },
      })
  }, [isEditable])

  useEffect(() => {
    document.addEventListener('keydown', cancelEdit, false)

    return () => {
      document.removeEventListener('keydown', cancelEdit, false)
    }
  }, [cancelEdit])
}
