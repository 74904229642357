import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectTalents } from '@redux/reducers/talentReducer'
import { listWorkspaceTalents } from '@redux/reducers/talentReducer'
import { useEffect, useMemo } from 'react'

export const useTalents = () => {
  const dispatch = useAppDispatch()
  const { talents } = useAppSelector(selectTalents)

  const talentOptions = useMemo(
    () => (talents ? talents.map((talent) => ({ eid: talent.eid, value: talent.display_name, profile_image: talent.profile_image })) : []),
    [talents]
  )

  useEffect(() => {
    dispatch(listWorkspaceTalents({ params: { page: '1', per_page: '50' } }))
  }, [dispatch])

  return { talentOptions }
}
