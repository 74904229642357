import { StyleProp, StyleSheet } from 'react-native'
import { GlobalStyles } from '../globalStyles'

const DefaultStyles = {
  justifyContent: GlobalStyles.JUSTIFY_CONTENT,
  width: GlobalStyles.STATUS_SIZES.WIDTH,
  height: GlobalStyles.STATUS_SIZES.HEIGHT,
  borderRadius: GlobalStyles.STATUS_BORDER_RADIUS,
} as StyleProp<object> as Record<string, number>

export const StatusStyles = StyleSheet.create({
  DEFAULT: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_INFO,
  },
  DRAFT: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_YIELD,
  },
  LIVE: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_POSITIVE,
  },
  COMPLETED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_INFO,
  },
  CANCELLED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
  },
  BOOKED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_POSITIVE,
  },
  OVERDUE: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
  },
  NEW: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
  },
  APPLIED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_POSITIVE,
  },
  DECLINED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
  },
  REMOVED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
  },
  WITHDRAWN: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
  },
  ARCHIVED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_INFO,
  },
})
