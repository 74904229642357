import { CardV2, GlobalStyles, Icon, StackV2, Text, Tooltip } from '@andromeda'
import IconButton from '@mui/material/IconButton'
import { StyleProp, StyleSheet, TextStyle } from 'react-native'
import { currencyFormatter } from '../helpers'
import { JobWidgetProps } from './types'

const LabelWithButton = ({
  label,
  styles,
  icon,
  info,
  onPress,
}: {
  label: string
  styles?: StyleProp<TextStyle>
  icon?: JSX.Element
  info?: string
  onPress?: () => void
}) => (
  <StackV2 width={'100%'} direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
    <StackV2 direction={'row'} flex={1} alignItems={'center'} spacing={2}>
      {icon && icon}
      <Text
        style={
          styles || {
            color: GlobalStyles.MONO_BLACK,
            fontSize: GlobalStyles.FONT_SIZES.XTRA_LARGE,
            fontWeight: GlobalStyles.FONT_WEIGHT_MEDIUM,
          }
        }>
        {label}
      </Text>
      {info && <Tooltip text={info} />}
    </StackV2>
    {true && (
      <IconButton onClick={onPress} style={{ height: 40, width: 40, borderRadius: 8 }}>
        <Icon.ChevronRight color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />
      </IconButton>
    )}
  </StackV2>
)

const BottomLabel = ({ label, info }: { label: string; info?: string }) => (
  <StackV2 direction={'row'} alignItems={'center'} spacing={2} flexWrap={'wrap'}>
    <Text
      style={{
        fontSize: GlobalStyles.FONT_SIZES.SMALL,
        color: GlobalStyles.MONO_BLACK,
        fontWeight: GlobalStyles.FONT_WEIGHT_REGULAR,
      }}>
      {label}
    </Text>
    {info && <Tooltip text={info} />}
  </StackV2>
)
const JobOverview = ({ stat, opp, total }: JobWidgetProps) => {
  return (
    <CardV2 className="widget-content" style={{ flex: 1, borderRadius: GlobalStyles.BORDER_RADIUS }}>
      <StackV2
        alignItems="flex-start"
        direction="column"
        style={{
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderColor: GlobalStyles.SLATE_100,
        }}>
        <LabelWithButton label={stat.label} icon={stat.icon} info={stat.info} onPress={stat.onPress} />
        <Text
          type="heading"
          style={{
            fontSize: 80,
            color: GlobalStyles.MONO_BLACK,
          }}
          bold>
          {stat.count}
        </Text>
      </StackV2>
      <StackV2 height={'auto'} direction="row" spacing={2}>
        {opp.map(({ label, count, category, info, onPress }, index) => {
          return (
            <StackV2
              flex={2}
              key={`heading-${index}`}
              direction="column"
              alignItems="flex-start"
              style={{
                borderRightWidth: index !== opp.length - 1 ? StyleSheet.hairlineWidth : 0,
              }}>
              <LabelWithButton
                label={category}
                styles={{
                  fontSize: GlobalStyles.FONT_SIZES.TINY,
                  color: GlobalStyles.MONO_BLACK,
                  fontWeight: GlobalStyles.FONT_WEIGHT_REGULAR,
                }}
                onPress={onPress}
              />
              <Text
                type="heading"
                style={{
                  fontSize: 32,
                  color: GlobalStyles.MONO_BLACK,
                  lineHeight: 40,
                }}
                bold>
                {count}
              </Text>
              <BottomLabel label={label} info={info} />
            </StackV2>
          )
        })}
      </StackV2>
      <StackV2
        key="heading"
        direction="column"
        alignItems="flex-start"
        spacing="none"
        style={{
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderColor: GlobalStyles.SLATE_100,
        }}>
        <StackV2 direction="row" spacing={2} alignItems={'center'}>
          <Text
            style={{
              fontSize: 32,
              fontWeight: GlobalStyles.FONT_WEIGHT_MEDIUM,
              color: GlobalStyles.MONO_BLACK,
            }}>
            {currencyFormatter(total.amount, total.currency).toLocaleString()}
          </Text>
          <Text
            style={{
              fontSize: GlobalStyles.FONT_SIZES.SMALL,
              color: GlobalStyles.SLATE_700,
              fontWeight: GlobalStyles.FONT_WEIGHT_MEDIUM,
            }}>
            {total.currency}
          </Text>
        </StackV2>
        <BottomLabel label={total.label} info={total.info} />
      </StackV2>
    </CardV2>
  )
}

export default JobOverview
