import { StyleSheet, StyleProp } from 'react-native'
import { GlobalStyles } from '../globalStyles'

const DefaultStyles = {
  width: '100%',
  display: 'flex',
  position: 'relative',
  fontSize: GlobalStyles.FONT_SIZES.SMALL,
  paddingTop: 15,
  paddingBottom: 15,
  paddingLeft: 17,
  paddingRight: 17,
  color: GlobalStyles.SLATE_700,
  // flexDirection: 'row',
  // alignItems: 'center',
  textAlign: 'left',
  lineHeight: 18,
  borderRadius: GlobalStyles.BORDER_RADIUS,
  borderColor: GlobalStyles.SLATE_300,
  outlineStyle: 'none',
} as StyleProp<object> as Record<string, number>

export const InputStyles = StyleSheet.create({
  primary: {
    ...DefaultStyles,
    borderWidth: 1,
    borderRadius: 10,
    ':disabled': {
      backgroundColor: GlobalStyles.SLATE_50,
    },
  },
  secondary: {
    ...DefaultStyles,
    margin: 0,
    borderRadius: 10,
    borderWidth: 1,
  },
  shadow: {
    ...DefaultStyles,
    margin: 0,
    borderRadius: 10,
    borderWidth: 0,
  },
  label: {
    ...DefaultStyles,
    fontSize: 14,
    marginBottom: 4,
  },
  container: {
    ...DefaultStyles,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    borderWidth: 2,
    borderColor: GlobalStyles.COLOR_TRANSPARENT,
    width: '100%',
  },
  inputWrapper: {
    flex: 1,
    paddingHorizontal: 0,
  },
  inputContainer: {
    fontSize: 14,
  },
  inputIconPositionRight: {
    display: 'flex',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
  },
  disabled: {
    opacity: 0.5,
  },
})

export const PhoneInputStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    borderWidth: 2,
    borderColor: GlobalStyles.MONO_WHITE,
    width: '100%',
  },
  focusedContainer: {
    borderWidth: 2,
    borderColor: GlobalStyles.SLATE_300,
  },
  countryDropdown: {
    width: 'auto',
  },
  dropdownContainer: {
    flex: 0,
    borderWidth: 0,
    borderRadius: 0,
    minWidth: 'auto',
    paddingHorizontal: 0,
  },
  inputWrapper: {
    flex: 1,
  },
  inputLabel: {
    fontSize: GlobalStyles.FONT_SIZES.SMALL,
    width: 100,
  },
  inputContainer: {
    borderWidth: 0,
    borderRadius: 0,
    fontSize: GlobalStyles.FONT_SIZES.SMALL,
    outlineStyle: 'none',
    paddingLeft: 0,
  },
})
