import moment from 'moment'

export const getDashedDate = (datetime: string) => {
  if (datetime) {
    return datetime.split(' ')[0]
  }
  return ''
}

export const getDateDiffFromCurrentDate = (datetime: Date) => {
  const from = new Date(datetime)
  const to = new Date()
  return {
    years: moment(to).diff(from, 'years'),
    months: moment(to).diff(from, 'months'),
    weeks: moment(to).diff(from, 'weeks'),
    days: moment(to).diff(from, 'days'),
    hours: moment(to).diff(from, 'hours'),
    minutes: moment(to).diff(from, 'minutes'),
    seconds: moment(to).diff(from, 'seconds'),
  }
}

export const getInterval = (datetime: Date) => {
  const interval = getDateDiffFromCurrentDate(datetime)
  let message = ''
  if (interval.years && interval.years > 0) {
    message = interval.years === 1 ? `a year ago` : `${interval.years} years ago`
  } else if (interval.months && interval.months > 0) {
    message = interval.months === 1 ? `a month ago` : `${interval.months} months ago`
  } else if (interval.weeks && interval.weeks > 0) {
    message = interval.weeks === 1 ? `a week ago` : `${interval.weeks} weeks ago`
  } else if (interval.days && interval.days > 0) {
    message = interval.days === 1 ? `1 day ago` : `${interval.days} days ago`
  } else if (interval.hours && interval.hours > 0) {
    message = interval.hours === 1 ? `1 hour ago` : `${interval.hours} hours ago`
  } else if (interval.minutes && interval.minutes > 0) {
    message = interval.minutes === 1 ? `1 minute ago` : `${interval.minutes} minutes ago`
  } else if (interval.seconds && interval.seconds > 10) {
    message = 'few seconds ago'
  } else {
    message = 'just now'
  }

  return {
    interval,
    message,
  }
}
