// @ts-nocheck
import { Button, Card, GlobalStyles, Icon, ScrollView, Stack, Text } from '@andromeda'
import { FlyoutModalHeader, Section } from '@components'
import { AppStyles } from '@styles'
import { ModalContext } from '@utils/context/Modal.context'
import { useContext } from 'react'
import { CardProposalProps } from 'src/andromeda/Card/types'

const SENT_PROPOSALS = [
  {
    title: 'Addidas 40th birthday celebration',
    status: {
      type: 'live',
      text: 'Draft',
    },
    details: {
      proposalDate: new Date(),
      dateFrom: new Date(),
      dateTo: new Date('12/20/2022'),
      location: 'South Wales',
      createdDate: new Date(),
      ownerName: 'John Mason',
      budgetGuide: '$15,000 - $25,000',
      organisationName: 'Organisation Name',
      tags: [
        {
          type: 'secondary',
          text: 'Tag Label',
          size: 'lg',
        },
        {
          type: 'secondary',
          text: 'Tag Label',
          size: 'lg',
        },
      ],
      labels: [
        {
          type: 'default',
          text: 'New',
          size: 'sm',
        },
        {
          type: 'outlined',
          text: 'Shortlisted (6)',
          icon: 'Bookmark',
          size: 'sm',
        },
        {
          type: 'declined',
          text: 'Withdrawn',
          size: 'sm',
        },
      ],
      starsCount: 0,
      maxStarsCount: 2,
    },
  },
  {
    title: 'Some Job Celebration Anniversary 20th',
    status: {
      type: 'live',
      text: 'Draft',
    },
    details: {
      proposalDate: new Date(),
      dateFrom: new Date(),
      dateTo: new Date('12/20/2022'),
      location: 'South Wales',
      createdDate: new Date(),
      ownerName: 'John Mason',
      budgetGuide: '$15,000 - $25,000',
      organisationName: 'Organisation Name',
      tags: [
        {
          type: 'secondary',
          text: 'Tag Label',
          size: 'lg',
        },
        {
          type: 'secondary',
          text: 'Tag Label',
          size: 'lg',
        },
      ],
      labels: [
        {
          type: 'default',
          text: 'New',
          size: 'sm',
        },
        {
          type: 'outlined',
          text: 'Shortlisted (6)',
          icon: 'Bookmark',
          size: 'sm',
        },
        {
          type: 'declined',
          text: 'Withdrawn',
          size: 'sm',
        },
      ],
      starsCount: 0,
      maxStarsCount: 2,
    },
  },
  {
    title: 'Some Job Celebration Anniversary 20th',
    status: {
      type: 'live',
      text: 'Draft',
    },
    details: {
      proposalDate: new Date(),
      dateFrom: new Date(),
      dateTo: new Date('12/20/2022'),
      location: 'South Wales',
      createdDate: new Date(),
      ownerName: 'John Mason',
      budgetGuide: '$15,000 - $25,000',
      organisationName: 'Organisation Name',
      tags: [
        {
          type: 'secondary',
          text: 'Tag Label',
          size: 'lg',
        },
        {
          type: 'secondary',
          text: 'Tag Label',
          size: 'lg',
        },
      ],
      labels: [
        {
          type: 'default',
          text: 'New',
          size: 'sm',
        },
        {
          type: 'outlined',
          text: 'Shortlisted (6)',
          icon: 'Bookmark',
          size: 'sm',
        },
        {
          type: 'declined',
          text: 'Withdrawn',
          size: 'sm',
        },
      ],
      starsCount: 0,
      maxStarsCount: 2,
    },
  },
]

const SentProposals = () => {
  const { toggleModal } = useContext(ModalContext)
  const headerButtons = [
    <Button key="job-proposal" type="primary" space="2xs" style={{ paddingHorizontal: 20 }} onPress={() => toggleModal('jobProposal')}>
      <Text>
        <Icon.Edit color={GlobalStyles.MONO_WHITE} size={AppStyles.BUTTON_SIZE_SMALL} />
      </Text>
      <Text style={{ color: GlobalStyles.MONO_WHITE }}>New Proposal</Text>
    </Button>,
  ]

  const _renderProposals = () => {
    return SENT_PROPOSALS.map((proposal, index) => {
      return <Card.Proposal key={index} {...(proposal as CardProposalProps)} />
    })
  }
  return (
    <Stack direction="column" space="none" style={{ backgroundColor: GlobalStyles.SLATE_50 }}>
      <FlyoutModalHeader
        key={'job-proposal-sent-flyout'}
        returnText="Back to Job"
        contextModal={'jobProposal'}
        headerButtons={headerButtons}
      />
      <ScrollView
        style={{
          width: '100%',
          height: '100%',
          paddingBottom: 20,
        }}
        contentContainerStyle={{ minHeight: '100%' }}>
        <Section title="Proposals" icon={<Icon.FileText />}>
          <Stack direction="column" align="flex-start" space="sm">
            <Text type="heading" style={{ fontSize: AppStyles.FONT_SIZES.LIST_COUNT, color: GlobalStyles.MONO_BLACK }}>
              {SENT_PROPOSALS.length}
            </Text>
            <Stack direction="column" align={'stretch'}>
              {_renderProposals()}
            </Stack>
          </Stack>
        </Section>
      </ScrollView>
    </Stack>
  )
}

export { SentProposals }
