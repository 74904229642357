import { Avatar, AvatarGroup, GlobalStyles, Text, Tooltip } from '@andromeda'
import { Types } from '@orbit'
import { Button, Stack } from '@mui/material'
import { MouseEvent, useContext, useMemo } from 'react'
import AddUsersModalContext from '../AddUsersModal.context'
import { getInitials } from '@utils/functions/helperFunctions'
import { ModalContext } from '@utils/context/Modal.context'

type SelectedUserProps = {
  hasUpdatePermissions: boolean
  draftSelection: Types.Job.iJobUsersResponse
  onConfirmSelection: (e: MouseEvent<HTMLButtonElement>) => void
}

const SelectedUsers = ({ hasUpdatePermissions, draftSelection, onConfirmSelection }: SelectedUserProps) => {
  const {
    modalProps: { saveUsersStatus, currentSelectedUsers, modalContext },
    newInvitedUsers,
  } = useContext(AddUsersModalContext)
  const { closeModal } = useContext(ModalContext)
  const _getTalentProfileImage = (user: Types.User.iUser) => {
    if (typeof user.profile_image === 'string') {
      return user.profile_image
    } else if (user.profile_image && user.profile_image.full_url) {
      return user.profile_image.full_url
    }
    return undefined
  }

  const usersList = useMemo(() => {
    if (draftSelection) {
      const { users, invites } = draftSelection
      return [...(users || []), ...(invites || [])].filter((user, index, currentArr) => {
        return currentArr.findIndex((currUser) => currUser.eid === user.eid) === index
      })
    }

    return []
  }, [draftSelection])

  const hasChanged = useMemo(() => {
    if (currentSelectedUsers) {
      const combinedEids = currentSelectedUsers.invites.concat(currentSelectedUsers.users).map((user) => user.eid)

      // Check if the length of combinedEids is equal to the length of usersList
      // then check if each user in usersList is not in currentSelectedUsers
      if (combinedEids.length === usersList.length) {
        return !usersList.every((newUser) => combinedEids.includes(newUser.eid))
      }

      return true
    }

    return false
  }, [currentSelectedUsers, usersList])

  return (
    <Stack
      direction="row"
      justifyContent={'space-between'}
      alignItems={'center'}
      height={'auto'}
      spacing={2}
      className="dialog-footer"
      style={{
        borderTop: `1px solid ${GlobalStyles.SLATE_100}`,
        borderBottomLeftRadius: GlobalStyles.BORDER_RADIUS,
        borderBottomRightRadius: GlobalStyles.BORDER_RADIUS,
      }}>
      {hasUpdatePermissions && (
        <>
          <Stack flex={1} direction="row" height={'auto'} alignItems="flex-start" width={'auto'} style={{ flexWrap: 'wrap' }}>
            <AvatarGroup offset={5} extraAvatarProps={{ size: 'xs' }}>
              {usersList.map((user) => (
                <Tooltip key={user.eid} text={user.name ?? ''} showIcon={false}>
                  <Avatar
                    size={'md'}
                    style={{
                      borderWidth: 2,
                      borderColor: GlobalStyles.MONO_WHITE,
                      alignItems: 'center',
                    }}
                    placeHolder={
                      <Text style={{ textAlign: 'center', fontSize: GlobalStyles.FONT_SIZES.MEDIUM }}>{getInitials(user.name)}</Text>
                    }
                    image={_getTalentProfileImage(user)}
                  />
                </Tooltip>
              ))}
            </AvatarGroup>
          </Stack>
          <Stack alignItems={'flex-start'} height={'auto'} justifyContent={'flex-end'}>
            <Button
              variant="contained"
              onClick={(e) => {
                if (hasChanged) {
                  onConfirmSelection(e)
                } else {
                  modalContext && closeModal(modalContext)
                }
              }}
              disabled={saveUsersStatus === 'loading' || !usersList.length || (!newInvitedUsers.length && !hasChanged)}>
              {`Confirm Selection ${usersList.length ? `(${usersList.length})` : ''}`}
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  )
}

export { SelectedUsers }
