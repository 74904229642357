import { StyleSheet, StyleProp } from 'react-native'
import { GlobalStyles } from '../globalStyles'

const DefaultStyles = {
  width: '100%',
  display: 'flex',
  borderColor: GlobalStyles.SLATE_100,
} as StyleProp<object> as Record<string, number>

export const StackStyles = StyleSheet.create({
  default: {
    ...DefaultStyles,
  },
  '2xs': {
    width: GlobalStyles.PADDING_SIZES.xxs,
  },
  xs: {
    width: GlobalStyles.PADDING_SIZES['xs'],
  },
  sm: {
    width: GlobalStyles.PADDING_SIZES['sm'],
  },
  md: {
    width: GlobalStyles.PADDING_SIZES['md'],
  },
  lg: {
    width: GlobalStyles.PADDING_SIZES['lg'],
  },
  xl: {
    width: GlobalStyles.PADDING_SIZES['xl'],
  },
  '2xl': {
    width: GlobalStyles.PADDING_SIZES.xxl,
  },
  none: {
    width: GlobalStyles.PADDING_SIZES['none'],
  },
  bordered: {
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  spaced: {
    borderLeftWidth: 1,
  },
  roundedLeft: {
    borderTopLeftRadius: GlobalStyles.BORDER_RADIUS,
    borderBottomLeftRadius: GlobalStyles.BORDER_RADIUS,
  },
  roundedRight: {
    borderTopRightRadius: GlobalStyles.BORDER_RADIUS,
    borderBottomRightRadius: GlobalStyles.BORDER_RADIUS,
  },
})

export const StackColumnStyles = StyleSheet.create({
  default: {
    ...DefaultStyles,
  },
  '2xs': {
    height: GlobalStyles.PADDING_SIZES.xxs,
  },
  xs: {
    height: GlobalStyles.PADDING_SIZES['xs'],
  },
  sm: {
    height: GlobalStyles.PADDING_SIZES['sm'],
  },
  md: {
    height: GlobalStyles.PADDING_SIZES['md'],
  },
  lg: {
    height: GlobalStyles.PADDING_SIZES['lg'],
  },
  xl: {
    height: GlobalStyles.PADDING_SIZES['xl'],
  },
  '2xl': {
    height: GlobalStyles.PADDING_SIZES.xxl,
  },
  none: {
    height: GlobalStyles.PADDING_SIZES['none'],
  },
  bordered: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  spaced: {
    borderTopWidth: 1,
  },
  roundedTop: {
    borderTopLeftRadius: GlobalStyles.BORDER_RADIUS,
    borderTopRightRadius: GlobalStyles.BORDER_RADIUS,
  },
  roundedBottom: {
    borderBottomLeftRadius: GlobalStyles.BORDER_RADIUS,
    borderBottomRightRadius: GlobalStyles.BORDER_RADIUS,
  },
})
