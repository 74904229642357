import { API, Comment } from '../types'
import { HttpClient } from './api.service'

export const commentService = {
  list: ({ type, eid }: { type: string; eid: string }) => {
    return HttpClient.get<null, [Comment.iComment]>({
      endpoint: `${API.CommentEndpoints.COMMENT}/${type}/${eid}`,
      payload: null,
    })
  },
  get: ({ eid }: { eid: string }) => {
    return HttpClient.get<null, Comment.iComment>({
      endpoint: `${API.CommentEndpoints.COMMENT}/${eid}`,
      payload: null,
    })
  },
  create: ({ type, eid, payload }: { type: string; eid: string; payload: Comment.iCommentPayload }) => {
    return HttpClient.post<Comment.iCommentPayload, Comment.iComment>({
      endpoint: `${API.CommentEndpoints.COMMENT}/${type}/${eid}`,
      payload,
    })
  },
  update: ({ eid, payload }: { eid: string; payload: Comment.iCommentPayload }) => {
    return HttpClient.put<Comment.iCommentPayload, Comment.iComment>({
      endpoint: `${API.CommentEndpoints.COMMENT}/${eid}`,
      payload,
    })
  },
  delete: ({ eid }: { eid: string }) => {
    return HttpClient.delete<null, null>({
      endpoint: `${API.CommentEndpoints.COMMENT}/${eid}`,
      payload: null,
    })
  },
}
