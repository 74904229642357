import * as FeatherIcons from 'react-feather'
import NumberList from './number-list.icon'

// Define a type for your custom icons
type CustomIcons = {
  NumberList: typeof NumberList & { displayName?: string } // displayName is needed for other existing pages that uses Icon
}

// Combine the types from FeatherIcons and CustomIcons
type IconType = typeof FeatherIcons & CustomIcons

// Create the Icon object with the combined types
const Icon: IconType = {
  ...FeatherIcons,
  NumberList,
}

export { Icon }
