import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import {
  AccountReducer,
  AgreementsReducer,
  AuthReducer,
  CommentReducer,
  DeliverableReducer,
  InviteReducer,
  JobsReducer,
  PasswordRecovery,
  RolesReducer,
  TalentProfileReducer,
  TalentReducer,
  UsersReducer,
  WorkspaceReducer,
  NotificationReducer,
  AuditReducer,
  DashboardReducer,
  SearchReducer,
  ImporterReducer,
  IdpReducer,
} from './reducers'
import storage from './storage'

/**
 * Wrap your reducer inside persistReducer if you want to save the state of the reducer
 */
const persistedAuth = persistReducer({ key: 'auth', storage }, AuthReducer)
const persistedWorkspace = persistReducer({ key: 'workspace', storage }, WorkspaceReducer)

export const store = configureStore({
  reducer: {
    auth: persistedAuth,
    jobs: JobsReducer,
    deliverable: DeliverableReducer,
    workspace: persistedWorkspace,
    recovery: PasswordRecovery,
    accounts: AccountReducer,
    comments: CommentReducer,
    talentProfile: TalentProfileReducer,
    talents: TalentReducer,
    agreements: AgreementsReducer,
    users: UsersReducer,
    invite: InviteReducer,
    roles: RolesReducer,
    notifications: NotificationReducer,
    audit: AuditReducer,
    dashboard: DashboardReducer,
    search: SearchReducer,
    importer: ImporterReducer,
    idp: IdpReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

export type AppState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>
