import { Button, FilePicker, GlobalStyles, Icon, Text, View } from '@andromeda'
import { StepSlider } from '@components'
import { Stack } from '@mui/material'
import { ModalContext, ModalTypes } from '@utils/context/Modal.context'
import { useContext, useRef, useState } from 'react'
import { AddProfilePicture } from './add-profile-picture'
import { CropProfilePicture } from './crop-profile-picture'
import { ProfilePicture } from './profile-picture'

const ProfilePictureForm = () => {
  const { closeModal, options } = useContext(ModalContext)
  const avatar = options?.modalProps?.profilePictureForm?.avatar || null
  const [currentStep, setCurrentStep] = useState<number>(0)
  const modalType: ModalTypes = 'profilePictureForm'
  const [profileSrc, setProfileSrc] = useState<string | undefined>(undefined)
  const [profilePicture, setProfilePicture] = useState<string | null>(null)
  const [fileMeta, setFileMeta] = useState<File | null>(null)
  const [remove, setRemove] = useState(false)

  const formTitle = options?.modalProps?.profilePictureForm?.title || 'Profile Picture'

  const _handleAddProfile = () => {
    setCurrentStep(currentStep + 1)
  }

  const _handleUpload = () => {
    setCurrentStep(currentStep + 1)
  }

  const _handleSaveProfile = () => {
    options?.modalProps?.profilePictureForm?._handleAvatarSave({ fileMeta, profilePicture })
    closeModal(modalType)
  }

  const _handlePick = (e: any) => {
    const profileMeta = e.files[0]
    if (profileMeta) {
      setFileMeta(profileMeta)
      const reader = new FileReader()
      reader.addEventListener('load', () => reader.result && setProfileSrc(reader.result.toString()))
      reader.readAsDataURL(profileMeta)
      if (currentStep <= 1) {
        setCurrentStep(currentStep + 1)
      }
    }
  }

  const _handleCrop = (e: any) => {
    setProfilePicture(e)
  }

  const _handleRemoveConfirm = () => {
    closeModal(modalType)
  }

  const _handleRemove = () => {
    setRemove(true)
    setCurrentStep(currentStep + 1)
  }

  const profilePictureRef = useRef()
  const profileAddRef = useRef()
  const profileCropRef = useRef()
  const filePickerOpts = {
    multiple: false,
    fileTypes: '.jpg,.png',
  }
  const profilePictureSteps = [
    {
      title: formTitle,
      content: <ProfilePicture avatar={avatar} key="profile-picture" description={options?.modalProps?.profilePictureForm?.description} />,
      footerButtons: [
        // TODO: uncomment
        // will need to update current endpoint to add remove func
        // avatar ? (
        false ? (
          <Button key="remove" type={'outlined'} style={{ borderRadius: 6 }} onPress={_handleRemove} space="2xs">
            <View>
              <Icon.Trash size={GlobalStyles.FONT_SIZES.MEDIUM} color={GlobalStyles.PRIMARY_500} />
            </View>
            <Text style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL, color: GlobalStyles.PRIMARY_500, textAlign: 'center' }}>Remove</Text>
          </Button>
        ) : (
          <></>
        ),
        <Button
          key="add-profile-picture"
          type={'primary'}
          style={{ borderRadius: 6 }}
          onPress={_handleAddProfile}
          // disabled={props.disabled}
          space="2xs">
          <Icon.Plus size={GlobalStyles.FONT_SIZES.MEDIUM} color={GlobalStyles.MONO_WHITE} />
          <Text style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL, color: GlobalStyles.MONO_WHITE, textAlign: 'center' }}>
            Add {formTitle}
          </Text>
        </Button>,
      ],
      ref: profilePictureRef,
    },
    // TODO: uncomment
    // will need to update current endpoint to add remove func
    // remove
    false
      ? {
          title: 'Remove Profile Picture',
          content: (
            <AddProfilePicture avatar={avatar} key="add-profile-picture" description="This image will be removed from your profile" />
          ),
          footerButtons: [
            <Button key="remove" type={'primary'} style={{ borderRadius: 6 }} onPress={_handleRemoveConfirm} space="2xs">
              <View>
                <Icon.Trash size={GlobalStyles.FONT_SIZES.MEDIUM} color={GlobalStyles.MONO_WHITE} />
              </View>
              <Text style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL, color: GlobalStyles.MONO_WHITE, textAlign: 'center' }}>Remove</Text>
            </Button>,
          ],
          ref: profileAddRef,
        }
      : {
          title: `Add ${formTitle}`,
          content: (
            <AddProfilePicture
              avatar={avatar}
              key="add-profile-picture"
              description={options?.modalProps?.profilePictureForm?.uploadText}
            />
          ),
          footerButtons: [
            <FilePicker
              key="profile-picker"
              options={filePickerOpts}
              space="2xs"
              style={{ borderRadius: 6 }}
              onChange={_handlePick}
              leftElement={<></>}>
              <View style={{ marginRight: GlobalStyles.MARGIN_SIZES['3xs'] }}>
                <Icon.Upload size={GlobalStyles.FONT_SIZES.MEDIUM} color={GlobalStyles.PRIMARY_500} />
              </View>
              <Text style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL, color: GlobalStyles.PRIMARY_500, textAlign: 'center' }}>Upload</Text>
            </FilePicker>,
            //   <Button
            //       key="take-a-picture"
            //       type={'outlined'}
            //       style={{ borderRadius: 6 }}
            //       onPress={_handleUpload}
            //       space="2xs"
            //       disabled={true}>
            //       <View>
            //           <Icon.Camera size={GlobalStyles.FONT_SIZES.MEDIUM} color={GlobalStyles.PRIMARY_500} />
            //       </View>
            //       <Text style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL, color: GlobalStyles.PRIMARY_500, textAlign: 'center' }}>
            //           Take a Picture
            //       </Text>
            //   </Button>,
          ],
          ref: profileAddRef,
        },
    {
      title: 'Crop Image',
      content: <CropProfilePicture key="crop-profile-picture" imgSrc={profileSrc} handleCrop={_handleCrop} />,
      footerButtons: [
        // <FilePicker
        //     key="profile-picker"
        //     options={filePickerOpts}
        //     space="2xs"
        //     style={{ borderRadius: 6 }}
        //     onChange={_handlePick}
        //     leftElement={<></>}>
        //     <View>
        //         <Icon.Upload size={GlobalStyles.FONT_SIZES.MEDIUM} color={GlobalStyles.PRIMARY_500} />
        //     </View>
        //     <Text style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL, color: GlobalStyles.PRIMARY_500, textAlign: 'center' }}>
        //         Upload
        //     </Text>
        // </FilePicker>,
        <Button key="save-profile-picture" type={'primary'} style={{ borderRadius: 6 }} onPress={_handleSaveProfile} space="2xs">
          <View style={{ marginRight: GlobalStyles.MARGIN_SIZES['3xs'] }}>
            <Icon.Save size={GlobalStyles.FONT_SIZES.MEDIUM} color={GlobalStyles.MONO_WHITE} />
          </View>
          <Text style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL, color: GlobalStyles.MONO_WHITE, textAlign: 'center' }}>
            Save {formTitle}
          </Text>
        </Button>,
      ],
      ref: profileCropRef,
    },
  ]

  return (
    <Stack width={{ sm: '500px' }}>
      <StepSlider steps={profilePictureSteps} currentStep={currentStep} modalType={modalType} />
    </Stack>
  )
}

export { ProfilePictureForm }
