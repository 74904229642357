import { Button, DropdownOption, DropdownSearch, GlobalStyles, Icon, SearchableProps, Text, View } from '@andromeda'
import { Stack, StackProps } from '@mui/material'
import { AppStyles } from '@styles'
import { useEffect, useState } from 'react'
import { Chip } from '../chip'

interface iSearchTagProps {
  onChipPress?: (e: any) => void
  handleCreateNewTag?: (tag: string) => void
  showSuggestions?: boolean
  errorMessage?: string
  searchTagWrapperStyle?: StackProps['style']
  hasAddTag?: boolean
}

const SearchTag = ({ hasAddTag = false, ...props }: SearchableProps & iSearchTagProps) => {
  const [search, setSearch] = useState<string | null>(null)
  const [options, setOptions] = useState<DropdownOption[] | undefined>(props.options)

  useEffect(() => {
    setOptions(props.options)
  }, [props.options])

  const _handleChipPress = (data: DropdownOption) => {
    props.onChipPress && props.onChipPress(data)
  }

  const _boldSubstring = (str: string, sub: string) => {
    const haystack = str.toLowerCase()
    const needle = sub.toLowerCase()
    const key = haystack.indexOf(needle)

    if (!sub || key === -1) return str

    const len = needle.length
    const subs = []
    subs.push(str.substring(0, key))
    subs.push(str.slice(key, key + len))
    subs.push(str.substring(key + len))

    return subs
  }

  const _stripDiacritics = (text: string) => {
    return typeof text.normalize !== 'undefined' ? text.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : text
  }

  /**
   * used for highlighting dropdown options based on searched keyword
   */
  const _highlightOption = (sub: string) => {
    setSearch(sub)
    if (sub && options) {
      const keyword = sub.toLowerCase()
      const highlightedOptions = options.map((option) => {
        let value = option.value
        if (value) {
          let candidate = value.toLowerCase()
          candidate = _stripDiacritics(candidate)

          const shouldHighlight = candidate.indexOf(keyword) > -1
          if (shouldHighlight) {
            const parsed = _boldSubstring(value, sub)
            if (typeof parsed !== 'string') {
              const newOption = Object.assign({}, option, { subs: parsed })
              return newOption
            }
          } else {
            return option
          }
        }
        return option
      })
      setOptions(highlightedOptions)
    } else {
      setOptions(options)
    }
  }

  const _handleCreateNewTag = () => {
    if (search) {
      props.handleCreateNewTag && props.handleCreateNewTag(search)
    }
  }
  const _renderAddTag = (options: DropdownOption[] | undefined) => {
    if (search) {
      const addDeliverableStr = `Add '${search}'`
      return (
        <View style={{ padding: 10 }}>
          <Button
            onPress={(e: any) => _handleCreateNewTag()}
            text={addDeliverableStr}
            style={{ borderRadius: 4 }}
            type={'outlined'}
            space={'2xs'}
            leftElement={
              <View>
                <Icon.PlusCircle size={GlobalStyles.ICON_SIZE} color={GlobalStyles.PRIMARY_500} />
              </View>
            }
          />
        </View>
      )
    } else {
      return <></>
    }
  }

  return (
    <Stack direction={'column'} alignItems={'flex-start'} flex={1} style={props.searchTagWrapperStyle}>
      <View style={{ width: '100%' }}>
        <DropdownSearch
          {...props}
          options={options}
          onChangeText={_highlightOption}
          // To be added back in the future
          actionComponent={hasAddTag ? _renderAddTag : () => <></>}
        />
        {props.errorMessage && (
          <Text size={'tiny'} style={{ color: GlobalStyles.STATUS_NEGATIVE, paddingTop: AppStyles.PADDING_SIZES.XS }}>
            {props.errorMessage}
          </Text>
        )}
      </View>
      {props.showSuggestions && (
        <Stack direction={'row'} flex={1} height={'auto'} style={{ flexWrap: 'wrap' }}>
          <Text>{'Suggested'}</Text>
          {props.options?.map((option: DropdownOption, key: number) => {
            if (key < 5) {
              return (
                <Chip
                  key={option.eid || key}
                  text={option.value as string}
                  leftElement={
                    <Button
                      type={'secondary'}
                      style={{
                        width: AppStyles.CHIP_BUTTON_SIZE,
                        height: AppStyles.CHIP_BUTTON_SIZE,
                        padding: 0,
                        backgroundColor: 'transparent',
                      }}
                      onPress={(e: any) => _handleChipPress(option)}>
                      <Text style={{ color: GlobalStyles.SLATE_700 }}>
                        <Icon.Plus size={AppStyles.ICON_SIZES.SM} />
                      </Text>
                    </Button>
                  }
                />
              )
            }
          })}
        </Stack>
      )}
    </Stack>
  )
}

export { SearchTag }
