import { GlobalStyles, Icon, Text } from '@andromeda'
import { AppStyles } from '@styles'
import { ModalContext } from '@utils/context/Modal.context'
import { iModalHeaderProps } from '@utils/types/modal'
import { useContext } from 'react'
import { Typography, Stack, Button } from '@mui/material'

const ModalHeader = (props: iModalHeaderProps) => {
  const { closeModal } = useContext(ModalContext)

  const _closeModal = () => {
    if (props.onCancel) props.onCancel()
    else closeModal(props.modalContext)
  }

  return (
    <Stack
      flex={1}
      direction={'row'}
      alignItems={'center'}
      justifyContent="space-between"
      height={'auto'}
      spacing={2}
      className="dialog-header">
      <Stack direction="row" alignItems="center" columnGap="10px">
        {props.backButton && <Stack marginRight="24px">{props.backButton}</Stack>}
        {props.leftIcon}
        <Typography fontSize="24px" fontWeight={GlobalStyles.FONT_WEIGHT_MEDIUM} color={GlobalStyles.MONO_BLACK}>
          {props.modalTitle}
        </Typography>
        {props.rightText && (
          <Text size="sm" bold style={props.rightTextStyle}>
            {props.rightText}
          </Text>
        )}
      </Stack>
      {!props.noCloseButton && (
        <Button
          variant="outlined"
          onClick={() => _closeModal()}
          style={{ height: 40, width: 40, borderRadius: 8, padding: 0 }}
          aria-label="Close Modal">
          <Icon.X strokeWidth={2} size={AppStyles.ICON_SIZES.SM} color={GlobalStyles.PRIMARY_500} />
        </Button>
      )}
    </Stack>
  )
}

export default ModalHeader
