import ListMain from './List'
import ListGroup from './ListGroup'
import type { ListComponentType } from './types'

const ListTemp: any = ListMain
ListTemp.Group = ListGroup

const List = ListTemp as ListComponentType

export { List }
