import { GlobalStyles } from '@andromeda'
import { formControlClasses, textFieldClasses } from '@mui/material'
import { DateTimePickerProps, DateTimePicker } from '@mui/x-date-pickers'

import { Moment } from 'moment'

export const FormDateTimePicker = (props: DateTimePickerProps<Moment>) => {
  return (
    <DateTimePicker
      sx={{
        [`&.${formControlClasses.root}.${textFieldClasses.root}`]: {
          minWidth: { xs: '230px' },
        },
        '.MuiInputBase-root.MuiOutlinedInput-root': {
          borderRadius: '6px',
        },
        '.MuiInputBase-input.MuiOutlinedInput-input': {
          padding: '9px 0px 9px 12px',
          fontSize: '14px',
          minHeight: '40px',
          boxSizing: 'border-box',
        },
        '.MuiInputAdornment-positionEnd .MuiButtonBase-root.MuiIconButton-root': {
          paddingLeft: 0,
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: GlobalStyles.SLATE_100,
        },
      }}
      slotProps={{
        actionBar: {
          actions: ['clear', 'accept'],
        },
        ...props.slotProps,
      }}
      {...props}
    />
  )
}
