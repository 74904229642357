import { Types } from '@orbit'
import { Dispatch, SetStateAction, createContext, useState } from 'react'

export const DeliverableActionsContext = createContext<{
  deliverableToDuplicate?: Types.Deliverable.iDeliverable
  setDeliverableToDuplicate: (() => void) | Dispatch<SetStateAction<Types.Deliverable.iDeliverable | undefined>>
  isAddingDeliverable: boolean
  setIsAddingDeliverable: (() => void) | Dispatch<SetStateAction<boolean>>
}>({ setDeliverableToDuplicate: () => {}, isAddingDeliverable: false, setIsAddingDeliverable: () => {} })

export const DeliverableActionsProvider = ({ children }: { children: JSX.Element }) => {
  const [deliverableToDuplicate, setDeliverableToDuplicate] = useState<Types.Deliverable.iDeliverable | undefined>()
  const [isAddingDeliverable, setIsAddingDeliverable] = useState(false)

  return (
    <DeliverableActionsContext.Provider
      value={{ deliverableToDuplicate, setDeliverableToDuplicate, isAddingDeliverable, setIsAddingDeliverable }}>
      {children}
    </DeliverableActionsContext.Provider>
  )
}
