import { DesktopDatePickerProps } from '@mui/x-date-pickers'
import moment from 'moment'

export const DATE_PICKER_ACTION_BUTTONS: DesktopDatePickerProps<moment.Moment>['slotProps'] = {
  actionBar: {
    actions: ['clear', 'accept'],
  },
}

export const API_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
