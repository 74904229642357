import { Types } from '@orbit'
import { ViewProps as NativeViewProps, StyleProp, ViewStyle, TextStyle, FlexAlignType } from 'react-native'
import { SpaceType } from '../Stack/types'
import { TextProps } from '../Text/types'

export enum StatusDefaults {
  TYPE = 'DEFAULT',
  ALIGN = 'center',
  SPACE = 'xs',
  VARIANT = 'default',
}

export type StatusVariant = 'default' | 'rounded'

export interface StatusProps extends NativeViewProps {
  type: Types.Job.StatusTypes
  style?: StyleProp<ViewStyle>
  text?: string
  variant?: StatusVariant
  textStyle?: StyleProp<TextStyle>
  wrapperStyle?: StyleProp<ViewStyle>
  textProps?: TextProps
  align?: FlexAlignType
  space?: SpaceType
}
