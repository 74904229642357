import { ModalFooter, ModalHeader } from '@components'
import { GlobalStyles, StackV2, Text } from '@andromeda'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext } from 'react'

export const OwnerRequired = () => {
  const { closeModal, options } = useContext(ModalContext)
  const props = options?.modalProps ? options.modalProps[ModalPages.OWNER_REQUIRED] : undefined

  const _confirm = () => {
    if (props?.onConfirm) props.onConfirm()
    else closeModal(ModalPages.OWNER_REQUIRED)
  }

  return (
    <StackV2 spacing={2} height="auto" className="dialog-container" style={{ backgroundColor: GlobalStyles.MONO_WHITE }}>
      <ModalHeader
        modalContext={ModalPages.OWNER_REQUIRED}
        modalTitle={'Owner Required'}
        textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
      />
      <div className="dialog-content">
        <Text>{'In order to perform this action, you must first make another user the Owner of this job.'}</Text>
      </div>

      <ModalFooter backgroundColor={GlobalStyles.MONO_WHITE} onButtonPress={() => _confirm()} buttonText={'Got it!'} disabled={false} />
    </StackV2>
  )
}
