import { Activity, Dropdown, GlobalStyles, Icon, Loading } from '@andromeda'
import { Stack, Typography } from '@mui/material'
import { labelStyles } from './Overview'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { getAudits, selectAudit } from '@redux/reducers/auditReducer'
import { useEffect } from 'react'

const SORTING_OPTIONS = [{ id: 1, value: 'Most Recent', label: 'due-soonest' }]

export const ActivityPanel = ({ eid }: { eid: string }) => {
  const dispatch = useAppDispatch()
  const { deliverable: activities, requestStatus } = useAppSelector(selectAudit)

  useEffect(() => {
    dispatch(getAudits({ eid: eid, type: 'deliverable' }))
  }, [dispatch, eid])

  return (
    <Stack gap="38px" display="flex" flexDirection="column">
      <Stack
        border={`1px solid ${GlobalStyles.SLATE_100}`}
        borderRadius="6px"
        padding="12px"
        display="flex"
        flexDirection="column"
        gap="14px">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap="12px" alignItems="center">
            <Icon.Activity color={GlobalStyles.SLATE_500} width={20} height={20} />
            <Typography {...labelStyles} color={GlobalStyles.SLATE_500}>
              {' '}
              ACTIVITY
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography {...labelStyles}>Sort by</Typography>
            <Dropdown
              style={{ backgroundColor: 'red' }}
              labelStyle={{
                color: GlobalStyles.PRIMARY_500,
                fontWeight: GlobalStyles.FONT_WEIGHT_MEDIUM,
              }}
              onSelect={() => {}}
              type="shadow"
              selectedItem={SORTING_OPTIONS[0]}
              options={SORTING_OPTIONS}
              dropdownContainerStyle={{ width: '100px' }}
              // @ts-expect-error
              stackProps={{ spacing: 0 }}
            />
          </Stack>
        </Stack>
        {requestStatus.read === 'loading' ? <Loading /> : <Activity activities={activities} />}
      </Stack>
    </Stack>
  )
}
