/* eslint-disable jsx-a11y/alt-text */
import { Stack } from '@andromeda'
import { ImageCrop } from '@components'
import { AppStyles } from '@styles'

const CropProfilePicture = ({ imgSrc, handleCrop }: { imgSrc: string | undefined; handleCrop: (e: any) => void }) => {
  return (
    <Stack
      direction="column"
      space="lg"
      style={{ paddingHorizontal: AppStyles.MODAL_PADDING_HORIZONTAL, paddingBottom: AppStyles.MODAL_PADDING_HORIZONTAL }}
      align="flex-start">
      <Stack direction="column">{Boolean(imgSrc) && <ImageCrop imageSrc={imgSrc} onChange={(e) => handleCrop(e)} />}</Stack>
    </Stack>
  )
}

export { CropProfilePicture }
