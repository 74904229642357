import { GlobalStyles, Icon, Tooltip } from '@andromeda'
import { Stack, Typography, TypographyProps } from '@mui/material'
import { Textfield } from 'src/components/form/Textfield'
import { getIcon } from './components'

const SOCIAL_CAMPAIGNS = [
  {
    platform: 'facebook',
    tag: '/mo.halas',
    followers: '16m',
    engagement: '18.05%',
    likesPerPost: '2599',
  },
  {
    platform: 'youtube',
    tag: '/mo.halas',
    followers: '16m',
    engagement: '18.05%',
    likesPerPost: '2599',
  },
  {
    platform: 'instagram',
    tag: '@mohalas',
    followers: '16m',
    engagement: '18.05%',
    likesPerPost: '2599',
  },
  {
    platform: 'twitter',
    tag: '@mohalas',
    followers: '16m',
    engagement: '18.05%',
    likesPerPost: '2599',
  },
  {
    platform: 'tiktok',
    tag: '@mohalas',
    followers: '16m',
    engagement: '18.05%',
    likesPerPost: '2599',
  },
]

const Label = (props: TypographyProps) => (
  <Typography sx={{ fontSize: '12px', color: GlobalStyles.SLATE_700, lineHeight: '16px', letterSpacing: '-0.12px' }} {...props} />
)

const MinorValue = (props: TypographyProps) => (
  <Typography
    sx={{ fontSize: '12px', fontWeight: 500, color: GlobalStyles.MONO_BLACK, lineHeight: '16px', letterSpacing: '-0.12px' }}
    {...props}
  />
)
export const SocialCampaign = () => (
  <Stack paddingBottom="24px">
    <Stack
      direction="row"
      sx={{
        flexWrap: 'wrap',
        justifyContent: 'center',
        ['>div']: {
          marginTop: '-1px',
          marginLeft: '-1px',
          border: `1px solid ${GlobalStyles.SLATE_100}`,
          boxSizing: 'border-box',
        },
        ['>div:last-child']: {
          marginRight: '-1px',
        },
      }}>
      {SOCIAL_CAMPAIGNS.map(({ platform, engagement, followers, likesPerPost, tag }) => (
        <Stack key={platform} minWidth="184px" flex={1} alignItems="center" rowGap="8px" padding="24px">
          {getIcon({ platform })}
          <Typography
            sx={{
              color: GlobalStyles.PRIMARY_500,
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '20px' /* 150% */,
              letterSpacing: '-0.14px',
            }}>
            {tag}
          </Typography>
          <Typography
            sx={{
              color: GlobalStyles.MONO_BLACK,
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '24px' /* 150% */,
              letterSpacing: '-0.16px',
            }}>
            {followers}
          </Typography>
          <Label>Followers</Label>
          <MinorValue>{engagement}</MinorValue>
          <Label>Engagement</Label>
          <MinorValue>{likesPerPost}</MinorValue>
          <Label>Likes per post</Label>
        </Stack>
      ))}
    </Stack>
    <Stack direction={{ sm: 'row' }} justifyContent="center" alignItems="center" padding="24px" columnGap="8px">
      <Typography>Add a Social Account</Typography>
      <Tooltip text="Add a Social Account" />
      <Textfield name="account" sx={{ width: { xxs: '100%', sm: '400px' } }} registerOptions={{ required: false }} />
    </Stack>
  </Stack>
)
