import { useEffect, useRef, useState } from 'react'
import { Types } from '@orbit'
import useDebouncedCallback from './useDebouncedCallback'

const DEFAULT_QUERY = { page: 1, searchTerm: '' }

type tUseLoadOnScroll<T> = {
  list: Array<T>
  listMeta: Types.Meta.iMeta | null
  status: string
  labelField?: string
  fetchList: (params: Partial<typeof DEFAULT_QUERY>) => void
}

export const useLoadOnScroll = <T>({ list, listMeta, labelField, status, fetchList }: tUseLoadOnScroll<T>) => {
  const [options, setOptions] = useState<Array<T>>(list || [])
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const fetchListRef = useRef(fetchList)
  const _fetchData = fetchListRef.current

  const loadMore = useDebouncedCallback(() => {
    if (!isLoading && listMeta?.current_page! < listMeta?.last_page!) {
      setCurrentPage((prev) => prev + 1)
    }
  }, 250)

  const searchOption = useDebouncedCallback((searchTerm) => {
    const isExist = options.find((data) => {
      const _data = String(labelField ? data[labelField as keyof typeof data] : data)
      return _data?.toLocaleLowerCase().includes(searchTerm)
    })

    if (searchTerm && isExist) return

    setOptions([])
    setCurrentPage(1)
    setSearchTerm(searchTerm)
    _fetchData({ page: 1, searchTerm })
  }, 500)

  const isLoading = status === 'loading'

  useEffect(() => {
    _fetchData({})

    return () => {
      setOptions([])
    }
  }, [_fetchData])

  useEffect(() => {
    if (!isLoading && currentPage > 1 && currentPage !== listMeta?.current_page!) {
      _fetchData({ page: currentPage, searchTerm })
    }
  }, [_fetchData, currentPage, searchTerm, isLoading, listMeta])

  useEffect(() => {
    if (list?.length) {
      setOptions((prev) => (listMeta?.current_page! > 1 ? [...prev, ...list] : list))
    }
  }, [list, listMeta])

  return {
    options,
    isLoading,
    loadMore,
    searchOption,
  }
}
