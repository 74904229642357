import { RefObject, useEffect, useLayoutEffect, useState } from 'react'

export const useCardWidth = (ref: RefObject<HTMLDivElement>, selectedDeliverableEid?: string) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    setWidth(ref?.current?.clientWidth ?? 0)
    setHeight(ref?.current?.clientHeight ?? 0)
  }, [selectedDeliverableEid])

  useEffect(() => {
    function handleWindowResize() {
      // @ts-expect-error
      setWidth(ref.current?.clientWidth)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return { width, height }
}
