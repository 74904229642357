interface IDrawerContext {
  open: boolean
  toggleWorkspaceDrawer?: () => void
  autoCollapse?: boolean
  setDrawerOpen?: (open: boolean) => void
}

const defaultState = {
  open: true,
}

export const DrawerContext = React.createContext<IDrawerContext>(defaultState)

import React, { FC, useState } from 'react'

export const DrawerProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const [open, setOpen] = useState(() => {
    let drawerState = defaultState.open
    return drawerState
  })
  const [autoCollapse] = useState<boolean>(true)

  const toggleWorkspaceDrawer = () => {
    setOpen(!open)
  }

  return (
    <DrawerContext.Provider
      value={{
        open,
        toggleWorkspaceDrawer,
        autoCollapse,
        setDrawerOpen: (val) => setOpen(val),
      }}>
      {children}
    </DrawerContext.Provider>
  )
}
