import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { InputProps } from '../Input/types'
import { LayoutRectangle } from 'react-native'
import { StackProps } from '../Stack/types'
import { NavPositionShape, ScrollableOrientationShape } from 'react-dates'

export interface LayoutChangeEvent {
  nativeEvent: {
    layout: LayoutRectangle
  }
}

export type DateRangeData = {
  startDate: Date | null | string
  endDate: Date | null | string
}

export enum DateRangeDefaults {
  DATE_ID = 'default',
  START_PLACEHOLDER = '01/07/2022',
  END_PLACEHOLDER = '02/07/2022',
  NUM_OF_MONTHS = 2,
  DAY_SIZE = 42,
  DIRECTION_UP = 'up',
  DIRECTION_DOWN = 'down',
  FORMAT = 'DD/MM/YYYY',
}

export type ModalPositionProps = {
  element?: any
  menuPosition?: any
  visible?: boolean
  didEnter?: (ref: any) => void
  didExit?: () => void
  _computePosition?: () => void
  _onLayout: (e: LayoutChangeEvent) => void
}

export interface DateRangeProps {
  /** Text input field customizations */
  textInputProps?: InputProps
  /** takes a Date, string or null */
  initialStartDate?: Date | string | null
  initialEndDate?: Date | string | null
  minDate?: Date | string | null
  maxDate?: Date | string | null
  /**
   * dateId for start and end dates
   */
  dateId?: string
  /**
   * controls how many calendar month to show
   */
  numberOfMonths?: number
  /**
   * Start Date placeholder
   */
  startDatePlaceholderText?: string
  /**
   * End Date placeholder
   */
  endDatePlaceholderText?: string
  /**
   * control the size of each day in the calendar
   */
  daySize?: number
  /**
   * show clear dates button
   */
  showClearDates?: boolean
  /**
   * disable the picker
   */
  disabled?: boolean
  /**
   * Controls where calendar opens
   */
  openDirection?: DateRangeDefaults.DIRECTION_UP | DateRangeDefaults.DIRECTION_DOWN
  /**
   * onClose handler for picker
   */
  onClose?: (e: any) => void
  /**
   * custom display format
   */
  displayFormat?: string
  /**
   * custom display format (input)
   */
  inputDisplayFormat?: string
  /**
   * handler for getting StartDate and EndDate
   */
  onDateChange?: (dates: DateRangeData) => void
  /**
   * specify blocked dates
   */
  isDayBlocked?: () => void
  /**
   * Element to be rendered on the left of the picker
   */
  leftElement?: JSX.Element
  /**
   * Element to be rendered on the right of the picker
   */
  rightElement?: JSX.Element
  /**
   * Prop to customize Wrapper
   */
  wrapperStyle?: StyleProp<ViewStyle>
  stackProps?: StackProps
  errorMessage?: string | null
  errorMessageStyle?: StyleProp<TextStyle>
  style?: StyleProp<ViewStyle>
  minimumNights?: number
  orientation?: ScrollableOrientationShape
  navPosition?: NavPositionShape
  onConfirm: (dates: DateRangeData) => void
  onClear?: () => void
  containerStyle?: StyleProp<ViewStyle>
}

export interface SingleDatePickerProps {
  /** Text input field customizations */
  textInputProps?: InputProps
  /** takes a Date, string or null */
  initialDate?: Date | string | null
  /**
   * dateId for start and end dates
   */
  dateId?: string
  /**
   * controls how many calendar month to show
   */
  numberOfMonths?: number
  /**
   * Date placeholder
   */
  placeholder?: string
  /**
   * control the size of each day in the calendar
   */
  daySize?: number
  /**
   * show clear dates button
   */
  showClearDate?: boolean
  /**
   * disable the picker
   */
  disabled?: boolean
  /**
   * Controls where calendar opens
   */
  openDirection?: DateRangeDefaults.DIRECTION_UP | DateRangeDefaults.DIRECTION_DOWN
  /**
   * onClose handler for picker
   */
  onClose?: (e: any) => void
  /**
   * custom display format (output)
   */
  displayFormat?: string
  /**
   * custom display format (input)
   */
  inputDisplayFormat?: string
  /**
   * handler for getting StartDate and EndDate
   */
  onDateChange?: (date: Date | string | null) => void
  /**
   * specify blocked dates
   */
  isDayBlocked?: () => void
  /**
   * Element to be rendered on the left of the picker
   */
  leftElement?: JSX.Element
  /**
   * Element to be rendered on the right of the picker
   */
  rightElement?: JSX.Element
  /**
   * Prop to customize Wrapper
   */
  wrapperStyle?: StyleProp<ViewStyle>
  stackProps?: StackProps
  errorMessage?: string | null
  errorMessageStyle?: StyleProp<TextStyle>
  style?: StyleProp<ViewStyle>
  onConfirm: (date: Date | string | null) => void
  onClear?: () => void
  containerStyle?: StyleProp<ViewStyle>
}

export enum TimePickerDefaults {
  PLACEHOLDER = '04:00 PM',
}

export type TimeData = {
  hour: string
  minute: string
  isMorning: boolean
  format24: string
}
export interface TimePickerProps {
  /** Text input field customizations */
  textInputProps?: InputProps
  /**
   * must be HH:mm Local Time format
   * eg. 2:23 AM
   */
  initialTime?: string
  placeholder?: string
  timeID?: string
  touchableInputStyle?: StyleProp<ViewStyle>
  wrapperStyle?: StyleProp<ViewStyle>
  inputModalContainer?: StyleProp<ViewStyle>

  /**
   * Callback called on modal dismiss.
   * returns displayTime and time Object
   */
  onTimeChange: (e: { displayedTime: string; data: TimeData }) => void
  /**
   * Element to be rendered on the left of the picker
   */
  leftElement?: JSX.Element
  /**
   * Element to be rendered on the right of the picker
   */
  rightElement?: JSX.Element
  disabled?: boolean
}

export type PickerComponentType = ((props: DateRangeProps) => JSX.Element) & {
  SinglePicker: (props: SingleDatePickerProps) => JSX.Element
  RangePicker: (props: DateRangeProps) => JSX.Element
  TimePicker: (props: TimePickerProps) => JSX.Element
}
