import { CardV2, DoughnutChart, DropDownItemV2, DropdownV2, GlobalStyles, Icon, StackV2, Text } from '@andromeda'
import Box from '@mui/material/Box'
import { BarChartProps } from '../../Charts/Bar/Bar'
import { iMenuOption } from '../types'
import { FILTER_OPTIONS, STATUS_OPTIONS } from './constants'
import { Typography } from '@mui/material'

export interface DeliverableOverviewProps extends Pick<BarChartProps, 'data'> {
  deliverableCount: number
  statusFilter?: iMenuOption
  onChangeStatusFilter: (e: iMenuOption) => void
  dateFilter?: iMenuOption
  onChangeDateFilter: (e: iMenuOption) => void
}

const DeliverableOverview = ({
  data,
  deliverableCount,
  statusFilter,
  onChangeStatusFilter = () => {},
  dateFilter,
  onChangeDateFilter = () => {},
}: DeliverableOverviewProps) => {
  return (
    <CardV2
      className="widget-content"
      // sx={{ maxWidth: 300 }}
      // height={{ xxs: 350, md: 'auto' }}
      style={{ flex: 1, alignItems: 'center', justifyContent: 'flex-start', overflow: 'scroll' }}>
      <Typography color={GlobalStyles.SLATE_700} lineHeight="48px">{`${deliverableCount} Deliverables`}</Typography>
      <StackV2 marginTop="8px" flex={1} spacing={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <DropdownV2
          wrapperStyle={{ flex: 1 }}
          title={statusFilter?.label || STATUS_OPTIONS[0].label}
          menuIcon={<Icon.ChevronDown color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />}>
          {STATUS_OPTIONS.map((option) => (
            <DropDownItemV2 key={option.value} onClick={() => onChangeStatusFilter(option)}>
              {option.label}
            </DropDownItemV2>
          ))}
        </DropdownV2>
        <DropdownV2
          wrapperStyle={{ flex: 1 }}
          title={dateFilter?.label || FILTER_OPTIONS[0].label}
          menuIcon={<Icon.ChevronDown color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />}>
          {FILTER_OPTIONS.map((option) => (
            <DropDownItemV2 key={option.value} onClick={() => onChangeDateFilter(option)}>
              {option.label}
            </DropDownItemV2>
          ))}
        </DropdownV2>
      </StackV2>
      <Box maxHeight={420} minHeight={300} marginBottom="8px">
        <DoughnutChart data={data} options={{ plugins: { legend: { display: false } } }} />
      </Box>
    </CardV2>
  )
}

export default DeliverableOverview
