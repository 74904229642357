import CardMain from './Card'
import CardUser from './CardUser'
import CardProposal from './CardProposal'
import CardError from './CardError'
import CardAlert from './CardAlert'
export { default as CardV2 } from './CardV2'

import type { CardComponentType } from './types'

const CardTemp: any = CardMain
CardTemp.Proposal = CardProposal
CardTemp.User = CardUser
CardTemp.Error = CardError
CardTemp.Alert = CardAlert

const Card = CardTemp as CardComponentType

export { Card }
