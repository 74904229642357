import { Button, GlobalStyles, Input, Stack, Text } from '@andromeda'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectAccounts, sendLink } from '@redux/reducers/accountsReducer'
import { selectIdp } from '@redux/reducers/idpReducer'
import { setResetEmail } from '@redux/reducers/passwordRecovery'
import { AppStyles } from '@styles'
import { ModalContext } from '@utils/context/Modal.context'
import validateIDPEmail from '@utils/functions/validateIDPEmail'
import { validateEmail } from '@utils/index'
import { useContext, useState } from 'react'

const EmailResetForm = ({ onSubmit }: { onSubmit: () => void }) => {
  const { closeModal } = useContext(ModalContext)
  const { list: idpList } = useAppSelector(selectIdp)
  const [emailValid, setEmailValid] = useState<boolean>(false)

  /**
   * Input field values
   */
  const [username, setUsername] = useState<string>('')

  /**
   * Custom Error message hooks
   */
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  /**
   * Redux actions
   */
  const dispatch = useAppDispatch() // For dispatching Actions
  const { sendLinkStatus } = useAppSelector(selectAccounts)

  const doSubmit = () => {
    if (!username) {
      setErrorMessage('Please enter an email address')
    } else {
      dispatch(setResetEmail(username))
      dispatch(sendLink(username)).finally(() => {
        onSubmit && onSubmit()
      })
    }
  } // For dispatching Actions

  const _handleChange = (value: string) => {
    setUsername(value)
    const invalidEmail = value === '' || !validateEmail(value)

    setEmailValid(true)

    if (idpList?.length && validateIDPEmail(idpList, value)) {
      setErrorMessage(`IDP email detected. Reset your password on your Identity Provider's page.`)
      setEmailValid(false)
    } else if (invalidEmail) {
      setErrorMessage('Invalid email')
      setEmailValid(false)
    } else setErrorMessage('')
  }

  return (
    <Stack
      direction="column"
      style={{ paddingHorizontal: AppStyles.MODAL_PADDING_HORIZONTAL, paddingBottom: AppStyles.MODAL_PADDING_HORIZONTAL }}
      align="flex-start">
      <Text type={'paragraph'}>Please enter your email address and we will send you a link to reset your password.</Text>
      <Text type={'paragraph'}>Enter Your Email*</Text>
      <Input
        name="password-username"
        value={username}
        errorMessage={errorMessage}
        placeholder="john.doe@pickstar.com.au"
        style={{ backgroundColor: 'GlobalStyles.MONO_WHITE', borderColor: GlobalStyles.SLATE_100 }}
        wrapperStyle={{ height: 'auto' }}
        onChangeText={(value: string) => _handleChange(value)}
      />

      <Stack height={'auto'} justify={'space-between'}>
        <Button
          onPress={() => {
            closeModal('resetPasswordForm')
          }}
          style={{ padding: 0 }}
          transparent>
          <Text size="sm" style={{ color: GlobalStyles.PRIMARY_500 }}>
            Cancel
          </Text>
        </Button>
        <Button
          disabled={!emailValid}
          loading={sendLinkStatus === 'loading'}
          onPress={() => {
            doSubmit()
          }}
          style={{ borderRadius: 6 }}
          type="primary"
          text="Submit"
        />
      </Stack>
    </Stack>
  )
}

EmailResetForm.displayName = 'EmailResetForm'

export { EmailResetForm }
