import { StyleProp, StyleSheet } from 'react-native'
import { GlobalStyles } from '../globalStyles'

export const AvatarSizes = {
  xs: GlobalStyles.AVATAR_SIZES.XS,
  sm: GlobalStyles.AVATAR_SIZES.SMALL,
  md: GlobalStyles.AVATAR_SIZES.MEDIUM,
  lg: GlobalStyles.AVATAR_SIZES.LARGE,
  xl: GlobalStyles.AVATAR_SIZES.XLARGE,
  '2xl': GlobalStyles.AVATAR_SIZES['2XLARGE'],
  '3xl': GlobalStyles.AVATAR_SIZES['3XLARGE'],
}

const DefaultStyles = {
  justifyContent: GlobalStyles.JUSTIFY_CONTENT,
  overflow: GlobalStyles.OVERFLOW,
} as StyleProp<object> as Record<string, number>

export const AvatarStyles = StyleSheet.create({
  primary: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.SLATE_100,
  },
})

export const AvatarSizeStyles = StyleSheet.create({
  xs: {
    ...DefaultStyles,
    width: GlobalStyles.AVATAR_SIZES.XS,
    height: GlobalStyles.AVATAR_SIZES.XS,
  },
  sm: {
    ...DefaultStyles,
    width: GlobalStyles.AVATAR_SIZES.SMALL,
    height: GlobalStyles.AVATAR_SIZES.SMALL,
  },
  md: {
    ...DefaultStyles,
    width: GlobalStyles.AVATAR_SIZES.MEDIUM,
    height: GlobalStyles.AVATAR_SIZES.MEDIUM,
  },
  lg: {
    ...DefaultStyles,
    width: GlobalStyles.AVATAR_SIZES.LARGE,
    height: GlobalStyles.AVATAR_SIZES.LARGE,
  },
  xl: {
    ...DefaultStyles,
    width: GlobalStyles.AVATAR_SIZES.XLARGE,
    height: GlobalStyles.AVATAR_SIZES.XLARGE,
  },
  '2xl': {
    ...DefaultStyles,
    width: GlobalStyles.AVATAR_SIZES['2XLARGE'],
    height: GlobalStyles.AVATAR_SIZES['2XLARGE'],
  },
  '3xl': {
    ...DefaultStyles,
    width: GlobalStyles.AVATAR_SIZES['3XLARGE'],
    height: GlobalStyles.AVATAR_SIZES['3XLARGE'],
  },
})
