import { Button } from '@mui/material'
import { Section, UserRow } from '../components'
import { GlobalStyles, Icon, Loading } from '@andromeda'
import { useAppSelector } from '@redux/hooks'
import { selectJobs } from '@redux/reducers/jobsReducer'

export const Invited = () => {
  const { viewedDeliverableUsers, viewedDeliverableUsersStatus } = useAppSelector(selectJobs)

  return (
    <Section SectionIcon={(props) => <Icon.Clock {...props} />} title="Invited" noAdd rowGap="10px">
      {viewedDeliverableUsersStatus == 'success' ? (
        viewedDeliverableUsers?.invites.map(({ eid, name, profile_image, ownership_type }) => (
          <UserRow
            key={eid}
            AvatarIcon={<Icon.Clock size={35} color={GlobalStyles.SLATE_200} />}
            name={name}
            profile_image={profile_image?.full_url}
            subtext={ownership_type?.toLowerCase()}
            Actions={<Button variant="text">Resend</Button>}
          />
        ))
      ) : (
        <Loading />
      )}
    </Section>
  )
}
