import { ModalFooter, ModalHeader } from '@components'
import { Button, GlobalStyles, Icon, Stack, Text, View } from '@andromeda'
import { useAppSelector } from '@redux/hooks'
import { selectDeliverable } from '@redux/reducers/deliverableReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext } from 'react'

export const MarkCompleteJobAgreement = () => {
  const { closeModal, options } = useContext(ModalContext)
  const props = options?.modalProps?.markCompleteJobAgreement

  const { requestStatus } = useAppSelector(selectDeliverable)

  const _handleSubmit = () => {
    props.onConfirm()
  }

  return (
    <Stack rounded space="none" height="auto" direction="column" style={{ backgroundColor: GlobalStyles.MONO_WHITE }}>
      <ModalHeader
        modalContext={ModalPages.MARK_COMPLETE_JOB_AGREEMENT}
        modalTitle={`Complete Job for ${props.talentName}?`}
        textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
        style={{
          paddingBottom: 0,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.md,
          alignItems: 'center',
        }}
      />

      <Stack
        direction="column"
        align="flex-start"
        space="xs"
        style={{
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingVertical: GlobalStyles.PADDING_SIZES.sm,
        }}>
        <Text size="sm" style={{ color: GlobalStyles.MONO_BLACK }}>
          {'This action will:'}
        </Text>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>
            <Icon.Check color={GlobalStyles.SLATE_300} />
          </Text>
          <Text size="sm">{'Change all booked deliverables to done.'}</Text>
        </Stack>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>
            <Icon.Check color={GlobalStyles.SLATE_300} />
          </Text>
          <Text size="sm">{'Change assigned deliverables to removed.'}</Text>
        </Stack>
      </Stack>

      {/* <View style={{ paddingHorizontal: GlobalStyles.PADDING_SIZES.md, width: '100%' }}>
                <Divider style={{ borderColor: GlobalStyles.SLATE_100, marginVertical: 0 }} />
            </View> */}

      <ModalFooter
        backgroundColor={GlobalStyles.MONO_WHITE}
        wrapperStyle={{
          shadowOpacity: 0,
          justifyContent: 'flex-end',
          paddingBottom: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.sm,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
        }}
        buttonStyles={{ backgroundColor: GlobalStyles.MONO_WHITE }}
        buttonTextStyle={{
          color: GlobalStyles.STATUS_NEGATIVE,
        }}
        leftComponent={
          <Button
            type="secondary"
            style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
            onPress={() => closeModal(ModalPages.MARK_COMPLETE_JOB_AGREEMENT)}
            textStyle={{ color: GlobalStyles.PRIMARY_500 }}
            text="Cancel"
          />
        }
        onButtonPress={_handleSubmit}
        buttonText={'Mark Complete'}
        loading={requestStatus.markComplete === 'loading'}
        buttonVariant="primary"
        disabled={false}
      />
    </Stack>
  )
}
