import { useMemo, useRef, useState } from 'react'
import { useReadOnly, useSlateStatic } from 'slate-react'
import { Button, Divider, Popover, Stack } from '@mui/material'
import { GlobalStyles, Icon } from '@andromeda'
import { ElementProps } from 'src/andromeda/RichEditor/tools'
import { unwrapLink } from './utils'
import { CustomElement } from 'src/andromeda/RichEditor/custom-types'

const allowedSchemes = ['http:', 'https:', 'mailto:', 'tel:']

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: 0 }}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

const RichEditorLink = ({
  attributes,
  updateLink,
  children,
  element,
}: ElementProps & { updateLink?: (element: CustomElement) => void }) => {
  const LINK_URL = (element as unknown as { link: string }).link
  const editor = useSlateStatic()
  const isReadOnly = useReadOnly()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const anchorRef = useRef(null)
  const popoverOpen = Boolean(anchorEl)

  const safeUrl = useMemo(() => {
    let parsedUrl: URL | null = null

    try {
      parsedUrl = new URL(LINK_URL)
      // eslint-disable-next-line no-empty
    } catch {}
    if (parsedUrl && allowedSchemes.includes(parsedUrl.protocol)) {
      return parsedUrl.href
    }
    return 'about:blank'
  }, [LINK_URL])

  return (
    <a
      {...attributes}
      ref={anchorRef}
      href={safeUrl}
      target="_blank"
      style={{ display: 'inline-block' }}
      spellCheck={false}
      onClick={(e) => {
        if (!isReadOnly && !popoverOpen) setAnchorEl(e.currentTarget)
      }}>
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />

      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => setAnchorEl(null)}>
        <Stack
          flexWrap="nowrap"
          direction={'row'}
          contentEditable={false}
          columnGap="8px"
          alignItems={'center'}
          style={{
            backgroundColor: 'white',
            boxShadow: '0 0 5px 2px #e2e4e8',
            borderRadius: '8px',
            padding: '8px',
            zIndex: 1,
            whiteSpace: 'nowrap',
          }}>
          <Stack
            columnGap="8px"
            alignItems={'center'}
            direction={'row'}
            flexWrap="nowrap"
            style={{
              whiteSpace: 'nowrap',
            }}>
            Go to link:
            <Button
              href={safeUrl}
              rel="noreferrer"
              target="_blank"
              style={{
                padding: `${GlobalStyles.PADDING_SIZES.xxs / 2}px ${GlobalStyles.PADDING_SIZES.xxs}px`,
                minHeight: 'auto',
              }}>
              {safeUrl}
            </Button>
          </Stack>
          {!!updateLink && (
            <>
              <Divider orientation="vertical" flexItem />
              <Button
                type="button"
                variant="text"
                style={{
                  padding: `${GlobalStyles.PADDING_SIZES.xxs / 2}px ${GlobalStyles.PADDING_SIZES.xxs}px`,
                  minHeight: 'auto',
                }}
                onClick={() => {
                  setAnchorEl(null)
                  updateLink && updateLink(element)
                }}>
                Change
              </Button>
            </>
          )}
          <Divider orientation="vertical" flexItem />
          <Button
            type="button"
            variant="text"
            color="error"
            onClick={() => {
              setAnchorEl(null)
              unwrapLink(editor)
            }}
            style={{
              padding: `${GlobalStyles.PADDING_SIZES.xxs / 2}px ${GlobalStyles.PADDING_SIZES.xxs}px`,
              minHeight: '32px',
            }}>
            <Icon.Link color="#d32f2f" size={GlobalStyles.ICON_SIZE} />
          </Button>
        </Stack>
      </Popover>
    </a>
  )
}

export default RichEditorLink
