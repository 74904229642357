import { Button, GlobalStyles, Icon, ScrollView, View } from '@andromeda'
import { ModalHeader } from '@components'
import { Stack } from '@mui/material'
import { AppStyles } from '@styles'
import { ModalContext, ModalTypes } from '@utils/context/Modal.context'
import { useScreenDimensions } from '@utils/hooks/dimensions'
import { useContext, useEffect, useRef, useState } from 'react'

export type SliderStep = {
  title: string
  content: JSX.Element
  footerButtons?: JSX.Element[]
  /** Used for ScrollView resizing */
  ref: any
}

interface SliderStepsProps {
  steps: SliderStep[]
  currentStep: number
  modalType?: ModalTypes
  showHeader?: boolean
  showFooter?: boolean
  showFooterCancel?: boolean
  showHeaderIcon?: boolean
  headerIcon?: JSX.Element
}

const StepSlider = ({
  steps,
  currentStep = 0,
  modalType,
  showHeader = true,
  showFooter = true,
  showFooterCancel = true,
  showHeaderIcon = true,
  ...props
}: SliderStepsProps) => {
  const { closeModal } = useContext(ModalContext)
  const scrollViewRef = useRef<ScrollView>(null)
  const [scrollViewWidth, setScrollViewWidth] = useState<number>(0)
  const [scrollViewHeight, setScrollViewHeight] = useState<number | string | undefined>('auto')
  const [scrollEnabled, setScrollEnabled] = useState<boolean>(false)

  const { screenSize } = useScreenDimensions()
  const responsiveStyles = {
    paddingTop: !screenSize.md ? AppStyles.MODAL_MOBILE_PADDING : AppStyles.MODAL_PADDING_VERTICAL / 2,
    paddingBottom: !screenSize.md ? AppStyles.MODAL_MOBILE_PADDING : AppStyles.MODAL_PADDING_VERTICAL,
    paddingHorizontal: !screenSize.md ? AppStyles.MODAL_MOBILE_PADDING : AppStyles.MODAL_PADDING_HORIZONTAL,
  }

  /**
   * Control the ScrollView height
   */
  useEffect(() => {
    if (currentStep < steps.length) {
      setScrollViewHeight(steps[currentStep].ref?.current.clientHeight || 'auto')
    } else {
      if (closeModal && modalType) closeModal(modalType)
    }
  }, [currentStep, closeModal, modalType, scrollViewWidth, steps.length, steps])

  /**
   * Scroll to current step whenever the ScrollView resizes
   */
  const disableScrollDelay = 500
  const _handleResize = () => {
    setScrollEnabled(true)
    scrollViewRef.current?.scrollTo({ x: scrollViewWidth * currentStep, animated: currentStep === 0 ? false : true })
    setTimeout(() => {
      setScrollEnabled(false)
    }, disableScrollDelay)
  }

  const _renderHeaderIcon = () => {
    if (showHeaderIcon) {
      if (props.headerIcon) return props.headerIcon
      else {
        return <Icon.User strokeWidth={1} size={32} color={GlobalStyles.MONO_BLACK} />
      }
    } else {
      return <></>
    }
  }

  const _renderContent = (step: SliderStep, key: number) => {
    return (
      <View
        ref={step.ref}
        key={key}
        style={{ width: scrollViewWidth }}
        onLayout={(e) => {
          let { height } = e.nativeEvent.layout
          if (key === currentStep) setScrollViewHeight(height)
        }}>
        {step.content}
      </View>
    )
  }

  const _handleCancel = () => {
    if (modalType && closeModal) closeModal(modalType)
  }

  return (
    <Stack className="dialog-container" direction="column" style={{ backgroundColor: GlobalStyles.MONO_WHITE, height: 'fit-content' }}>
      {showHeader && modalType ? (
        <ModalHeader modalTitle={steps[currentStep]?.title} modalContext={modalType} leftIcon={_renderHeaderIcon()} />
      ) : (
        <></>
      )}
      <View
        style={{ width: '100%', height: 'auto' }}
        onLayout={(event) => {
          let { width } = event.nativeEvent.layout
          setScrollViewWidth(width)
        }}>
        <ScrollView
          ref={scrollViewRef}
          pagingEnabled
          scrollEnabled={scrollEnabled}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ height: scrollViewHeight }}
          onContentSizeChange={_handleResize}
          horizontal>
          {steps.map(_renderContent)}
        </ScrollView>
      </View>
      {/* </Box> */}
      <View
        style={{
          width: '100%',
          shadowColor: 'rgba(0, 0, 0, 0.05)',
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.9,
          shadowRadius: 30,
          elevation: 5,
        }}>
        {showFooter && (
          <Stack
            direction="row"
            height={'auto'}
            alignItems={'center'}
            className="dialog-footer"
            justifyContent={showFooterCancel ? 'space-between' : 'flex-end'}
            style={{ ...responsiveStyles }}>
            {showFooterCancel && (
              <Button
                type="secondary"
                style={{ backgroundColor: GlobalStyles.MONO_WHITE, paddingHorizontal: 0 }}
                onPress={_handleCancel}
                textStyle={{ color: GlobalStyles.PRIMARY_500 }}
                text="Cancel"
              />
            )}
            {steps[currentStep].footerButtons && <Stack direction="row">{steps[currentStep].footerButtons?.map((button) => button)}</Stack>}
          </Stack>
        )}
      </View>
    </Stack>
  )
}

export { StepSlider }
