import React, { memo, useRef, useState } from 'react'
import { EmailResetConfirmation } from './email-reset/email-reset-confirmation'
import { EmailResetForm } from './email-reset/email-reset-form'
import { CreatePasswordForm } from './sms-reset/create-password-form'
import { PasswordChangeConfirmation } from './sms-reset/password-change-confirmation'
import { SecurityCodeForm } from './sms-reset/security-code-form'
import { SMSResetForm } from './sms-reset/sms-reset-form'
import { StepSlider } from '@components'
import { PasswordResetOption } from './option'

const ResetPasswordOptions = memo(() => {
  const [currentStep, setCurrentStep] = useState<number>(0) // Current step of the password reset flow
  const [selectedFlow, setSelectedFlow] = useState<number>(0)
  const resetOptionRef = useRef()

  const modalType = 'resetPasswordForm'
  const _handleFormSubmit = () => {
    setCurrentStep(currentStep + 1)
  }

  const _handleResetOption = (value: any) => {
    setSelectedFlow(value.id as number)
  }

  const initialStep = [
    {
      title: 'Forgot Your Password?',
      content: <PasswordResetOption key={'PasswordResetOptionForm'} onChange={_handleResetOption} onSubmit={_handleFormSubmit} />,
      ref: resetOptionRef,
    },
  ]

  const resetEmailRef = useRef()
  const resetEmailConfirmRef = useRef()
  const emailResetSteps = [
    ...initialStep,
    {
      title: 'Forgot Your Password?',
      content: <EmailResetForm key={'EmailResetForm'} onSubmit={_handleFormSubmit} />,
      ref: resetEmailRef,
    },
    {
      title: 'An Email has Been Sent!',
      content: <EmailResetConfirmation key={'EmailResetConfirmation'} />,
      ref: resetEmailConfirmRef,
    },
  ]

  const resetSMSRef = useRef()
  const resetSMSCodeRef = useRef()
  const resetSMSPasswordRef = useRef()
  const resetSMSConfirmRef = useRef()
  const smsResetSteps = [
    ...initialStep,
    {
      title: 'Find Your Account',
      content: <SMSResetForm key={'SMSResetForm'} onSubmit={_handleFormSubmit} />,
      ref: resetSMSRef,
    },
    {
      title: 'Enter Security Code',
      content: <SecurityCodeForm key={'SecurityCodeForm'} onSubmit={_handleFormSubmit} />,
      ref: resetSMSCodeRef,
    },
    {
      title: 'Choose a New Password',
      content: <CreatePasswordForm key={'CreatePasswordForm'} onSubmit={_handleFormSubmit} />,
      ref: resetSMSPasswordRef,
    },
    {
      title: 'Password Changed',
      content: <PasswordChangeConfirmation key={'PasswordChangeConfirmation'} />,
      ref: resetSMSConfirmRef,
    },
  ]

  const resetFlows = [emailResetSteps, smsResetSteps]

  return (
    <StepSlider
      steps={resetFlows[selectedFlow]}
      currentStep={currentStep}
      modalType={modalType}
      showHeaderIcon={false}
      showFooter={false}
    />
  )
})

ResetPasswordOptions.displayName = 'ResetPasswordOptions'

export { ResetPasswordOptions }
