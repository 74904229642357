import { API, Organisation, User } from '../types'
import { Department } from '../types/departments'
import { HttpClient } from './api.service'

export const departmentsService = {
  listDepartments: (params?: Organisation.iListOrganisationsParams) => {
    return HttpClient.get<null, Department.iDeptListResponse[]>({
      endpoint: `${API.DepartmentsEndpoints.BASE}`
        .concat(`?query=${params?.query ?? ''}`)
        .concat(`&page=${params?.page ?? '1'}`)
        .concat(`&per_page=${params?.per_page ?? '20'}`),
      payload: null,
    })
  },
  create: (payload: Department.iDepartmentPayload) => {
    return HttpClient.post<Department.iDepartmentPayload, Department.iDepartment>({
      endpoint: API.DepartmentsEndpoints.BASE,
      payload,
    })
  },
  listUsers: (eid: string) => {
    return HttpClient.get<null, User.iUser[]>({
      endpoint: API.DepartmentsEndpoints.USERS.replace(':role', eid),
      payload: null,
    })
  },
  attachUsers: (eid: string, payload: Department.iAttachUsersToDepartmentPayload) => {
    return HttpClient.post<Department.iAttachUsersToDepartmentPayload, Department.iDepartment>({
      endpoint: API.DepartmentsEndpoints.ATTACH.replace(':role', eid),
      payload,
    })
  },
  update: (eid: string, payload: Department.iDepartmentPayload) => {
    return HttpClient.put<Department.iDepartmentPayload, Department.iDepartment>({
      endpoint: API.DepartmentsEndpoints.UPDATE.replace(':role', eid),
      payload,
    })
  },
  generateUploadUrl: ({ file_name }: { file_name: string }) => {
    return HttpClient.post<null, User.iSignedImageUploadURLResponse>({
      endpoint: `${API.DepartmentsEndpoints.GENERATE_URL}`.replace(':file_name', file_name),
    })
  },
}
