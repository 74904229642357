import { StyleSheet } from 'react-native'
import { GlobalStyles } from '../globalStyles'

export const IconColors = {
  disabled: GlobalStyles.SLATE_300,
  primary: GlobalStyles.PRIMARY_500,
  secondary: GlobalStyles.SLATE_300,
  shadow: GlobalStyles.PRIMARY_500,
}

export const DropdownStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
    border: '',
    height: 50,
    minWidth: 120,
    paddingHorizontal: 17,
    paddingVertical: 15,
    zIndex: 1,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: GlobalStyles.SLATE_100,
  },
  select: {
    position: 'absolute',
    backgroundColor: GlobalStyles.MONO_WHITE,
    width: '90%',
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 12,
    borderColor: GlobalStyles.SLATE_100,
    maxHeight: 300,
  },
  item: {
    marginHorizontal: 0,
    marginVertical: 0,
    backgroundColor: GlobalStyles.MONO_WHITE,
  },
  selectedLabel: {
    fontSize: 14,
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chevronIcon: {
    paddingTop: 2,
    justifyContent: 'center',
    width: GlobalStyles.ICON_SIZE,
    height: GlobalStyles.ICON_SIZE,
  },
  iconPositionRight: {
    position: 'absolute',
    marginRight: GlobalStyles.MARGIN_SIZES.base,
  },
  placeholder: {
    color: GlobalStyles.SLATE_700,
  },

  // Shadowed styles
  borderless: {
    borderWidth: 0,
  },

  shadowedDropdown: {
    paddingHorizontal: 0,
    paddingVertical: GlobalStyles.PADDING_SIZES.xs,
    marginTop: GlobalStyles.MARGIN_SIZES['2xs'],
    borderRadius: GlobalStyles.BORDER_RADIUS,
  },
  shadowedIconContainer: {
    width: GlobalStyles.HEADER_FONT_SIZES.MEDIUM,
    height: GlobalStyles.HEADER_FONT_SIZES.MEDIUM,
  },
})
