import { forwardRef } from 'react'
import { Text } from '../Text'
import { View } from '../View'
import { Stack } from '../Stack'
import { LabelDefaults, LabelProps } from './types'
import { LabelStyles, LabelTextStyles, LabelTransparentStyles, LabelTransparentTextStyles, LabelSizes } from './styles'
import { StackV2 } from '../StackV2'

// TODO: Refactor variants to make it more dynamic
export const Label = (
  {
    style,
    children,
    textStyle,
    size = LabelDefaults.SIZE,
    type = LabelDefaults.TYPE,
    space = LabelDefaults.SPACE,
    transparent = false,
    textSize = LabelDefaults.TEXT_SIZE,
    ...props
  }: LabelProps,
  ref?: any
) => {
  return (
    <View
      style={[
        LabelStyles[type],
        props.rounded && { borderRadius: 100 },
        transparent && LabelTransparentStyles[type],
        LabelSizes[size],
        style,
      ]}
      {...props}
      ref={ref}>
      <StackV2 justifyContent="center" spacing={2}>
        {props.leftElement ?? null}
        {children ?? (
          <Text
            type="paragraph"
            size={textSize}
            style={[LabelTextStyles[type], transparent && LabelTransparentTextStyles[type], textStyle]}>
            {props.text}
          </Text>
        )}
        {props.rightElement ?? null}
      </StackV2>
    </View>
  )
}

export default forwardRef(Label)
