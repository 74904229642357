import { Button, GlobalStyles, Icon, Stack, StackV2, Text, View } from '@andromeda'
import { ModalFooter, ModalHeader } from '@components'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext } from 'react'

export const KeyInfoMissing = () => {
  const { closeModal, options } = useContext(ModalContext)
  const props = options?.modalProps?.keyInfoMissing

  const _handleContinue = () => {
    if (props.onConfirm) props.onConfirm()
    else closeModal(ModalPages.KEY_INFO_MISSING)
  }

  const _addDetails = () => {
    if (props.addDetails) props.addDetails()
    else closeModal(ModalPages.KEY_INFO_MISSING)
  }

  return (
    <StackV2 className="dialog-container" height="auto" direction="column" style={{ backgroundColor: GlobalStyles.MONO_WHITE }}>
      <ModalHeader
        modalContext="keyInfoMissing"
        modalTitle={'Key Information Missing'}
        textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
      />
      <StackV2 direction="column" alignItems="flex-start" spacing={2} className="dialog-content">
        <Text>This information can be critical in providing talent and users the details of the deliverables:</Text>
        <Text size="sm">Completing recommended fields will:</Text>

        <StackV2 direction="row" spacing={2} alignItems="flex-start">
          <Text style={{ lineHeight: 15 }}>
            <Icon.Check color={GlobalStyles.SLATE_300} />
          </Text>
          <Text size="sm">Enable automated reminders.</Text>
        </StackV2>

        <StackV2 direction="row" spacing={2} alignItems="flex-start">
          <Text style={{ lineHeight: 15 }}>
            <Icon.Check color={GlobalStyles.SLATE_300} />
          </Text>
          <Text size="sm">Enable prompts to mark deliverables as done.</Text>
        </StackV2>

        <StackV2 direction="row" spacing={2} alignItems="flex-start">
          <Text style={{ lineHeight: 15 }}>
            <Icon.Check color={GlobalStyles.SLATE_300} />
          </Text>
          <Text size="sm">Improve reporting.</Text>
        </StackV2>
      </StackV2>

      {/* <View style={{ paddingHorizontal: GlobalStyles.PADDING_SIZES.md, width: '100%' }}>
                <Divider style={{ borderColor: GlobalStyles.SLATE_100, marginVertical: 0 }} />
            </View> */}
      {/* <ModalFooter
                backgroundColor={GlobalStyles.SLATE_900}
                wrapperStyle={{ justifyContent: 'space-between' }}
                leftComponent={
                    <Button
                        type="secondary"
                        style={{ backgroundColor: GlobalStyles.COLOR_TRANSPARENT }}
                        onPress={() => onSubmit && onSubmit()}
                        textStyle={{ color: GlobalStyles.MONO_WHITE, fontSize: GlobalStyles.FONT_SIZES.BASE }}
                        text="Cancel"
                    />
                }
                onButtonPress={() => handleSubmitInvite()}
                buttonText={'Create Account'}
                disabled={selectedRoles.length === 0}
                divider={false}
                loading={status === 'loading'}
            /> */}
      <ModalFooter
        backgroundColor={GlobalStyles.MONO_WHITE}
        wrapperStyle={{
          justifyContent: 'space-between',
        }}
        leftComponent={
          <Button
            type="secondary"
            style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
            onPress={() => _addDetails()}
            textStyle={{ color: GlobalStyles.PRIMARY_500 }}
            text="Add Details"
          />
        }
        onButtonPress={_handleContinue}
        buttonText={'Continue Without Details'}
        rightIcon={<Icon.ChevronRight color={GlobalStyles.MONO_WHITE} />}
        buttonVariant="danger"
        disabled={false}
      />
    </StackV2>
  )
}
