const BORDER_RADIUS = 12
const BUTTON_BORDER_RADIUS = 8

const MAX_BORDER_RADIUS = 100
const BORDER_WIDTH = 1
const PADDING = 10
const PADDING_HORIZONTAL = 20
const ALIGN_ITEMS = 'center'
const JUSTIFY_CONTENT = 'center'
const DISPLAY = 'flex'
const FLEX_DIRECTION = 'row'
const TEXT_ALIGN = 'left'
const ICON_SIZE = 16

const SLATE_50 = '#F2F3F5'
const SLATE_100 = '#E2E4E9'
const SLATE_200 = '#C2C6D1'
const SLATE_300 = '#A2A8B9'
const SLATE_400 = '#828AA1'
const SLATE_500 = '#656D86'
const SLATE_600 = '#565C72'
const SLATE_700 = '#464C5D'
const SLATE_800 = '#373B49'
const SLATE_900 = '#282B35'
const SLATE_1000 = '#1D1E22'

const MONO_WHITE = '#FFFFFF'
const MONO_GRAY = '#F5F5F5'
const MONO_CHARCOAL = '#222222'
const MONO_BLACK = '#1D1E22'
const ORANGE = '#FF6138'

const PRIMARY_500 = '#065DE0'
const PRIMARY_600 = '#296F87'
const CHAT_BUBBLE = '#CDDFF9'

const FONT_WEIGHT_MEDIUM = '500' as const
const FONT_WEIGHT_REGULAR = '400' as const

const STATUS_POSITIVE = '#0B845C'
const STATUS_YIELD = '#FDCA61'
const STATUS_NEGATIVE = '#E70D32'
const STATUS_DRAFT = '#FDCA61'
const STATUS_INFO = '#3885FA'

const COLOR_TRANSPARENT = 'transparent'
const OVERFLOW = 'hidden'

const WINDOW_BREAKPOINTS = {
  XTRA_SMALL: 320,
  SMALL: 440,
  MEDIUM: 720,
  LARGE: 1080,
  XTRA_LARGE: 1480,
}
const BADGE_SIZES = {
  PRIMARY: 32,
  SECONDARY: 24,
}

const AVATAR_SIZES = {
  XS: 30,
  SMALL: 32,
  MEDIUM: 40,
  LARGE: 56,
  XLARGE: 64,
  '2XLARGE': 80,
  '3XLARGE': 96,
}

const LABEL_SIZES = {
  SMALL: {
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  MEDIUM: {
    paddingVertical: 6,
    paddingHorizontal: 10,
  },
  LARGE: {
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
}

const FONT_SIZES = {
  XTRA_LARGE: 24,
  LARGE: 20,
  MEDIUM: 18,
  BASE: 16,
  SMALL: 14,
  TINY: 12,
}

const HEADER_FONT_SIZES = {
  XTRA_LARGE: 80,
  LARGE: 48,
  MEDIUM: 40,
  BASE: 32,
  SMALL: 24,
  TINY: 18,
}

const POSITION = {
  POSITION_RELATIVE: 'relative',
  POSITION_ABSOLUTE: 'absolute',
}
const STATUS_SIZES = {
  WIDTH: 10,
  HEIGHT: 10,
}

const STATUS_BORDER_RADIUS = 2
const STATUS_BORDER_RADIUS_ROUNDED = 20

const RADIO_SIZES = {
  RADIOBUTTON: 24,
  RADIOBUTTONINNER: 12,
}

const BUTTON_SIZES = {
  LARGE: 48,
  MEDIUM: 40,
  SMALL: 15,
}

const PAGINATION_BUTTONS = {
  WIDTH: 40,
  HEIGHT: 40,
}

const PADDING_SIZES = {
  xxs: 8,
  xs: 14,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 44,
  xxl: 64,
  none: 0,
}

const JOBS_PADDINGS = {
  base: 12,
  large: 40,
}

const MARGIN_SIZES = {
  xl: 26,
  large: 24,
  base: 16,
  sm: 14,
  xs: 12,
  '2xs': 8,
  '3xs': 4,
}

const INPUT_SHADOWED = {
  shadowColor: 'rgba(0, 0, 0, 0.1)',
  shadowOffset: { width: 0, height: 1 },
  shadowOpacity: 0.9,
  shadowRadius: 40,
  elevation: 5,
}

const BUTTON_SHADOWED = {
  shadowColor: 'rgba(0, 0, 0, 0.1)',
  shadowOffset: { width: 0, height: 2 },
  shadowOpacity: 0.5,
  shadowRadius: 2,
  elevation: 1,
}

const BOX_SHADOW = `1px 2px 5px ${SLATE_100}`

const CALENDAR_EVENT_BG = 'rgba(52, 140, 170, 0.2)'

export const GlobalStyles = {
  BORDER_RADIUS,
  BUTTON_BORDER_RADIUS,
  BORDER_WIDTH,
  MAX_BORDER_RADIUS,
  PADDING,
  PADDING_HORIZONTAL,
  MARGIN_SIZES,
  JUSTIFY_CONTENT,
  ALIGN_ITEMS,
  DISPLAY,
  FLEX_DIRECTION,
  TEXT_ALIGN,
  ICON_SIZE,
  SLATE_50,
  SLATE_100,
  SLATE_200,
  SLATE_300,
  SLATE_400,
  SLATE_500,
  SLATE_600,
  SLATE_700,
  SLATE_800,
  SLATE_900,
  SLATE_1000,
  MONO_WHITE,
  MONO_GRAY,
  MONO_CHARCOAL,
  MONO_BLACK,
  ORANGE,
  PRIMARY_500,
  PRIMARY_600,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
  STATUS_POSITIVE,
  STATUS_YIELD,
  STATUS_NEGATIVE,
  STATUS_DRAFT,
  STATUS_INFO,
  COLOR_TRANSPARENT,
  OVERFLOW,
  WINDOW_BREAKPOINTS,
  AVATAR_SIZES,
  BADGE_SIZES,
  FONT_SIZES,
  HEADER_FONT_SIZES,
  POSITION,
  STATUS_SIZES,
  STATUS_BORDER_RADIUS,
  STATUS_BORDER_RADIUS_ROUNDED,
  RADIO_SIZES,
  BUTTON_SIZES,
  PADDING_SIZES,
  LABEL_SIZES,
  INPUT_SHADOWED,
  BUTTON_SHADOWED,
  CALENDAR_EVENT_BG,
  BOX_SHADOW,
  CHAT_BUBBLE,
}
