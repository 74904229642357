import React, { Children, forwardRef, Fragment, ReactElement, ReactNode } from 'react'
// import { applyResponsiveStyles } from '../helpers/responsive'
import { GlobalStyles } from '../globalStyles'
import { View } from '../View'
import { StackColumnStyles, StackStyles } from './styles'
import { SpacedChildrenProps, StackCellProps, StackDefaults, StackProps } from './types'

type ReactChildArray = ReturnType<typeof Children.toArray>

function groupedChildren(children: ReactNode): ReactChildArray {
  const childrenArray = Children.toArray(children)
  return childrenArray.reduce((reducedChildren: ReactChildArray, child) => {
    if ((child as ReactElement<any>).type === Fragment) {
      return reducedChildren.concat(groupedChildren((child as ReactElement<any>).props.children))
    }
    reducedChildren.push(child)
    return reducedChildren
  }, [])
}

const getSpacedChildren = ({ children, space, direction, reversed, border, rounded, childrenStyle, divider }: SpacedChildrenProps): any => {
  let mappedChildren = Children.toArray(groupedChildren(children))
  const numberofChildren = mappedChildren.length
  mappedChildren = reversed ? [...mappedChildren].reverse() : mappedChildren

  let getBorderedChild = (child: JSX.Element, index: number): JSX.Element => {
    if (!border) return child
    if (index === 0) return <StackCell style={border ? { borderWidth: 1 } : {}} element={child} />

    {
      /* Reversed direction support */
    }
    if (direction && direction.includes(StackDefaults.DIRECTION)) {
      if (space !== 'none') return <StackCell style={[StackStyles.bordered, StackStyles.spaced]} element={child} />
      return <StackCell style={StackStyles.bordered} element={child} />
    } else {
      if (space !== 'none') return <StackCell style={[StackColumnStyles.bordered, StackColumnStyles.spaced]} element={child} />
      return <StackCell style={StackColumnStyles.bordered} element={child} />
    }
  }

  let getRoundedChild = (child: JSX.Element, index: number) => {
    if (!rounded) return child

    {
      /* Reversed direction support */
    }
    if (space !== 'none' && direction) {
      if (direction.includes(StackDefaults.DIRECTION)) {
        return <StackCell style={[StackStyles.roundedLeft, StackStyles.roundedRight]} element={child} />
      } else {
        return <StackCell style={[StackColumnStyles.roundedTop, StackColumnStyles.roundedBottom]} element={child} />
      }
    }

    {
      /* Reversed direction support */
    }
    if (index === 0 && direction) {
      if (direction.includes(StackDefaults.DIRECTION)) {
        return <StackCell style={StackStyles.roundedLeft} element={child} />
      } else {
        return <StackCell style={StackColumnStyles.roundedTop} element={child} />
      }

      {
        /* Reversed direction support */
      }
    } else if (numberofChildren - 1 == index && direction) {
      if (direction.includes(StackDefaults.DIRECTION)) {
        return <StackCell style={StackStyles.roundedRight} element={child} />
      } else {
        return <StackCell style={StackColumnStyles.roundedBottom} element={child} />
      }
    } else return child
  }

  let getDividedChild = (child: JSX.Element, index: number) => {
    if (!divider) return child
    if (index !== 0) {
      return <StackCell style={[childrenStyle, StackDefaults.DIRECTION ? { borderLeftWidth: 1 } : { borderTopWidth: 1 }]} element={child} />
    } else {
      return <StackCell style={[childrenStyle]} element={child} />
    }
  }

  mappedChildren = mappedChildren.map((child: any, index: number) => {
    let modifiedChild = getBorderedChild(child, index)
    modifiedChild = getRoundedChild(modifiedChild, index)
    modifiedChild = getDividedChild(modifiedChild, index)

    return (
      <Fragment key={child.key ?? `spaced-child-${index}`}>
        <StackCell style={childrenStyle} element={modifiedChild} />

        {/* Reversed direction support */}
        {index + 1 !== mappedChildren.length && direction ? (
          <View style={[direction.includes(StackDefaults.DIRECTION) ? StackStyles[space] : StackColumnStyles[space]]} />
        ) : null}
      </Fragment>
    )
  })

  return mappedChildren
}

const Stack = (
  {
    children,
    style,
    childrenStyle,
    space = StackDefaults.SPACE,
    direction = StackDefaults.DIRECTION,
    align = StackDefaults.ALIGN,
    justify = StackDefaults.JUSTIFY,
    border = false,
    reversed = false,
    rounded = false,
    divider = false,
    flex,
    width = StackDefaults.WIDTH,
    height = StackDefaults.HEIGHT,
    ...props
  }: StackProps,
  ref?: any
) => {
  return (
    <View
      style={[
        StackStyles.default,
        {
          flex: flex,
          flexDirection: direction,
          alignItems: align,
          justifyContent: justify,
          width: width,
          height: height,
        },
        style,
        // applyResponsiveStyles(props),
        rounded ? { borderRadius: GlobalStyles.BORDER_RADIUS } : {},
      ]}
      ref={ref}>
      {getSpacedChildren({ children, space, direction, reversed, border, divider, rounded, childrenStyle })}
    </View>
  )
}

const StackCell: React.FC<StackCellProps> = ({ element, style }) => {
  return React.cloneElement(element, {
    style: [style, element.props.style],
  })
}

export default forwardRef(Stack)
