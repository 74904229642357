import { Button, GlobalStyles, Icon, RichEditor, RichReader, Text, iCommentThread } from '@andromeda'
import { Avatar, AvatarGroup, Stack, Typography, Button as MUIButton, buttonBaseClasses, buttonClasses } from '@mui/material'
import Box from '@mui/material/Box'
import { Types } from '@orbit'
import moment from 'moment'
import { Fragment, KeyboardEvent, forwardRef, useCallback, useEffect, useState } from 'react'
import { LoadingType } from '../Loading/types'
import { getProfilePic } from '../helpers'
import { useComments } from 'src/components/comments/useComments'
import { RichEditorValue } from '../RichEditor/RichEditor'

const CommentThread = (
  {
    isReply,
    eid,
    creator,
    content,
    created_at,
    replies,
    onPressDelete,
    onPressEdit,
    onPressReply,
    onSubmit,
    wrapperStyle,
    wrapperChildrenStyle,
    buttonsWrapperStyle,
    buttonChildrenStyle,
    space = 'none',
    commentStyle,
    isSaving,
    isDeleting = false,
    actionCommentId = null,
    showDelete = true,
    showEdit = true,
    showReply = true,
    depth = 0,
    usersList = [],
    talentsList = [],
    ...props
  }: iCommentThread,
  ref?: any
) => {
  const [isReplying, setIsReplying] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [commentIsDeleting, setCommentIsDeleting] = useState<boolean>(false)
  const [reply, setReply] = useState<string | null>(null)
  const [activeComment, setActiveComment] = useState<iCommentThread | null>(null)
  const { state, updateState, setState } = useComments()

  const isOwner = creator?.eid === props.editorId
  const bubbleColor = !isOwner ? GlobalStyles.CHAT_BUBBLE : GlobalStyles.SLATE_50
  const bubblePosition = !isOwner
    ? {
        left: 'auto',
        right: '-5px',
        bottom: '-13px',
        transform: 'rotate(-30deg)',
      }
    : {
        left: '-5px',
        right: 'auto',
        bottom: '-13px',
        transform: 'rotate(-60deg)',
      }

  const timestampFormat = 'D MMM, h:mma'
  const loadingType = 'blue' as LoadingType

  const _updateReply = useCallback((e: string) => {
    setReply(e)
  }, [])

  const _handleEditorCancel = () => {
    setIsReplying(false)
    setIsEditing(false)
    setReply('')
  }

  const _handleAction = (action: 'edit' | 'reply' | 'delete', e: iCommentThread) => {
    switch (action) {
      case 'edit':
      case 'reply':
        const isReply = action === 'reply'
        const isEdit = action === 'edit'
        let stateUpdate: Partial<typeof state> = {}

        if (state && state.activeComment && state.activeComment.eid !== e?.eid) {
          const noReplyChanges = state.isReply && !state.commentContent
          const noEditChanges = state.isEdit && state.commentContent === state.activeComment?.content

          if (noReplyChanges || noEditChanges) {
            stateUpdate = { showDiscardDialog: false, toDiscardComment: state?.activeComment, pendingComment: e, isReply, isEdit }
          } else {
            stateUpdate = { showDiscardDialog: true, pendingComment: e, isReply, isEdit }
          }
        } else {
          stateUpdate = { activeComment: e, isReply, isEdit }
          setIsEditing(isEdit)
          setIsReplying(isReply)
        }

        updateState({ ...stateUpdate, commentContent: isEdit ? e.content : '' })
        setActiveComment(e)
        break
      case 'delete':
        onPressDelete && onPressDelete(e)
        break
      default:
        break
    }
  }

  const _handleSubmit = (content: RichEditorValue, onSuccess: (callback: () => void) => void) => {
    setState && setState({ isEdit: false, isReply: false, showDiscardDialog: false })
    onSubmit && onSubmit(content, onSuccess)
  }

  const _renderEditButton = (creator: Types.Comment.iCreator) => {
    if (showEdit && ((props.editorId && creator.eid === props.editorId) || props.allEdit)) {
      return (
        <>
          {depth === 0 && showReply && <Stack sx={{ borderLeft: `1px solid ${GlobalStyles.MONO_BLACK}`, height: '24px', opacity: 0.1 }} />}
          <MUIButton variant="text" onClick={() => _handleAction('edit', { eid, content, creator, created_at, replies })}>
            Edit
          </MUIButton>
        </>
      )
    }
    return <></>
  }

  const _renderDeleteButton = (creator: Types.Comment.iCreator) => {
    if (showDelete && ((props.editorId && creator.eid === props.editorId) || props.allDelete)) {
      return (
        <>
          <Stack sx={{ borderLeft: `1px solid ${GlobalStyles.MONO_BLACK}`, height: '24px', opacity: 0.1 }} />
          <MUIButton
            onClick={() => _handleAction('delete', { eid, content, creator, created_at, replies })}
            variant="text"
            disabled={commentIsDeleting}>
            Delete
          </MUIButton>
        </>
      )
    }
    return <></>
  }

  const _handleKeyDownEvent = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      const content = state.commentContent?.replaceAll(/(<br>|\\n)/g, '')

      if (content?.trim() === '') {
        _handleEditorCancel()
        setState({ showDiscardDialog: false, isEdit: false, isReply: false })
      } else {
        updateState({ showDiscardDialog: true, toDiscardComment: undefined })
      }
    }
  }

  useEffect(() => {
    let content = ''

    if (isReplying && activeComment) {
      onPressReply && onPressReply(activeComment)
      onPressEdit && onPressEdit(null)
    }

    if (isEditing && activeComment) {
      content = activeComment.content || ''
      onPressEdit && onPressEdit(activeComment)
      onPressReply && onPressReply(null)
    }

    const setReplyTO = setTimeout(() => {
      setReply(content)
    }, 100)

    return () => {
      if (setReplyTO) clearTimeout(setReplyTO)
    }
  }, [activeComment, isReplying, isEditing, onPressReply, onPressEdit])

  useEffect(() => {
    const { showDiscardDialog, toDiscardComment, pendingComment, commentContent, isEdit, isReply } = state

    if (!showDiscardDialog && toDiscardComment) {
      if (toDiscardComment.eid === eid) {
        setIsReplying(false)
        setIsEditing(false)
        setReply('')
      }

      if (pendingComment?.eid === eid) {
        setIsReplying(isReply)
        setIsEditing(isEdit)
      }

      setActiveComment(pendingComment || null)
      setState({
        activeComment: pendingComment,
        commentContent,
        showDiscardDialog,
        isEdit,
        isReply,
      })
    }
  }, [state, setState, eid])

  useEffect(() => {
    setCommentIsDeleting(isDeleting && eid === actionCommentId)
  }, [isDeleting])

  useEffect(() => {
    if (!isSaving) {
      setIsEditing(false)
      setIsReplying(false)
    }
  }, [isSaving])

  return (
    <Stack
      className="thread"
      style={wrapperStyle}
      width={'100%'}
      direction="row"
      spacing={2}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      height={'auto'}>
      <Avatar
        // size={'sm'}
        // placeHolder={
        //     <Text
        //         style={{
        //             textAlign: 'center',
        //             fontSize: GlobalStyles.FONT_SIZES.LARGE,
        //         }}>
        //         {getInitials(creator?.name)}
        //     </Text>
        // }
        alt={creator?.name}
        src={creator.profile_image?.full_url}
      />
      <input type="hidden" value={`${eid}`} />
      <Stack flex={1} rowGap="16px">
        <Stack alignItems={'flex-start'} justifyContent={'flex-start'} rowGap="6px">
          <Stack
            direction="row"
            height="40px"
            width={'100%'}
            spacing={2}
            justifyContent="space-between"
            alignItems={'center'}
            style={{ flexWrap: 'wrap' }}>
            {/* <Stack direction="row" spacing={1} width={'auto'} alignItems={'center'}> */}
            <Typography fontSize="14px" color={GlobalStyles.SLATE_700}>
              {creator.name}
            </Typography>
            {/* {isReply && (
              <Text size="tiny" style={{ color: GlobalStyles.SLATE_500 }}>
                commented
              </Text>
            )} */}
            {/* </Stack> */}
            <Typography fontSize="12px" color={GlobalStyles.SLATE_500}>
              {moment(new Date(created_at)).format(timestampFormat)}
            </Typography>
          </Stack>
          <Stack
            sx={{
              backgroundColor: bubbleColor,
              width: 'calc(100% - 10px)',
              position: 'relative',
              borderRadius: '12px',
              marginRight: isOwner ? '22px !important' : 0,
              paddingX: '12px',
              boxSizing: 'border-box',
              ['&:after']: {
                content: "' '",
                position: 'absolute',
                width: '0',
                height: '0',
                border: '12px solid',
                borderColor: `${bubbleColor} ${bubbleColor} transparent transparent`,
                ...bubblePosition,
              },
              '>div': {
                inlineSize: 'calc(100% - 10px)',
                '>p': {
                  wordBreak: 'break-word',
                },
              },
            }}>
            <RichReader initialValue={content ?? ''} commentStyle={commentStyle} mentions={{ users: usersList, talents: talentsList }} />
          </Stack>
          <Stack
            direction="row"
            height={'auto'}
            alignItems="center"
            sx={{
              columnGap: '6px',
              height: '24px',
              [`.${buttonBaseClasses.root}.${buttonClasses.root}`]: {
                color: GlobalStyles.SLATE_500,
                fontSize: '12px',
                fontWeight: 400,
                padding: 0,
              },
            }}>
            {depth === 0 && showReply && (
              <MUIButton onClick={() => _handleAction('reply', { eid, content, creator, created_at, replies })} variant="text">
                Reply
              </MUIButton>
            )}
            {_renderEditButton(creator)}
            {_renderDeleteButton(creator)}
          </Stack>
          {(isReplying || isEditing) && (
            <RichEditor
              key={`${reply?.toString()}-${isReplying}-${isEditing}}`}
              initialValue={reply}
              onClickCancel={_handleEditorCancel}
              onClickSave={_handleSubmit}
              mentions={{ users: usersList, talents: talentsList }}
              onChange={(e) => {
                updateState && updateState({ commentContent: e })
              }}
              onKeyDown={_handleKeyDownEvent}
              isSaving={isSaving}
              type={props.type}
            />
          )}
        </Stack>
        <Replies
          depth={depth + 1}
          replies={replies}
          onPressDelete={onPressDelete}
          onPressEdit={onPressEdit}
          onPressReply={onPressReply}
          onSubmit={_handleSubmit}
          wrapperStyle={wrapperStyle}
          wrapperChildrenStyle={wrapperChildrenStyle}
          buttonsWrapperStyle={buttonsWrapperStyle}
          buttonChildrenStyle={buttonChildrenStyle}
          space={space}
          commentStyle={commentStyle}
          isSaving={isSaving}
          isDeleting={isDeleting}
          actionCommentId={actionCommentId}
          showDelete={showDelete}
          showEdit={showEdit}
          showReply={showReply}
          editorId={props.editorId}
          allEdit={props.allEdit}
          allDelete={props.allDelete}
          type={props.type}
          usersList={usersList}
          talentsList={talentsList}
        />
      </Stack>
    </Stack>
  )
}

const Replies = ({ depth, replies, onPressDelete, onPressEdit, onPressReply, onSubmit, ...props }: Partial<iCommentThread>) => {
  const [showSubthreads, setShowSubThreads] = useState(false)

  const _generateKey = (data: Types.Comment.iComment) => {
    let key = data.eid
    if (data.updated_at) {
      const updateDatetime = new Date(data.updated_at).getTime()
      key = `${data.eid}-${updateDatetime}`
    }
    return key
  }

  if (!replies || replies.length == 0) return null
  if (replies.length === 1) {
    return (
      <CommentThread
        depth={depth}
        key={_generateKey(replies[0])}
        isReply={true}
        eid={replies[0].eid}
        content={replies[0].content}
        creator={replies[0].creator}
        created_at={replies[0].created_at}
        replies={replies[0].children}
        onPressDelete={onPressDelete}
        onPressEdit={onPressEdit}
        onPressReply={onPressReply}
        onSubmit={onSubmit}
        wrapperStyle={props.wrapperStyle}
        wrapperChildrenStyle={props.wrapperChildrenStyle}
        buttonsWrapperStyle={props.buttonsWrapperStyle}
        buttonChildrenStyle={props.buttonChildrenStyle}
        space={props.space}
        commentStyle={props.commentStyle}
        isSaving={props.isSaving}
        isDeleting={props.isDeleting}
        actionCommentId={props.actionCommentId}
        showDelete={props.showDelete}
        showEdit={props.showEdit}
        showReply={props.showReply}
        editorId={props.editorId}
        allEdit={props.allEdit}
        allDelete={props.allDelete}
        type={props.type}
        usersList={props.usersList}
        talentsList={props.talentsList}
      />
    )
  } else {
    return (
      <Fragment>
        {showSubthreads ? (
          replies.map((reply) => (
            <CommentThread
              depth={depth}
              key={_generateKey(reply)}
              isReply={true}
              eid={reply.eid}
              content={reply.content}
              creator={reply.creator}
              created_at={reply.created_at}
              replies={reply.children}
              onPressDelete={onPressDelete}
              onPressEdit={onPressEdit}
              onPressReply={onPressReply}
              onSubmit={onSubmit}
              wrapperStyle={props.wrapperStyle}
              wrapperChildrenStyle={props.wrapperChildrenStyle}
              buttonsWrapperStyle={props.buttonsWrapperStyle}
              buttonChildrenStyle={props.buttonChildrenStyle}
              space={props.space}
              commentStyle={props.commentStyle}
              isSaving={props.isSaving}
              isDeleting={props.isDeleting}
              actionCommentId={props.actionCommentId}
              showDelete={props.showDelete}
              showEdit={props.showEdit}
              showReply={props.showReply}
              editorId={props.editorId}
              allEdit={props.allEdit}
              allDelete={props.allDelete}
              type={props.type}
              usersList={props.usersList}
              talentsList={props.talentsList}
            />
          ))
        ) : (
          <Stack
            flex={1}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            style={{ backgroundColor: GlobalStyles.SLATE_50, padding: GlobalStyles.PADDING }}
            borderRadius={2}>
            <Button onPress={() => setShowSubThreads(true)} transparent>
              <Stack spacing={2} direction={'row'} flex={1} alignItems="center">
                <Box>
                  <Text
                    style={{
                      color: GlobalStyles.PRIMARY_500,
                      textAlign: 'justify',
                    }}>
                    <Icon.CornerDownRight />
                  </Text>
                  <Text
                    style={{
                      color: GlobalStyles.PRIMARY_500,
                      textAlign: 'justify',
                    }}>
                    {`${replies.length} replies`}
                  </Text>
                </Box>
                <AvatarGroup
                  // offset={3}
                  total={replies.length}
                  // extraAvatarProps={{ size: 'sm', placeholderTextProps: { size: 'tiny' } }}
                >
                  {replies.map((subthread, subthreadIndex) => (
                    <Avatar
                      // size={'sm'}
                      // placeHolder={
                      //     <Text
                      //         style={{
                      //             textAlign: 'center',
                      //             fontSize: GlobalStyles.FONT_SIZES.LARGE,
                      //         }}>
                      //         {getInitials(subthread.creator?.name)}
                      //     </Text>
                      // }
                      alt={subthread.creator?.name}
                      src={getProfilePic(subthread.creator.profile_image)}
                      key={subthreadIndex}
                    />
                  ))}
                </AvatarGroup>
              </Stack>
            </Button>
            <Button onPress={() => setShowSubThreads(true)} transparent type="secondary" text="View thread" />
          </Stack>
        )}
      </Fragment>
    )
  }
}

export default forwardRef(CommentThread)
