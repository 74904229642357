import { GlobalStyles } from '@andromeda'
import * as yup from 'yup'

const lowercasePattern = /^(?=.*[a-z])[A-Za-z\d!@#$%^&*()_+=\-`~<>/\\[\]{}":"?.,~'|]{0,}$/g
const uppercasePattern = /^(?=.*[A-Z])[A-Za-z\d!@#$%^&*()_+=\-`~<>/\\[\]{}":"?.,~'|]{0,}$/g
const numberPattern = /^(?=.*[\d])[A-Za-z\d!@#$%^&*()_+=\-`~<>/\\[\]{}":"?.,~'|]{0,}$/g
const symbolPattern = /^(?=.*?[#?!@$%^&*-])[A-Za-z\d!@#$%^&*()_+=\-`~<>/\\[\]{}":"?.,~'|]{0,}$/g

const _hasFiveUnique = (password: string) => {
  let unique = 0
  const passwordArr = password.split('')
  const passwordArr2 = password.split('')
  passwordArr.forEach((char, idx) => {
    let charExist = 0
    passwordArr2.forEach((char2, idx2) => {
      if (idx !== idx2) {
        if (char === char2) charExist++
      }
    })
    if (charExist === 0) unique++
  })

  return unique >= 5
}

const passwordStrengthCalculate = (password: string) => {
  let totalPoints = 0
  let passedConditions = 0

  /**
   * has at least 8 characters (10pts)
   */
  if (password.length > 7) {
    totalPoints += 10
    passedConditions++
  }

  /**
   * has lowercase letter (5pts)
   */
  if (lowercasePattern.test(password)) {
    totalPoints += 5
    passedConditions++
  }

  /**
   * has uppercase letter (5pts)
   */
  if (uppercasePattern.test(password)) {
    totalPoints += 5
    passedConditions++
  }

  /**
   * has number (5pts)
   */
  if (numberPattern.test(password)) {
    totalPoints += 5
    passedConditions++
  }

  /**
   * has symbol / special character (10pts)
   */
  if (symbolPattern.test(password)) {
    totalPoints += 10
    passedConditions++
  }

  /**
   * has 5 unique characters (5pts)
   */
  if (_hasFiveUnique(password)) {
    totalPoints += 5
    passedConditions++
  }

  const grade = totalPoints + passedConditions * 10

  let text = ''
  let color = ''
  if (grade < 65) {
    text = 'Poor'
    color = GlobalStyles.STATUS_NEGATIVE
  } else if (grade >= 65 && grade < 80) {
    text = 'Fair'
    color = GlobalStyles.STATUS_YIELD
  } else if (grade >= 80 && grade < 100) {
    text = 'Good'
    color = GlobalStyles.PRIMARY_500
  } else if (grade >= 100) {
    text = 'Excellent'
    color = GlobalStyles.STATUS_POSITIVE
  }

  return {
    grade,
    text,
    color,
  }
}

export const yupPasswordSchema = yup
  .string()
  .required('The password field is required.')
  .min(8, 'Password should have atleast 8 characters.')
  .matches(lowercasePattern, 'Password should have atleast 1 lowercase letter.')
  .matches(uppercasePattern, 'Password should have atleast 1 uppercase letter.')
  .matches(uppercasePattern, 'Password should have atleast 1 number letter.')
  .matches(symbolPattern, 'Password should have atleast 1 special character.')

export default passwordStrengthCalculate
