import { GlobalStyles } from '@andromeda'
import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react'

export type WorkspaceTheme = {
  primaryColor: string
  secondaryColor: string
  tertiaryColor: string
  pageBGColor: string
}

const DEFAULTS = {
  primaryColor: '#272b34',
  secondaryColor: '#ff6138',
  tertiaryColor: '#663837',
  pageBGColor: GlobalStyles.SLATE_50,
}

export const WorkspaceThemeContext = createContext<{
  defaults: typeof DEFAULTS
  primaryColor: string
  setPrimaryColor: Dispatch<SetStateAction<string>> | (() => void)
  secondaryColor: string
  setSecondaryColor: Dispatch<SetStateAction<string>> | (() => void)
  tertiaryColor: string
  setTertiaryColor: Dispatch<SetStateAction<string>> | (() => void)
  pageBGColor: string
  setPageBGColor: Dispatch<SetStateAction<string>> | (() => void)
}>({
  defaults: DEFAULTS,
  primaryColor: DEFAULTS.primaryColor,
  setPrimaryColor: () => {},
  secondaryColor: DEFAULTS.secondaryColor,
  setSecondaryColor: () => {},
  tertiaryColor: DEFAULTS.tertiaryColor,
  setTertiaryColor: () => {},
  pageBGColor: DEFAULTS.pageBGColor,
  setPageBGColor: () => {},
})

export const WorkspaceThemeProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const { settings } = useAppSelector(selectWorkspace)

  const [primaryColor, setPrimaryColor] = useState(settings?.primary_color || DEFAULTS.primaryColor)
  const [secondaryColor, setSecondaryColor] = useState(settings?.secondary_color || DEFAULTS.secondaryColor)
  const [tertiaryColor, setTertiaryColor] = useState(settings?.tertiary_color || DEFAULTS.tertiaryColor)
  // !REFACTOR - typing to be corrected
  const [pageBGColor, setPageBGColor] = useState((settings as any)?.page_background_color || DEFAULTS.pageBGColor)

  useEffect(() => {
    if (settings) {
      setPrimaryColor(settings.primary_color || DEFAULTS.primaryColor)
      setSecondaryColor(settings.secondary_color || DEFAULTS.secondaryColor)
      setTertiaryColor(settings.tertiary_color || DEFAULTS.tertiaryColor)
      // !REFACTOR - typing to be corrected
      setPageBGColor((settings as any).page_background_color || DEFAULTS.pageBGColor)
    }
  }, [settings])

  return (
    <WorkspaceThemeContext.Provider
      value={{
        defaults: DEFAULTS,
        primaryColor,
        setPrimaryColor,
        secondaryColor,
        setSecondaryColor,
        tertiaryColor,
        setTertiaryColor,
        pageBGColor,
        setPageBGColor,
      }}>
      {children}
    </WorkspaceThemeContext.Provider>
  )
}
