import { Button, DropdownOption, GlobalStyles, Icon, Input, StackV2, Text } from '@andromeda'
import Box from '@mui/material/Box'
import {
  CircularProgress,
  FormHelperText,
  InputAdornment,
  Button as MUIButton,
  Typography,
  inputBaseClasses,
  outlinedInputClasses,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { Orbit, Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { resetMyAccountSlice } from '@redux/reducers/accountsReducer'
import { resetAgreementSlice } from '@redux/reducers/agreementsReducer'
import { fetchCurrentPermissions, login, me, resetAuthSlice, selectAuth } from '@redux/reducers/authReducer'
import { resetCommentSlice } from '@redux/reducers/commentsReducer'
import { resetDeliverableSlice } from '@redux/reducers/deliverableReducer'
import { resetInviteSlice, selectInvite, viewInvite } from '@redux/reducers/inviteReducer'
import { resetJobsSlice } from '@redux/reducers/jobsReducer'
import { resetPasswordRecoverySlice } from '@redux/reducers/passwordRecovery'
import { resetTalentProfileSlice } from '@redux/reducers/talentProfileReducer'
import { resetTalentSlice } from '@redux/reducers/talentReducer'
import { resetUserSlice } from '@redux/reducers/usersReducer'
import { fetchAllWorkspaces, resetWorkspaceSlice, selectWorkspace, setWorkspace } from '@redux/reducers/workspaceReducer'
import { identifyUser, trackEvent } from '@utils/tracking/helpers'
import TnC from '@utils/constants/tnc.json'
import { ModalContext } from '@utils/context/Modal.context'
import { NavigationContext } from '@utils/context/Navigation.context'
import { iConsentRes } from '@utils/types/auth'
import { useRouter } from 'next/router'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Textfield } from '../form/Textfield'
import { FormAutoComplete } from '../form/AutoComplete'
import { listIdp, redirectIdp, selectIdp } from '@redux/reducers/idpReducer'
import Image from 'next/image'
import { DevTool } from '@hookform/devtools'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ERROR_MESSAGES } from '@utils/constants/validation'
import { BrowserRefContext } from '@utils/context/BrowserRef.context'
import { Capacitor } from '@capacitor/core'
import { getStatus, requestPermission } from '@utils/functions/AppTracking'
import validateIDPEmail from '@utils/functions/validateIDPEmail'

const primaryTextColor = GlobalStyles.MONO_BLACK

interface iLogin {
  callback: () => void
  hideFooter: boolean
  submitText: string
  resetInvite: boolean
}

const Login = memo(({ callback, hideFooter, submitText, resetInvite = true }: Partial<iLogin>) => {
  const router = useRouter()

  const dispatch = useAppDispatch() // For dispatching Actions

  const { status, token, error, me: profile, loggedOut } = useAppSelector(selectAuth)
  const { status: workspaceStatus, workspaceList } = useAppSelector(selectWorkspace)
  const { list } = useAppSelector(selectIdp)
  const { invite } = useAppSelector(selectInvite)
  const doFetchAllPermissions = useCallback(() => dispatch(fetchCurrentPermissions()), [dispatch]) // For dispatching Actions

  const { toggleModal } = useContext(ModalContext)
  const { goToDashboard } = useContext(NavigationContext)
  const { setBrowserRef } = useContext(BrowserRefContext)

  /**
   * Input field values
   */
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        username: yup
          .string()
          .required('Username is required')
          .test('idpRegex', ERROR_MESSAGES.LOGIN.SIGN_IN_WITH_IDP, (value) => !validateIDPEmail(list, value))
          .test('inviteEmail', ERROR_MESSAGES.LOGIN.INVITE, (value) => {
            return invite && router.query.token && router.query.inviteEid ? invite.email === value : true
          }),
        password: yup.string().required('Password is required'),
      }),
    [list, invite, router.query.token, router.query.inviteEid]
  )

  const form = useForm<Types.Authentication.iAuthCredentials & { idp: DropdownOption }>({
    defaultValues: { username: '', password: '' },
    // @ts-expect-error
    resolver: yupResolver(validationSchema),
    mode: router.query.token && router.query.inviteEid ? 'onSubmit' : 'onChange',
  })
  const selectedIdp = useWatch({ control: form.control, name: 'idp' })
  /**
   * Custom Error message hooks
   */
  const [usernamErrorMessage, setUsernameErrorMessage] = useState<string | undefined>()
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | undefined>()

  /**
   * Turn secureTextEntry on/off hook
   */
  const [passwordUnmasked, setPasswordUnmasked] = useState<boolean>(false)

  const idpOptions = useMemo(() => list.map((idp) => ({ ...idp, id: idp.slug })), [list])

  const resetState = async () => {
    await Promise.all([
      dispatch(resetWorkspaceSlice()),
      dispatch(resetMyAccountSlice()),
      dispatch(resetAgreementSlice()),
      dispatch(resetCommentSlice()),
      dispatch(resetDeliverableSlice()),
      dispatch(resetJobsSlice()),
      dispatch(resetPasswordRecoverySlice()),
      dispatch(resetTalentProfileSlice()),
      dispatch(resetTalentSlice()),
      dispatch(resetUserSlice()),
      dispatch(resetAuthSlice()),
      ...(resetInvite ? [dispatch(resetInviteSlice())] : []),
    ])
  }

  const doSubmit: SubmitHandler<Types.Authentication.iAuthCredentials> = (data) => {
    trackEvent({ event: 'Login Submitted' })
    setErrorMessage('')
    dispatch(login(data))
  }

  useEffect(() => {
    if (router.query.token && router.query.inviteEid) {
      dispatch(viewInvite({ token: router.query.token as string, eid: router.query.inviteEid as string }))
    }
  }, [router.query.inviteEid, router.query.token])

  useEffect(() => {
    dispatch(listIdp())
  }, [dispatch])

  useEffect(() => {
    getStatus()
    requestPermission()
  }, [])

  useEffect(() => {
    const isLoggedIn = status === 'success'
    // console.log('In Login')
    // console.log('baseUrl: ', process.env.baseUrl)
    // console.log('oAuthUrl: ', process.env.oAuthUrl)
    // console.log('BASE_URL: ', process.env.BASE_URL)
    // console.log('OAUTH_URL: ', process.env.OAUTH_URL)
    // console.log('isLoggedIn: ', isLoggedIn)
    // console.log('workspaceStatus: ', workspaceStatus)
    // console.log('error: ', error)

    // console.log('workspaceStatus: ', Intl.DateTimeFormat().resolvedOptions().timeZone)
    // console.log('navigator.platform: ', navigator.platform)
    // console.log('navigator: ', navigator)
    // navigator.geolocation.getCurrentPosition((res) => { console.log("res", res) }, (err) => { console.log("err", err) })

    if (isLoggedIn && !loggedOut) {
      if (callback) {
        callback()
      } else {
        if (!workspaceList?.length) {
          dispatch(fetchAllWorkspaces())
            .then((res) => {
              const ERROR = (res as { error: Error }).error

              if (ERROR?.message.includes('401')) {
                setErrorMessage('User is inactive.')
              } else if (ERROR?.message.includes('403')) {
                setErrorMessage('Your email address is not verified.')
                // @ts-expect-error
              } else if (!res.payload?.length) {
                router.push('/workbench')
              }
            })
            .catch((err) => {
              console.error('==>err:', err)
              resetState()
            })
        } else {
          const allowedWorkspaces = workspaceList as [Types.Workspaces.iWorkspace]
          if (allowedWorkspaces) {
            if ((allowedWorkspaces ?? []).length > 0) {
              // @ts-ignore: will update the type later when BE is done
              let defaultWorkspace = allowedWorkspaces[0]

              Orbit.Services.AxiosBaseInstance.defaults.headers.common['x-pickstar-workspace'] = defaultWorkspace.slug
              if (token) {
                Orbit.Services.AxiosBaseInstance.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`
              }

              dispatch(me()).then((res) => {
                if ((res as { error: { message: string } }).error?.message.includes('401')) {
                  setErrorMessage('User is inactive.')
                  return
                }

                if (res.meta.requestStatus === 'fulfilled' && !!res.payload.default_workspace) {
                  defaultWorkspace =
                    allowedWorkspaces.find((workspace) => workspace.eid === res.payload.default_workspace) || defaultWorkspace
                }

                dispatch(setWorkspace(defaultWorkspace.slug))
                setTimeout(() => {
                  const { from } = router.query
                  // fetch workspace permissions
                  doFetchAllPermissions()

                  if (from) {
                    router.replace(from.toString())
                  } else {
                    if (['/', '/login'].find((matcher) => window.history.state?.url === matcher)) goToDashboard(defaultWorkspace.slug)
                  }
                }, 1000)
              })
            } else {
              router.push('/workbench')
            }
          }
        }
      }
    }
  }, [status, workspaceList])
  // useEffect(() => {
  //     if (loggedOut) {
  //         // resetState()
  //     }
  // })

  useEffect(() => {
    if (token?.access_token && !loggedOut) {
      !profile &&
        dispatch(me()).then((res) => {
          const consent = JSON.parse(localStorage.getItem('consent') ?? '{}') as iConsentRes
          if (consent) {
            identifyUser({ consent, user: res.payload })
            trackEvent({
              event: TrackingEventEnums.Account.ACCOUNT_LOGIN,
              eventProperties: {
                component_name: 'Login',
              },
            })
          }
        })
    } else {
      resetState()
      console.error('login no token')
      setTimeout(() => {
        const { from } = router.query
        if (from) {
          // router.replace(from.toString())
        }
      }, 1000)
    }
  }, [token])

  return (
    // <Transition key={'login'} type="slide">
    <FormProvider {...form}>
      <DevTool control={form.control} />
      <form onSubmit={form.handleSubmit(doSubmit)}>
        <StackV2 direction="column" alignItems={'flex-start'} rowGap="16px" width={{ sm: '430px' }}>
          <label htmlFor="username" style={{ fontSize: '16px', fontWeight: '500', color: GlobalStyles.MONO_BLACK }}>
            Your Email*
          </label>
          <Textfield
            id="username"
            name="username"
            placeholder="Email Address"
            sx={{
              [`.${inputBaseClasses.input}.${outlinedInputClasses.input}`]: {
                backgroundColor: 'white',
                padding: '16px 24px',
                fontSize: '16px',
                height: '56px',
              },
              width: '100%',
            }}
          />
          <label htmlFor="password" style={{ fontSize: '16px', fontWeight: '500', color: GlobalStyles.MONO_BLACK }}>
            Your Password*
          </label>
          <Textfield
            id="password"
            name="password"
            type={passwordUnmasked ? 'text' : 'password'}
            placeholder="**********"
            sx={{
              [`.${inputBaseClasses.input}.${outlinedInputClasses.input}`]: {
                backgroundColor: 'white',
                padding: '16px 24px',
                fontSize: '16px',
                height: '56px',
              },
              width: '100%',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onPress={() => setPasswordUnmasked(!passwordUnmasked)}
                    style={{
                      backgroundColor: GlobalStyles.COLOR_TRANSPARENT,
                      borderColor: GlobalStyles.SLATE_100,
                    }}>
                    <Text>
                      {passwordUnmasked ? <Icon.EyeOff color={GlobalStyles.PRIMARY_500} /> : <Icon.Eye color={GlobalStyles.PRIMARY_500} />}
                    </Text>
                  </Button>
                </InputAdornment>
              ),
              sx: {
                backgroundColor: 'white',
              },
            }}
          />
          {!errorMessage && status === 'success' && (
            <Text size="sm" style={{ color: primaryTextColor }}>{`Signed in. Redirecting...`}</Text>
          )}
          {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
          {status === 'failed' && (
            <Text
              size="sm"
              style={{
                color: 'red',
              }}>{`Sign in failed. Please check your username/password and try again. ${error?.error_description}`}</Text>
          )}
        </StackV2>
        <StackV2 direction="column" alignItems={'stretch'} justifyContent={'space-between'} marginTop="38px" rowGap="38px">
          <MUIButton type="submit" variant="contained" sx={{ padding: '16px 0', minHeight: '56px', width: { sm: '320px' } }}>
            {!errorMessage && (status === 'loading' || workspaceStatus === 'loading' || status === 'success') ? (
              <CircularProgress size={16} sx={{ color: 'white' }} />
            ) : (
              submitText || 'Sign in'
            )}
          </MUIButton>

          <StackV2 direction={{ sm: 'row' }} columnGap="4px" alignItems="center">
            <MUIButton
              variant="text"
              sx={{ padding: 0, marginY: '-8px' }}
              onClick={() =>
                router.push(
                  router.query.inviteEid && router.query.token
                    ? {
                        pathname: `/register/[inviteEid]`,
                        query: {
                          inviteEid: router.query.inviteEid,
                          token: router.query.token,
                        },
                      }
                    : '/register'
                )
              }>
              Create new account
            </MUIButton>
            {!!idpOptions.length && <Typography color={GlobalStyles.SLATE_700}>or Sign in with an Identity Provider:</Typography>}
          </StackV2>
          {!!idpOptions.length && (
            <FormAutoComplete
              name="idp"
              textfieldProps={{
                placeholder: 'Select an IDP',
              }}
              options={idpOptions}
              getOptionLabel={(option) => option.name}
            />
          )}
          {!!selectedIdp && (
            <MUIButton
              variant="outlined"
              startIcon={
                <Image src={selectedIdp.logo_image?.full_url ?? ''} alt="logo" height={50} width={50} style={{ objectFit: 'contain' }} />
              }
              onClick={() => {
                dispatch(redirectIdp(selectedIdp.id as string)).then((res) => {
                  if (res.meta.requestStatus === 'fulfilled') {
                    if (Capacitor.isNativePlatform()) {
                      // @ts-expect-error
                      const ref = cordova?.InAppBrowser?.open(res.payload.data.targetUrl, '_blank', 'location=yes')
                      setBrowserRef(ref)
                    } else {
                      // @ts-expect-error
                      window.location.href = res.payload.data.targetUrl
                    }
                  }
                })
              }}
              aria-label={`Sign in with ${selectedIdp.name}`}>
              {`Sign in with ${selectedIdp.name}`}
            </MUIButton>
          )}
          <StackV2
            direction={{ sm: 'row' }}
            style={{ flexWrap: 'wrap' }}
            alignItems={{ xxs: 'start', sm: 'center' }}
            justifyContent={'space-between'}
            rowGap={{ xxs: '12px' }}>
            <StackV2 direction="row" columnGap="8px" alignItems="center">
              <Checkbox style={{ padding: 0 }} />
              <Text size="sm" style={{ color: primaryTextColor }}>
                Keep me logged in
              </Text>
            </StackV2>
            <Button
              onPress={() => {
                toggleModal('resetPasswordForm')
              }}
              style={{ padding: 0 }}
              transparent>
              <Text size="sm" style={{ color: GlobalStyles.PRIMARY_500 }}>
                Forgot your password?
              </Text>
            </Button>
          </StackV2>
          {!hideFooter && (
            <Text size="tiny" style={{ color: GlobalStyles.MONO_BLACK }}>
              By signing up, signing in or continuing, I agree to the Terms of Use and{' '}
              <a href={TnC.privacy_policy} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              .
            </Text>
          )}
        </StackV2>
      </form>
    </FormProvider>
    // </Transition>
  )
})

Login.displayName = 'Login'

export { Login }
