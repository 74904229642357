import React, { MutableRefObject } from 'react'
import { RecursiveArray, StyleProp, ViewStyle, TextStyle } from 'react-native'

export enum ListDefaults {
  TYPE = 'primary',
  PLATFORM = 'web',
}

export type ListTypes = 'primary' | 'secondary'

export type ListValue = {
  id: number
  value: string
}

export interface ListProps {
  /**
   * The type of List
   */
  type?: ListTypes
  /**
   * Single value to display in the list
   */
  item: ListValue
  /**
   * Add hover functionality
   */
  hover?: boolean
  /**
   * Custom styles for the list container
   */
  style?: StyleProp<ViewStyle>
  /**
   * Custom styles for the list text
   */
  textStyle?: StyleProp<TextStyle>
}

export interface ListGroupProps {
  /**
   * The type of List
   */
  type?: ListTypes
  /**
   * Values array to pass data to display in the list
   */
  values?: RecursiveArray<ListValue | any>
  /**
   * Add hover functionality
   */
  hover?: boolean
  /**
   * Custom styles for the list container
   */
  style?: StyleProp<ViewStyle>
  /**
   * Custom styles for the list text
   */
  textStyle?: StyleProp<TextStyle>
  /**
   * Callback that returns pressed item
   */
  onItemPress: (e: any) => void
  /**
   * Alternative key labelling for list. fallsback to value if undefined
   * eg. {id: 0, value: 'test', label: 'something'}
   * provide the value 'label' to render 'something' instead of 'test'
   */
  valuesLabel?: string

  /**
   * custom action to be appended at the bottom of the list
   */
  actionComponent?: JSX.Element | JSX.Element[]

  /**
   * custom List Item renderer
   */
  renderItem?: (item: any, isHovered: boolean) => JSX.Element | JSX.Element[] | null
  /**
   * List hover style
   */
  listHoverStyle?: StyleProp<ViewStyle>
  /**
   * List text hover style
   */
  listTextHoverStyle?: StyleProp<TextStyle>
  disabledItem?: any
  disabledItemStyle?: StyleProp<ViewStyle>
}

export type ListComponentType = ((props: ListProps) => JSX.Element) & {
  Group: React.MemoExoticComponent<(props: ListGroupProps, ref?: MutableRefObject<any>) => JSX.Element>
}
