import { useState } from 'react'
import { Descendant, Editor, Range, Transforms } from 'slate'
import { CustomElement } from 'src/andromeda/RichEditor/custom-types'
import { LinkElement } from './utils'

const useRichEditorLink = (editor: Editor) => {
  const [showEditor, setShowEditor] = useState(false)
  const [link, setLink] = useState('')
  const [text, setText] = useState('')

  const openLinkEditor = () => {
    const { selection } = editor
    const selectedText = selection && Range.isCollapsed(selection) ? '' : selection && Editor.string(editor, selection)

    // Set the selection to the end of the document if there is no selection
    if (!selection) {
      const endPoint = Editor.end(editor, [])
      Transforms.select(editor, endPoint)
    }

    if (selectedText) {
      setText(selectedText)
    }

    setShowEditor(true)
  }

  const updateLink = (element: CustomElement) => {
    const { link: prevLink, type, children } = element as LinkElement
    let prevText = ''

    if (type === 'link') {
      if (children?.length === 1) {
        prevText = (children[0] as Descendant & { text: string }).text
      }

      setText(prevText)
      setLink(prevLink || '')
      openLinkEditor()
    }
  }

  const closeLinkEditor = () => {
    setLink('')
    setText('')
    setShowEditor(false)
  }

  return {
    showEditor,
    setShowEditor,
    link,
    setLink,
    text,
    setText,
    updateLink,
    openLinkEditor,
    closeLinkEditor,
  }
}

export default useRichEditorLink
