import { Section, UserRow } from '../components'
import { Icon, Loading } from '@andromeda'
import { useContext, useMemo } from 'react'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useRouter } from 'next/router'
import { useAppSelector } from '@redux/hooks'
import { selectJobs } from '@redux/reducers/jobsReducer'
import { hasPermission } from '@utils/functions/permission'
import { DeliverablePermission } from '@utils/enum/permissions'

export const Users = ({ eid, noAdd }: { eid: string; noAdd?: boolean }) => {
  const { query } = useRouter()

  const { toggleModal } = useContext(ModalContext)

  const { viewedDeliverableUsers, viewedDeliverableUsersStatus, viewedJob } = useAppSelector(selectJobs)

  const canAddUsers = useMemo(() => {
    return hasPermission({
      permissions: viewedJob.permissions,
      allowedPermissions: [DeliverablePermission.UPDATE],
    })
  }, [viewedJob.permissions])

  return (
    <Section
      SectionIcon={(props) => <Icon.Users {...props} />}
      title="Users"
      rowGap="10px"
      onClickAdd={() =>
        toggleModal(ModalPages.ADD_USERS_TO_DELIVERABLE, {
          modalProps: {
            [ModalPages.ADD_USERS_TO_DELIVERABLE]: {
              deliverableEid: (query.deliverableId as string) || eid,
            },
          },
        })
      }
      noAdd={!canAddUsers || noAdd}>
      {viewedDeliverableUsersStatus == 'success' ? (
        viewedDeliverableUsers?.users.map(({ eid, name, profile_image, ownership_type }) => (
          <UserRow key={eid} name={name} profile_image={profile_image?.full_url} subtext={ownership_type?.toLowerCase()} Actions={<></>} />
        ))
      ) : (
        <Loading />
      )}
    </Section>
  )
}
