import { Button, Card, GlobalStyles, Icon, Stack, Text, View } from '@andromeda'
import { AppStyles } from '@styles'
import { useScreenDimensions } from '@utils/hooks/dimensions'
import { Roles } from '@utils/types/auth'
import Image from 'next/legacy/image'

interface iWorkspaceInviteCardProp {
  inviter: string
  invitedRole?: Roles
  invitedDate: string
  workspaceName: string
  workspaceImageUrl: any
}

const WorkspaceInviteCard = ({ inviter, invitedRole, invitedDate, workspaceName, workspaceImageUrl }: iWorkspaceInviteCardProp) => {
  const { screenSize } = useScreenDimensions()
  // const { roles } = useAppSelector(selectAuth)

  return (
    <Card style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
      <Stack
        rounded
        style={{
          backgroundColor: GlobalStyles.SLATE_800,
          paddingLeft: 0,
          paddingRight: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        direction="column"
        align="flex-start"
        space={'sm'}>
        <Stack
          align={screenSize.md ? 'center' : 'flex-start'}
          justify={'space-between'}
          direction={screenSize.md ? 'row' : 'column'}
          style={{
            padding: GlobalStyles.PADDING,
            paddingVertical: GlobalStyles.PADDING_SIZES.md,
            paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
            flexWrap: 'wrap',
          }}>
          <Stack width={screenSize.md ? 'auto' : undefined}>
            {/* <Tooltip />   TODO - add tooltiop body*/}
            <Text style={{ color: GlobalStyles.MONO_WHITE }} size={'sm'}>
              {`${inviter} has invited you to join the Adidas Workspace`}
            </Text>
          </Stack>
          <Button style={{ backgroundColor: GlobalStyles.STATUS_POSITIVE, borderRadius: GlobalStyles.BUTTON_BORDER_RADIUS }}>
            <Text>
              <Icon.CheckCircle size={AppStyles.BUTTON_SIZE_SMALL} color={GlobalStyles.MONO_WHITE} />
            </Text>
            <Text style={{ color: GlobalStyles.MONO_WHITE }} size={'sm'}>
              Accept
            </Text>
          </Button>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        align="flex-start"
        space={'sm'}
        style={{
          paddingHorizontal: screenSize.md ? AppStyles.CARD_HORIZONTAL_PADDING : 0,
          paddingVertical: screenSize.md ? AppStyles.CARD_VERTICAL_PADDING : 0,
        }}>
        <Stack direction="column" align="flex-start" space={'sm'} style={{ paddingHorizontal: 0 }}>
          <Stack justify={'space-between'} align="center" flex={1} space={screenSize.md ? 'md' : 'xs'}>
            <View style={{ width: 60, height: 60 }}>
              <Image alt="WorkspaceLogo" style={{ borderRadius: 30 }} src={workspaceImageUrl} layout="responsive" />
            </View>
            <Stack direction="column" align="flex-start" flex={1} space={'2xs'}>
              <Text
                style={{
                  color: GlobalStyles.MONO_BLACK,
                  fontSize: !screenSize.xs ? GlobalStyles.FONT_SIZES.SMALL : GlobalStyles.FONT_SIZES.XTRA_LARGE,
                }}>
                {workspaceName}
              </Text>
              <Text style={{ color: GlobalStyles.MONO_BLACK }} size={'sm'}>
                Invited: {invitedDate}
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  )
}

export default WorkspaceInviteCard
