import { forwardRef } from 'react'
// import { CardProps, CardDefaults } from './types'
import { Card as MUICard } from '@mui/material'
import { CardProps } from '@mui/material/Card'
import { GlobalStyles } from '../globalStyles'

const Card = ({ children, style: cardStyle, ...props }: CardProps, ref: any) => {
  const cardStyles = {
    backgroundColor: GlobalStyles.MONO_WHITE,
    borderRadius: GlobalStyles.BORDER_RADIUS,
    borderWidth: GlobalStyles.BORDER_WIDTH,
    padding: GlobalStyles.PADDING,
    paddingVertical: GlobalStyles.PADDING,
    overflow: 'visible',
    // paddingVertical: GlobalStyles.PADDING,
    // paddingHorizontal: GlobalStyles.PADDING_HORIZONTAL,
    borderColor: GlobalStyles.SLATE_100,
    ...cardStyle,
  }
  return (
    <MUICard sx={cardStyles} ref={ref} {...props}>
      {children && children}
    </MUICard>
    // <View {...props} style={[CardStyles.cardContainer, style]} ref={ref}>
    //     {children}
    // </View>
  )
}

export default forwardRef(Card)
