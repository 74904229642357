import { Button, GlobalStyles, Stack, Text, View } from '@andromeda'
import { ModalFooter, ModalHeader } from '@components'
import { AppStyles } from '@styles'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext, useState } from 'react'

type iLeavePrompt = {
  subject: 'job' | 'deliverable'
  modal: ModalPages.LEAVE_JOB | ModalPages.LEAVE_DELIVERABLE
}

export const LeavePrompt = ({ subject, modal }: iLeavePrompt) => {
  const { closeModal, options } = useContext(ModalContext)
  const props = options?.modalProps ? options.modalProps[modal] : undefined

  const [isLeaving, setIsLeaving] = useState<boolean>(false)

  const _confirm = () => {
    if (props?.onConfirm) props.onConfirm()
    else closeModal(modal)
  }

  const _cancel = () => {
    props?.onCancel && props.onCancel()
    closeModal(modal)
  }

  return (
    <Stack rounded space={'sm'} height="auto" direction="column" align={'flex-start'} style={{ backgroundColor: GlobalStyles.MONO_WHITE }}>
      <ModalHeader
        modalContext={modal}
        modalTitle={`Leave ${subject}?`}
        textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
        style={{
          paddingBottom: 0,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.md,
        }}
      />
      <View style={{ paddingHorizontal: GlobalStyles.PADDING_SIZES.md, paddingTop: GlobalStyles.PADDING_SIZES.sm }}>
        <Text>{`Are you sure you want to remove yourself from this ${subject}?`}</Text>
      </View>

      <Stack
        direction="column"
        align="flex-start"
        space="xs"
        style={{
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.sm,
        }}>
        <Text size="sm" style={{ color: GlobalStyles.MONO_BLACK }}>
          {'What this means:'}
        </Text>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>&#x2022;</Text>
          <Text size="sm">{`You will no longer get notified of updates on this ${subject}.`}</Text>
        </Stack>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>&#x2022;</Text>
          <Text size="sm">{'Users will be updated of this change.'}</Text>
        </Stack>
      </Stack>

      <ModalFooter
        backgroundColor={GlobalStyles.MONO_WHITE}
        wrapperStyle={{
          shadowOpacity: 0,
          justifyContent: 'flex-end',
          paddingBottom: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.sm,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
        }}
        buttonStyles={{
          paddingVertical: 12,
          paddingHorizontal: 18,
          borderWidth: 0,
          backgroundColor: GlobalStyles.MONO_WHITE,
          height: AppStyles.BUTTON_SIZES.MD,
        }}
        onButtonPress={_confirm}
        buttonText={`Leave ${subject}`}
        buttonTextStyle={{
          color: GlobalStyles.STATUS_NEGATIVE,
          fontSize: GlobalStyles.FONT_SIZES.SMALL,
          textTransform: 'capitalize',
        }}
        disabled={false}
        leftComponent={
          <Button
            type="secondary"
            style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
            onPress={() => _cancel()}
            textStyle={{ color: GlobalStyles.PRIMARY_500 }}
            text="Cancel"
          />
        }
        loading={isLeaving}
        loadingType={'blue'}
      />
    </Stack>
  )
}
