import { GlobalStyles } from '@andromeda'
import { ModalHeader } from '@components'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { archiveJobById, selectJobs } from '@redux/reducers/jobsReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { NavigationContext } from '@utils/context/Navigation.context'
import { useContext } from 'react'
import { toast } from 'react-toastify'

export const ArchiveJob = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const { viewedJob, deleteJobStatus } = useAppSelector(selectJobs)

  const { closeModal } = useContext(ModalContext)
  const { goToRequests } = useContext(NavigationContext)

  const _handleSubmit = () => {
    toast
      .promise(dispatch(archiveJobById({ jobEid: viewedJob.eid })), {
        pending: 'Archiving job...',
      })
      .then(async (response) => {
        toast.dismiss()
        if (response.meta.requestStatus === 'fulfilled') {
          toast.success('Job has been archived.')
          goToRequests()
        }
        closeModal(ModalPages.ARCHIVE_JOB)
      })
  }

  return (
    <Stack className="dialog-container" style={{ backgroundColor: GlobalStyles.MONO_WHITE }} width={{ xxs: '100%', sm: '500px' }}>
      <ModalHeader modalTitle="Archive Job?" modalContext={ModalPages.ARCHIVE_JOB} />
      <Stack className="dialog-content" rowGap="8px">
        <Typography>You are about to archive this Job. This action cannot be undone. Do you wish to proceed?</Typography>
      </Stack>
      <Stack direction="row" justifyContent="end" columnGap="8px" className="dialog-footer">
        <Button variant="outlined" onClick={() => closeModal(ModalPages.ARCHIVE_JOB)}>
          Cancel
        </Button>
        <Button variant="contained" style={{ minWidth: '80px' }} onClick={_handleSubmit} disabled={deleteJobStatus === 'loading'}>
          {deleteJobStatus === 'loading' ? (
            <CircularProgress size={12} style={{ marginRight: `${GlobalStyles.MARGIN_SIZES['2xs']}px` }} />
          ) : undefined}
          Archive
        </Button>
      </Stack>
    </Stack>
  )
}
