const HEADER_PADDING_HORIZONTAL = 96
const HEADER_PADDING_VERTICAL = 16

const BUTTON_SIZE_XLARGE = 64
const BUTTON_SIZE_LARGE = 40
const BUTTON_SIZE_MEDIUM = 20
const BUTTON_SIZE_SMALL = 15
const BUTTON_SIZE_TINY = 12
const BUTTON_VERTICAL_PADDING = 12
const BUTTON_HORIZONTAL_PADDING = 16
const BUTTON_SMALL_PADDING = 12
const BUTTON_RADIUS = 8

const MODAL_PADDING_VERTICAL = 48
const MODAL_PADDING_HORIZONTAL = 40

const MODAL_MOBILE_PADDING = 20

const CONTAINER_PADDING_VERTICAL = 12
const MODAL_BORDER_RADIUS = 12

// const CONTAINER_PADDING_VERTICAL = 64
const CONTAINER_PADDING_HORIZONTAL = 96

const CARD_MOBILE_PADDING = 12
const CARD_VERTICAL_PADDING = 32
const CARD_HORIZONTAL_PADDING = 40

const MENU_ITEM_HEIGHT = { xxs: '48px', sm: '64px' }

const SETTINGS_HEADER_HEIGHT = 168

const APP_BREAKPOINTS = {
  SMALL: 640,
  MEDIUM: 768,
  LARGE: 1024,
  XL: 1280,
  XXL: 1536,
}

const FONT_SIZES = {
  TITLE: 48,
  SET_TITLE: 40,
  FORM_TITLE: 28,
  FORM_INPUT_GROUP: 26,
  LIST_COUNT: 80,
  PROFILE_PLACEHOLDER: 100,
  MODAL_TITLE: 32,
}

const AVATAR_SIZES = {
  MODAL: 240,
  PROFILE_PAGE: 96,
  PROFILE_PAGE_SM: 80,
  DEFAULT: 36,
  BANNER_SM: 60,
  BANNER: 120,
  DASHBOARD: 56,
}

const PADDING_SIZES = {
  '3XL': 56,
  '2XL': 50,
  XL: 40,
  LG: 32,
  MD: 26,
  BASE: 24,
  SM: 16,
  XS: 10,
  XXS: 8,
  '3XS': 6,
  '4XS': 4,
  BANNER_RIGHT: 300,
  BANNER_RIGHT_SM: 90,
}

const LABEL_PADDING_VERTICAL = 4
const LABEL_PADDING_HORIZONTAL = 8
const LABEL_PADDING = 12

const MARGIN_SIZES = {
  XXS: 6,
  XS: 12,
  SM: 16,
  MD: 24,
  LG: 32,
  XL: 40,
  BANNER_DOTS: 10,
}

const BORDER_RADIUS_SIZES = {
  LG: 32,
  XS: 10,
  XXS: 8,
}

const BORDER_RADIUS = {
  XL: 32,
  BASE: 12,
  SM: 8,
  XS: 4,
  XXS: 2,
}

const SECONDARY = '#FCC310'
const SUBTLE = '#767676'

const CHIP_BUTTON_SIZE = 14

const ICON_SIZES = {
  '3XL': 64,
  XL: 40,
  LG: 24,
  MD: 18,
  BASE: 16,
  SM: 14,
  XS: 12,
}

const BUTTON_SIZES = {
  LG: 48,
  MD: 40,
}

const LABEL_SIZES = {
  LG: 40,
  MD: 32,
}

const SUBTLE_POSITIVE = '#DBFFE1'
const SUBTLE_INFO = '#ECF3FF'
const SUBTLE_NEGATIVE = '#f559591A'
const SUBTLE_SLATE = '#a2a8b9'

const SUBTLE_DISABLED_INFO = '#CED3DD'

const CELL_HEIGHT = 80
const CELL_HEADER_HEIGHT = 104
const COLUMN_MIN_WIDTH = 300

const COLUMN_WIDTH = {
  XS: 120,
  SM: 160,
  BASE: 300,
}

const BANNER_DOTS = {
  LG: 40,
  BASE: 28,
  MD: 24,
  SM: 18,
}

export const AppStyles = {
  HEADER_PADDING_HORIZONTAL,
  HEADER_PADDING_VERTICAL,
  BUTTON_SIZE_XLARGE,
  BUTTON_SIZE_LARGE,
  BUTTON_SIZE_MEDIUM,
  BUTTON_SIZE_SMALL,
  BUTTON_SIZE_TINY,
  BUTTON_VERTICAL_PADDING,
  BUTTON_HORIZONTAL_PADDING,
  BUTTON_SMALL_PADDING,
  BUTTON_RADIUS,
  MODAL_PADDING_VERTICAL,
  MODAL_PADDING_HORIZONTAL,
  MODAL_MOBILE_PADDING,
  MODAL_BORDER_RADIUS,
  CONTAINER_PADDING_VERTICAL,
  CONTAINER_PADDING_HORIZONTAL,
  CARD_HORIZONTAL_PADDING,
  CARD_MOBILE_PADDING,
  CARD_VERTICAL_PADDING,
  MENU_ITEM_HEIGHT,
  APP_BREAKPOINTS,
  FONT_SIZES,
  SETTINGS_HEADER_HEIGHT,
  LABEL_PADDING_HORIZONTAL,
  LABEL_PADDING_VERTICAL,
  PADDING_SIZES,
  AVATAR_SIZES,
  MARGIN_SIZES,
  BORDER_RADIUS_SIZES,
  SECONDARY,
  BORDER_RADIUS,
  SUBTLE,
  LABEL_PADDING,
  CHIP_BUTTON_SIZE,
  ICON_SIZES,
  BUTTON_SIZES,
  LABEL_SIZES,
  SUBTLE_POSITIVE,
  SUBTLE_INFO,
  SUBTLE_NEGATIVE,
  SUBTLE_SLATE,
  SUBTLE_DISABLED_INFO,
  CELL_HEIGHT,
  CELL_HEADER_HEIGHT,
  COLUMN_MIN_WIDTH,
  COLUMN_WIDTH,
  BANNER_DOTS,
}
