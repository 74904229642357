import { Button, GlobalStyles, Icon, Stack, Text, View } from '@andromeda'
import { AnimatePresence, motion } from 'framer-motion'
import { Children, ReactNode, useState } from 'react'

interface iAccordionProps {
  key?: string
  children: ReactNode
  titleComponent: JSX.Element
  activeBackgroundColor?: string
  onToggle?: (accordionKey: string | undefined) => void
  onButtonPress?: () => void
  isOpen?: boolean
  defaultOpen?: boolean
  iconColor?: string
  iconOpen?: string | null
  iconClose?: string | null
}

const Accordion = ({
  key,
  children,
  isOpen: externalOpen,
  onToggle,
  titleComponent,
  defaultOpen = false,
  activeBackgroundColor = GlobalStyles.COLOR_TRANSPARENT,
  iconColor = GlobalStyles.MONO_WHITE,
  iconOpen = 'ChevronUp',
  iconClose = 'ChevronDown',
  onButtonPress,
}: iAccordionProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const childrenArray = Children.toArray(children)

  const _handleToggle = () => {
    if (externalOpen === undefined) {
      setIsOpen(!isOpen)
    }

    onToggle && onToggle(key)
  }

  if (!childrenArray) return null

  const _renderRightIcon = () => {
    const icons = Object.values(Icon)
    const IconOpenComponent = iconOpen && icons.find((icon) => icon.displayName?.toLowerCase() === iconOpen.toLowerCase())
    const IconCloseComponent = iconClose && icons.find((icon) => icon.displayName?.toLowerCase() === iconClose.toLowerCase())

    if (isOpen) {
      if (!IconOpenComponent) return iconOpen === null ? <></> : <Icon.ChevronUp size={GlobalStyles.BUTTON_SIZES.SMALL} color={iconColor} />
      return <IconOpenComponent size={GlobalStyles.BUTTON_SIZES.SMALL} color={iconColor} />
    } else {
      if (!IconCloseComponent)
        return iconClose === null ? <></> : <Icon.ChevronDown size={GlobalStyles.BUTTON_SIZES.SMALL} color={iconColor} />
      return <IconCloseComponent size={GlobalStyles.BUTTON_SIZES.SMALL} color={iconColor} />
    }
  }

  return (
    <motion.div>
      <AnimatePresence>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Button
            hover
            type={'secondary'}
            transparent
            style={[{ flex: 1, padding: 0 }, (externalOpen || isOpen) && { backgroundColor: activeBackgroundColor }]}
            onPress={_handleToggle}>
            <Stack justify={'space-between'}>{titleComponent}</Stack>
          </Button>
          <View style={{ marginLeft: 'auto' }}>
            <Button
              hover
              type={'secondary'}
              transparent
              style={[{ padding: 0 }, (externalOpen || isOpen) && { backgroundColor: activeBackgroundColor }]}
              onPress={onButtonPress}>
              <Text style={{ textAlign: 'right' }}>{_renderRightIcon()}</Text>
            </Button>
          </View>
        </View>
        {childrenArray.map(
          (child, i) =>
            (isOpen || externalOpen) && (
              <motion.div
                key={`item-${i}-${key}`}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.4,
                    delay: 0.09 * i,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    duration: 0.4,
                    delay: 0.05 * childrenArray.length - i,
                  },
                }}>
                {child}
              </motion.div>
            )
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default Accordion
