import { Alert as MUIAlert } from '@mui/material'
import { forwardRef, memo, useEffect, useState } from 'react'
import { AlertStyles } from './styles'
import { AlertDefaults, AlertProps } from './types'

const Alert = ({ children, style, textStyle, type = AlertDefaults.TYPE, space = AlertDefaults.SPACE, ...props }: AlertProps, ref?: any) => {
  const [hovered, setHovered] = useState<boolean>(false)

  /**
   * TODO: Add close button to hide/remove the alert.
   */
  useEffect(() => {}, [type, style, textStyle, hovered])

  return (
    // <Stack sx={{ width: '100%' }} >
    <MUIAlert icon={props.leftElement ?? null} style={{ ...AlertStyles[type], ...style, alignItems: 'center' }}>
      {/* {props.leftElement ?? null} */}
      {children}
      {props.rightElement ?? null}
    </MUIAlert>
    // </Stack>
    // <Box
    //     // onMouseEnter={() => (props.hover && isWeb() ? setHovered(true) : null)}
    //     // onMouseLeave={() => (props.hover && isWeb() ? setHovered(false) : null)}
    //     sx={{ ...AlertStyles[type], ...style }}
    //     ref={ref}
    //     {...props}>
    //     <StackV2
    //         direction={'row'}
    //         justifyContent={props.justifyContent || 'center'}
    //         alignItems={props.alignContent || 'center'}
    //         spacing={2}>
    //         {props.leftElement ?? null}
    //         {/* <View style={props.textWrapperStyle}> */}
    //         <Text style={[AlertTextStyles[type], hovered && AlertHoverTextStyles[type], textStyle]} {...props}>
    //             {children}
    //         </Text>
    //         {/* </View> */}
    //         {props.rightElement ?? null}
    //     </StackV2>
    // </Box>
  )
}

export default memo(forwardRef(Alert))
