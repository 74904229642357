// import { Button } from '../Button'
import { Icon } from '../Icon'
import { Text } from '../Text'
import { FilePickerProps } from './types'
import { useEffect, useState } from 'react'
import { GlobalStyles } from '../globalStyles'
import { View } from '../View'
import { Button, Stack } from '@mui/material'

/**
 * File picker button.
 * Extends Button component
 * Returns FileList
 */
const FilePicker = ({ text, options, id = 'file-picker-button', children, ...props }: FilePickerProps) => {
  const [accept, setAccept] = useState<string>('*/*')
  const [multiple, setMultiple] = useState<boolean>(false)

  useEffect(() => {
    if (options.fileTypes) setAccept(options.fileTypes)
    else setAccept('*/*')

    if (options.multiple) setMultiple(options.multiple)
    else setMultiple(false)
  }, [options])

  const _setFiles = (files: File[], invalidFiles: File[]) => {
    const response = {
      files,
      invalidFiles,
      errorMessage: invalidFiles.length > 0 ? 'Some files exceed the maximum file size' : null,
    }
    if (props.onChange) {
      props.onChange(response)
    }
  }

  const _handleChange = async (e: any) => {
    const files = [...e.target.files]
    const fileLimit = options.maxSizeKB || 0
    if (files && files.length > 0) {
      let filesExceedLimit: File[] = []
      if (fileLimit > 0) {
        const filesInLimit = files.filter((file) => file.size / 1000 <= fileLimit)
        filesExceedLimit = files.filter((file) => file.size / 1000 > fileLimit)
        _setFiles(filesInLimit, filesExceedLimit)
      } else {
        _setFiles(files, filesExceedLimit)
      }
    }
  }

  const _renderChildren = () => {
    if (children) return children

    return (
      <Stack direction="row" columnGap="10px">
        <Text>
          <Icon.Edit color={GlobalStyles.PRIMARY_500} size={GlobalStyles.BUTTON_SIZES.SMALL} />
        </Text>
        <Text size="base" style={{ color: GlobalStyles.PRIMARY_500 }}>
          {text ?? 'Upload'}
        </Text>
      </Stack>
    )
  }

  return (
    <View style={props.wrapperStyle}>
      <Button component="label" variant="outlined" size={'large'} sx={{ color: GlobalStyles.MONO_WHITE }}>
        <input id={id} type="file" onChange={_handleChange} accept={accept} multiple={multiple} style={{ display: 'none' }} />
        {_renderChildren()}
        {/* Upload file */}
        {/* <VisuallyHiddenInput type="file" /> */}
      </Button>
    </View>
  )
}

export default FilePicker
