import { Button, GlobalStyles, Icon, Stack, StackV2, Text, View } from '@andromeda'
import { AppStyles } from '@styles'
import { ModalContext, ModalTypes } from '@utils/context/Modal.context'
import { useScreenDimensions } from '@utils/hooks/dimensions'
import { CSSProperties, isValidElement, useContext } from 'react'

interface FlyoutModalHeaderProps {
  returnText: string
  headerButtons?: Array<JSX.Element>
  contextModal: ModalTypes
  style?: CSSProperties
  onClose?: () => void
}

const FlyoutModalHeader = ({ returnText, headerButtons, contextModal, style, onClose }: FlyoutModalHeaderProps) => {
  const { toggleModal } = useContext(ModalContext)
  const { screenSize } = useScreenDimensions()

  const _handleCloseModal = () => {
    if (onClose) onClose()
    else toggleModal(contextModal)
  }

  return (
    <StackV2 direction="row" className="dialog-header">
      <View>
        <StackV2 direction="row" alignItems="center" spacing={2}>
          <Button
            type="outlined"
            style={{
              backgroundColor: GlobalStyles.MONO_WHITE,
              borderColor: GlobalStyles.SLATE_100,
              width: AppStyles.BUTTON_SIZES.MD,
              height: AppStyles.BUTTON_SIZES.MD,
            }}
            onPress={() => _handleCloseModal()}>
            <Text>
              <Icon.ArrowLeft color={GlobalStyles.PRIMARY_500} size={15} />
            </Text>
          </Button>
          {screenSize.sm && (
            <Text size="sm" bold style={{ color: GlobalStyles.SLATE_500 }}>
              {returnText}
            </Text>
          )}
        </StackV2>
      </View>
      <View>
        <StackV2 direction="row" justifyContent="space-between" spacing={2}>
          {headerButtons?.map((button, key: number) => isValidElement(button) && <View key={`header-button-${key}`}>{button}</View>)}
        </StackV2>
      </View>
    </StackV2>
  )
}

export default FlyoutModalHeader
