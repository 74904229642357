import { GlobalStyles, StackV2, Text, View } from '@andromeda'
import { forwardRef, memo } from 'react'
import { StatusStyles } from './styles'
import { StatusDefaults, StatusProps } from './types'

const Status = (
  {
    style,
    text,
    type = StatusDefaults.TYPE,
    space = StatusDefaults.SPACE,
    align = StatusDefaults.ALIGN,
    variant = StatusDefaults.VARIANT,
    ...props
  }: StatusProps,
  ref?: any
) => {
  return (
    <StackV2 direction={'row'} alignItems={align} justifyContent={'center'} spacing={1}>
      <View
        style={[StatusStyles[type], variant === 'rounded' && { borderRadius: GlobalStyles.STATUS_BORDER_RADIUS_ROUNDED }, style]}
        ref={ref}
        {...props}
      />
      {text && (
        <Text style={[{ color: GlobalStyles.SLATE_700 }, props.textStyle]} {...props.textProps}>
          {text}
        </Text>
      )}
    </StackV2>
  )
}

export default memo(forwardRef(Status))
