import { DropdownOption } from '@andromeda'
import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { CURRENCY_LIST } from '@utils/constants/currencies'

const BUDGET_CURRENCY_OPTIONS = [
  { id: 'dollars', value: '$' },
  { id: 'points', value: 'pts' },
  { id: 'duration', value: 'Hrs' },
]

export const useCurrencyCode = () => {
  const { settings } = useAppSelector(selectWorkspace)

  return settings?.currencies
    ? settings?.currencies?.map((curr) => {
        return { id: curr, value: curr, symbol: CURRENCY_LIST.find(({ value }) => value.toLowerCase() === curr.toLowerCase())?.symbol }
      })
    : BUDGET_CURRENCY_OPTIONS
}
