import { GlobalStyles, Icon } from '@andromeda'
import { ModalHeader } from '@components'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useAppSelector } from '@redux/hooks'
import { selectJobs } from '@redux/reducers/jobsReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { iJobCompleteModalProps } from '@utils/types/jobs'
import { useContext } from 'react'

const JobComplete = (props: iJobCompleteModalProps): JSX.Element => {
  const { closeModal } = useContext(ModalContext)
  const { completeJobStatus } = useAppSelector(selectJobs)

  const _handleSubmit = () => {
    if (props.onConfirm) props.onConfirm()
    else closeModal(ModalPages.JOB_COMPLETE)
  }

  return (
    <Stack className="dialog-container" style={{ backgroundColor: GlobalStyles.MONO_WHITE }} width={{ xxs: '100%', sm: '550px' }}>
      <ModalHeader modalTitle="Complete Job?" modalContext={ModalPages.JOB_COMPLETE} />

      <Stack className="dialog-content" rowGap="8px">
        <Stack direction="column" alignItems="flex-start" rowGap={`${GlobalStyles.MARGIN_SIZES.sm}px`}>
          <Typography style={{ color: GlobalStyles.MONO_BLACK, fontSize: GlobalStyles.FONT_SIZES.SMALL }}>This action will:</Typography>

          <Stack direction="row" alignItems="flex-start" columnGap={`${GlobalStyles.MARGIN_SIZES['2xs']}px`}>
            <Typography style={{ lineHeight: '15px' }}>
              <Icon.Check color={GlobalStyles.SLATE_300} />
            </Typography>
            <Typography style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
              This will notify any invited or booked stars, along with any users viewing the job.
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="flex-start" columnGap={`${GlobalStyles.MARGIN_SIZES['2xs']}px`}>
            <Typography style={{ lineHeight: '15px' }}>
              <Icon.Check color={GlobalStyles.SLATE_300} />
            </Typography>
            <Typography style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>Change assigned stars to removed</Typography>
          </Stack>

          <Stack direction="row" alignItems="flex-start" columnGap={`${GlobalStyles.MARGIN_SIZES['2xs']}px`}>
            <Typography style={{ lineHeight: '15px' }}>
              <Icon.Check color={GlobalStyles.SLATE_300} />
            </Typography>
            <Typography style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
              Notify relevant users that the job has been completed
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="end" columnGap="8px" className="dialog-footer">
        <Button variant="outlined" onClick={() => closeModal(ModalPages.JOB_COMPLETE)}>
          Cancel
        </Button>
        <Button variant="contained" onClick={_handleSubmit} disabled={completeJobStatus === 'loading'}>
          {completeJobStatus === 'loading' ? (
            <CircularProgress size={12} style={{ marginRight: `${GlobalStyles.MARGIN_SIZES['2xs']}px` }} />
          ) : undefined}
          Mark Complete
        </Button>
      </Stack>
    </Stack>
  )
}

export default JobComplete
