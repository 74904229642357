// TODO: fix
// @ts-nocheck
import { Types } from '@orbit'
import { store } from '@redux/store'
import { iConsentRes } from '@utils/types/auth'
import { iUser } from '@utils/types/user'
import mixpanel from 'mixpanel-browser'

const prod = process.env.NODE_ENV === 'production'

export const identifyUser = ({ consent, user }: { consent: iConsentRes; user?: iUser }) => {
  if ([consent[2], consent[3], consent[4]].some((val) => val === true)) {
    console.info('initing')
    const MIXPANEL_TOKEN = process.env.mixpanel_token
    mixpanel.init(MIXPANEL_TOKEN ?? '', { debug: !prod, track_pageview: true, persistence: 'localStorage' })

    const state = store.getState()
    const currentUser = user ?? state.auth.me
    if (currentUser) {
      const me = currentUser
      mixpanel.identify(me?.eid)
      mixpanel.people.set({
        name: me?.name ?? '',
        email: me?.email ?? '',
        ...(me.roles?.length ? { role: me.roles[0].label } : {}),
        ...(state.workspace.activeWorkspace
          ? { workspace: state.workspace.activeWorkspace?.name, workspaceSlug: state.workspace.activeWorkspace?.slug }
          : {}),
      })
    }
  } else {
    console.info('opting out')
    // mixpanel.reset()
    // setOptOut(true)
  }
}

/**
 *
 * @param param0
 */
export const trackEvent = ({
  event,
  eventProperties,
  user,
  job,
  deliverable,
  workspace,
}: {
  event: string
  eventProperties?: any | undefined
  user?: iUser | null
  job?: Types.Job.iJob | null
  deliverable?: Types.Deliverable.iDeliverable | null
  workspace?: string | null
}) => {
  const state = store.getState()
  const currentUser = user ?? state.auth.me
  let customProperties = {}

  // console.log('user ', currentUser)

  //create job evenProprties
  if (job) {
    customProperties = {
      job_eid: job.eid,
      job_name: job.name,
      job_client: job.client,
      job_budget_amount_from: job.budget_amount_from,
      job_budget_amount_to: job.budget_amount_to,
      job_budget_currency_code: job.budget_currency_code,
      job_created_at: job.created_at,
      job_deliverables: job.deliverables,
      job_description: job.description,
      job_shortlisted_talents: job.shortlisted_talents,
      job_start_datetime: job.start_datetime,
      job_status: job.status,
      job_updated_at: job.updated_at,
      workspace: workspace,
    }
  }

  if (deliverable) {
    customProperties = { ...deliverable }
    //put in the deliverable attributes as deliverable_something_something_dark_side
  }

  if (currentUser) {
    if (process.env.mixpanel_token) {
      const MIXPANEL_TOKEN = process.env.mixpanel_token
      mixpanel.init(MIXPANEL_TOKEN ?? 'placeholder', { debug: !prod, track_pageview: true, persistence: 'localStorage' })
      mixpanel.track(event, { ...eventProperties, ...customProperties, user_id: currentUser.eid })
    }
    // track(
    //     event,
    //     { ...eventProperties, ...customProperties },
    //     {
    //         user_id: currentUser.eid,
    //     }
    // ).promise.then((result) => {
    //     console.log(result) // {...} (The final event object sent to Amplitude)
    // })
    // flush()
  } else {
    const MIXPANEL_TOKEN = process.env.mixpanel_token
    mixpanel.init(MIXPANEL_TOKEN ?? 'placeholder', { debug: !prod, track_pageview: true, persistence: 'localStorage' })
    mixpanel.track(event, { ...eventProperties, ...customProperties })
  }

  //if we have job ID can we add the job data?
}
