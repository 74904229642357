import { GlobalStyles } from '@andromeda'
import { ModalHeader } from '@components'
import { Button, Stack, Typography } from '@mui/material'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext } from 'react'

export const UnsavedChanges = () => {
  const { closeModal, options } = useContext(ModalContext)
  const props = options?.modalProps?.unsavedChanges

  const _handleClose = () => {
    closeModal(ModalPages.UNSAVED_CHANGES)
    if (props && props.exitWithoutSave) props.exitWithoutSave()
  }

  return (
    <Stack className="dialog-container" style={{ backgroundColor: GlobalStyles.MONO_WHITE }} width={{ xxs: '100%', sm: '500px' }}>
      <ModalHeader modalTitle="Exit without saving?" modalContext={ModalPages.UNSAVED_CHANGES} />
      <Stack className="dialog-content" rowGap="8px">
        <Typography>{'You will lose all unsaved changes if you choose to exit without saving.'}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="end" columnGap="8px" className="dialog-footer">
        <Button variant="outlined" onClick={() => closeModal(ModalPages.UNSAVED_CHANGES)}>
          Return to Deliverable
        </Button>
        <Button variant="contained" onClick={_handleClose}>
          Exit without Saving
        </Button>
      </Stack>
    </Stack>
  )
}
