import { DropdownOption, GlobalStyles, Icon } from '@andromeda'
import { ModalHeader } from '@components'
import { DevTool } from '@hookform/devtools'
import { Box, Button, Chip, Grid, Stack } from '@mui/material'
import { Types } from '@orbit'
import { useAppDispatch } from '@redux/hooks'
import { createMultipleDeliverable } from '@redux/reducers/deliverableReducer'
import { createJob, fetchAllJobs } from '@redux/reducers/jobsReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { NavigationContext } from '@utils/context/Navigation.context'
import { useDeliverableTypes } from '@utils/hooks/useDeliverableTypes'
import { useEventTypes } from '@utils/hooks/useEventTypes'
import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { FormAutoComplete } from 'src/components/form/AutoComplete'
import { Textfield } from 'src/components/form/Textfield'
import { ColumnLabel } from 'src/components/job-details/components'
import { FormSearchTagV2 } from 'src/components/search/searchtag.new'
import JobCreated from 'src/components/toasts/job-created'

const InputRow = ({ children, label, tooltipText = '' }: { children: JSX.Element; label: string; tooltipText?: string }) => (
  <Stack gap="8px">
    <Grid item xs={4} display="flex" alignItems="center" gap="4px">
      <ColumnLabel label={label} tooltipText={tooltipText} />
    </Grid>
    {children}
  </Stack>
)
const initialFormValue = { title: '', client: '' }

const TaggedDropdown = ({
  options,
  setSelected,
  selected,
  name,
  ...props
}: {
  options?: DropdownOption[]
  setSelected: Dispatch<SetStateAction<DropdownOption[]>>
  selected: DropdownOption[]
  name: string
}) => (
  <Stack gap="8px">
    <FormSearchTagV2
      name={name}
      options={options}
      onSelect={(event) => setSelected((prev) => [...prev, event])}
      placeholder="Select any relevant tags"
      sx={{
        '&.MuiInputBase-root.MuiOutlinedInput-root': {
          padding: '9px',
        },
      }}
      {...props}
    />
    <Box>
      {selected?.map((item) => (
        <Chip
          size="small"
          key={item.eid}
          label={
            <Stack alignItems="center" direction="row" gap="5px">
              {item.value}
              <Icon.XCircle
                size={13}
                cursor="pointer"
                onClick={() => {
                  setSelected((prev) => prev.filter((i) => i.eid !== item.eid))
                }}
              />
            </Stack>
          }
          style={{
            color: GlobalStyles.SLATE_700,
            backgroundColor: GlobalStyles.SLATE_50,
            fontSize: '12px',
            padding: '6px',
            borderRadius: '12px',
          }}
          sx={{ marginRight: '4px' }}
        />
      ))}
    </Box>
  </Stack>
)

const TEXTFIELD_STYLES = {
  '.MuiOutlinedInput-input': {
    backgroundColor: 'white',
    fontSize: '14px',
  },
}

type tAddJobForm = Pick<Types.Job.iJobPayload, 'name' | 'client' | 'event_types'> & {
  deliverable_type_eid?: Array<Types.Deliverable.iDeliverableType & { value: string }>
}

export const AddJob = () => {
  const router = useRouter()

  const dispatch = useAppDispatch()

  const { goToRequestById } = useContext(NavigationContext)
  const { closeModal } = useContext(ModalContext)

  const [isCreatingJob, setIsCreatingJob] = useState(false)

  const form = useForm<tAddJobForm>()
  const selectedEvents = useWatch({ name: 'event_types', control: form.control })
  const selectedTypes = useWatch({ name: 'deliverable_type_eid', control: form.control })

  const { eventTypeOptions } = useEventTypes({ selectedEvents })
  const { deliverableTypes } = useDeliverableTypes({ selectedTypes })

  const handleClearAll = () => {
    form.reset()
  }

  const handleCreateJob = (data: tAddJobForm, callback: (jobEid: string) => void) => {
    if (selectedTypes?.length === 0) {
      toast.error('You must have at least one deliverable type to create a job.')
    } else {
      setIsCreatingJob(true)
      toast.promise(
        dispatch(
          createJob({
            ...data,
            event_type_eids: data.event_types?.map(({ eid }) => eid),
          } as unknown as Types.Job.iJobPayload)
        ).then((createJobResponse) => {
          if (createJobResponse.meta.requestStatus === 'fulfilled') {
            const { eid } = createJobResponse.payload
            if (selectedTypes?.length) {
              const deliverables = selectedTypes?.map((type) => {
                const deliverable: Types.Deliverable.iDeliverablePayload = {
                  eid: `D${type.eid}-temp-eid`,
                  name: type.value as string,
                  deliverable_type_eid: type.eid,
                  start_datetime: null,
                  end_datetime: null,
                }
                return deliverable
              })
              dispatch(createMultipleDeliverable({ job_eid: eid, deliverables })).then((deliverableResponse) => {
                if (deliverableResponse.meta.requestStatus === 'fulfilled') {
                  callback(createJobResponse.payload.eid)
                }

                setIsCreatingJob(false)
                toast.success(<JobCreated jobId={eid} />)
                closeModal(ModalPages.ADD_JOB)
              })
            }
          } else {
            setIsCreatingJob(false)
          }
        }),
        {
          pending: 'Creating job...',
        }
      )
    }
  }

  const handleContinue = (data: tAddJobForm) => {
    handleCreateJob(data, (jobEid) => goToRequestById(jobEid))
  }

  const handleSaveDraft = (data: tAddJobForm) => {
    handleCreateJob(data, () => {
      if (router.route === '/[workspace]/jobs') {
        dispatch(
          fetchAllJobs({
            page: '1',
          })
        )
      }
    })
  }

  return (
    <FormProvider {...form}>
      <DevTool control={form.control} />
      <form>
        <Stack sx={{ backgroundColor: GlobalStyles.MONO_WHITE, borderRadius: '8px', width: { sm: '344px', md: '450px' } }}>
          <ModalHeader
            modalContext={ModalPages.ADD_JOB}
            modalTitle={'Create a Job'}
            textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
          />
          <Stack gap="8px" className="dialog-content">
            <InputRow label="Job Title">
              <Textfield name="name" sx={TEXTFIELD_STYLES} />
            </InputRow>
            <InputRow label="Client" tooltipText="Only internal users will see this">
              <Textfield name="client" sx={TEXTFIELD_STYLES} />
            </InputRow>
            <InputRow
              label="Event Type"
              tooltipText="What event is being organised as part of this job? This gives stars valuable context, and will help with your reporting">
              <FormAutoComplete multiple name="event_types" options={eventTypeOptions} />
            </InputRow>
            <InputRow
              label="Deliverables"
              tooltipText="What event is being organised as part of this job? This gives stars valuable context, and will help with your reporting">
              <FormAutoComplete
                multiple
                name="deliverable_type_eid"
                options={deliverableTypes}
                rules={{
                  required: 'You must have at least one deliverable type to create a job.',
                }}
              />
            </InputRow>
          </Stack>
          <Stack direction="row" justifyContent="space-between" className="dialog-footer">
            <Button variant="text" onClick={handleClearAll} disabled={isCreatingJob}>
              Clear All
            </Button>
            <Stack direction="row" spacing={2}>
              <Button type="submit" variant="outlined" onClick={form.handleSubmit(handleContinue)} disabled={isCreatingJob}>
                Continue
              </Button>
              <Button type="submit" variant="contained" onClick={form.handleSubmit(handleSaveDraft)} disabled={isCreatingJob}>
                Save Draft
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  )
}
