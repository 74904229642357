import { ReactNode } from 'react'
import { StyleProp, ViewProps, TextStyle, ViewStyle, ImageStyle, ImageURISource } from 'react-native'
import { StackProps } from '../Stack/types'
import { TextProps } from '../Text/types'

export enum AvatarDefaults {
  TYPE = 'primary',
  SIZE = 'md',
}

export type AvatarType = 'primary'

export type AvatarSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'

export interface AvatarProps extends ViewProps {
  /**
   * The type of Avatar
   */
  type?: AvatarType
  /**
   * Size of Avatar
   */
  size?: AvatarSizes
  /**
   * The username to display on hover
   */
  hover?: boolean
  /**
   * Custom styles for the Avatar container
   */
  style?: StyleProp<ViewStyle>
  /**
   * Custom styles for the Avatar image
   */
  imageStyle?: StyleProp<ImageStyle>
  /**
   * Image url
   */
  image?: string
  /**
   * Additional Data to be passed if Avatar is pressable
   */
  data?: any
  /**
   * Handler for onPress
   */
  onPress?: (data: any) => void
  /**
   * Set if Avatar is pressable or not
   */
  isPressable?: boolean
  /**
   * Left component to stick to the Avatar with
   */
  leftComponent?: JSX.Element
  /**
   * Right component to stick to the Avatar with
   */
  rightComponent?: JSX.Element
  /**
   * String placeholder like user initials
   */
  placeHolder?: JSX.Element
}

export enum AvatarGroupDefaults {
  OFFSET = 2,
  SPACINGS = 'small',
}

export interface AvatarGroupProps extends ViewProps {
  /**
   * Size of Avatar
   */
  sizes?: AvatarSizes
  /**
   * Amount of avatar to show before overflow
   */
  offset?: number

  // total count of avatars
  total?: number
  /**
   * String placeholder like user initials
   */
  placeHolder?: JSX.Element

  /**
   * Avatar childrens
   */
  children: JSX.Element[]

  /**
   * Avatar children spacing
   * */
  spacings?: 'medium' | 'small' | number

  /**
   * additional Props for the extra avatar counter
   */
  extraAvatarProps?: {
    size?: AvatarSizes
    placeholderTextProps?: TextProps
    style?: StyleProp<ViewStyle>
  }
}
