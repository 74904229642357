import React, { MutableRefObject } from 'react'

import { CardProps } from '@mui/material/Card'
import { Types } from '@orbit'
import { StyleProp, ViewStyle } from 'react-native'
import { DropdownOption } from '../Dropdown/types'
import { LabelProps, LabelTypes } from '../Label/types'
import { SpaceType, StackProps } from '../Stack/types'

export enum CardDefaults {
  PLATFORM = 'web',
}

export enum CardUserDefaults {
  TYPE = 'user',
}

export type CardUserType = 'user' | 'star' | 'manager' | 'owner'

export type JobStatus = {
  type: LabelTypes
  text: string
}

export type JobDetails = {
  dateFrom: Date
  dateTo: Date
  location: string
  createdDate: Date
  ownerName: string
  budgetGuide: string
  price: number
  gst: string
  tags: JobsLabelProps[]
  labels: JobsLabelProps[]
  bookedCount: number
  starsCount: number
  maxStarsCount: number
  [key: string]: any
}

export type DeliverableEventType = {
  eid: string
  name: string
  icon?: string | null
}

export type JobAgreement = {
  fee_currency_code: string
  fee_amount: number | null
  status: string
  eid: string
  updated_at: string | null
  created_at: string | null
  job?: any
  talent: Types.Talent.iTalent
}

export type DeliverableAgreement = {
  eid: string
  status: string
  created_at: string
  updated_at: string
  jobAgreement?: JobAgreement
}

export type DeliverableStatus = {
  status: string
}

export type Deliverable = {
  eid: string
  name: string
  description: string | null
  start_datetime: string
  end_datetime: string | null
  created_at: string
  current_number_of_talents?: number | null
  number_of_talents_required?: number | null
  deliverable_agreements?: DeliverableAgreement[] | null
  deliverable_type: {
    eid: string
    name: string
  }
  location?: Types.Location.iLocation | null
  status: Types.Job.StatusTypes
  notifCount?: number | string | null
  job?: any
}

export interface JobsLabelProps extends LabelProps {
  icon?: string
}

export interface iCardUserSelect {
  item: DropdownOption
  user: Types.User.iUser
}

export type CardUserProps = CardProps & {
  id?: string
  /**
   * User card type
   */
  type?: CardUserType
  /**
   * User card data
   */
  userData: Types.User.iUser
  /**
   * Show permission selection element
   */
  showPermissionSelect?: boolean
  /**
   * Hidden component, dropdown sibling
   */
  hiddenComponent?: JSX.Element | JSX.Element[]
  /**
   * Show hidden component
   */
  showHiddenComponent?: boolean
  /**
   * Dropdown options
   */
  dropdownOptions?: DropdownOption[]
  /**
   * Dropdown list label
   */
  dropdownOptionLabel?: string
  /**
   * User card add/select button style
   */
  buttonStyle?: StyleProp<ViewStyle>
  /**
   * Dropdown selected option
   */
  selectedItem?: DropdownOption
  /**
   * Main stack wrapper style
   */
  wrapperStyle?: StyleProp<ViewStyle>
  /**
   * Space between user card buttons
   */
  buttonSpace?: SpaceType
  /**
   * Show user card message button
   */
  showMessageButton?: boolean
  /**
   * Show user card contact button
   */
  showContactButton?: boolean
  /**
   * Custom rendering for user card sub details
   */
  subDetails?: JSX.Element
  /**
   * Validate the dropdown value, adds red border if nothing is selected
   */
  validateDropdown?: boolean
  /**
   * User card selected/active
   */
  selected: boolean
  /**
   * String placeholder like user initials
   */
  avatarPlaceholder?: JSX.Element
  /** Permission dropdown placeholder */
  permissionPlaceholder?: string
  /**
   * User card on dropdown select
   */
  disableMessage?: boolean
  disableContact?: boolean
  disableLeave?: boolean
  onPermissionSelect?: (e: iCardUserSelect) => void
  /** On user card select */
  onSelect: (data: { selected: boolean; details: Types.User.iUser; setIsSelected: (selected: boolean) => void }) => void
  /** On message button click */
  onMessage?: () => void
  /** On contact button click */
  onContact?: () => void
  /** On leave button click */
  onLeave?: () => void
  /** Use to override name */
  nameOverride?: string
  leaveButtonStyle?: StyleProp<ViewStyle>
  messageButtonStyle?: StyleProp<ViewStyle>
  contactButtonStyle?: StyleProp<ViewStyle>
  leaveButtonText?: string
  /** Disable all buttons except leave */
  disabled?: boolean
  /** Prevent select event */
  preventSelect?: boolean
  /**
   * Show user card add button
   */
  showAddButton?: boolean
  dropdownLength?: string
}

export type ProposalDetails = {
  dateFrom: Date
  dateTo: Date
  location: string
  createdDate: Date
  ownerName: string
  budgetGuide: string
  tags: JobsLabelProps[]
  labels: JobsLabelProps[]
  starsCount: number
  maxStarsCount: number
  [key: string]: any
}
export interface CardProposalProps extends CardProps {
  title: string
  details: ProposalDetails
  status: JobStatus
}

export interface ErrorCardProps extends CardProps {
  errorIcon: JSX.Element
  heading: string
  subHeading: string | JSX.Element | JSX.Element[]
  actionButtons?: React.ReactNode
  containerStyle?: StyleProp<ViewStyle>
}

export enum AlertDefaults {
  type = 'primary',
  variant = 'default',
}
export type AlertType = 'primary' | 'danger'
export interface AlertCardProps extends StackProps {
  /**
   * @required Card Title
   */
  title: string
  /**
   * @required Text to show in Alert
   */
  text: string
  /**
   * @optional can be primary or danger.
   *
   * defaults to primary
   */
  type?: AlertType
  /**
   * @optional tells card to render ListItems
   *
   */
  list?: boolean
  /**
   * @optional used for List variant. not shown otherwise
   */
  listItems?: string[]
  /**
   * @required primary button text.
   */
  confirmBtnText: string
  /**
   * @required callback on primary button press
   */
  onPress: () => void
  /**
   * @required callback on cancel button press
   */
  onCancel: () => void
  /**
   * @optional callback on X button on modal title.
   *
   * uses onCancel callback as default.
   */
  onExit?: () => void
}

export interface JobWidgetProps extends CardProps {
  stat: {
    icon: JSX.Element
    label: string
    count: number
    path?: string
    info?: string
    onPress?: () => void
  }
  opp: Array<{
    category: string
    count: number
    label: string
    path?: string
    info?: string
    onPress?: () => void
  }>
  total: {
    amount: number
    currency: string
    label: string
    info?: string
  }
}

export type CardComponentType = ((props: CardProps) => JSX.Element) & {
  User: React.MemoExoticComponent<(props: any, ref?: MutableRefObject<any>) => JSX.Element>
  Proposal: React.MemoExoticComponent<(props: CardProposalProps, ref?: MutableRefObject<any>) => JSX.Element>
  Error: React.MemoExoticComponent<(props: ErrorCardProps, ref?: MutableRefObject<any>) => JSX.Element>
  Alert: React.MemoExoticComponent<(props: AlertCardProps, ref?: MutableRefObject<any>) => JSX.Element>
}
