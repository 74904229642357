import { Types } from '@orbit'
import { useRouter } from 'next/router'
import { useWorkspaces } from './useWorkspaces'
import { useEffect, useState } from 'react'

const getPersistedWorkspace = () => {
  try {
    return JSON.parse(JSON.parse(localStorage.getItem('persist:workspace') as string).activeWorkspace)
  } catch (error) {
    return undefined
  }
}

export const useWorkspace = () => {
  const router = useRouter()
  const workspacesQuery = useWorkspaces()

  const [activeWorkspace, setActiveWorkspace] = useState(getPersistedWorkspace())

  useEffect(() => {
    if (workspacesQuery.data?.length) {
      if (router.query.workspace) {
        setActiveWorkspace(workspacesQuery.data?.find((ws: Types.Workspaces.iWorkspace) => ws.slug === router.query.workspace))
      } else {
        setActiveWorkspace(workspacesQuery.data[0])
      }
    }
  }, [router.query.workspace, workspacesQuery.data])

  return { activeWorkspace, setActiveWorkspace }
}
