import { GlobalStyles, Icon, Tooltip } from '@andromeda'
import { Button, IconButton, Radio, Stack, TextField, Typography } from '@mui/material'
import Image from 'next/image'
import LogoPlaceholder from 'public/pickstar-logo.png'
import moment from 'moment'
import { useContext, useState } from 'react'
import { getIcon } from './components'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'

export const Posts = () => {
  return (
    <Stack rowGap="14px" className="paper-border" padding="12px">
      <Stack direction="row" columnGap="10px" paddingY="10px">
        <Icon.ThumbsUp color={GlobalStyles.SLATE_500} size={20} />
        <Typography color={GlobalStyles.SLATE_500} textTransform="uppercase" fontSize="14px" fontWeight={500} letterSpacing="-0.14px">
          SOCIAL CAMPAIGNS
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" paddingY="8px">
        <Stack direction="row" columnGap="10px" alignItems="center">
          <Typography fontSize="14px" fontWeight={500} color={GlobalStyles.MONO_BLACK}>
            Post URL
          </Typography>
          <Tooltip text="url" />
        </Stack>
        <TextField placeholder="https://www.instagram.com/you" sx={{ minWidth: '224px' }} />
      </Stack>
      <Stack
        columnGap="8px"
        sx={{
          '>div': {
            paddingY: '14px',
            borderBottom: `1px solid ${GlobalStyles.SLATE_100}`,
          },
          '>div:last-child': {
            borderBottom: 'none',
          },
        }}>
        {new Array(2).fill(1).map((_, index) => (
          <Stack direction="row" key={`post-${index}`} columnGap="8px">
            <Post />
            <Metric />
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
const Metric = () => {
  const { toggleModal } = useContext(ModalContext)

  const [isHovered, setHovered] = useState(false)

  return (
    <Stack
      padding="8px"
      justifyContent="space-between"
      position="relative"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      {isHovered && (
        <IconButton sx={{ padding: 0, position: 'absolute', right: '8px' }}>
          <Icon.XCircle color={GlobalStyles.SLATE_700} />
        </IconButton>
      )}
      <Stack flex={0.5} rowGap="8px">
        {new Array(4).fill(1).map((_, index) => (
          <Stack key={`metric-${index}`}>
            <Typography color={GlobalStyles.MONO_BLACK} fontSize="14px" fontWeight={500} letterSpacing="-0.14px">
              Data Amount
            </Typography>
            <Typography color={GlobalStyles.SLATE_700} fontSize="14px" letterSpacing="-0.14px">
              Metric Name
            </Typography>
          </Stack>
        ))}
      </Stack>
      <Stack direction="row" columnGap="10px">
        <Button
          variant="text"
          startIcon={<Icon.Search size={20} />}
          sx={{ padding: 0 }}
          onClick={() => {
            toggleModal(ModalPages.SOCIAL_CAMPAIGNS)
          }}>
          Find Similar Posts
        </Button>
        <Tooltip text="" />
      </Stack>
    </Stack>
  )
}

export const Post = ({ id, selected, onClick }: { id?: number; selected?: boolean; onClick?: (id: number) => void }) => (
  <Stack
    className="paper-border"
    padding="8px"
    flex={0.5}
    rowGap="8px"
    border={selected ? `1px solid ${GlobalStyles.PRIMARY_500}` : 'none'}
    onClick={() => onClick && id && onClick(id)}
    sx={{ cursor: 'pointer' }}>
    <Stack alignItems="center">
      <Image alt="post" src={LogoPlaceholder} height={150} width={150} />
    </Stack>
    <Stack direction="row" columnGap="8px">
      {getIcon({ platform: 'instagram' })}
      <Typography color={GlobalStyles.SLATE_700} fontWeight={500} fontSize="14px" letterSpacing="-0.14px">
        Obiwan.Kenobi
      </Typography>
    </Stack>
    <Typography color={GlobalStyles.SLATE_500} fontSize="12px" letterSpacing="-0.12px">
      {moment(new Date()).format('DD MMMM YYYY')}
    </Typography>
    <Typography color={GlobalStyles.SLATE_700} fontSize="14px" letterSpacing="-0.14px" className="line-clamp-2">
      Voluptate exercitation dolor ullamco voluptate sint laboris fugiat est. Tempor ad incididunt laborum adipisicing ad consequat in ut.
      Fugiat duis reprehenderit elit amet ullamco est et nisi labore. Pariatur dolor dolore in exercitation. Elit qui culpa fugiat officia
      sit consectetur officia aliqua. Elit aliqua ut id sunt officia in consequat aute. Adipisicing cillum fugiat anim eiusmod laborum
      cupidatat ad Lorem ullamco nostrud nulla sunt.
    </Typography>
    <Stack direction="row" justifyContent="space-between">
      <Button variant="text" sx={{ padding: 0, alignSelf: 'baseline', fontWeight: 400, minHeight: 'fit-content' }}>
        Read more
      </Button>
      {onClick && <Radio checked={selected} sx={{ padding: 0, height: 'fit-content' }} />}
    </Stack>
  </Stack>
)
