import { Button, GlobalStyles, Icon, Stack, View } from '@andromeda'
import { ModalFooter, ModalHeader } from '@components'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { AppStyles } from '@styles'
import { ModalContext } from '@utils/context/Modal.context'
import { useContext, useState } from 'react'

const RadioOptions = [
  { id: 0, label: 'Talent will be notified of assigned and booked deliverables', value: 'notifyAll' },
  { id: 1, label: 'Only notify talent when talent is booked on a deliverable', value: 'onlyBooked' },
]

const NotificationSettings = () => {
  const { toggleModal, options } = useContext(ModalContext)

  const [selectedRadio, setSelectedRadio] = useState<string>(RadioOptions[0].value)

  // const _handleRadioChange = (item: RadioValue) => {
  //     setSelectedRadio(item)
  // }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setSelectedRadio((event.target as HTMLInputElement).value)
  }

  const _handleConfirm = () => {
    toggleModal('notificationSettings')
  }

  return (
    <Stack rounded height="auto" direction="column" style={{ backgroundColor: GlobalStyles.MONO_WHITE }} space="none">
      <ModalHeader
        modalTitle={`Notification Settings for ${options?.modalProps?.notificationSettings.name}`}
        modalContext={'notificationSettings'}
        leftIcon={
          <View>
            <Icon.Settings strokeWidth={1} size={32} color={GlobalStyles.MONO_BLACK} />
          </View>
        }
      />
      <Stack
        style={{
          backgroundColor: GlobalStyles.MONO_WHITE,
          paddingTop: AppStyles.MODAL_PADDING_VERTICAL / 2,
          paddingBottom: AppStyles.MODAL_PADDING_VERTICAL,
          paddingHorizontal: AppStyles.MODAL_PADDING_HORIZONTAL,
        }}
        justify={'space-around'}
        direction="column"
        height={'auto'}
        space="lg"
        align="flex-start">
        {/* Radio Group */}
        <FormControl>
          <RadioGroup aria-labelledby="notification-options" name="notification-option" value={selectedRadio} onChange={handleChange}>
            {RadioOptions.map((option) => (
              <FormControlLabel key={option.id} value={option.value} control={<Radio />} label={option.label} />
            ))}
          </RadioGroup>
        </FormControl>
        {/* <Radio.Group
                    direction="column"
                    height={'auto'}
                    align="flex-start"
                    onChangeValue={_handleRadioChange}
                    defaultSelected={selectedRadio && selectedRadio.id}
                    items={RadioOptions}
                /> */}
      </Stack>
      <ModalFooter
        backgroundColor={GlobalStyles.MONO_WHITE}
        wrapperStyle={{ justifyContent: 'space-between' }}
        buttonStyles={{
          paddingVertical: 12,
          paddingHorizontal: 18,
          borderRadius: 8,
        }}
        leftComponent={
          <Button
            type="secondary"
            style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
            onPress={() => toggleModal('notificationSettings')}
            textStyle={{ color: GlobalStyles.PRIMARY_500 }}
            text="Cancel"
          />
        }
        onButtonPress={_handleConfirm}
        buttonText={'Confirm'}
      />
    </Stack>
  )
}

export default NotificationSettings
