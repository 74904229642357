import { API, Audits } from '../types'
import { HttpClient } from './api.service'

export const auditService = {
  list: ({ type, eid }: { type: string; eid: string }) => {
    const endpoint = API.AuditEndpoints.AUDIT.replace(':auditable_type', type).replace(':auditable_eid', eid)
    return HttpClient.get<null, Audits.iAudit[]>({
      endpoint,
    })
  },
}
