import { Icon } from '@andromeda'
import { ModalHeader } from '@components'
import { Button, Stack } from '@mui/material'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext, useState } from 'react'
import { Post } from 'src/components/social-campaigns/post'

export const SocialCampaigns = () => {
  const [selected, setSelected] = useState<number[]>([])

  return (
    <Stack minWidth={{ sm: '800px' }}>
      <ModalHeader modalContext={ModalPages.SOCIAL_CAMPAIGNS} leftIcon={<Icon.ThumbsUp />} modalTitle="Add Similar Post" />
      <Stack
        className="dialog-content"
        direction="row"
        flexWrap="wrap"
        sx={{ '>div': { maxWidth: '185px' } }}
        justifyContent="center"
        columnGap="4px">
        {new Array(4).fill(1).map((_, index) => (
          <Post
            key={`post-${index}`}
            id={index}
            selected={selected.some((s) => s === index)}
            onClick={(i) => {
              setSelected((prev) => {
                if (selected.some((s) => s === i)) {
                  return prev.filter((f) => f !== i)
                } else {
                  return [...prev, i]
                }
              })
            }}
          />
        ))}
      </Stack>
      <Stack className="dialog-footer" alignItems="end">
        <Button variant="outlined" sx={{ width: 'fit-content' }}>
          Add Selection to Deliverable
        </Button>
      </Stack>
    </Stack>
  )
}
