import { Avatar, Button, GlobalStyles, Icon, Input, StackV2, Text, View } from '@andromeda'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { updateUser, updateUserProfilePicture } from '@redux/reducers/authReducer'
import { AppStyles } from '@styles'
import { ModalContext, ModalOptions } from '@utils/context/Modal.context'
import { useScreenDimensions } from '@utils/hooks/dimensions'
import { iMyAccountsProps } from '@utils/types/my-accounts'
import { memo, useCallback, useContext, useEffect, useState } from 'react'
import { FlexStyle } from 'react-native'
import { toast } from 'react-toastify'
import { validateEmail } from 'src/utils'
import IconButton from '@mui/material/IconButton'
import { useRouter } from 'next/router'
import { Types } from '@orbit'

const ProfileManagerAccounts = ({ isEditMode, profile, onSubmit, onSave, onCancel }: iMyAccountsProps) => {
  const router = useRouter()

  const dispatch = useAppDispatch()

  const { toggleModal } = useContext(ModalContext)

  const [account, setAccount] = useState({
    name: '',
    email: '',
    profile_image: (profile?.profile_image ?? {}) as Types.User.iProfileImage | null,
  })

  const [emailError, setEmailError] = useState<string>('')

  const { screenSize } = useScreenDimensions()
  const screenIsMedium = screenSize.md

  // Styles applied to various sections for responsiveness
  const responsiveStyles = {
    responsiveAvatarSection: {
      direction: !screenIsMedium ? 'column' : ('row' as FlexStyle['flexDirection']),
      justify: !screenIsMedium ? 'center' : ('flex-start' as FlexStyle['justifyContent']),
      avatarStyle: {
        width: !screenIsMedium ? 80 : 96,
        height: !screenIsMedium ? 80 : 96,
        borderRadius: !screenIsMedium ? 40 : 48,
      },
      avatarImageStyle: {
        width: !screenIsMedium ? 80 : 96,
        height: !screenIsMedium ? 80 : 96,
      },
      fullNameWidth: !screenIsMedium ? '100%' : ('auto' as number | 'auto' | '100%'),
    },
    responsiveProfile: {
      borderBottomWidth: !screenIsMedium ? 0 : 2,
    },
  }

  const _handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  const _handleSubmit = () => {
    toast
      .promise(dispatch(updateUser(account)), {
        pending: 'Updating profile.',
      })
      .then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          toast.success('Profile updated successfully.')
          onSave && onSave(response.payload)
          _handleCancel()
        } else {
          toast.error(response.payload.message || response.payload.error.message || 'Error updating profile .')
        }
      })
  }

  const _handleAvatarSave = ({ fileMeta, profilePicture }: { fileMeta: File; profilePicture: string }) => {
    dispatch(updateUserProfilePicture({ fileMeta, profilePicture })).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        setAccount((prev) => ({ ...prev, profile_image: response.payload.profile_image }))
      }
    })
  }

  const avatarModalOptions: ModalOptions = {
    modalProps: {
      profilePictureForm: {
        _handleAvatarSave: _handleAvatarSave,
        avatar: profile?.profile_image?.full_url,
      },
    },
  }

  const _updateAccount = (value: string, key: string) => {
    setAccount({
      ...account,
      [key]: value,
    })
    if (key === 'email') {
      if (value !== '' && !validateEmail(value)) setEmailError('Invalid email')
      else setEmailError('')
    }
  }

  useEffect(() => {
    setAccount({
      email: profile?.email ?? '',
      name: profile?.name ?? '',
      profile_image: profile?.profile_image,
    })
  }, [profile])

  return (
    <StackV2 width={'100%'} alignItems="flex-start" spacing={2} direction="column">
      {/* Avatar and Full Name */}
      <StackV2
        direction={responsiveStyles.responsiveAvatarSection.direction}
        justifyContent={responsiveStyles.responsiveAvatarSection.justify}
        alignItems="center"
        spacing={2}>
        <View>
          {/* Photo Upload button */}
          {isEditMode && (
            <View
              style={{
                position: 'absolute',
                zIndex: 1,
                left: 52,
                bottom: -5,
              }}>
              <IconButton
                style={{
                  borderRadius: 40,
                  backgroundColor: GlobalStyles.SLATE_100,
                }}
                onClick={() => toggleModal('profilePictureForm', avatarModalOptions)}>
                <Icon.Camera size={16} strokeWidth={3} color={GlobalStyles.PRIMARY_500} style={{ textAlign: 'center' }} />
              </IconButton>
            </View>
          )}

          <Avatar
            style={{
              ...responsiveStyles.responsiveAvatarSection.avatarStyle,
            }}
            imageStyle={responsiveStyles.responsiveAvatarSection.avatarImageStyle}
            image={account?.profile_image?.full_url || undefined}
            placeHolder={
              <Icon.User
                size={screenIsMedium ? AppStyles.AVATAR_SIZES.PROFILE_PAGE : AppStyles.AVATAR_SIZES.PROFILE_PAGE_SM}
                color={GlobalStyles.SLATE_200}
                strokeWidth={1}
              />
            }
          />
        </View>

        {isEditMode ? (
          <StackV2 direction="column" spacing={2} alignItems="flex-start" width={responsiveStyles.responsiveAvatarSection.fullNameWidth}>
            <Text bold style={{ color: GlobalStyles.MONO_BLACK }}>
              Full Name*
            </Text>
            <Input
              name="name"
              style={{
                fontSize: GlobalStyles.FONT_SIZES.BASE,
                borderColor: GlobalStyles.SLATE_100,
                padding: AppStyles.PADDING_SIZES.BASE,
              }}
              placeholder="Full Name"
              value={account.name}
              onChange={(e: any) => _updateAccount(e.target.value, 'name')}
            />
          </StackV2>
        ) : (
          <Text bold style={{ fontSize: 24 }}>
            {account.name}
          </Text>
        )}
      </StackV2>

      {/* Profile Body */}
      <StackV2 alignItems="flex-start" direction={isEditMode || !screenIsMedium ? 'column' : 'row'} width={'100%'}>
        <StackV2
          alignItems="flex-start"
          width={'100%'}
          flex={1}
          direction="column"
          spacing={2}
          style={{ ...responsiveStyles.responsiveProfile }}>
          <Text bold size="md" style={{ color: GlobalStyles.MONO_BLACK }}>
            {isEditMode ? 'Email*' : 'Email'}
          </Text>
          {isEditMode ? (
            <Input
              name="email"
              style={{
                fontSize: GlobalStyles.FONT_SIZES.BASE,
                borderColor: GlobalStyles.SLATE_100,
                padding: AppStyles.PADDING_SIZES.BASE,
              }}
              placeholder="Email"
              value={account.email}
              onChange={(e: any) => _updateAccount(e.target.value, 'email')}
            />
          ) : (
            <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
              {account.email}
            </Text>
          )}
        </StackV2>

        {/* <StackV2 align="flex-start" flex={1} direction="column" space="sm" style={responsiveStyles.responsiveProfile}>
                    <Text bold size="md" style={{ color: GlobalStyles.MONO_BLACK }}>
                        {isEditMode ? 'Mobile*' : 'Mobile'}
                    </Text>
                    {isEditMode ? (
                        <Input
                            style={{ fontSize: 16, borderColor: GlobalStyles.SLATE_100, padding: 24, minWidth: 408 }}
                            placeholder="Mobile"
                            defaultValue="+61 423 456 789"
                        />
                    ) : (
                        <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
                            0423 456 789
                        </Text>
                    )}
                </StackV2> */}

        {/* <StackV2 align="flex-start" flex={1} direction="column" space="sm">
                    <Text bold size="md" style={{ color: GlobalStyles.MONO_BLACK }}>
                        Location
                    </Text>
                    {isEditMode ? (
                        <Input
                            style={{ fontSize: 16, borderColor: GlobalStyles.SLATE_100, padding: 24, minWidth: 408 }}
                            placeholder="Location"
                            defaultValue="Sydney, Australia"
                        />
                    ) : (
                        <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
                            Sydney, Australia
                        </Text>
                    )}
                </StackV2> */}
      </StackV2>

      {/* Edit mode Button Group */}
      {isEditMode && (
        <StackV2 width={'100%'} spacing={2} direction={'row'} alignItems="center" justifyContent="flex-end">
          <Button
            type="secondary"
            text="Cancel"
            textStyle={{ color: GlobalStyles.PRIMARY_500 }}
            style={{
              backgroundColor: GlobalStyles.MONO_WHITE,
              borderRadius: 8,
              paddingVertical: 12,
              paddingHorizontal: 16,
            }}
            onPress={_handleCancel}
          />

          <Button
            type="primary"
            text="Confirm"
            style={{ borderRadius: 8, paddingVertical: 12, paddingHorizontal: 16 }}
            onPress={_handleSubmit}
          />
        </StackV2>
      )}
    </StackV2>
  )
}

export default memo(ProfileManagerAccounts)
