import { GlobalStyles, Icon, Tooltip } from '@andromeda'
import { Box, Grid, Grid2Props, Stack, StackProps, Typography } from '@mui/material'
import { useContext } from 'react'
import { hasUpdatePermissionsContext, isEditingContext, DrawerContext } from './Details'
import { EditIconContext } from '@utils/context/EditIcon.context'

export const Paper = ({ children, style, ...props }: { children?: JSX.Element | JSX.Element[] } & StackProps) => (
  <Stack className="paper-border" style={{ backgroundColor: '#fff', ...(style || {}) }} {...props}>
    {children}
  </Stack>
)
type ColumnLabelProps = {
  label: string
  tooltipText?: string
}
export const ColumnLabel = ({ label, tooltipText = '', ...props }: ColumnLabelProps) => (
  <>
    <Typography color={GlobalStyles.MONO_BLACK} fontWeight={500} fontSize="14px" sx={{ marginBottom: { xxs: '5px', sm: 0 } }}>
      {label}
    </Typography>
    <Tooltip text={tooltipText} />
  </>
)

export const Row = ({
  children,
  hasBorders = true,
  ...props
}: { children: JSX.Element | JSX.Element[]; hasBorders?: boolean } & Grid2Props) => {
  const { isEditing, setIsEditing } = useContext(isEditingContext)
  const { isUpdatable } = useContext(hasUpdatePermissionsContext)

  return (
    <Grid
      container
      onClick={() => (isUpdatable ? setIsEditing(true) : () => {})}
      sx={{
        cursor: isUpdatable ? (isEditing ? '' : 'pointer') : '',
      }}
      boxSizing="border-box"
      rowGap={{ xxs: '10px', sm: 0 }}
      {...props}>
      {children}
    </Grid>
  )
}
export const CommonGrid = ({
  label,
  tooltipText = '',
  value = '',
  alignItems = '',
  gridProps,
}: {
  value?: string | JSX.Element | null
  alignItems?: string
  gridProps?: Grid2Props
} & ColumnLabelProps) => {
  const { visibleEditIcon, setVisibleEditIcon } = useContext(EditIconContext)
  const { isEditing } = useContext(isEditingContext)
  const { isUpdatable } = useContext(hasUpdatePermissionsContext)
  const { isOpen } = useContext(DrawerContext)

  return (
    <Grid
      item
      xxs={12}
      md={isOpen ? 12 : 6}
      xl={6}
      padding="10px 24px"
      display="flex"
      alignItems="center"
      onMouseEnter={() => setVisibleEditIcon(label)}
      onMouseLeave={() => setVisibleEditIcon(null)}
      sx={{
        borderBottom: `1px solid ${GlobalStyles.SLATE_100}`,
      }}
      {...gridProps}>
      <Stack direction="row" width="100%" alignItems={alignItems}>
        <Stack direction="row" minWidth="130px" alignItems="center" gap="4px">
          <ColumnLabel label={label} tooltipText={tooltipText} />
        </Stack>
        <Stack flex={1} direction="row" className="items-center justify-between" flexWrap="wrap" gap="4px">
          {typeof value === 'string' ? (
            <Typography color={GlobalStyles.SLATE_700} fontSize="14px">
              {value}
            </Typography>
          ) : (
            <Stack flex={1}>{value}</Stack>
          )}
          <Box display={{ xxs: 'none', sm: 'initial' }}>
            <Icon.Edit2
              size={16}
              color={GlobalStyles.PRIMARY_500}
              style={{ visibility: isUpdatable && visibleEditIcon === label && !isEditing ? 'visible' : 'hidden' }}
            />
          </Box>
        </Stack>
      </Stack>
    </Grid>
  )
}
