export const COST_MODE_OPTIONS = [
  {
    id: 'PER_JOB',
    value: 'Job Cost',
  },
  {
    id: 'PER_DELIVERABLE',
    value: 'Deliverable Cost',
  },
]
