import 'react-dates/lib/css/_datepicker.css'
import DateRangePicker from './DateRangePicker'
import SingleDatePicker from './SingleDatePicker'
import TimePicker from './TimePicker'
import type { PickerComponentType } from './types'

const PickerTemp: any = DateRangePicker
PickerTemp.SinglePicker = SingleDatePicker
PickerTemp.TimePicker = TimePicker
PickerTemp.RangePicker = DateRangePicker

const Picker = PickerTemp as PickerComponentType

export { Picker }
