import { Card, GlobalStyles, Icon, Stack } from '@andromeda'
import { FlyoutModalHeader } from '@components'

const InvalidAccess = () => {
  return (
    <Stack
      direction="column"
      space="none"
      style={{
        backgroundColor: GlobalStyles.SLATE_50,
      }}>
      <FlyoutModalHeader key={'invalid-access-flyout'} returnText="Back to Job" contextModal={'invalidAccess'} />
      <Card.Error
        errorIcon={<Icon.AlertCircle size={150} strokeWidth={1} color={GlobalStyles.SLATE_300} />}
        heading="You don't have permission to view this Deliverable, or it no longer exists"
        subHeading="Please contact an Administrator if this is an error."
      />
    </Stack>
  )
}

export { InvalidAccess }
