import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { iModalProps } from '@utils/types/modal'
import { forwardRef } from 'react'

const style = {
  position: 'absolute',
  zIndex: 15,
  top: { sm: '5%' },
  // bottom: '50%',
  left: { sm: '25%' },
  // transform: 'translate(-50%, -50%)',
  maxWidth: { sm: 600 },
}

const CenterModal = ({ children, onBackdropPress, ...props }: iModalProps, ref?: any) => {
  return (
    <Modal
      id="modal-body"
      open={!!props.show}
      onClose={() => onBackdropPress}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      // style={{ overflow: 'scroll' }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}>
      <Box
        className="dialog-container"
        sx={{
          backgroundColor: 'white',
          position: 'absolute',
          zIndex: 15,
          top: { sm: '50%' },
          transform: { sm: 'translate(-50%,-50%)' },
          left: { xxs: 0, sm: '50%' },
          width: { xxs: '100vw', sm: 'fit-content' },
          overflowY: 'auto',
          maxHeight: {
            xxs: `calc(${window.innerHeight}px - env(safe-area-inset-top) - env(safe-area-inset-bottom))`,
            sm: `${window.innerHeight}px`,
          },
          marginTop: { xxs: 'env(safe-area-inset-top)', sm: 0 },
          marginBottom: { xxs: 'env(safe-area-inset-bottom)', sm: 0 },
          height: 'fit-content',
          ...props.containerStyles,
        }}>
        {children}
      </Box>
    </Modal>
  )
}

export default forwardRef(CenterModal)
