import { Button, GlobalStyles, StackV2, Text, View } from '@andromeda'
import { iModalFooterProps } from '@utils/types/modal'

const ModalFooter = ({
  backgroundColor = GlobalStyles.SLATE_900,
  wrapperStyle,
  stackDirection = 'row',
  divider = true,
  loading,
  ...props
}: iModalFooterProps) => {
  const _handlePress = () => {
    props.onButtonPress && props.onButtonPress()
  }

  return (
    <StackV2
      direction="row"
      alignItems={'center'}
      justifyContent={'flex-end'}
      className="dialog-footer"
      style={{
        backgroundColor,
        position: 'relative',
        bottom: 0,
        left: 0,
      }}>
      {props.leftComponent ?? null}
      <StackV2 direction={stackDirection} width={'auto'} spacing={2} alignItems="center">
        {props.leftStackedComponent ?? null}
        <View style={{ paddingLeft: 15 }}>
          <Button
            type={props.buttonType}
            variant={props.buttonVariant}
            style={[props.buttonStyles, { alignItems: 'center' }]}
            onPress={_handlePress}
            loading={loading}
            loadingType={props.loadingType}
            disabled={props.disabled}>
            {props.leftIcon ?? null}

            <Text
              bold
              style={[
                { fontSize: GlobalStyles.FONT_SIZES.BASE, color: GlobalStyles.MONO_WHITE, textAlign: 'center' },
                props.buttonTextStyle,
              ]}>
              {props.buttonText}
            </Text>

            {props.rightIcon ?? null}
          </Button>
        </View>
      </StackV2>
    </StackV2>
  )
}

export default ModalFooter
