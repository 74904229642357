import { Avatar, GlobalStyles, Icon } from '@andromeda'
import { Chip, Grid, Stack, Tooltip, TooltipProps, Typography } from '@mui/material'
import { TYPE_CHIP_STYLES } from './job-card/job-card'

type tHoverData = {
  [key: string]: (
    | {
        eid?: string
        name?: string
        profile_image?: string
      }
    | any
  )[]
}

export const QuickLook = ({ data, children }: Pick<TooltipProps, 'children'> & { data: tHoverData }) => {
  const HoverDetails = () =>
    data && (
      <Stack
        width="344px"
        style={{
          backgroundColor: 'white',
          padding: '8px 24px',
          borderRadius: '6px',
          boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.25)',
        }}
        rowGap="8px">
        {Object.keys(data).map((key) => (
          <>
            <Typography marginTop="8px" color={GlobalStyles.SLATE_500} fontWeight="500" fontSize="14px">
              {key.split('_').join(' ')}
            </Typography>
            <Grid container columns={2} columnSpacing="24px" rowSpacing="8px">
              {data[key].map((item: any) =>
                key.toLowerCase().includes('type') ? (
                  <Grid key={item.eid} item>
                    <Chip size="small" key={item.eid} label={item.name} style={TYPE_CHIP_STYLES} sx={{ marginRight: '4px' }} />
                  </Grid>
                ) : (
                  <Grid key={item.eid} item xxs={1}>
                    <Stack direction="row" alignItems="center" columnGap="10px">
                      <Avatar
                        key={item.eid}
                        size="xs"
                        image={!!item.profile_image ? item.profile_image.full_url ?? item.profile_image : undefined}
                        placeHolder={<Icon.User size={GlobalStyles.AVATAR_SIZES.XS - 5} color={GlobalStyles.SLATE_200} strokeWidth={1} />}
                        style={{
                          borderWidth: 2,
                          borderColor: GlobalStyles.SLATE_200,
                        }}
                      />
                      {item.name && (
                        <Typography color={GlobalStyles.SLATE_700} fontWeight="500" fontSize="12px">
                          {item.name}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                )
              )}
            </Grid>
          </>
        ))}
      </Stack>
    )

  return (
    <Tooltip
      enterDelay={300}
      enterNextDelay={300}
      title={<HoverDetails />}
      placement="bottom"
      PopperProps={{
        sx: {
          '.MuiTooltip-tooltip': {
            maxWidth: '334px',
            backgroundColor: 'transparent !important',
          },
        },
      }}>
      {children}
    </Tooltip>
  )
}
