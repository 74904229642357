import { AppTrackingTransparency, AppTrackingStatusResponse } from 'capacitor-plugin-app-tracking-transparency'

export const getStatus = async (): Promise<AppTrackingStatusResponse> => {
  const response = await AppTrackingTransparency.getStatus()

  return response
}

export const requestPermission = async (): Promise<AppTrackingStatusResponse> => {
  const response = await AppTrackingTransparency.requestPermission()

  if (response.status === 'authorized') {
    const AcceptButton = document.getElementsByClassName('iubenda-cs-accept-btn')?.[0]
    if (AcceptButton) (AcceptButton as HTMLElement).click()
  } else if (response.status === 'denied') {
    const DenyButton = document.getElementsByClassName('iubenda-cs-reject-btn')?.[0]
    if (DenyButton) (DenyButton as HTMLElement).click()
  }

  return response
}
