import { TransitionDefaults } from '@utils/functions/getTransitionVariant'
import { motion, MotionProps } from 'framer-motion'
import { getTransitionVariant, TransitionTypes } from 'src/utils'

export interface iTransitionProps {
  type: TransitionTypes
  children: React.ReactChild[] | React.ReactChild
  className?: string
  initial?: boolean | string
  key?: number | string
  animate?: string[]
  zIndex?: string
  style?: MotionProps['style']
}

export const Transition = ({
  children,
  initial = TransitionDefaults.INITIAL,
  type = TransitionDefaults.TYPE,
  zIndex = '8888',
  ...props
}: iTransitionProps) => {
  return (
    <motion.div
      key={props.key}
      initial={initial}
      variants={getTransitionVariant(type, zIndex)}
      animate={props.animate ?? ['enter']}
      exit={['exit']}
      className={props.className}
      style={props.style}>
      {children}
    </motion.div>
  )
}
