import { Types } from '@orbit'
import { ModalPages } from '@utils/context/Modal.context'
import { WorkspaceType } from '@utils/types/workspace'
import { createContext } from 'react'

type UserSelection = { users: Types.User.iUser[]; invites: Types.User.iUser[] }

export interface AddUsersModalContentProps {
  enableCreateUser: boolean
  hasUpdatePermissions: boolean
  hasAddUserPermissions: boolean
  currentSelectedUsers: UserSelection
  newInvitedUsers?: Array<Types.Invite.iInviteCreatePayload>
  modalContext: ModalPages
  eventTypes: Partial<{
    invite: string
    select: string
    unselect: string
    inviteConfirmed: string
  }>
  redirectRoute: string
  requestId: string
  saveUsersStatus?: string
  leaveStatus?: string
  inviteAdditionalInfo: string
  showInviteRoleSelection?: boolean
  handleInvite: (
    draftSelection: UserSelection,
    inviteData: Types.Invite.iInviteCreatePayload,
    onFulfilled: (data: Types.Job.iDeliverableUsersResponse) => void
  ) => void
  handleSaveUsers: (draftSelection: UserSelection) => void
  handleLeave: (user: Types.User.iUser, draftSelection: UserSelection, setDraftSelection: (draftSelection: UserSelection) => void) => void
}

interface AddUsersModalContextProps {
  modalProps: Partial<AddUsersModalContentProps>
  workspace: WorkspaceType
  draftSelection: UserSelection
  newInvitedUsers: Array<Types.Invite.iInviteCreatePayload>
  isCreatingNewUser: boolean
  setDraftSelection: (draftSelection: UserSelection) => void
  setIsCreatingNewUser: (status: boolean) => void
}

const DefaultContext: AddUsersModalContextProps = {
  modalProps: {
    enableCreateUser: false,
    hasUpdatePermissions: false,
    hasAddUserPermissions: false,
    currentSelectedUsers: { users: [], invites: [] },
    eventTypes: {},
    saveUsersStatus: 'idle',
    leaveStatus: 'idle',
    inviteAdditionalInfo: '',
  },
  workspace: null,
  draftSelection: { users: [], invites: [] },
  isCreatingNewUser: false,
  newInvitedUsers: [],
  setDraftSelection: () => undefined,
  setIsCreatingNewUser: () => undefined,
}

const AddUsersModalContext = createContext<AddUsersModalContextProps>(DefaultContext)

export default AddUsersModalContext
