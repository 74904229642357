import { GlobalStyles, Icon } from '@andromeda'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { listAgreements } from '@redux/reducers/agreementsReducer'
import { completeJob, selectJobs, setViewedJob } from '@redux/reducers/jobsReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { useQueryClient } from '@tanstack/react-query'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { JobPermission } from '@utils/enum/permissions'
import { JOB_STATUS } from '@utils/enum/status/job'
import { hasPermission } from '@utils/functions/permission'
import { useMenu } from '@utils/hooks/useMenu'
import { iJobCompleteModalProps } from '@utils/types/jobs'
import { useContext, useMemo } from 'react'
import { toast } from 'react-toastify'

export const ActionMenu = ({ disabled = false }: { disabled?: boolean }) => {
  const queryClient = useQueryClient()
  const { viewedJob } = useAppSelector(selectJobs)
  const { workspace } = useAppSelector(selectWorkspace)
  const dispatch = useAppDispatch()

  const { closeModal, options, toggleModal } = useContext(ModalContext)

  const { anchorEl, handleClick, handleClose, open } = useMenu()

  const isJobCompleted = useMemo(() => viewedJob.status === JOB_STATUS.COMPLETED, [viewedJob.status])

  const canCompleteJob = useMemo(() => {
    return hasPermission({
      permissions: viewedJob.permissions,
      allowedPermissions: [JobPermission.COMPLETE],
    })
  }, [viewedJob.permissions])

  const canArchiveJob = useMemo(() => {
    return hasPermission({
      permissions: viewedJob.permissions,
      allowedPermissions: [JobPermission.UPDATE],
    })
  }, [viewedJob.permissions])

  const handleCompleteJob = () => {
    toast
      .promise(dispatch(completeJob(viewedJob.eid)), {
        pending: 'Completing job...',
      })
      .then(async (response) => {
        toast.dismiss()
        if (response.meta.requestStatus === 'fulfilled') {
          toast.success('Job has been completed.')
          dispatch(setViewedJob(response.payload))
          queryClient.setQueryData(['jobDetails', workspace, viewedJob.eid], response.payload)
          // get updated job agreements
          dispatch(listAgreements({ jobEid: viewedJob.eid }))
          closeModal(ModalPages.JOB_COMPLETE)
        } else {
          toast.error(response.payload.message || 'Error completing job. Please try again.')
        }
      })
  }

  return (
    <>
      <IconButton
        size="small"
        sx={{ minWidth: { xs: 16 }, color: GlobalStyles.SLATE_800, padding: 0 }}
        onClick={handleClick}
        aria-label="job actions">
        <Icon.MoreVertical color={disabled ? GlobalStyles.SLATE_200 : GlobalStyles.PRIMARY_500} size={20} />
      </IconButton>
      <Menu
        id="job-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem
          onClick={(e) => {
            const jobCompleteModalOptions: iJobCompleteModalProps = {
              onConfirm: handleCompleteJob,
            }
            toggleModal(ModalPages.JOB_COMPLETE, {
              modalProps: {
                ...options,
                [ModalPages.JOB_COMPLETE]: jobCompleteModalOptions,
              },
            })
            handleClose(e)
          }}
          disabled={viewedJob.status === JOB_STATUS.DRAFT || isJobCompleted || !canCompleteJob}>
          Complete
        </MenuItem>
        <MenuItem
          disabled={!canArchiveJob}
          onClick={(e) => {
            toggleModal(ModalPages.ARCHIVE_JOB)
            handleClose(e)
          }}>
          Archive
        </MenuItem>
      </Menu>
    </>
  )
}
