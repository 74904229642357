import { Types } from '@orbit'
import { StyleSheet, StyleProp } from 'react-native'
import { GlobalStyles } from '../globalStyles'

const JobsDefaultStyles = {
  flex: 1,
  borderLeftWidth: 3,
  paddingVertical: GlobalStyles.PADDING,
  paddingHorizontal: GlobalStyles.PADDING_HORIZONTAL,
  borderTopLeftRadius: 3,
  borderBottomLeftRadius: 3,
} as StyleProp<any>

export const CardStyles = StyleSheet.create({
  cardContainer: {
    backgroundColor: GlobalStyles.MONO_WHITE,
    borderRadius: GlobalStyles.BORDER_RADIUS,
    borderWidth: GlobalStyles.BORDER_WIDTH,
    paddingVertical: GlobalStyles.PADDING,
    paddingHorizontal: GlobalStyles.PADDING_HORIZONTAL,
    borderColor: GlobalStyles.SLATE_100,
  },
})

export const StatusColors: { [key in Types.Job.StatusTypes]: string } = {
  DEFAULT: GlobalStyles.PRIMARY_500,
  DRAFT: GlobalStyles.STATUS_YIELD,
  LIVE: GlobalStyles.STATUS_POSITIVE,
  COMPLETED: GlobalStyles.STATUS_INFO,
  CANCELLED: GlobalStyles.STATUS_NEGATIVE,
  BOOKED: GlobalStyles.STATUS_POSITIVE,
  OVERDUE: GlobalStyles.STATUS_NEGATIVE,
  NEW: GlobalStyles.STATUS_NEGATIVE,
  APPLIED: GlobalStyles.STATUS_POSITIVE,
  DECLINED: GlobalStyles.STATUS_NEGATIVE,
  REMOVED: GlobalStyles.STATUS_NEGATIVE,
  ARCHIVED: GlobalStyles.STATUS_INFO,
  WITHDRAWN: GlobalStyles.STATUS_YIELD,
}

export const CardUserStyles = StyleSheet.create({
  container: {
    borderColor: 'transparent',
    borderRadius: GlobalStyles.BORDER_RADIUS,
    minHeight: 50,
    border: `1px solid ${GlobalStyles.SLATE_100}`,
    boxShadow: 'none',
  },
  responsiveContainer: {
    paddingVertical: 12,
    paddingHorizontal: 12,
  },
  avatarStyle: {
    borderColor: GlobalStyles.MONO_WHITE,
    borderWidth: 2,
    marginRight: 12,
  },
  secondaryText: {
    color: GlobalStyles.SLATE_700,
    fontSize: GlobalStyles.FONT_SIZES.SMALL,
  },
  buttonContainer: {
    paddingLeft: 15,
  },
  contactButtonContainer: {
    paddingLeft: 15,
    paddingRight: 12,
  },
  actionBtnStackContainer: {
    marginLeft: 'auto',
    minHeight: 40,
  },
  responsiveStack: {
    marginBottom: 10,
  },
  button: {
    borderRadius: GlobalStyles.BUTTON_BORDER_RADIUS,
    width: GlobalStyles.BUTTON_SIZES.MEDIUM,
    height: GlobalStyles.BUTTON_SIZES.MEDIUM,
    borderColor: GlobalStyles.SLATE_100,
    borderWidth: 1,
  },
  activeBtn: {
    backgroundColor: GlobalStyles.PRIMARY_500,
  },
  dropdownBtn: {
    paddingHorizontal: 8,
    paddingVertical: 8,
    height: 40,
    minWidth: 100,
    borderRadius: GlobalStyles.BUTTON_BORDER_RADIUS,
    borderColor: GlobalStyles.SLATE_100,
    backgroundColor: GlobalStyles.MONO_WHITE,
  },
  dropdownLabel: {
    color: GlobalStyles.PRIMARY_500,
    fontSize: GlobalStyles.FONT_SIZES.SMALL,
    fontWeight: '500',
  },
  dropdownWrapper: {
    paddingHorizontal: GlobalStyles.PADDING,
  },
})

export const CardJobStyles = StyleSheet.create({
  wrapper: {
    flex: 1,
    paddingVertical: 0,
    // paddingHorizontal: 0,
  },
  btnIconStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  collapsibleHeaderText: {
    color: GlobalStyles.SLATE_500,
    fontWeight: '600',
    fontSize: 12,
  },
  collapsibleTypography: {
    color: GlobalStyles.MONO_BLACK,
    fontWeight: '500',
    fontSize: GlobalStyles.FONT_SIZES.SMALL,
  },
  headingContainer: {
    flexDirection: 'column',
  },
  heading: {
    flexDirection: 'row',
  },
  headingText: {
    fontSize: 20,
  },
  headingLabelWrapper: {
    marginLeft: 'auto',
  },
  headingLabel: {
    backgroundColor: GlobalStyles.STATUS_YIELD,
  },
  subHeading: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  subHeadingResponsive: {
    alignItems: 'flex-start',
  },
  labelWrapper: {
    marginRight: 'auto',
    flexDirection: 'row',
  },
  labelContainer: {
    marginRight: 6,
  },
  label: {
    borderRadius: 4,
    paddingTop: 6,
    paddingBottom: 6,
    paddingHorizontal: 10,
    marginVertical: 4,
  },
  textWrapper: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  textWrapperRepsonsive: {
    marginVertical: 10,
    flexDirection: 'row',
    marginRight: 'auto',
  },
  textLabel: {
    fontWeight: '900',
    marginRight: 6,
  },
  textStyle: {
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  content: {
    marginTop: 36,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  contentResponsive: {
    marginTop: 10,
  },
  detailsContentWrapper: {
    flexDirection: 'column',
    marginRight: 'auto',
  },
  detailsContentContainer: {
    flexDirection: 'row',
  },
  detailsContentChild: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: 10,
    paddingRight: 24,
  },
  pricingDetails: {
    flexDirection: 'column',
  },
  pricingDetailsResponsive: {
    marginTop: 16,
  },
  pricingDetailsText: {
    marginVertical: 6,
    paddingVertical: 4,
    textAlign: 'right',
  },
  actionPanel: {
    marginLeft: 'auto',
    flex: 0.1,
    paddingVertical: 16,
    paddingHorizontal: 20,
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: 'rgb(238, 238, 238)',
    justifyContent: 'space-between',
  },
  actionPanelResponsive: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 'unset',
    borderBottomLeftRadius: 3,
    borderTopRightRadius: 0,
  },
  jobCardResponsive: {
    borderBottomLeftRadius: 0,
  },
  divider: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: 'rgb(170, 170, 170)',
    marginVertical: 20,
  },
  footer: {
    marginTop: 6,
    marginBottom: 16,
  },
  actionBtn: {
    backgroundColor: 'rgb(255, 255, 255)',
    marginVertical: 6,
  },
  active: {
    ...JobsDefaultStyles,
    borderLeftColor: 'rgba(26, 82, 223, 0.9)',
  },
  pending: {
    ...JobsDefaultStyles,
  },
  cancelled: {
    ...JobsDefaultStyles,
  },
})
