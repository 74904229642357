class EasingValues {
  private constructor(private readonly key: string, public readonly value: number[]) {}

  static readonly DEFAULT = new EasingValues('DEFAULT', [0.175, 0.85, 0.42, 0.96]).value
  static readonly SPRING = new EasingValues('SPRING', [0.175, 0.85, 0.2, 1.15]).value
}

export enum TransitionDefaults {
  TYPE = 'fade',
  INITIAL = 'exit',
}

export type TransitionTypes = 'fade' | 'slide' | 'slide-left' | 'slide-right' | 'slide-down' | 'slide-up' | 'grow' | 'shrink' | 'flip180'

const getTransitionVariant = (type: TransitionTypes, zIndex: string) => {
  switch (type) {
    case 'fade':
      return {
        exit: {
          opacity: 0,
          zIndex: `-${zIndex}`,
          transition: { duration: 0.5, ease: EasingValues.DEFAULT },
        },
        enter: {
          x: 0,
          y: 0,
          opacity: 1,
          zIndex: zIndex,
          transition: {
            duration: 0.5,
            ease: EasingValues.DEFAULT,
          },
        },
      }
    case 'slide':
      return {
        exit: {
          x: 500,
          y: 0,
          opacity: 0,
          transition: { duration: 0.5, ease: EasingValues.DEFAULT },
        },
        enter: {
          x: 0,
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: EasingValues.DEFAULT,
          },
        },
      }
    case 'slide-left':
      return {
        exit: {
          x: 0,
          y: 0,
          opacity: 1,
          transition: { duration: 0.5, ease: EasingValues.DEFAULT },
        },
        enter: {
          x: 500,
          y: 0,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: EasingValues.DEFAULT,
          },
        },
      }
    case 'slide-right':
      return {
        exit: {
          x: 500,
          y: 0,
          opacity: 0,
          transition: { duration: 0.5, ease: EasingValues.DEFAULT },
        },
        enter: {
          x: 0,
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: EasingValues.DEFAULT,
          },
        },
      }
    case 'slide-down':
      return {
        exit: {
          x: 0,
          y: -100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: EasingValues.DEFAULT,
          },
        },
        enter: {
          x: 0,
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: EasingValues.DEFAULT,
          },
        },
      }
    case 'slide-up':
      return {
        exit: {
          x: 0,
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: EasingValues.DEFAULT,
          },
        },
        enter: {
          x: 0,
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: EasingValues.DEFAULT,
          },
        },
      }
    case 'grow':
      return {
        exit: {
          width: '56px',
          transition: { duration: 0.5, ease: EasingValues.SPRING },
        },
        enter: {
          width: '15rem',
          transition: {
            duration: 0.5,
            ease: EasingValues.SPRING,
          },
        },
      }
    case 'shrink':
      return {
        exit: {
          width: '15rem',
          transition: { duration: 0.5, ease: EasingValues.SPRING },
        },
        enter: {
          width: '56px',
          transition: {
            duration: 0.5,
            ease: EasingValues.SPRING,
          },
        },
      }
    case 'flip180':
      return {
        exit: {
          transform: 'rotate(180deg)',
          transition: { duration: 0.5, ease: EasingValues.DEFAULT },
        },
        enter: {
          transform: 'rotate(0deg)',
          transition: {
            duration: 0.5,
            ease: EasingValues.DEFAULT,
          },
        },
      }
  }
}

export default getTransitionVariant
