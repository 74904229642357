import { GlobalStyles } from '@andromeda'
import { Stack, StackProps, SxProps, Typography } from '@mui/material'

const borderStyle = `1px solid ${GlobalStyles.SLATE_100}`
const inputFieldStyle: SxProps = {
  fontSize: '14px',
}
export const FieldRow = ({
  label,
  formComponent,
  noBorders = false,
  labelAlignment = 'flex-start',
  ...props
}: { label: string; formComponent: JSX.Element; noBorders?: boolean; labelAlignment?: StackProps['justifyContent'] } & StackProps) => (
  <Stack direction="row" borderTop={noBorders ? 'none' : borderStyle} {...props}>
    <Stack
      width={{ xxs: '100px', sm: '150px' }}
      padding="24px 16px 24px 32px"
      boxSizing="border-box"
      justifyContent={labelAlignment}
      borderRight={borderStyle}>
      <Typography fontSize="14px" fontWeight={GlobalStyles.FONT_WEIGHT_MEDIUM} color={GlobalStyles.MONO_BLACK}>
        {label}
      </Typography>
    </Stack>
    <Stack flex={1} padding="24px 32px" width={{ sm: '400px' }} boxSizing="border-box">
      {formComponent}
    </Stack>
  </Stack>
)
