import { Button, GlobalStyles, Icon, Stack, Text } from '@andromeda'
import { ModalFooter, ModalHeader } from '@components'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { createDeliverableAgreement, markAsAssigned, selectAgreement } from '@redux/reducers/agreementsReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { JobAgreementAssignTalentModalProps } from '@utils/types/job_agreements'
import { useContext } from 'react'
import { toast } from 'react-toastify'

export const AssignTalent = (props: JobAgreementAssignTalentModalProps): JSX.Element => {
  const { toggleModal, options } = useContext(ModalContext)
  // const props = options?.modalProps?.assignTalent

  const { markStatus, createDeliverableStatus } = useAppSelector(selectAgreement)
  const dispatch = useAppDispatch()

  const _handleSubmit = () => {
    if (props.isCreate && props.deliverableEid) {
      toast
        .promise(
          dispatch(
            createDeliverableAgreement({
              payload: {
                job_agreement_eid: props.jobAgreementEid,
                deliverable_eid: props.deliverableEid,
              },
            })
          ),
          {}
        )
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            toast.success('Talent has been assigned.')
            props.onConfirm && props.onConfirm(response.payload)
          } else {
            toast.error('Changes were not saved. Please try again.')
          }
        })
    } else {
      toast
        .promise(
          dispatch(
            markAsAssigned({
              jobAgreementEid: props.jobAgreementEid,
              payload: {
                deliverable_agreement_eids: props.agreementEids,
              },
            })
          ),
          {}
        )
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            toast.success('Talent has been assigned.')
            props.onConfirm && props.onConfirm(response.payload)
          } else {
            toast.error('Changes were not saved. Please try again.')
          }
        })
    }
  }

  const _renderTitle = () => {
    if (props.allDeliverableAgreements) {
      return `Assign ${props.deliverablesLength} deliverables to ${props.talentName}`
    }

    return `Assign ${props?.talentName} for ${props?.agreementEids?.length} of ${props?.deliverablesLength} Deliverables?`
  }

  return (
    <Stack rounded space="none" height="auto" direction="column" style={{ backgroundColor: GlobalStyles.MONO_WHITE }}>
      <ModalHeader
        modalContext={ModalPages.ASSIGN_TALENT}
        modalTitle={_renderTitle()}
        textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
        style={{
          paddingBottom: 0,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.md,
        }}
      />

      <Stack
        direction="column"
        style={{
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingVertical: GlobalStyles.PADDING_SIZES.md,
        }}>
        {props.allDeliverableAgreements ? (
          <Stack direction="column" align="flex-start" space="xs">
            <Stack direction="row" space="2xs" align="flex-start">
              <Text style={{ lineHeight: 15 }}>
                <Icon.Check color={GlobalStyles.SLATE_300} />
              </Text>
              <Text size="sm">{'Any deliverables marked done will remian unaffected.'}</Text>
            </Stack>
            <Stack direction="row" space="2xs" align="flex-start">
              <Text style={{ lineHeight: 15 }}>
                <Icon.Check color={GlobalStyles.SLATE_300} />
              </Text>
              <Text size="sm">{'All deliverables that are booked will move to assign.'}</Text>
            </Stack>
          </Stack>
        ) : (
          <Text>Are you sure you want to assign this talent?</Text>
        )}
      </Stack>

      <ModalFooter
        backgroundColor={GlobalStyles.MONO_WHITE}
        wrapperStyle={{
          shadowOpacity: 0,
          justifyContent: 'flex-end',
          paddingBottom: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.sm,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
        }}
        buttonStyles={{
          paddingVertical: 12,
          paddingHorizontal: 18,
          borderRadius: 8,
        }}
        leftComponent={
          <Button
            type="secondary"
            style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
            onPress={() => toggleModal('assignTalent')}
            textStyle={{ color: GlobalStyles.PRIMARY_500 }}
            text="Cancel"
          />
        }
        onButtonPress={_handleSubmit}
        loading={markStatus === 'loading' || createDeliverableStatus === 'loading'}
        buttonText={'Assign Talent'}
        disabled={false}
      />
    </Stack>
  )
}
