import { GlobalStyles, FilePicker } from '@andromeda'
import { ModalHeader } from '@components'
import { Button, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { uploadAgreementAttachment } from '@redux/reducers/agreementsReducer'
import { uploadDeliverableAttachment } from '@redux/reducers/deliverableReducer'
import { selectJobs, uploadJobAttachment } from '@redux/reducers/jobsReducer'
import { uploadTalentAttachment } from '@redux/reducers/talentProfileReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { PayloadAction } from '@reduxjs/toolkit'
import { useQueryClient } from '@tanstack/react-query'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

export const UploadFile = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { workspace } = useAppSelector(selectWorkspace)
  const { attachJobFileStatus, viewedJob } = useAppSelector(selectJobs)

  const { options, closeModal } = useContext(ModalContext)

  const [files, setFiles] = useState<File[] | undefined>()

  const isLoading = useMemo(() => attachJobFileStatus === 'loading', [attachJobFileStatus])

  const _handleChange = async (e: any) => {
    const files = [...e.files]
    if (files && files.length > 0) {
      let filesExceedLimit: File[] = []
      setFiles(files)
    }
  }

  const generateURL = () => {
    let action: Promise<PayloadAction<any, string, { requestStatus: string }>>

    if (files) {
      if (options?.modalProps?.uploadFiles?.jobEid) {
        action = dispatch(uploadJobAttachment({ eid: options?.modalProps?.uploadFiles?.jobEid, files }))
      } else if (options?.modalProps?.uploadFiles?.talentEid) {
        action = dispatch(uploadTalentAttachment({ eid: options?.modalProps?.uploadFiles?.talentEid, files }))
      } else if (options?.modalProps?.uploadFiles?.agreementEid && options?.modalProps?.uploadFiles?.deliverableEid) {
        action = dispatch(
          uploadAgreementAttachment({
            eid: options?.modalProps?.uploadFiles?.agreementEid,
            files,
            deliverableEid: options?.modalProps?.uploadFiles?.deliverableEid,
            expandedJobEid: options?.modalProps?.uploadFiles?.expandedJobEid,
          })
        )
      } else if (options?.modalProps?.uploadFiles?.deliverableEid) {
        action = dispatch(uploadDeliverableAttachment({ eid: options?.modalProps?.uploadFiles?.deliverableEid, files }))
      }
    }
    toast.promise(
      // @ts-expect-error
      action.then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          const uploadedFiles = options?.modalProps?.uploadFiles

          if (uploadedFiles?.jobEid) {
            queryClient.invalidateQueries({ queryKey: ['jobDetails', workspace, uploadedFiles.jobEid], type: 'active' })
          } else if (viewedJob?.eid && (uploadedFiles?.agreementEid || uploadedFiles?.deliverableEid)) {
            queryClient.invalidateQueries({ queryKey: ['deliverable'], type: 'active' })
            queryClient.invalidateQueries({ queryKey: ['deliverables', viewedJob?.eid], type: 'active' })
          }

          toast.success('Attachments uploaded successfully')
          closeModal(ModalPages.UPLOAD_FILES)
        }
      }),
      { pending: 'Uploading attachments...' }
    )
  }

  return (
    <Stack className="dialog-container" style={{ backgroundColor: GlobalStyles.MONO_WHITE }} width={{ xxs: '100%', sm: '500px' }}>
      <ModalHeader modalTitle="Upload file" modalContext={ModalPages.UPLOAD_FILES} />
      <Stack className="dialog-content" rowGap="8px">
        <Stack>{!!files?.length && files.map(({ name }) => <Typography key={name}>{name}</Typography>)}</Stack>
        <FilePicker options={{ multiple: true }} onChange={_handleChange} leftElement={<></>} text="Choose File - Browse" />
      </Stack>
      <Stack direction="row" justifyContent="end" columnGap="8px" className="dialog-footer">
        <Button variant="outlined" disabled={isLoading} onClick={() => closeModal(ModalPages.UPLOAD_FILES)}>
          Cancel
        </Button>
        <Button variant="contained" onClick={generateURL} disabled={isLoading}>
          Upload
        </Button>
      </Stack>
    </Stack>
  )
}
