import { Button, GlobalStyles, Icon, Label, ScrollView, Stack, Text } from '@andromeda'
import { Section } from '@components'
import { useAppSelector } from '@redux/hooks'
import { selectJobs } from '@redux/reducers/jobsReducer'
import { AppStyles } from '@styles'
import { ModalContext, ModalOptions } from '@utils/context/Modal.context'
import { useScreenDimensions } from '@utils/hooks/dimensions'
import moment from 'moment'
import { useContext, useRef } from 'react'
import { FlyoutModalHeader } from 'src/components/modal'

const STARS = [
  {
    avatar: 'https://userstock.io/data/wp-content/uploads/2020/06/aiony-haust-3TLl_97HNJo-300x300.jpg',
    name: 'Star Name',
    occupation: 'Sportsperson',
    followers: '1k',
  },
  {
    avatar: 'https://userstock.io/data/wp-content/uploads/2020/06/aiony-haust-3TLl_97HNJo-300x300.jpg',
    name: 'Star Name',
    occupation: 'Sportsperson',
    followers: '1k',
  },
  {
    avatar: 'https://userstock.io/data/wp-content/uploads/2020/06/aiony-haust-3TLl_97HNJo-300x300.jpg',
    name: 'Star Name',
    occupation: 'Sportsperson',
    followers: '1k',
  },
  {
    avatar: 'https://userstock.io/data/wp-content/uploads/2020/06/aiony-haust-3TLl_97HNJo-300x300.jpg',
    name: 'Star Name',
    occupation: 'Sportsperson',
    followers: '1k',
  },
  {
    avatar: 'https://userstock.io/data/wp-content/uploads/2020/06/aiony-haust-3TLl_97HNJo-300x300.jpg',
    name: 'Star Name',
    occupation: 'Sportsperson',
    followers: '1k',
  },
  {
    avatar: 'https://userstock.io/data/wp-content/uploads/2020/06/aiony-haust-3TLl_97HNJo-300x300.jpg',
    name: 'Star Name',
    occupation: 'Sportsperson',
    followers: '1k',
  },
]

const DELIVERABLES = [
  {
    title: 'Socialise with Guests',
    date: '18/01/22 > 05/02/22',
    location: 'Sydney, NSW',
    appearance: 'In-Person',
    organisation: 'Pickstar',
    createdAt: '08/02/2022',
    labels: ['Tag 1', 'Tag 2'],
  },
  {
    title: 'Socialise with Guests',
    date: '18/01/22 > 05/02/22',
    location: 'Sydney, NSW',
    appearance: 'In-Person',
    organisation: 'Pickstar',
    createdAt: '08/02/2022',
    labels: ['Tag 1', 'Tag 2'],
  },
]

const PROPOSAL = {
  dateGuide: {
    startDate: '01/07/22',
    endDate: '01/12/22',
    isFixed: true,
  },
  budgetGuide: {
    budget: '$100,000',
    isFixed: true,
  },
  shortlisted: 6,
  deliverables: 6,
  description:
    "Tally-ho sausage roll any road, best be off alright geezer any road, best be off I could reet fancy a bowler hat a diamond geezer, cockney oo ecky thump had a barney with the inlaws spend a penny it's me peepers nosh a bit wonky supper.",
}

const JobProposal = () => {
  const { screenSize } = useScreenDimensions()

  const ref = useRef()
  const { toggleModal } = useContext(ModalContext)
  const modalOptions: ModalOptions = {
    hideOthers: false,
  }

  const { viewedJob } = useAppSelector(selectJobs)

  const headerButtons = [
    <Button
      key="send-proposal"
      type="primary"
      space="2xs"
      style={{ paddingHorizontal: 20 }}
      onPress={() => toggleModal('sendProposal', modalOptions)}>
      <Text style={{ color: GlobalStyles.MONO_WHITE }}>Send</Text>
      <Text>
        <Icon.Send color={GlobalStyles.MONO_WHITE} size={AppStyles.BUTTON_SIZE_SMALL} />
      </Text>
    </Button>,
  ]

  const dateFormat = 'D/MM/YYYY'
  const _getDeliverableDate = (start: string | null, end: string | null) => {
    if (start) {
      const startDate = moment(new Date(start)).format(dateFormat)
      const endDate = end ? moment(new Date(end)).format(dateFormat) : null
      return `${startDate} ${endDate ? ' > ' + endDate : ''}`
    }
    return ''
  }

  return (
    <Stack ref={ref} direction="column" space="none" style={{ backgroundColor: GlobalStyles.SLATE_50 }}>
      <FlyoutModalHeader key={'job-proposal-flyout'} returnText="View Job" contextModal={'jobProposal'} headerButtons={headerButtons} />
      <ScrollView
        style={{
          width: '100%',
          height: '100%',
          paddingBottom: 20,
        }}
        contentContainerStyle={{ minHeight: '100%' }}>
        <Section
          title="Proposal"
          icon={
            <Text style={{ color: GlobalStyles.MONO_BLACK }}>
              <Icon.FileText />
            </Text>
          }>
          <Stack direction="column" align="flex-start" space="sm">
            <Text type="paragraph" style={{ fontSize: AppStyles.FONT_SIZES.SET_TITLE, color: GlobalStyles.MONO_BLACK }}>
              {'Adidas Predator Ambassador'}
            </Text>
            <Stack
              direction={!screenSize.md ? 'column' : 'row'}
              border
              rounded
              space="none"
              align="stretch"
              style={{ borderColor: GlobalStyles.SLATE_100, marginTop: 20, alignContent: 'center', justifyContent: 'center' }}
              childrenStyle={{ borderColor: GlobalStyles.SLATE_100 }}>
              <Stack
                flex={1}
                direction="column"
                align="stretch"
                style={{
                  backgroundColor: GlobalStyles.MONO_WHITE,
                  paddingTop: 15,
                  paddingBottom: 24,
                  paddingHorizontal: 32,
                }}>
                <Stack flex={1} space="none">
                  <Text type="paragraph" size="base">
                    Job Date Guide
                  </Text>
                  {/* <Tooltip />  TODO - add tooltiop body*/}
                </Stack>
                <Stack flex={1}>
                  <Text type="paragraph" size="md">
                    {`${PROPOSAL.dateGuide.startDate} - ${PROPOSAL.dateGuide.endDate}`}
                  </Text>
                  <Label
                    type={'OUTLINED'}
                    style={{ paddingVertical: 0 }}
                    text={PROPOSAL.dateGuide.isFixed ? 'Fixed' : 'Flexible'}
                    textStyle={{ fontSize: GlobalStyles.FONT_SIZES.TINY, color: GlobalStyles.PRIMARY_600 }}
                  />
                </Stack>
              </Stack>
              <Stack
                flex={1}
                direction="column"
                align="stretch"
                style={{
                  backgroundColor: GlobalStyles.MONO_WHITE,
                  paddingTop: 15,
                  paddingBottom: 24,
                  paddingHorizontal: 32,
                }}>
                <Stack flex={1} space="none">
                  <Text type="paragraph" size="base">
                    Budget Guide
                  </Text>
                  {/* <Tooltip /> TODO - add tooltiop body*/}
                </Stack>
                <Stack flex={1}>
                  <Text type="paragraph" size="md">
                    {PROPOSAL.budgetGuide.budget}
                  </Text>
                  <Label
                    type={'OUTLINED'}
                    style={{ paddingVertical: 0 }}
                    text={PROPOSAL.budgetGuide.isFixed ? 'Fixed' : 'Flexible'}
                    textStyle={{ fontSize: GlobalStyles.FONT_SIZES.TINY, color: GlobalStyles.PRIMARY_600 }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              border
              rounded
              space="none"
              style={{
                borderColor: GlobalStyles.SLATE_100,
                marginTop: 10,
                marginBottom: 40,
              }}>
              <Stack
                flex={1}
                direction="column"
                align="stretch"
                style={{
                  backgroundColor: GlobalStyles.MONO_WHITE,
                  paddingVertical: 24,
                  paddingHorizontal: 32,
                  borderTopRightRadius: GlobalStyles.BORDER_RADIUS,
                  borderBottomRightRadius: GlobalStyles.BORDER_RADIUS,
                }}>
                <Text>{PROPOSAL.description}</Text>
              </Stack>
            </Stack>
          </Stack>
        </Section>
        <Section
          title={`Shortlist (${PROPOSAL.shortlisted})`}
          icon={
            <Text style={{ color: GlobalStyles.MONO_BLACK }}>
              <Icon.Bookmark />
            </Text>
          }>
          {/* <Stack direction="row" align="flex-start" flex={1} style={{ flexWrap: 'wrap', marginBottom: 30 }} space="none">
                        {STARS.map((star, index) => {
                            return (
                                <StarTile
                                    key={index}
                                    avatar={star.avatar}
                                    name={star.name}
                                    occupation={star.occupation}
                                    followers={star.followers}
                                />
                            )
                        })}
                    </Stack> */}
        </Section>

        <Section
          title={`Deliverables (${PROPOSAL.deliverables})`}
          icon={
            <Text style={{ color: GlobalStyles.MONO_BLACK }}>
              <Icon.Copy />
            </Text>
          }>
          {/* <Stack direction="column" align="flex-start" flex={1} style={{ flexWrap: 'wrap', marginBottom: 30 }} space="sm">
                        {viewedJob?.deliverables &&
                            viewedJob?.deliverables.map((deliverable: Types.Deliverable.iDeliverable, index: number) => {
                                return (
                                    <DeliverableTile
                                        key={index}
                                        eid={deliverable.eid}
                                        title={deliverable.name}
                                        date={_getDeliverableDate(deliverable.start_datetime, deliverable.end_datetime)}
                                        location={deliverable.location || null}
                                        createdAt={deliverable.created_at}
                                        label={deliverable.deliverable_type.name}
                                        status={(deliverable?.status as LabelTypes) || null}
                                    />
                                )
                            })}
                    </Stack> */}
        </Section>
      </ScrollView>
    </Stack>
  )
}

export { JobProposal }
