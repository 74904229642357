import { StyleSheet } from 'react-native'
import { GlobalStyles } from '../globalStyles'

const defaultStyles = {
  borderRadius: GlobalStyles.MAX_BORDER_RADIUS,
  backgroundColor: GlobalStyles.SLATE_50,
  padding: 4,
}

export const BarStyles = StyleSheet.create({
  wrapper: {
    ...defaultStyles,
  },
  small: {
    padding: 4,
  },
  large: {
    padding: 8,
  },
  innerLoader: {
    borderRadius: GlobalStyles.MAX_BORDER_RADIUS,
  },
})
