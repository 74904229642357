import { DropdownOption } from '@andromeda'
import { Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { getTypes, selectDeliverable } from '@redux/reducers/deliverableReducer'
import { useEffect, useMemo, useState } from 'react'

export const useDeliverableTypes = ({ selectedTypes = [] }: { selectedTypes?: Types.Deliverable.iDeliverableType[] }) => {
  const dispatch = useAppDispatch()
  const { types } = useAppSelector(selectDeliverable)

  const [deliverableTypes, setDeliverableTypeOptions] = useState<DropdownOption[]>([])

  const categoryOptions = useMemo(() => (types ? types.map(({ eid, name }) => ({ eid, value: name })) : []), [types])
  const typeOptions = useMemo(() => {
    if (selectedTypes.length !== 1) return []

    const children = types ? types.find(({ eid }) => eid === selectedTypes[0].eid)?.children : []

    return children ? children.map(({ eid, name }) => ({ eid, value: name })) : []
  }, [selectedTypes, types])

  useEffect(() => {
    dispatch(getTypes())
  }, [dispatch])

  useEffect(() => {
    if (types && types.length) {
      setDeliverableTypeOptions(
        types
          .map((type) => ({
            eid: type.eid,
            value: type.name,
          }))
          .filter((item) => !selectedTypes.find((selected) => selected.eid === item.eid))
      )
    }
  }, [types, selectedTypes])

  return {
    categoryOptions,
    typeOptions,
    deliverableTypes,
    selectedTypes,
  }
}
