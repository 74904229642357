import { useState, useRef, useEffect } from 'react'
import {
  TouchableOpacity as NativeTouchableOpacity,
  Modal as NativeModal,
  Pressable as NativePressable,
  TextInput as NativeTextInput,
} from 'react-native'
import { View } from '../View'
import { Input } from '../Input'
import { Text } from '../Text'
import { PickrStyle, TimePickrStyle } from './style'
import { TimePickerDefaults, TimePickerProps, ModalPositionProps } from './types'
import { withModal } from './ModalHOC'
import moment from 'moment'

const TimePicker = ({
  placeholder = TimePickerDefaults.PLACEHOLDER,
  element,
  menuPosition,
  visible,
  didExit,
  didEnter,
  _onLayout,
  ...props
}: TimePickerProps & ModalPositionProps) => {
  /* Time related states */
  const [hourInput, setHourInput] = useState<string>('')
  const [minuteInput, setMinuteInput] = useState<string>('00')
  const [displayTime, setDisplayTime] = useState<string>('')
  const [isMorning, setIsMorning] = useState<boolean>(true)
  const inputMinuteRef = useRef<NativeTextInput>(null)
  const elemRef = useRef<NativeTouchableOpacity>(null)

  /**
   * UI related states
   */
  const [isHourFocused, setHourFocused] = useState<boolean>(false)
  const [isMinuteFocused, setMinuteFocused] = useState<boolean>(false)

  const _validateInput = (input: string, isHour = false) => {
    const isNotNum = isNaN(parseInt(input))
    if (isNotNum || input === '') return false

    const parsedInput = parseInt(input)
    const isBetweenHour = parsedInput <= 12 && parsedInput >= 1
    const isBetweenMinute = parsedInput <= 59 && parsedInput >= 0

    if (isHour) {
      return isBetweenHour
    }

    return isBetweenMinute
  }

  const _handleHourChange = (data: string) => {
    const isValidHour = _validateInput(data, true)

    if (!isValidHour) {
      const isEmpty = data === '' || hourInput === ''

      setHourInput(isEmpty ? '' : hourInput)
      return
    }

    setHourInput(`${parseInt(data)}`)
    if (parseInt(data) > 1) {
      if (inputMinuteRef && inputMinuteRef.current) inputMinuteRef.current.focus()
    }
  }

  const _handleMinuteChange = (data: string) => {
    const isValidMinute = _validateInput(data)

    if (!isValidMinute) {
      const isEmpty = data === '' || minuteInput === ''

      setMinuteInput(isEmpty ? '' : minuteInput)
      return
    }

    setMinuteInput(`${data}`)
  }

  const _handleKeyPrevent = (event: any) => {
    const key = event.nativeEvent.key
    if (event.nativeEvent === ' ') {
      event.preventDefault()
    } else if (key === 'Enter') {
      _handleModalDismiss()
    } else if (key === 'p' || key === 'P') {
      setIsMorning(false)
    } else if (key === 'a' || key === 'A') {
      setIsMorning(true)
    }
  }

  const _appendLeadingZero = (data: number) => {
    return data < 10 ? `0${data}` : `${data}`
  }

  const _handleModalDismiss = () => {
    if (hourInput !== '' && minuteInput !== '') {
      const timeSuffix = isMorning ? 'AM' : 'PM'
      const appendedHour = _appendLeadingZero(parseInt(hourInput))
      const appendedMinute = _appendLeadingZero(parseInt(minuteInput))
      const displayedTime = `${appendedHour}:${appendedMinute} ${timeSuffix}`

      setDisplayTime(displayedTime)

      const format24 = moment(displayedTime, 'HH:mm a').format('HH:mm:ss')
      if (props.onTimeChange) {
        props.onTimeChange({
          displayedTime,
          data: { hour: hourInput, minute: minuteInput, isMorning, format24 },
        })
      }
    }

    didExit && didExit()
  }

  useEffect(() => {
    if (props.initialTime) {
      const data = props.initialTime.split(/[: ]/)
      const initialHour = _appendLeadingZero(parseInt(data[0]))
      const initialMinute = data[1]
      const initialSuffix = data[2].toUpperCase() === 'AM'

      setHourInput(`${parseInt(initialHour)}`)
      setMinuteInput(initialMinute)
      setIsMorning(initialSuffix)

      setDisplayTime(`${initialHour}:${initialMinute} ${data[2]}`)
    }
  }, [])

  const _renderModalInput = () => {
    return (
      <NativeModal visible={visible} transparent animationType="fade" onRequestClose={_handleModalDismiss}>
        <NativePressable style={TimePickrStyle.pressableOverlay} onPress={_handleModalDismiss}>
          <NativePressable style={[TimePickrStyle.inputModal, menuPosition, props.inputModalContainer]}>
            <View style={TimePickrStyle.innerContainer} onLayout={_onLayout}>
              <View style={[isHourFocused && TimePickrStyle.textInputFocused, TimePickrStyle.inputOuterWrapper]}>
                <Input
                  autoFocus
                  selectTextOnFocus
                  onFocus={() => setHourFocused(true)}
                  onBlur={() => setHourFocused(false)}
                  onChangeText={_handleHourChange}
                  onKeyPress={_handleKeyPrevent}
                  placeholder="04"
                  maxLength={2}
                  wrapperStyle={[TimePickrStyle.textInputWrapper]}
                  style={[TimePickrStyle.textInputContainer, isHourFocused && { fontWeight: '500' }]}
                  value={hourInput}
                  keyboardType={'number-pad'}
                />
              </View>
              <Text>:</Text>
              <View style={[TimePickrStyle.inputOuterWrapper, isMinuteFocused && TimePickrStyle.textInputFocused]}>
                <Input
                  ref={inputMinuteRef}
                  selectTextOnFocus
                  placeholder="00"
                  onFocus={() => setMinuteFocused(true)}
                  onBlur={() => setMinuteFocused(false)}
                  onChangeText={_handleMinuteChange}
                  onKeyPress={_handleKeyPrevent}
                  maxLength={2}
                  wrapperStyle={[TimePickrStyle.textInputWrapper]}
                  style={[TimePickrStyle.textInputContainer, isMinuteFocused && { fontWeight: '500' }]}
                  value={minuteInput}
                  keyboardType={'number-pad'}
                />
              </View>
              <NativeTouchableOpacity style={{ flex: 0.2 }} onPress={() => setIsMorning(!isMorning)}>
                <Text style={{ textAlign: 'center' }}>{isMorning ? 'AM' : 'PM'}</Text>
              </NativeTouchableOpacity>
            </View>
          </NativePressable>
        </NativePressable>
      </NativeModal>
    )
  }

  return (
    <View style={[props.wrapperStyle]}>
      {props.leftElement ?? null}
      <NativeTouchableOpacity ref={elemRef} onPress={(e) => didEnter && didEnter(elemRef)} disabled={props.disabled}>
        <Input placeholder={placeholder} value={displayTime} wrapperStyle={{ padding: 0 }} {...props.textInputProps} />
      </NativeTouchableOpacity>
      {_renderModalInput()}

      {props.rightElement ?? null}
    </View>
  )
}

export default withModal(TimePicker) as (props: TimePickerProps) => JSX.Element
