import { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'
import { iResponsiveStyles } from '../types/common'

export enum responsiveStyleEnums {
  XXL = 'xxl',
  XL = 'xl',
  LG = 'lg',
  MD = 'md',
  SM = 'sm',
  XS = 'xs',
  XXS = 'xxs',
}

const getSize: () => keyof iResponsiveStyles = () => {
  if (Dimensions.get('window').width >= 1536) return responsiveStyleEnums.XXL
  if (Dimensions.get('window').width >= 1280) return responsiveStyleEnums.XL
  if (Dimensions.get('window').width >= 1024) return responsiveStyleEnums.LG
  if (Dimensions.get('window').width >= 768) return responsiveStyleEnums.MD
  if (Dimensions.get('window').width >= 640) return responsiveStyleEnums.SM
  if (Dimensions.get('window').width >= 400) return responsiveStyleEnums.XS
  return responsiveStyleEnums.XXS
}

const getWindowHeight = () => {
  return Dimensions.get('window').height
}

const getWindowWidth = () => {
  return Dimensions.get('window').width
}

export const useScreenDimensions = () => {
  const [size, setSize] = useState<responsiveStyleEnums>(getSize())
  const [windowHeight, setWindowHeight] = useState<number>(getWindowHeight())
  const [windowWidth, setWindowWidth] = useState<number>(getWindowWidth())

  useEffect(() => {
    const dimensionsListener = Dimensions.addEventListener('change', (result) => {
      setSize(getSize())
      setWindowHeight(getWindowHeight())
      setWindowWidth(getWindowWidth())
    })

    return () => {
      dimensionsListener.remove()
    }
  })

  return { size, windowHeight, windowWidth }
}
