import { Orbit, Types } from '@orbit'
import { AppState } from '@redux/store'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiResponseError } from '@utils/types/auth'
import { iNotificationInitialState } from '@utils/types/notification'

const initialState: iNotificationInitialState = {
  notifications: null,
  notificationMeta: null,
  unreadCount: null,
  listStatus: 'idle',
  unseenCount: null,
  userNotificationSettings: null,
  userNotificationSettingsLoads: false,
}

type tUserNotificationSettings = { [key: string]: Types.Notification.iUserNotificationSetting }

export const listUserNotifications = createAsyncThunk(
  'notifications/userList',
  async (params: Types.Notification.iNotificationsQueryParams, { rejectWithValue }): Promise<Types.Notification.iNotifications | any> => {
    try {
      const response = await Orbit.Services.notificationService.listUserNotifications(params)
      return response
    } catch (error) {
      return rejectWithValue((error as ApiResponseError).response.data)
    }
  }
)

export const getUserUnreadNotificationCount = createAsyncThunk(
  'notifications/userUnreadCount',
  async (_, { rejectWithValue }): Promise<Array<Types.Notification.iNotificationCount> | any> => {
    try {
      const response = await Orbit.Services.notificationService.getUserUnreadNotificationsCount()
      return response
    } catch (error) {
      return rejectWithValue((error as ApiResponseError).response.data)
    }
  }
)

export const markReadUserNotification = createAsyncThunk(
  'notifications/markRead',
  async (payload: { payload: Types.Notification.iNotificationMarkReadPayload }, { rejectWithValue }): Promise<Array<null> | any> => {
    try {
      const response = await Orbit.Services.notificationService.markReadUserNotification(payload)
      return response
    } catch (error) {
      return rejectWithValue((error as ApiResponseError).response.data)
    }
  }
)

export const getUserUnseenNotificationCount = createAsyncThunk(
  'notifications/userUnseenCount',
  async (_, { rejectWithValue }): Promise<Array<Types.Notification.iNotificationCount> | any> => {
    try {
      const response = await Orbit.Services.notificationService.getUserUnseenNotificationsCount()
      return response
    } catch (error) {
      return rejectWithValue((error as ApiResponseError).response.data)
    }
  }
)

export const markSeenAllUserNotification = createAsyncThunk(
  'notifications/markSeenAll',
  async (_, { rejectWithValue }): Promise<null | any> => {
    try {
      const response = await Orbit.Services.notificationService.markSeenAllUserNotification()
      return response
    } catch (error) {
      return rejectWithValue((error as ApiResponseError).response.data)
    }
  }
)

export const markReadAllUserNotification = createAsyncThunk(
  'notifications/markReadAll',
  async (_, { rejectWithValue }): Promise<null | any> => {
    try {
      const response = await Orbit.Services.notificationService.markReadAllUserNotification()
      return response
    } catch (error) {
      return rejectWithValue((error as ApiResponseError).response.data)
    }
  }
)

export const getUserNotificationSettings = createAsyncThunk(
  'notifications/getUserSettings',
  async (_, { rejectWithValue }): Promise<tUserNotificationSettings | any> => {
    try {
      const response = await Orbit.Services.notificationService.getUserNotificationSettings()
      return response?.data
    } catch (error) {
      return rejectWithValue((error as ApiResponseError).response)
    }
  }
)

export const updateUserNotificationSettings = createAsyncThunk(
  'notifications/updateUserSettings',
  async (payload: { [key: string]: Partial<Types.Notification.iUserNotificationSetting> }, { rejectWithValue }): Promise<null | any> => {
    try {
      const response = await Orbit.Services.notificationService.updateUserNotificationSettings(payload as tUserNotificationSettings)
      return response?.data
    } catch (error) {
      return rejectWithValue((error as ApiResponseError).response?.data)
    }
  }
)

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        listUserNotifications.fulfilled,
        (state: iNotificationInitialState, action: PayloadAction<Types.Notification.iNotifications>) => {
          state.notifications = action.payload.data
          // @ts-expect-error
          state.notificationMeta = action.payload.meta
          state.listStatus = 'success'
        }
      )
      .addCase(listUserNotifications.pending, (state: iNotificationInitialState) => {
        state.listStatus = 'loading'
      })
      .addCase(listUserNotifications.rejected, (state: iNotificationInitialState) => {
        state.listStatus = 'failed'
      })
      .addCase(
        getUserUnreadNotificationCount.fulfilled,
        (state: iNotificationInitialState, action: PayloadAction<Types.Notification.iNotificationCount>) => {
          state.unreadCount = action.payload.data.count
        }
      )
      .addCase(markReadUserNotification.fulfilled, (state: iNotificationInitialState) => {
        state.unreadCount = state.unreadCount ? state.unreadCount - 1 : state.unreadCount
      })
      .addCase(
        getUserUnseenNotificationCount.fulfilled,
        (state: iNotificationInitialState, action: PayloadAction<Types.Notification.iNotificationCount>) => {
          state.unseenCount = action.payload.data.count
        }
      )
      .addCase(markSeenAllUserNotification.fulfilled, (state: iNotificationInitialState) => {
        state.unseenCount = 0
      })
      .addCase(markReadAllUserNotification.fulfilled, (state: iNotificationInitialState) => {
        state.unreadCount = 0
      })
      .addCase(getUserNotificationSettings.pending, (state) => {
        state.userNotificationSettingsLoads = true
      })
      .addCase(getUserNotificationSettings.fulfilled, (state, { payload }) => {
        state.userNotificationSettings = payload
        state.userNotificationSettingsLoads = false
      })
      .addCase(getUserNotificationSettings.rejected, (state) => {
        state.userNotificationSettingsLoads = false
      })
      .addCase(updateUserNotificationSettings.fulfilled, (state, { payload }) => {
        state.userNotificationSettings = payload
      })
  },
})

export const { reducer } = notificationSlice
export const selectNotifications = (state: AppState) => state.notifications
