import { forwardRef, memo, useState } from 'react'
import { Text } from 'react-native'
import { isWeb } from '../helpers'
import { View } from '../View'
import { ListHoverStyles, ListHoverTextStyles, ListStyles, ListTextStyles } from './styles'
import { ListDefaults, ListProps } from './types'

const ListMain = ({ style, textStyle, type = ListDefaults.TYPE, ...props }: ListProps, ref?: any) => {
  const [hovered, setHovered] = useState<number | null>()

  return (
    <View style={ListStyles.container}>
      <View
        onMouseEnter={() => (props.hover && isWeb() ? setHovered(props.item.id) : null)}
        onMouseLeave={() => (props.hover && isWeb() ? setHovered(null) : null)}
        style={[ListStyles[type], style, props.item.id === hovered && ListHoverStyles[type]]}>
        <Text style={[ListTextStyles[type], props.item.id === hovered && ListHoverTextStyles[type], textStyle]}>{props.item.value}</Text>
      </View>
    </View>
  )
}

export default memo(forwardRef(ListMain))
