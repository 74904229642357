import { StyleProp, StyleSheet } from 'react-native'
import { GlobalStyles } from '../globalStyles'

const DefaultStyles = {
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 4,
  backgroundColor: GlobalStyles.MONO_WHITE,
} as StyleProp<object> as Record<string, number>

const DefaultTextStyles = {
  color: GlobalStyles.MONO_WHITE,
  fontWeight: '500',
} as StyleProp<object> as Record<string, number>

export const LabelStyles = StyleSheet.create({
  DEFAULT: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.PRIMARY_500,
  },
  DRAFT: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_YIELD,
  },
  LIVE: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_POSITIVE,
    borderWidth: 1,
    borderColor: GlobalStyles.STATUS_POSITIVE,
  },
  BOOKED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_POSITIVE,
    borderWidth: 1,
    borderColor: GlobalStyles.STATUS_POSITIVE,
  },
  COMPLETED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_INFO,
  },
  CANCELLED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
  },
  PAID: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_POSITIVE,
  },
  OVERDUE: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
  },
  NEW: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
  },
  APPLIED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_POSITIVE,
  },
  WITHDRAWN: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
  },
  OUTLINED: {
    ...DefaultStyles,
    borderWidth: 1,
    borderColor: GlobalStyles.PRIMARY_600,
  },
  SECONDARY: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.SLATE_50,
    borderColor: GlobalStyles.SLATE_100,
    borderWidth: 1,
  },
  DECLINED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
  },
  REMOVED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_NEGATIVE,
  },
  ARCHIVED: {
    ...DefaultStyles,
    backgroundColor: GlobalStyles.STATUS_INFO,
  },
})

export const LabelTransparentStyles = StyleSheet.create({
  DEFAULT: {
    ...DefaultStyles,
  },
  DRAFT: {
    ...DefaultStyles,
  },
  LIVE: {
    ...DefaultStyles,
  },
  BOOKED: {
    ...DefaultStyles,
  },
  COMPLETED: {
    ...DefaultStyles,
  },
  CANCELLED: {
    ...DefaultStyles,
  },
  PAID: {
    ...DefaultStyles,
  },
  OVERDUE: {
    ...DefaultStyles,
  },
  NEW: {
    ...DefaultStyles,
  },
  APPLIED: {
    ...DefaultStyles,
  },
  WITHDRAWN: {
    ...DefaultStyles,
  },
  OUTLINED: {
    ...DefaultStyles,
  },
  SECONDARY: {
    ...DefaultStyles,
  },
  DECLINED: {
    ...DefaultStyles,
  },
  REMOVED: {
    ...DefaultStyles,
  },
  ARCHIVED: {
    ...DefaultStyles,
  },
})

export const LabelTextStyles = StyleSheet.create({
  DEFAULT: {
    ...DefaultTextStyles,
  },
  DRAFT: {
    ...DefaultTextStyles,
    color: GlobalStyles.MONO_BLACK,
  },
  LIVE: {
    ...DefaultTextStyles,
    color: GlobalStyles.MONO_WHITE,
  },
  BOOKED: {
    ...DefaultTextStyles,
    color: GlobalStyles.MONO_WHITE,
  },
  COMPLETED: {
    ...DefaultTextStyles,
  },
  CANCELLED: {
    ...DefaultTextStyles,
  },
  PAID: {
    ...DefaultTextStyles,
  },
  OVERDUE: {
    ...DefaultTextStyles,
  },
  NEW: {
    ...DefaultTextStyles,
  },
  APPLIED: {
    ...DefaultTextStyles,
  },
  WITHDRAWN: {
    ...DefaultTextStyles,
  },
  OUTLINED: {
    ...DefaultTextStyles,
    color: GlobalStyles.PRIMARY_600,
  },
  SECONDARY: {
    ...DefaultTextStyles,
    color: GlobalStyles.SLATE_700,
  },
  DECLINED: {
    ...DefaultTextStyles,
    color: GlobalStyles.STATUS_NEGATIVE,
  },
  REMOVED: {
    ...DefaultTextStyles,
    color: GlobalStyles.STATUS_NEGATIVE,
  },
  ARCHIVED: {
    ...DefaultTextStyles,
    color: GlobalStyles.STATUS_INFO,
  },
})

export const LabelTransparentTextStyles = StyleSheet.create({
  DEFAULT: {
    ...DefaultTextStyles,
  },
  DRAFT: {
    ...DefaultTextStyles,
  },
  LIVE: {
    ...DefaultTextStyles,
  },
  BOOKED: {
    ...DefaultTextStyles,
  },
  COMPLETED: {
    ...DefaultTextStyles,
  },
  CANCELLED: {
    ...DefaultTextStyles,
  },
  PAID: {
    ...DefaultTextStyles,
  },
  OVERDUE: {
    ...DefaultTextStyles,
  },
  NEW: {
    ...DefaultTextStyles,
  },
  APPLIED: {
    ...DefaultTextStyles,
  },
  WITHDRAWN: {
    ...DefaultTextStyles,
  },
  OUTLINED: {
    ...DefaultTextStyles,
  },
  SECONDARY: {
    ...DefaultTextStyles,
  },
  DECLINED: {
    ...DefaultTextStyles,
  },
  REMOVED: {
    ...DefaultTextStyles,
  },
  ARCHIVED: {
    ...DefaultTextStyles,
  },
})

export const LabelSizes = StyleSheet.create({
  sm: {
    ...GlobalStyles.LABEL_SIZES.SMALL,
  },
  base: {
    ...GlobalStyles.LABEL_SIZES.MEDIUM,
  },
  lg: {
    ...GlobalStyles.LABEL_SIZES.LARGE,
  },
})
