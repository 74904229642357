import { GlobalStyles } from '@andromeda'
import { ModalHeader } from '@components'
import { useAppSelector } from '@redux/hooks'
import { selectDeliverable } from '@redux/reducers/deliverableReducer'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext } from 'react'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'

const ConfirmDeliverableDelete = (): JSX.Element => {
  const { closeModal, options } = useContext(ModalContext)
  const { requestStatus } = useAppSelector(selectDeliverable)
  const { workspace } = useAppSelector(selectWorkspace)
  const props = options?.modalProps ? options.modalProps[ModalPages.DELETE_DELIVERABLE] : undefined

  const _handleSubmit = () => {
    if (props.onConfirm) {
      props.onConfirm()
      trackEvent({ event: TrackingEventEnums.Deliverable.DELIVERABLE_REMOVED, eventProperties: { workspace } })
    } else closeModal(ModalPages.DELETE_DELIVERABLE)
  }

  return (
    <Stack className="dialog-container" style={{ backgroundColor: GlobalStyles.MONO_WHITE }} width={{ xxs: '100%', sm: '500px' }}>
      <ModalHeader modalTitle="Archive Deliverable?" modalContext={ModalPages.DELETE_DELIVERABLE} />
      <Stack className="dialog-content" rowGap="8px">
        <Typography>You are about to archive this Deliverable. This action cannot be undone. Do you wish to proceed?</Typography>
      </Stack>
      <Stack direction="row" justifyContent="end" columnGap="8px" className="dialog-footer">
        <Button variant="outlined" onClick={() => closeModal(ModalPages.DELETE_DELIVERABLE)}>
          Cancel
        </Button>
        <Button variant="contained" onClick={_handleSubmit} disabled={requestStatus.delete === 'pending'}>
          {requestStatus.delete === 'pending' ? (
            <CircularProgress size={12} style={{ marginRight: `${GlobalStyles.MARGIN_SIZES['2xs']}px` }} />
          ) : undefined}
          Archive
        </Button>
      </Stack>
    </Stack>
  )
}

export { ConfirmDeliverableDelete }
