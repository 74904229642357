import { DropdownOption } from '@andromeda'
import { useEffect, useState } from 'react'
import { isValidPhoneNumber, formatPhoneNumberIntl, getCountries, getCountryCallingCode } from 'react-phone-number-input'
import countryNames from 'react-phone-number-input/locale/en.json'

export const useCountryCode = () => {
  const [countryOptions, setCountryOptions] = useState<DropdownOption[]>([])

  useEffect(() => {
    if (countryOptions.length === 0) {
      const countryData = getCountries().map((countryCode, index) => {
        return {
          id: index,
          countryCode,
          countryCallCode: getCountryCallingCode(countryCode),
          label: `${countryCode} (+${getCountryCallingCode(countryCode)})`,
          value: `${countryNames[countryCode]} +${getCountryCallingCode(countryCode)}`,
        }
      })

      setCountryOptions(countryData)
    }
  }, [countryOptions.length])

  return { countryOptions }
}
