import { memo, forwardRef } from 'react'
import { ScrollView } from 'react-native'
import { ScrollViewProps } from './types'

// const ScrollView = ({ children, ...props }: ScrollViewProps, ref?: any) => {
//     return (
//         <NativeScrollView {...props} ref={ref}>
//             {children}
//         </NativeScrollView>
//     )
// }

export default ScrollView
