import { Types } from '@orbit'
import { useAppDispatch } from '@redux/hooks'
import { updateDepartmentPicture, updateOrganisationPicture } from '@redux/reducers/rolesReducer'
import { PayloadAction } from '@reduxjs/toolkit'
import { useState } from 'react'
import { toast } from 'react-toastify'

export const useImageUpload = ({
  onChange,
  defaultImage,
  type,
}: {
  type: 'department' | 'organisation'
  onChange: (val: any) => void
  defaultImage?: Types.User.iProfileImage | null
}) => {
  const dispatch = useAppDispatch()

  const [image, setImage] = useState(defaultImage)

  const _handleAvatarSave = ({ fileMeta, profilePicture }: { fileMeta: File; profilePicture: string }) => {
    let action: Promise<PayloadAction<any, string, { requestStatus: string }>> = dispatch(
      type.toLowerCase() === 'Department'
        ? updateDepartmentPicture({ fileMeta, profilePicture })
        : updateOrganisationPicture({ fileMeta, profilePicture })
    )

    toast.dismiss()
    toast
      .promise(action, {
        pending: 'Uploading image...',
      })
      .then(async (response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          setImage(response.payload)
          onChange(response.payload.profile_image_eid)
          toast.success('Image updated.')
        } else {
          toast.error(response.payload.message || 'Error uploading image.')
        }
      })
  }

  return { image, _handleAvatarSave }
}
