import moment from 'moment'
import { AttributesData, StarData, Values } from './types'

export const capitalize = (word: string) => word[0].toUpperCase() + word.substring(1, word.length)
export const pluralize = (word: string, subject: Array<any> | null) => word + (subject && subject.length > 1 ? 's' : '')

const hasChanges = (oldVal: Values, newVal: Values, key: string) => {
  return !['_at', 'id', 'fee'].some((skip) => key.includes(skip)) && !(oldVal === newVal)
}

const transformDates = (key: string, previousValue: string, newValue: string) => {
  let type: AttributesData['type'] = 'text'
  return {
    type,
    previousValue,
    newValue,
  }
}

export const generateStars = (new_values: Values) => {
  if (typeof new_values === 'object' && new_values !== null) {
    Object.values(new_values).reduce((acc, val, index): Array<StarData> => {
      const nameKey = Object.keys(val as Values).find((key) => key.includes('name'))
      if (nameKey) {
        return [
          ...acc,
          {
            id: index,
            ...((val as Values)?.[nameKey] ? { name: (val as Values)[nameKey] as string } : {}),
            ...((val as Values)?.requestable_type ? { requestable_type: (val as Values).requestable_type as string } : {}),
          } as StarData,
        ]
      }
      return acc
    }, [] as Array<StarData>)
  }
  return []
}
const getDateAndTimeDiff = (name: string, oldVal: string, newVal: string) => {
  let diff: AttributesData[] = []
  const [key] = name.split('_')
  const [oldDate, oldTime] = oldVal ? oldVal.split(' ') : [null, null]
  const [newDate, newTime] = newVal ? newVal.split(' ') : [null, null]
  if (oldDate !== newDate) {
    diff.push({
      name: `${key} date`,
      type: 'datetime',
      previousValue: oldDate ? moment(oldDate).format('DD/MM/YYYY') : '',
      newValue: moment(newDate).format('DD/MM/YYYY'),
    })
  }
  if (oldTime !== newTime) {
    diff.push({
      name: `${key} time`,
      type: 'datetime',
      previousValue: oldVal ? moment(oldVal).format('LT') : '',
      newValue: moment(newVal).format('LT'),
    })
  }
  return diff
}

export const generateAttributes = (event: string, old_values: Values, new_values: Values) =>
  Object.keys(new_values).reduce((acc, key): Array<AttributesData> => {
    const oldVal = (old_values as Values)?.[key]
    const newVal = (new_values as Values)?.[key]
    if (hasChanges(oldVal as Values, newVal as Values, key)) {
      if (typeof newVal === 'object' && newVal !== null) {
        return [
          ...acc,
          ...Object.keys(newVal as Values).reduce((acc2, key2): Array<AttributesData> => {
            const oldVal2 = (oldVal as Values)?.[key2]
            const newVal2 = (newVal as Values)?.[key2]

            if (hasChanges(oldVal2 as Values, newVal2 as Values, key2)) {
              if (typeof newVal2 === 'object' && newVal2 !== null) {
                return [
                  ...acc2,
                  ...Object.keys(newVal2 as Values).reduce((acc3, key3): Array<AttributesData> => {
                    const oldVal3 = (oldVal2 as Values)?.[key3]
                    const newVal3 = (newVal2 as Values)?.[key3]

                    if (hasChanges(oldVal3 as Values, newVal3 as Values, key3)) {
                      if (typeof newVal3 === 'object' && newVal3 !== null) {
                        return [
                          ...acc3,
                          ...Object.keys(newVal3 as Values).reduce((acc4, key4): Array<AttributesData> => {
                            const oldVal4 = (oldVal3 as Values)?.[key4]
                            const newVal4 = (newVal3 as Values)?.[key4]

                            if (hasChanges(oldVal4 as Values, newVal4 as Values, key4)) {
                              const { type, previousValue, newValue } = transformDates(key4, oldVal4 as string, newVal4 as string)
                              return [
                                ...acc4,
                                {
                                  name: key4,
                                  previousValue,
                                  newValue,
                                  type,
                                },
                              ]
                            }
                            return acc4
                          }, [] as Array<AttributesData>),
                        ]
                      } else {
                        const { type, previousValue, newValue } = transformDates(key3, oldVal3 as string, newVal3 as string)
                        return [
                          ...acc3,
                          {
                            name: key3,
                            previousValue,
                            newValue,
                            type,
                          },
                        ]
                      }
                    }
                    return acc3
                  }, [] as Array<AttributesData>),
                ]
              } else {
                const { type, previousValue, newValue } = transformDates(key2, oldVal2 as string, newVal2 as string)
                return [
                  ...acc2,
                  {
                    name: key2,
                    previousValue,
                    newValue,
                    type,
                  },
                ]
              }
            }
            return acc2
          }, [] as Array<AttributesData>),
        ]
      } else {
        if (['start', 'end'].some((dateTimeKeys) => key.includes(dateTimeKeys))) {
          const dateTimeDiff = getDateAndTimeDiff(key, oldVal as string, newVal as string)
          return [...acc, ...dateTimeDiff]
        }
        const { type, previousValue, newValue } = transformDates(key, oldVal as string, newVal as string)
        return [
          ...acc,
          {
            name: key,
            previousValue,
            newValue,
            type,
          },
        ]
      }
    }
    return acc
  }, [] as Array<AttributesData>)
