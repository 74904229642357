import { Card, GlobalStyles, Icon } from '@andromeda'
import * as Sentry from '@sentry/nextjs'
import React, { ReactNode } from 'react'

class ErrorHandler extends React.Component<{ children: ReactNode }, { hasError: boolean }> {
  constructor(props: any) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI
    Sentry.captureException(error)
    return { hasError: true }
  }
  componentDidCatch(error: any, errorInfo: any) {
    Sentry.captureException(error)
    // You can use your own error logging service here
    console.error({ error, errorInfo })
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Card.Error
          errorIcon={<Icon.AlertCircle size={150} strokeWidth={1} color={GlobalStyles.SLATE_300} />}
          heading="Something went wrong"
          subHeading="Please contact an Administrator if this problem persists."
        />
      )
    }

    // Return children components in case of no error

    return this.props.children
  }
}

export const ErrorBoundary = ErrorHandler
