import { Button, Card, GlobalStyles, Icon, Stack, StackV2, Text } from '@andromeda'
import { StyleProp, ViewStyle } from 'react-native'

const ExternalJobFormSent = ({ cardContainerStyle }: { cardContainerStyle?: StyleProp<ViewStyle> }): JSX.Element => {
  const _email = () => {
    window.location.href = `mailto:info@pickstar.com.au`
  }

  return (
    <StackV2 direction="column" spacing={2} className={'content-container'}>
      <Card.Error
        errorIcon={<Icon.CheckCircle size={67} strokeWidth={1} color={GlobalStyles.MONO_BLACK} />}
        heading="We've got your request"
        subHeading={
          <>
            <Text
              key={'message-line-1'}
              type="paragraph"
              size={'base'}
              style={{ color: GlobalStyles.SLATE_700, marginTop: 20, textAlign: 'center' }}>
              {'A member of our team will be in touch to discuss the details of your request.'}
            </Text>
            <Text key={'message-line-2'} type="paragraph" size={'base'} style={{ color: GlobalStyles.SLATE_700, textAlign: 'center' }}>
              {'In the meantime, feel free to email us with any questions.'}
            </Text>
          </>
        }
        // containerStyle={[
        //     {
        //         paddingTop: 80,
        //         paddingBottom: 80,
        //         backgroundColor: GlobalStyles.SLATE_50,
        //         ...responsiveStyles,
        //     },
        //     cardContainerStyle,
        // ]}
        actionButtons={
          <Stack flex={1} justify="center" style={{ marginTop: 30 }}>
            <Button type="outlined" onPress={_email} transparent>
              <Text key={'email-icon'}>
                <Icon.Mail strokeWidth={1} color={GlobalStyles.PRIMARY_500} />
              </Text>
              <Text key={'email-label'} size={'base'} style={{ color: GlobalStyles.PRIMARY_500 }}>
                Email Us
              </Text>
            </Button>
          </Stack>
        }
      />
    </StackV2>
  )
}

export { ExternalJobFormSent }
