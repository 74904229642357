export enum CampaignPermission {
  CREATE = 'campaign.create', // can create a campaign and assign jobs to them
  READ = 'campaign.read', // read specific campaign
  READ_PUBLIC = 'campaign.read.public', // read any public campaign
  UPDATE = 'campaign.update', // update specific campaign
  DELETE = 'campaign.delete', // delete a specific campaign
  ASSIGN_TALENT = 'campaign.assign_talent', // can assign talent to a specific campaign
  SHARE_EXTERNAL = 'campaign.share', // can share campaign to external people
  APPLIED = 'campaign.applied', // can view campaign + jobs inside + deliverables inside
  LIST_WORKSPACE = 'campaign.list_workspace_campaigns', // can list all campaigns on workspace
  LIST_OWN = 'campaign.list_my_campaigns', // can list your campaigns on workspace
}

export enum DeliverablePermission {
  CREATE = 'deliverable.create',
  UPDATE = 'deliverable.update',
  READ = 'deliverable.read',
  ACCEPT = 'deliverable.accept',
  REJECT = 'deliverable.reject',
  DUPLICATE_ALL = 'deliverable.duplicate.*',
  DUPLICATE = 'deliverable.duplicate',
  AGREEMENT_CREATE = 'deliverable-agreement.create', // Create a deliverable agreement
  AGREEMENT_UPDATE = 'deliverable-agreement.update', // Update an existing deliverable agreement
  AGREEMENT_VIEW = 'deliverable-agreement.view', // Get a deliverable agreements
  AGREEMENT_MARK_COMPLETE = 'job-agreement.mark-completed', // Mark job agreement as completed
  AGREEMENT_MARK_COMPLETE_OWNED = 'job-agreement.mark-completed.owned', // Mark job agreement as completed for a job the user owns
  AGREEMENT_MARK_BOOKED = 'deliverable-agreement.mark-booked', // Can mark deliverable agreement as booked
  AGREEMENT_MARK_ASSIGNED = 'deliverable-agreement.mark-assigned', // Can mark deliverable agreement as assigned
  AGREEMENT_MARK_REMOVED = 'deliverable-agreement.mark-removed', // Can mark deliverable agreement as removed
  AGREEMENT_MARK_REMOVED_OWNED = 'deliverable-agreement.mark-removed.owned', // Can mark deliverable agreement as removed
  AGREEMENT_MARK_DONE = 'deliverable-agreement.mark-done', //Can mark deliverable agreement as done
  AGREEMENT_MARK_ASSIGNED_OWNED = 'deliverable-agreement.mark-assigned.owned', // Can mark deliverable agreement as assigned if they own the job
  AGREEMENT_MARK_DONE_OWNED = 'deliverable-agreement.mark-done.owned', // Can mark deliverable agreement as done if they own the job
  MARK_COMPLETE = 'deliverable.mark-complete', // mark a deliverable as complete
  MARK_COMPLETE_OWNED = 'deliverable.mark-complete.owned', // mark a deliverable as complete if they own the job
  AGREEMENT_MARK_DONE_MANAGED = 'deliverable-agreement.mark-done.managed', // Can mark deliverable agreement as done if managing the talent
  AGREEMENT_MARK_BOOKED_MANAGED = 'deliverable-agreement.mark-booked.managed', // Can mark deliverable agreement as booked if managing the talent
  PUBLISH = 'deliverable.mark_live', // mark a deliverable as live
  PUBLISH_OWNED = 'deliverable.mark_live.owned', // mark a deliverable as live if they own the job
}

export enum JobPermission {
  CREATE = 'job.create', // can create jobs
  CREATE_EXTERNAL = 'job.create-external', // Create an external request and send to an email address
  VIEW = 'job.read', // can view job
  UPDATE = 'job.update', // can update a specific job
  UPLOAD_FILE = 'job.upload-file', // can files to a specific job
  DELETE = 'job.delete', // can delete specific job
  APPROVE = 'job.approve', // can approve a specific job
  OWNER = 'job.read.owned', // job owner
  PUBLISH = 'job.publish', // can publish any job
  SHORTLIST = 'job.shortlist', // can create/modify job/s shortlist

  BUDGET = 'job.budget', // can see the budgets on a jobs
  COST = 'job.read-cost', // can see the cost details on a jobs
  DETAILS = 'job.read-details', // can read details section on a job
  CLIENT = 'job.read-client', // can see the client on a jobs

  LIST_ALL = 'job.list-all', // List all jobs
  LIST_MANAGED = 'job.list-managed', // List jobs with talent that you manage
  LIST_OWNED = 'job.list-owned', // List jobs you own
  LIST_ADDED = 'job.list-added', // List jobs you are added to

  ADD_USER = 'job.add-user', // can add/change users on a job you own
  COMPLETE = 'job.complete', // can set job status to completed
}

export enum UserPermission {
  LIST_WORKSPACE_USERS = 'user.listWorkspaceUsers',
  UPDATE = 'user.update',
  CREATE = 'user.create',
  READ = 'user.read',
}

export enum WorkspacePermission {
  CREATE = 'workspace.create',
  UPDATE = 'workspace.update',
  LIST = 'workspace.list',
}

export enum ImportPermissions {
  LIST = 'import.list',
  USER_INVITE = 'import.user-invites',
  TALENTS = 'import.talents',
  JOBS = 'import.jobs',
}

export enum TalentPermission {
  CREATE = 'talent.create', // Can create a talent profile
  UPDATE_PRIMARY = 'talent.update.primary', // Can update talent profiles that this user is the primary manager of.
  UPDATE_MANAGED = 'talent.update.managed', // Can update talent profiles that this user manages or owns
  UPDATE = 'talent.update', // Can update any talent profile
  LIST = 'talent.list-all', // Can list/search talent
  LIST_MANAGED = 'talent.list.managed', // Can list talent they manage or own
  READ = 'talent.read', // Can read a specific talent profile
  READ_MANAGED = 'talent.read.managed', //Can read talent they manage or own
  DIRECTORY = 'talent.directory', // User can see the talent directory
  LIST_OWN = 'talent.list.my',
  USER_MANAGED = 'talent.user-managed',
}

export enum EventPermission {
  SUPER = 'events', // Can do all event things
  CREATE = 'event.create', // Can create events
}

export enum InvitePermission {
  CREATE = 'invite.create', // Can create invite
}

export enum EventTypePermission {
  LIST = 'event_type.list', //can list all event types
}

export enum CommentPermission {
  COMMENT_CREATE_MEMBER = 'comment.create.member', //user can create a comment only if they are a member of the resource
  COMMENT_CREATE = 'comment.create', //user can create a comment on this resource (ie job)
  COMMENT_CREATE_OWNED = 'comment.create.owned', //user can create a comment only if they own the resource
  COMMENT_UPDATE = 'comment.update', //can create comment on commentables that you are a member of
  COMMENT_LIST = 'comment.list', // can view the list of all comments on workspace
  COMMENT_LIST_INTERNAL = 'comment.list.internal', // can view the list of all internal comments on workspace
  COMMENT_LIST_EXTERNAL = 'comment.list.external', // can view the list of all external comments on workspace
  COMMENT_LIST_MEMBER = 'comment.list.member', // can view the list of all comments of the commentables that you are a member of
  COMMENT_LIST_MEMBER_INTERNAL = 'comment.list.internal.member', // can view the list of all internal comments of the commentables that you are a member of
  COMMENT_LIST_MEMBER_EXTERNAL = 'comment.list.external.member', // can view the list of all external comments of the commentables that you are a member of
  COMMENT_CREATE_INTERNAL = 'comment.create.internal', // can create an internal comment on workspace
  COMMENT_CREATE_EXTERNAL = 'comment.create.external', // can create an external comment on workspace
  COMMENT_CREATE_MEMBER_INTERNAL = 'comment.create.internal.member', // can create an internal comment on commentables that you are a member of
  COMMENT_CREATE_MEMBER_EXTERNAL = 'comment.create.external.member', // can create an external comment on commentables that you are a member of
  COMMENT_READ = 'comment.read', // can get any comment on workspace
  COMMENT_READ_INTERNAL = 'comment.read.internal', // can get one internal comment on workspace
  COMMENT_READ_EXTERNAL = 'comment.read.external', // can get one external comment on workspace
  COMMENT_READ_MEMBER = 'comment.read.member', // can get any comment on workspace of the commentables that you are a member of
  COMMENT_READ_MEMBER_INTERNAL = 'comment.read.internal.member', // can get one internal comment of the commentables that you are a member of
  COMMENT_READ_MEMBER_EXTERNAL = 'comment.read.EXTERNAL.member', // can get one EXTERNAL comment of the commentables that you are a member of
}

export enum GlobalPermission {
  NOTIFICATIONS = 'notification.global-notifications',
}

export enum DirectoryPermissions {
  TALENTS = 'directory.talent',
  USERS = 'directory.users',
  ORGANISATIONS = 'directory.organisations',
  DEPARTMENTS = 'directory.departments',
  MANAGE_TALENT = 'directory.managed-talent',
}

export enum RolePermissions {
  ADD_USER = 'role.add-user',
}
