import { memo, forwardRef } from 'react'
import { View as NativeView } from 'react-native'
import { ViewProps } from './types'

const View = ({ children, ...props }: ViewProps, ref?: any) => {
  return (
    <NativeView {...props} ref={ref}>
      {children}
    </NativeView>
  )
}

export default memo(forwardRef(View))
