import { iCommentThread } from '@andromeda'
import { createContext, useCallback, useContext, useState } from 'react'

interface iCommentsState {
  activeComment?: iCommentThread
  pendingComment?: iCommentThread
  toDiscardComment?: iCommentThread
  isReply: boolean
  isEdit: boolean
  commentContent?: string | null
  showDiscardDialog: boolean
}

interface iCommentsContext {
  state: iCommentsState
  setState: (state: iCommentsState) => void
  updateState: (newState: Partial<iCommentsState>) => void
}

const CommentThreadContext = createContext<iCommentsContext | undefined>(undefined)

const CommentsProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState<iCommentsState>({} as iCommentsState)

  const updateState = useCallback(
    (newState: Partial<iCommentsState>) => {
      setState({ ...state, ...newState } as iCommentsState)
    },
    [state]
  )

  return <CommentThreadContext.Provider value={{ state, setState, updateState }}>{children}</CommentThreadContext.Provider>
}

const useComments = () => {
  const context = useContext(CommentThreadContext) || {}
  return context as iCommentsContext
}

export { CommentsProvider, useComments }
