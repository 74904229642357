import { DropdownOption, GlobalStyles, Icon, Loading } from '@andromeda'
import { Box, Button, IconButton, ListSubheader, MenuItem, Select, SelectProps, Stack, TextField } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Controller, ControllerRenderProps, RegisterOptions, useFormContext } from 'react-hook-form'

type tSearchTagV2 = Omit<SelectProps, 'onSelect'> & {
  secondary?: boolean
  noSearch?: boolean
  open?: boolean
  value?: DropdownOption
  options?: DropdownOption[]
  onSelect: (option: DropdownOption) => void
  onClose?: () => void
  labelKey?: string
  hasAddTag?: boolean
  handleCreateNewTag?: (tag: DropdownOption) => void
}
export const SearchTagV2 = ({
  secondary = false,
  noSearch = false,
  value,
  options,
  onSelect,
  onClose,
  style,
  sx,
  MenuProps,
  placeholder,
  defaultValue,
  labelKey,
  hasAddTag = false,
  handleCreateNewTag,
  ...props
}: tSearchTagV2) => {
  const [searchString, setSearchString] = useState('')
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<DropdownOption | undefined>(defaultValue ?? undefined)

  const filteredOptions = useMemo(
    () =>
      searchString.length && options?.length
        ? options.filter(({ value }) => value?.toLowerCase().includes(searchString.toLowerCase()))
        : options || [],
    [options, searchString]
  )

  useEffect(() => {
    if (value) {
      setSelected(value)
    }
  }, [value])

  useEffect(() => {
    if (typeof props.open !== 'undefined') setOpen(props.open)
  }, [props.open])

  return (
    <Select
      {...props}
      MenuProps={{
        autoFocus: false,
        sx: {
          '.MuiPaper-root': {
            maxWidth: '200px',
            maxHeight: '300px',
            boxShadow: 'none',
            border: `1px solid ${GlobalStyles.SLATE_100}`,
          },
        },
        ...MenuProps,
      }}
      open={open}
      onOpen={() => setOpen(true)}
      // override onChange to prevent MUI from resetting the value
      onChange={() => {}}
      onClose={() => {
        setOpen(false)
        onClose && onClose()
      }}
      value={selected?.value ?? ''}
      IconComponent={() => (
        <IconButton
          onClick={() => setOpen((prev) => !prev)}
          sx={{
            marginRight: '6px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}>
          {open ? (
            <Icon.ChevronUp size={16} color={GlobalStyles.SLATE_500} />
          ) : (
            <Icon.ChevronDown size={16} color={GlobalStyles.SLATE_500} />
          )}
        </IconButton>
      )}
      style={{ minWidth: '100%', minHeight: '40px', boxSizing: 'border-box', ...style }}
      displayEmpty
      renderValue={(val) => (!!val ? val : placeholder)}
      sx={{
        borderRadius: '6px',
        '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
          color: secondary
            ? `${GlobalStyles.PRIMARY_500} !important`
            : `${!!selected?.value ? GlobalStyles.SLATE_700 : GlobalStyles.SLATE_500} !important`,
        },
        ...(secondary && {
          '&MuiInputBase-root, .MuiOutlinedInput-notchedOutline, &.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
        }),
        ...sx,
      }}>
      {!noSearch && (
        <ListSubheader onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            size="small"
            autoFocus
            style={{ width: '100%' }}
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value)
            }}
          />
        </ListSubheader>
      )}
      {options?.length ? (
        filteredOptions.map((option) =>
          option.groupLabel ? (
            <ListSubheader key={option.groupLabel} style={{ fontSize: '14px', color: GlobalStyles.SLATE_300 }}>
              {option.groupLabel}
            </ListSubheader>
          ) : (
            <MenuItem
              key={option.id ?? option.eid ?? option.value}
              value={option.value}
              aria-label={option.value}
              onClick={() => {
                onSelect(option)
                setSelected(option)
              }}>
              {option.label || option.value}
            </MenuItem>
          )
        )
      ) : (
        <Stack height="50px" justifyContent="center">
          <Loading />
        </Stack>
      )}
      {hasAddTag ? (
        <Box paddingX="15px">
          <Button
            variant="outlined"
            fullWidth
            startIcon={<Icon.PlusCircle />}
            onClick={() => {
              const option: DropdownOption = { id: searchString, value: searchString }
              setSelected(option)
              handleCreateNewTag && handleCreateNewTag(option)
            }}>{`Add '${searchString}'`}</Button>
        </Box>
      ) : null}
    </Select>
  )
}

export const FormSearchTagV2 = ({
  name,
  registerOptions,
  field,
  ...props
}: tSearchTagV2 & {
  name: string
  registerOptions?: RegisterOptions<any, any>
  field?: ControllerRenderProps<any, any>
}) => {
  const { register, formState } = useFormContext()

  const error = Object.keys(formState.errors).length
    ? name.split('.').length === 1
      ? formState.errors[name]
      : // @ts-expect-error
        name.split('.').reduce((acc, curr) => {
          return acc?.[curr]
        }, formState.errors)
    : undefined

  return (
    <SearchTagV2
      {...(field ? field : register(name, { required: true, ...registerOptions }))}
      error={!!error}
      aria-label={name}
      role="combobox"
      {...props}
    />
  )
}

export type tControlledSelect = Omit<tSearchTagV2, 'onSelect'> & {
  name: string
  registerOptions?: RegisterOptions<any, any>
}
export const ControlledSelect = ({ name, registerOptions, ...props }: tControlledSelect) => {
  const { formState, control } = useFormContext()

  const error = Object.keys(formState.errors).length
    ? name.split('.').length === 1
      ? formState.errors[name]
      : // @ts-expect-error
        name.split('.').reduce((acc, curr) => {
          return acc?.[curr]
        }, formState.errors)
    : undefined

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => <SearchTagV2 value={value} error={!!error} {...props} onSelect={onChange} />}
    />
  )
}
