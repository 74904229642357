import { HttpClient } from './api.service'
import { API, Agreements, Job } from '../types'

export const agreementService = {
  createJobAgreement: ({ payload }: { payload: Agreements.iCreateJobAgreementPayload }) => {
    const { job_eid, job_agreements } = payload
    const endpoint = API.AgreementEndpoints.CREATE_JOB_AGREEMENT.replace(':job', job_eid)

    return HttpClient.post<Agreements.iCreateJobAgreementTalentPayload[], Agreements.iJobAgreement>({
      endpoint,
      payload: job_agreements,
    })
  },

  createDeliverableAgreement: ({ payload }: { payload: Agreements.iCreateDeliverableAgreementPayload }) => {
    const { job_agreement_eid, deliverable_eid } = payload

    const endpoint = API.AgreementEndpoints.CREATE_DELIVERABLE_AGREEMENT.replace(':deliverable', deliverable_eid).replace(
      ':jobAgreement',
      job_agreement_eid
    )

    return HttpClient.post<null, Agreements.iDeliverableAgreement>({
      endpoint,
    })
  },

  updateAgreementFee: ({ payload }: { payload: Agreements.iUpdateAgreementFeePayload }) => {
    const { job_agreement_eid } = payload

    const endpoint = API.AgreementEndpoints.UPDATE_JOB_AGREEMENT_FEE.replace(':jobAgreement', job_agreement_eid)

    return HttpClient.put<Omit<Agreements.iUpdateAgreementFeePayload, typeof job_agreement_eid>, Agreements.iJobAgreementList[]>({
      endpoint,
      payload,
    })
  },

  listAgreements: ({ jobEid }: { jobEid: string }) => {
    const endpoint = API.AgreementEndpoints.LIST_AGREEMENTS.replace(':job', jobEid)

    return HttpClient.get<string, Agreements.iJobAgreementList[]>({
      endpoint,
    })
  },

  markAsBooked: ({ jobAgreementEid, payload }: { jobAgreementEid: string; payload: Agreements.iBookedAgreementPayload }) => {
    const endpoint = API.AgreementEndpoints.MARK_AS_BOOKED.replace(':jobAgreement', jobAgreementEid)

    return HttpClient.put<Agreements.iBookedAgreementPayload, Agreements.iJobAgreementList[]>({
      endpoint,
      payload,
    })
  },
  markAsDone: ({ jobAgreementEid, payload }: { jobAgreementEid: string; payload: Agreements.iMarkAgreementPayload }) => {
    const endpoint = API.AgreementEndpoints.MARK_AS_DONE.replace(':jobAgreement', jobAgreementEid)

    return HttpClient.put<Agreements.iMarkAgreementPayload, Agreements.iJobAgreementList[]>({
      endpoint,
      payload,
    })
  },
  markAsAssigned: ({ jobAgreementEid, payload }: { jobAgreementEid: string; payload: Agreements.iMarkAgreementPayload }) => {
    const endpoint = API.AgreementEndpoints.MARK_AS_ASSIGNED.replace(':jobAgreement', jobAgreementEid)

    return HttpClient.put<Agreements.iMarkAgreementPayload, Agreements.iJobAgreementList[]>({
      endpoint,
      payload,
    })
  },
  markAsRemoved: ({ jobAgreementEid, payload }: { jobAgreementEid: string; payload: Agreements.iRemovedAgreementPayload }) => {
    const endpoint = API.AgreementEndpoints.MARK_AS_REMOVED.replace(':jobAgreement', jobAgreementEid)

    return HttpClient.put<Agreements.iMarkAgreementPayload, Agreements.iJobAgreementList[]>({
      endpoint,
      payload,
    })
  },
  completeJobAgreement: ({ jobAgreementEid }: { jobAgreementEid: string }) => {
    const endpoint = API.AgreementEndpoints.COMPLETE_JOB_AGREEMENT.replace(':jobAgreement', jobAgreementEid)
    return HttpClient.put<null, Agreements.iJobAgreementList[]>({
      endpoint,
    })
  },
  updateMultipleDeliverableAgreements: ({ payload }: { payload: Agreements.iDeliverableAgreementPayload }) => {
    const endpoint = API.AgreementEndpoints.UPDATE_MULTIPLE_AGREEMENTS
    return HttpClient.put<Agreements.iDeliverableAgreementPayload, Agreements.iJobAgreementList[]>({
      endpoint,
      payload,
    })
  },
  generateFileUploadUrl: ({ eid, file_names }: { eid: string; file_names: string[] }) => {
    return HttpClient.post<{ file_names: string[] }, Job.iFile[]>({
      endpoint: API.AgreementEndpoints.GENERATE_FILE_UPLOAD_URL.replace(':eid', eid),
      payload: { file_names },
    })
  },
  generateFileDownloadUrl: ({ eid, payload }: { eid: string; payload: Job.iAttachFilesPayload }) => {
    return HttpClient.post<Job.iAttachFilesPayload, Job.iFile[]>({
      endpoint: API.AgreementEndpoints.GENERATE_FILE_DOWNLOAD_URL.replace(':eid', eid),
      payload,
    })
  },
  attachFiles: ({ eid, payload }: { eid: string; payload: Job.iAttachFilesPayload }) => {
    return HttpClient.put<Job.iAttachFilesPayload, Agreements.iAttachFileResponse>({
      endpoint: API.AgreementEndpoints.ATTACH_FILES.replace(':eid', eid),
      payload,
    })
  },
  detachFiles: ({ eid, payload }: { eid: string; payload: Job.iAttachFilesPayload }) => {
    return HttpClient.put<Job.iAttachFilesPayload, Agreements.iAttachFileResponse>({
      endpoint: API.AgreementEndpoints.DETACH_FILES.replace(':eid', eid),
      payload,
    })
  },
}
