import { forwardRef } from 'react'
import { Text, View } from 'react-native'
import { BadgeDefaults, BadgeProps } from './types'
import { BadgeSizes, BadgeStyles, BadgeTextStyles } from './styles'

const Badge = ({ style, textStyle, type = BadgeDefaults.TYPE, ...props }: BadgeProps, ref?: any) => {
  const borderRadius = BadgeSizes[type] / 2
  return (
    <View style={[BadgeStyles[type], { borderRadius: borderRadius }, style]} ref={ref} {...props}>
      <Text style={[BadgeTextStyles[type], textStyle]}>{props.text}</Text>
    </View>
  )
}

export default forwardRef(Badge)
