import { Ref, useCallback, useEffect, useRef, useState } from 'react'
import {
  Modal as NativeModal,
  TouchableOpacity as NativeTouchableOpacity,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import { useScreenDimensions } from '../hooks/dimensions'
import { Icon } from '../Icon'
import { Input } from '../Input'
import { List } from '../List'
import Loading from '../Loading/Loading'
import { GlobalStyles } from '../globalStyles'
import { DropdownStyles, IconColors } from './styles'
import { DropdownDefaults, DropdownOption, DropdownPlacement, SearchableProps } from './types'
import { Stack, Typography } from '@mui/material'

const DropdownSearch = ({
  options,
  renderItem,
  actionComponent,
  optionLabel = DropdownDefaults.LABEL,
  dropdownPosition = DropdownDefaults.POSITION,
  type = DropdownDefaults.TYPE,
  hover = true,
  filteredLimit,
  repositionAllowance = 2,
  ...props
}: SearchableProps) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [dropdownPos, setDropdownPos] = useState<DropdownPlacement>({ top: 0 })
  const [dropdownWidth, setDropdownWidth] = useState<string | number>('90%')
  const [inputFocused, shouldInputFocus] = useState(true)
  const [searchValue, setSearchValue] = useState(
    props.selectedItem && props.selectedItemKey && props.selectedItem?.[props.selectedItemKey]
      ? props.selectedItem?.[props.selectedItemKey]
      : ''
  )
  const isWeb = useState<boolean>(Platform.OS === DropdownDefaults.PLATFORM)
  const dropdownBtn = useRef<NativeTouchableOpacity>(null)
  const searchInput = useRef<TextInput>(null)
  const inlineSearchInput = useRef<TextInput>(null)
  const { windowHeight, windowWidth } = useScreenDimensions()
  const [overlap, setOverlap] = useState<boolean>(false)
  const [dropdownYPos, setDropdownYPos] = useState<number>(0)
  const [dropdownHeight, setDropdownHeight] = useState<number>(0)

  useEffect(() => {
    if (props.selectedItem && props.selectedItemKey && props.selectedItem?.[props.selectedItemKey])
      setSearchValue(props.selectedItem?.[props.selectedItemKey])
  }, [props.selectedItem, props.selectedItemKey])

  useEffect(() => {
    if (props.selectedItem === undefined) setSearchValue('')
  }, [props.selectedItem])

  useEffect(() => {
    if (props.controlledOpen) _openDropdown()
  }, [props.controlledOpen])

  const _toggleSelect = () => {
    if (visible) {
      setVisible(false)
      if (props.unsetOnSelect) setSearchValue('')
      props.onClose && props.onClose()
    } else {
      _openDropdown()
      props.onOpen && props.onOpen()
    }
  }

  const _openDropdown = async () => {
    setOverlap(false)
    const [x, y, width, height]: [number, number, number, number] = await new Promise((resolve) =>
      dropdownBtn.current?.measureInWindow((...args: any) => resolve(args))
    )
    setDropdownYPos(y)
    setDropdownHeight(height)
    const dropdownStyles = StyleSheet.flatten([props.dropdownStyle])
    const dwidth = dropdownStyles?.width || width
    setDropdownWidth(dwidth)

    if (dropdownPosition === 'right') {
      setDropdownPos({ top: y + height + repositionAllowance, right: windowWidth - (x + width) })
    } else {
      setDropdownPos({ top: y + height + repositionAllowance, left: x })
    }
    searchInput.current?.focus()
    setVisible(true)
  }

  const _handleItemPress = (item: any) => {
    setSearchValue(item[optionLabel])
    props.onSelect(item)
    _toggleSelect()
  }

  const _handleFocus = useCallback(() => {
    // For some reason this is called again when the input blurs
    // so we set a flag to tell it to unfocus
    if (!inputFocused) {
      searchInput.current?.blur()
      shouldInputFocus(true)
    }
  }, [inputFocused, searchInput.current])

  const _handleBlur = useCallback(() => {
    if (visible && inputFocused) {
      searchInput.current?.focus()
      shouldInputFocus(false)
    }
  }, [visible, inputFocused, searchInput.current])

  // strip out the accents on words
  // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
  const _stripDiacritics = (text: string) => {
    return typeof text.normalize !== 'undefined' ? text.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : text
  }

  const _filterOptions = () => {
    // trim input
    let input = searchValue.trim()
    input = input.toLowerCase()
    input = _stripDiacritics(input)

    const filteredOptions =
      options?.length && options?.[0].groupLabel
        ? options?.reduce((acc: DropdownOption[], curr) => {
            if (
              curr.options?.findIndex((option: DropdownOption) => {
                let candidate = option[optionLabel]
                candidate = candidate.toLowerCase()
                candidate = _stripDiacritics(candidate)

                return candidate.indexOf(input) > -1
              }) !== -1
            ) {
              return [
                ...acc,
                {
                  ...curr,
                  options: curr?.options?.filter((option: DropdownOption) => {
                    let candidate = option[optionLabel]
                    candidate = candidate.toLowerCase()
                    candidate = _stripDiacritics(candidate)

                    return candidate.indexOf(input) > -1
                  }),
                },
              ]
            } else {
              return acc
            }
          }, [])
        : options?.filter((option) => {
            let candidate = option[optionLabel]
            candidate = candidate.toLowerCase()
            candidate = _stripDiacritics(candidate)

            return candidate.indexOf(input) > -1
          })

    return typeof filteredLimit === 'number' ? filteredOptions?.slice(0, filteredLimit) : filteredOptions
  }

  useEffect(() => {
    if (isWeb) {
      document.addEventListener('scroll', () => setVisible(false))
    }

    return () => {
      if (isWeb) {
        document.removeEventListener('scroll', () => setVisible(false))
      }
    }
  }, [])

  const _handleModalDismiss = () => {
    if (props.unsetOnSelect) setSearchValue('')
  }

  const _renderList = () => {
    const filteredOptions = _filterOptions()
    return (
      <NativeModal visible={visible} transparent animationType="none" onDismiss={_handleModalDismiss}>
        <TouchableWithoutFeedback style={{ flex: 1 }}>
          <View
            style={[
              DropdownStyles.select,
              { ...dropdownPos },
              type === 'shadow' && DropdownStyles.shadowedDropdown,
              props.dropdownStyle,
              { width: dropdownWidth },
            ]}
            onLayout={(e) => {
              const height = e.nativeEvent.layout.height
              const willOverlap = windowHeight - (dropdownYPos + dropdownHeight) < height
              if (overlap && dropdownYPos + height + repositionAllowance < windowHeight) {
                /**
                 * if initially overlapped but eventually has enough space
                 * don't let the dropdown list container pass the dropdown input
                 */
                const newPos: DropdownPlacement = { top: dropdownYPos - repositionAllowance }
                if (dropdownPos.left) newPos.left = dropdownPos.left
                if (dropdownPos.right) newPos.right = dropdownPos.right
                setDropdownPos(newPos)
                setOverlap(true)
              } else if (willOverlap) {
                let newPos: DropdownPlacement = { bottom: repositionAllowance }
                if (windowHeight - (repositionAllowance + height) > dropdownYPos) {
                  // if the dropdown y position is inside the dropdown input
                  newPos = { top: dropdownYPos }
                }
                if (dropdownPos.left) newPos.left = dropdownPos.left
                if (dropdownPos.right) newPos.right = dropdownPos.right
                setDropdownPos(newPos)
                setOverlap(true)
              }
            }}>
            {overlap && (
              // display search box on top of dropdown list
              <View
                style={{
                  paddingHorizontal: GlobalStyles.PADDING_SIZES.xs,
                  paddingBottom: GlobalStyles.PADDING_SIZES.sm,
                }}>
                <Input
                  size="sm"
                  autoFocus
                  ref={inlineSearchInput}
                  name={'inline-search-dropdown'}
                  placeholder={props.placeholder}
                  onChangeText={(text) => _handleInputChangeText(text)}
                  style={[{ borderWidth: 0, margin: 0, paddingLeft: 0 }, props.inlineInputStyle]}
                  wrapperStyle={[
                    {
                      borderWidth: 1,
                      borderColor: GlobalStyles.SLATE_100,
                      borderRadius: 4,
                      paddingHorizontal: GlobalStyles.PADDING_SIZES.sm,
                    },
                    props.inlineInputWrapperStyle,
                  ]}
                  value={searchValue}
                  leftElement={props.leftElement}
                  rightElement={props.rightElement || _renderInputRightElement()}
                  space={props.inputSpace}
                />
              </View>
            )}
            <ScrollView
              onScroll={({ nativeEvent }) => {
                const { layoutMeasurement, contentOffset, contentSize } = nativeEvent
                if (layoutMeasurement.height + contentOffset.y >= contentSize.height) {
                  props.lazyLoad && props.lazyLoad()
                }
              }}
              scrollEventThrottle={1000}>
              {filteredOptions?.length && filteredOptions[0].groupLabel ? (
                filteredOptions.map(({ groupLabel, options }) => (
                  <Stack marginLeft="5px" key={groupLabel}>
                    <Typography fontSize="14px" color={GlobalStyles.SLATE_300}>
                      {groupLabel}
                    </Typography>
                    <List.Group
                      values={options}
                      valuesLabel={optionLabel}
                      onItemPress={_handleItemPress}
                      hover={hover}
                      style={DropdownStyles.item}
                      actionComponent={actionComponent && actionComponent(options)}
                      renderItem={renderItem && renderItem}
                    />
                  </Stack>
                ))
              ) : (
                <List.Group
                  values={_filterOptions()}
                  valuesLabel={optionLabel}
                  onItemPress={_handleItemPress}
                  hover={hover}
                  style={DropdownStyles.item}
                  actionComponent={actionComponent && actionComponent(options)}
                  renderItem={renderItem && renderItem}
                />
              )}
              {props.isLazyLoading && <Loading />}
            </ScrollView>
          </View>
        </TouchableWithoutFeedback>
      </NativeModal>
    )
  }

  const _renderInputRightElement = () => {
    return (
      <View style={[DropdownStyles.chevronIcon, type === 'shadow' && DropdownStyles.shadowedIconContainer, props.iconContainerStyle]}>
        <Icon.ChevronDown size={GlobalStyles.ICON_SIZE} color={IconColors[props.disabled ? 'disabled' : type]} {...props.iconStyle} />
      </View>
    )
  }

  const _handleInputChangeText = (text: string) => {
    setSearchValue(text)
    props.onChangeText && props.onChangeText(text)
  }

  return (
    <View style={props.dropdownWrapperStyle}>
      <View style={props.containerStyle}>
        <Pressable
          testID={props.testID}
          ref={dropdownBtn ? (dropdownBtn as Ref<View>) : null}
          style={[
            DropdownStyles.container,
            type === 'shadow' && DropdownStyles.borderless,
            type === 'shadow' && visible && GlobalStyles.INPUT_SHADOWED,
            props.dropdownContainerStyle,
          ]}
          onPress={_toggleSelect}
          disabled={props.disabled}>
          <Input
            size="sm"
            name={`searchable-${props.inputName}`}
            ref={searchInput}
            placeholder={props.placeholder}
            onFocus={_handleFocus}
            onBlur={_handleBlur}
            onChangeText={(text) => _handleInputChangeText(text)}
            style={[{ borderWidth: 0, margin: 0, paddingLeft: 0 }, props.inputStyle]}
            value={searchValue}
            leftElement={props.leftElement}
            rightElement={props.rightElement || _renderInputRightElement()}
            space={props.inputSpace}
          />
          {_renderList()}
        </Pressable>
      </View>
    </View>
  )
}

export default DropdownSearch
