import { Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { updateMultipleDeliverableAgreement } from '@redux/reducers/agreementsReducer'
import { archiveDeliverable, publishDeliverable, updateDeliverable } from '@redux/reducers/deliverableReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { PayloadAction } from '@reduxjs/toolkit'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { Dispatch, SetStateAction, useContext } from 'react'
import { toast } from 'react-toastify'
import { DeliverableOverviewFormState } from './Overview'
import { FieldValues } from 'react-hook-form'
import { DELIVERABLE_DRAWER_TABS } from './constants'
import { API_DATE_FORMAT } from '@utils/constants/date'
import { decodeCustomFieldsValue } from 'src/components/custom-fields'

export const useDeliverableActions = ({
  deliverable,
  selectedTab,
  deliverableFormState,
  overviewForm,
  setIsLoading,
  setIsEditable,
  setDeliverableFormState,
  onSuccess,
  setIsDirty,
  isDirty,
  onClose,
  selectedTalents,
  costMode,
}: {
  selectedTab: string
  deliverableFormState: any
  overviewForm: FieldValues
  setIsLoading: Dispatch<SetStateAction<boolean>>
  deliverable?: Types.Deliverable.iDeliverable
  setIsEditable: Dispatch<SetStateAction<boolean>>
  setDeliverableFormState: Dispatch<any>
  onSuccess?: (deliverable?: Types.Deliverable.iDeliverable) => void
  setIsDirty: Dispatch<SetStateAction<boolean>>
  isDirty?: boolean
  onClose?: () => void
  selectedTalents?: Types.Talent.iTalent[]
  costMode: Types.Job.iJob['cost_mode']
}) => {
  const dispatch = useAppDispatch()
  const { workspace } = useAppSelector(selectWorkspace)

  const { closeModal, toggleModal } = useContext(ModalContext)

  const handleSubmit = async (data?: any) => {
    trackEvent({
      event: TrackingEventEnums.Deliverable.DELIVERABLE_UPDATE,
      eventProperties: {
        component_name: 'L2 Deliverable Panel',
        workspace: workspace,
      },
    })

    if (data?.custom_fields) {
      data.custom_fields = decodeCustomFieldsValue(data.custom_fields)
    }

    setIsLoading(true)
    switch (selectedTab) {
      case DELIVERABLE_DRAWER_TABS.OVERVIEW:
        const { start_date, end_date, selectedCategory, selectedType, ...payload } = Object.fromEntries(
          Object.keys(overviewForm.formState.dirtyFields).map((key: any) => [key, data[key as keyof DeliverableOverviewFormState]])
        )

        if (payload?.description) {
          payload.plain_description = data.plain_description
        }

        if (deliverable)
          await toast.promise(
            dispatch(
              updateDeliverable({
                eid: deliverable.eid,
                payload: {
                  ...payload,
                  job_eid: deliverable.job?.eid,
                  deliverable_type_eid: (selectedType?.id || selectedCategory?.id) as string,
                  ...(costMode === 'PER_DELIVERABLE' ? { deliverable_currency_code: data.deliverable_currency_code?.value } : {}),
                  ...(selectedCategory ? { deliverable_category_eid: selectedCategory?.id } : {}),
                  ...(start_date ? { start_datetime: start_date?.format(API_DATE_FORMAT) } : {}),
                  ...(end_date
                    ? {
                        end_datetime: end_date ? end_date?.format(API_DATE_FORMAT) : `${start_date?.format('YYYY-MM-DD')} 23:59:59`,
                      }
                    : {}),
                },
              })
            ).then((response) => {
              if (response.meta.requestStatus === 'fulfilled') {
                toast.success('Changes have been saved.')

                if (onSuccess) onSuccess(response.payload)

                setIsEditable(false)
                setIsDirty(false)
                setDeliverableFormState({ ...deliverableFormState })
                overviewForm.reset(data)
              } else {
                if (response.payload.error) {
                  toast.error(response.payload.error.message || 'Error updating deliverable.')
                }
              }
              setIsLoading(false)
            }),
            {
              pending: 'Updating deliverable...',
            }
          )
        break
      case DELIVERABLE_DRAWER_TABS.STATUS:
        await toast.promise(
          dispatch(updateMultipleDeliverableAgreement({ deliverable_agreements: deliverableFormState })).then((response) => {
            if (response.meta.requestStatus === 'fulfilled') {
              const results = (response.payload as { error?: string }[]).reduce(
                (
                  acc: {
                    success: number
                    error: number
                    errorMessage: string
                  },
                  curr
                ) => {
                  if (curr.error) {
                    return {
                      ...acc,
                      error: acc.error + 1,
                      errorMessage: !!acc.errorMessage ? acc.errorMessage : curr.error,
                    }
                  }
                  return { ...acc, success: acc.success + 1 }
                },
                {
                  success: 0,
                  error: 0,
                  errorMessage: '',
                }
              )

              if (results.success === deliverableFormState.length) {
                toast.success('Changes have been saved.')
                if (onSuccess) onSuccess()
              } else {
                if (results.error === deliverableFormState.length) {
                  toast.error(results.errorMessage)
                } else {
                  const isPlural = results.success > 1
                  toast.success(
                    `${results.success} change${isPlural ? 's' : ''} ${isPlural ? 'have' : 'has'} been saved. \n${results.error} failed (${
                      results.errorMessage
                    })`
                  )
                  if (onSuccess) onSuccess()

                  trackEvent({
                    event: TrackingEventEnums.Deliverable.DELIVERABLE_AGREEMENT_STATUS_UPDATED,
                    eventProperties: {
                      component_name: 'L2 Deliverable Panel',
                      workspace: workspace,
                    },
                  })
                }
              }
            }
          }),
          {
            pending: 'Updating deliverable...',
          }
        )

        setIsEditable(false)
        setIsDirty(false)
        setIsLoading(false)
        break
      default:
        break
    }
  }

  const publish = async () => {
    setIsLoading(true)
    if (isDirty) {
      await handleSubmit(overviewForm.getValues())
    }

    if (deliverable)
      await toast
        .promise(dispatch(publishDeliverable({ eid: deliverable.eid })), {
          pending: 'Publishing deliverable...',
        })
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            toast.success('Deliverable has been published.')
            // @ts-expect-error
            onSuccess && onSuccess({ eid: response.payload.eid, status: response.payload.status })
            setIsLoading(false)

            trackEvent({
              event: TrackingEventEnums.Deliverable.DELIVERABLE_PUBLISH,
              eventProperties: {
                component_name: 'L2 Deliverable Panel',
                workspace: workspace,
                deliverable_name: deliverable.name,
              },
            })
          } else {
            toast.error(response.payload.message || 'Error publishing deliverable.')
          }
        })
  }

  const handlePublish = async () => {
    if (isDirty && deliverable?.status !== 'LIVE') {
      toggleModal(ModalPages.CONFIRM_SAVE_PUBLISH, {
        modalProps: {
          [ModalPages.CONFIRM_SAVE_PUBLISH]: {
            saveAndPublish: async ({ setIsLoading, close }: { setIsLoading: (val: boolean) => void; close: () => void }) => {
              setIsLoading(true)
              publish().then(() => {
                setIsLoading(false)
                close()
              })
            },
          },
        },
      })
    } else {
      publish()
    }
  }

  const handleComplete = () => {
    if (deliverable) {
      trackEvent({
        event: TrackingEventEnums.Deliverable.DELIVERABLE_COMPLETED,
        eventProperties: {
          component_name: 'L2 Deliverable Panel',
          workspace: workspace,
          deliverable_name: deliverable.name,
        },
      })

      toggleModal(ModalPages.MARK_COMPLETE_DELIVERABLE, {
        modalProps: {
          [ModalPages.MARK_COMPLETE_DELIVERABLE]: {
            deliverableEid: deliverable.eid,
            onConfirm: (
              response: PayloadAction<
                any,
                string,
                {
                  requestStatus: 'fulfilled'
                }
              >
            ) => {
              if (response.meta.requestStatus === 'fulfilled') {
                toast.success('Deliverable has been completed.')
                // @ts-expect-error
                onSuccess && onSuccess({ eid: response.payload.data.eid, status: response.payload.data.status })
              } else {
                toast.error(response.payload.message || 'Error completing deliverable.')
              }

              closeModal(ModalPages.MARK_COMPLETE_DELIVERABLE)
            },
          },
        },
      })
    }
  }

  const handleArchive = () => {
    if (deliverable) {
      trackEvent({
        event: TrackingEventEnums.Deliverable.DELIVERABLE_ARCHIVED,
        eventProperties: {
          component_name: 'L2 Deliverable Panel',
          workspace: workspace,
          deliverable_name: deliverable.name,
        },
      })

      toggleModal(ModalPages.DELETE_DELIVERABLE, {
        modalProps: {
          [ModalPages.DELETE_DELIVERABLE]: {
            deliverableEid: deliverable.eid,
            onConfirm: () => {
              toast.promise(
                dispatch(archiveDeliverable({ eid: deliverable.eid })).then((response) => {
                  if (response.meta.requestStatus === 'fulfilled') {
                    toast.success('Deliverable has been archived.')
                    onSuccess && onSuccess()
                    onClose && onClose()
                  } else {
                    toast.error(response.payload.message || 'Error archiving deliverable.')
                  }
                }),
                {
                  pending: 'Archiving deliverable...',
                }
              )

              closeModal(ModalPages.DELETE_DELIVERABLE)
            },
          },
        },
      })
    }
  }

  return {
    handleSubmit,
    handlePublish,
    handleComplete,
    handleArchive,
  }
}
