import { cloneElement } from 'react'
import { Text } from '../Text'
import { View } from '../View'
import { GlobalStyles } from '../globalStyles'
import Avatar from './Avatar'
import { AvatarGroupDefaults, AvatarGroupProps } from './types'

const SPACINGS = {
  small: -10,
  medium: -4,
}

const AvatarGroup = ({
  children,
  total,
  sizes,
  offset = AvatarGroupDefaults.OFFSET,
  spacings = AvatarGroupDefaults.SPACINGS,
  extraAvatarProps,
  style,
  ...props
}: AvatarGroupProps) => {
  let clampedMax = offset < 2 ? 2 : offset
  const totalAvatars = total || children.length

  if (clampedMax === totalAvatars) {
    clampedMax += 1
  }

  clampedMax = Math.min(totalAvatars + 1, clampedMax)

  const maxAvatars = Math.min(children.length, clampedMax - 1)
  const marginOffset = typeof spacings === 'number' ? -spacings : SPACINGS[spacings]

  const extraAvatars = Math.max(totalAvatars - clampedMax, totalAvatars - maxAvatars, 0)

  return (
    <View style={[{ flexDirection: 'row', overflow: 'hidden' }, style]}>
      {children.slice(0, maxAvatars).map((child, index) => {
        return cloneElement(child, {
          key: index,
          style: {
            ...child.props.style,
            borderWidth: 2,
            borderColor: GlobalStyles.SLATE_200,
            marginRight: index === maxAvatars - 1 ? undefined : marginOffset,
            zIndex: index + 1,
          },
          sizes,
        })
      })}

      {extraAvatars ? (
        <Avatar
          style={[
            {
              backgroundColor: GlobalStyles.SLATE_50,
              marginLeft: marginOffset,
              borderWidth: 1,
              borderColor: GlobalStyles.SLATE_100,
              zIndex: maxAvatars + 1,
              borderRadius: 40,
              height: 20,
            },
            extraAvatarProps?.style,
          ]}
          placeHolder={
            <Text
              style={{
                textAlign: 'center',
                fontSize: GlobalStyles.FONT_SIZES.TINY,
                color: GlobalStyles.SLATE_700,
              }}
              {...extraAvatarProps?.placeholderTextProps}>
              +{extraAvatars}
            </Text>
          }
          size={extraAvatarProps?.size}
        />
      ) : null}
    </View>
  )
}

export default AvatarGroup
