import { memo, forwardRef } from 'react'
import { Text as NativeText } from 'react-native'
import { TextDefaults, TextProps } from './types'
import { HeaderTextSizeStyles, TextSizeStyles } from './styles'
import { GlobalStyles } from '../globalStyles'

const Text = (
  {
    style,
    children,
    type = TextDefaults.TYPE,
    size = TextDefaults.SIZE,
    active = false,
    activeColor = GlobalStyles.PRIMARY_500,
    ...props
  }: TextProps,
  ref?: any
) => {
  return (
    <NativeText
      style={[
        type === 'heading' ? HeaderTextSizeStyles[size] : TextSizeStyles[size],
        { fontWeight: props.bold ? '500' : '400' },
        active && { color: activeColor },
        style,
      ]}
      {...props}>
      {children}
    </NativeText>
  )
}

export default memo(forwardRef(Text))
