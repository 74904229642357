import { Button, GlobalStyles, Stack, Text } from '@andromeda'
import { ModalFooter, ModalHeader } from '@components'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { markAsRemoved, selectAgreement } from '@redux/reducers/agreementsReducer'
import { ModalContext } from '@utils/context/Modal.context'
import { useCallback, useContext, useState } from 'react'

export const RemoveTalent = () => {
  const { toggleModal, options } = useContext(ModalContext)
  const props = options?.modalProps?.removeTalent
  const [notifyTalent, setNotifyTalent] = useState(true)

  const { markStatus } = useAppSelector(selectAgreement)
  const dispatch = useAppDispatch()

  const _renderTitle = () => {
    const removeAllText = `Remove ${props?.talentName} from ${props?.deliverablesLength} Deliverables?`
    const removeQuantified = `Remove ${props?.talentName} from ${props?.agreementEids?.length} of ${props?.deliverablesLength} Deliverables?`

    if (props.deliverablesLength === 1) {
      return removeAllText
    }

    if (props.isBulk && !props.hasDone) {
      return removeAllText
    }

    return removeQuantified
  }

  const _handleSwitchToggle = useCallback(({ isToggled }: { isToggled: boolean }) => {
    setNotifyTalent(isToggled)
  }, [])

  const _handleSubmit = () => {
    dispatch(
      markAsRemoved({
        jobAgreementEid: props.jobAgreementEid,
        payload: {
          deliverable_agreement_eids: props.agreementEids,
          is_notify: notifyTalent,
        },
      })
    ).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        props.onConfirm(response.payload)
      }
    })
  }

  return (
    <Stack rounded space="none" height="auto" direction="column" style={{ backgroundColor: GlobalStyles.MONO_WHITE }}>
      <ModalHeader
        modalContext="removeTalent"
        modalTitle={_renderTitle()}
        textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
        style={{
          paddingBottom: 0,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.md,
        }}
      />

      <Stack
        direction="column"
        align="flex-start"
        space="xs"
        style={{
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingVertical: GlobalStyles.PADDING_SIZES.md,
        }}>
        <Text size="sm" style={{ color: GlobalStyles.MONO_BLACK }}>
          What this means:
        </Text>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>{'\u2022'}</Text>
          <Text size="sm">Any deliverables marked done will remain unaffected.</Text>
        </Stack>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>{'\u2022'}</Text>
          <Text size="sm">Unless a talent has done deliverables, they will lose access to the job.</Text>
        </Stack>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>{'\u2022'}</Text>
          <Text size="sm">Managers may lose the ability to view the job.</Text>
        </Stack>
      </Stack>

      <ModalFooter
        backgroundColor={GlobalStyles.MONO_WHITE}
        wrapperStyle={{
          shadowOpacity: 0,
          justifyContent: 'flex-end',
          paddingBottom: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.sm,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
        }}
        buttonStyles={{
          paddingVertical: 12,
          paddingHorizontal: 18,
          borderRadius: 8,
          backgroundColor: GlobalStyles.MONO_WHITE,
        }}
        buttonTextStyle={{
          color: GlobalStyles.STATUS_NEGATIVE,
          fontWeight: '500',
        }}
        leftComponent={
          <Button
            type="secondary"
            style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
            onPress={() => toggleModal('removeTalent')}
            textStyle={{ color: GlobalStyles.PRIMARY_500, fontWeight: '500' }}
            text="Cancel"
          />
        }
        onButtonPress={_handleSubmit}
        loading={markStatus === 'loading'}
        buttonText={'Remove Talent'}
        loadingType="blue"
        buttonVariant="danger"
        disabled={false}
      />
    </Stack>
  )
}
