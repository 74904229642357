import { useState, memo, forwardRef, useEffect, useRef } from 'react'
import { View } from '../View'
import { Text } from '../Text'
import { Dropdown } from '../Dropdown'
import { default as Input } from './Input'
import { isValidPhoneNumber, formatPhoneNumberIntl, getCountries, getCountryCallingCode } from 'react-phone-number-input'
import { PhoneInputStyles } from './styles'
//@ts-ignore
import countryNames from 'react-phone-number-input/locale/en.json'
import { DropdownOption } from '../Dropdown/types'
import { CountryType, PhoneInputDefaultCountry, PhoneInputDefaults, PhoneInputProps } from './types'
import { GlobalStyles } from '../globalStyles'
import { Icon } from '../Icon'

const PhoneInput = ({
  phoneLabel,
  placeholder = PhoneInputDefaults.PLACEHOLDER,
  defaultValue = PhoneInputDefaults.DEFAULT_VALUE,
  ...props
}: PhoneInputProps) => {
  const [countryOptions, setCountryOptions] = useState<DropdownOption[]>([])
  const [inputData, setInputData] = useState<string>(defaultValue)
  const [selectedCountry, setSelectedCountry] = useState<CountryType>(PhoneInputDefaultCountry)
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)

  useEffect(() => {
    if (countryOptions.length === 0) {
      const countryData = getCountries().map((countryCode, index) => {
        return {
          id: index,
          countryCode,
          countryCallCode: getCountryCallingCode(countryCode),
          label: `${countryCode} (+${getCountryCallingCode(countryCode)})`,
          value: `${countryNames[countryCode]} +${getCountryCallingCode(countryCode)}`,
        }
      })

      setCountryOptions(countryData)
    }
  }, [])

  useEffect(() => {
    setInputData(defaultValue)
  }, [defaultValue])

  const _handleOnChangeText = (text: string) => {
    const validations = {
      isValid: isValidPhoneNumber(`+${selectedCountry.countryCallCode}${text}`),
    }

    const appendedCallCode = `+${selectedCountry.countryCallCode}`

    const formattedPhoneNum = formatPhoneNumberIntl(`${appendedCallCode}${text}`).replace(appendedCallCode, '')

    if (formattedPhoneNum) {
      setInputData(formattedPhoneNum)
      props.onChange({ value: formattedPhoneNum, validations })
    } else {
      setInputData(text)
      props.onChange({ value: text, validations })
    }
  }

  const _handleOnSelect = (selectedItem: CountryType) => {
    setSelectedCountry(selectedItem)
    props.onCountrySelect && props.onCountrySelect(selectedItem)
  }

  const _handleFocus = () => {
    setIsInputFocused(true)
  }

  const _handleBlur = () => {
    setIsInputFocused(false)
  }

  return (
    <View style={[PhoneInputStyles.container, isInputFocused && PhoneInputStyles.focusedContainer, props.mainWrapperStyle]}>
      <Input
        placeholder={placeholder}
        onFocus={_handleFocus}
        onBlur={_handleBlur}
        onChangeText={_handleOnChangeText}
        value={inputData}
        wrapperStyle={[PhoneInputStyles.inputWrapper, props.inputWrapper]}
        style={[PhoneInputStyles.inputContainer, props.inputStyles]}
        keyboardType={'number-pad'}
        leftElement={
          <>
            {phoneLabel && <Text style={PhoneInputStyles.inputLabel}>{phoneLabel}</Text>}
            <Dropdown
              options={countryOptions}
              optionLabel={'label'}
              onSelect={_handleOnSelect}
              selectedItem={selectedCountry}
              dropdownPosition={'left'}
              dropdownStyle={[PhoneInputStyles.countryDropdown, props.dropdownStyle]}
              dropdownContainerStyle={[PhoneInputStyles.dropdownContainer, props.dropdownContainerStyle]}
              labelStyle={[{ color: GlobalStyles.PRIMARY_500, fontWeight: '500' }, props.labelStyle]}
              hideChevron={true}
              leftElement={
                <View style={{ paddingTop: 2 }}>
                  <Icon.ChevronDown size={GlobalStyles.FONT_SIZES.BASE} color={GlobalStyles.PRIMARY_500} strokeWidth={2.5} />
                </View>
              }
              stackProps={{ space: '2xs' }}
            />
          </>
        }
        space={'sm'}
        errorMessage={props.errorMessage}
        errorMessageStyle={props.errorMessageStyle}
      />
    </View>
  )
}

export default memo(PhoneInput)
