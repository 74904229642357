import { StyleSheet } from 'react-native'
import { GlobalStyles } from '../../globalStyles'

export const CalendarListStyles = StyleSheet.create({
  cell: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    flex: 0.5,
  },
  deliverableCell: {
    alignSelf: 'stretch',
    flex: 2,
    paddingVertical: GlobalStyles.PADDING_SIZES.sm,
    borderLeftWidth: 4,
  },
  date: {
    borderRightWidth: 1,
    borderRightColor: GlobalStyles.SLATE_100,
  },
  name: {
    color: GlobalStyles.MONO_BLACK,
    lineHeight: GlobalStyles.FONT_SIZES.XTRA_LARGE,
  },
  description: {
    color: GlobalStyles.SLATE_700,
    lineHeight: GlobalStyles.FONT_SIZES.XTRA_LARGE,
  },
  clientCell: {
    justifyContent: 'center',
    alignSelf: 'stretch',
    flex: 1,
    color: GlobalStyles.SLATE_700,
  },
  status: {
    paddingHorizontal: GlobalStyles.PADDING_SIZES.sm,
    width: 'fit-content',
  },
})

export const StatusColors = {
  DEFAULT: GlobalStyles.STATUS_INFO,
  DRAFT: GlobalStyles.STATUS_YIELD,
  LIVE: GlobalStyles.STATUS_POSITIVE,
  COMPLETED: GlobalStyles.STATUS_INFO,
  CANCELLED: GlobalStyles.STATUS_NEGATIVE,
  BOOKED: GlobalStyles.STATUS_POSITIVE,
  OVERDUE: GlobalStyles.STATUS_NEGATIVE,
  NEW: GlobalStyles.STATUS_NEGATIVE,
  APPLIED: GlobalStyles.STATUS_POSITIVE,
  DECLINED: GlobalStyles.STATUS_NEGATIVE,
  REMOVED: GlobalStyles.STATUS_NEGATIVE,
  WITHDRAWN: GlobalStyles.STATUS_NEGATIVE,
  ARCHIVED: GlobalStyles.STATUS_INFO,
}
