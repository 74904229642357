import { BarElement, CategoryScale, ChartData, Chart as ChartJS, Legend, LinearScale, PluginChartOptions, Title, Tooltip } from 'chart.js'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'
import { Bar as ChartsBar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export interface BarChartProps {
  data: ChartData<'bar', number[], unknown>
  options?: _DeepPartialObject<PluginChartOptions<'bar'>>
}

const BarChart = ({ data: dataset, options: oops }: BarChartProps) => {
  return (
    <ChartsBar
      updateMode="resize"
      options={{
        indexAxis: 'y' as const,

        maintainAspectRatio: false,
        bar: {
          datasets: { barThickness: 35 },
        },
        elements: {
          bar: {
            borderWidth: 2,
            borderRadius: 20,
            backgroundColor: '#0B845C',

            // borderRadius(ctx: { dataset: { data: number[] }, dataIndex: number }) {
            //     const value = ctx.dataset.data[ctx.dataIndex] as number;
            //     return value < 5 ? 0 : 20;
            // },
          },
        },
        responsive: true,
        plugins: { legend: { display: false } },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
          },
        },
      }}
      data={dataset}
    />
  )
}

export default BarChart
