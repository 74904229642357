import { ButtonV2, GlobalStyles } from '@andromeda'
import { ButtonProps } from '@mui/material'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Menu, { MenuProps } from '@mui/material/Menu'
import * as React from 'react'

type DropDownV2Props = {
  menuIcon?: JSX.Element
  title?: string
  customComponent?: string | JSX.Element
  children?: JSX.Element[]
  menuProps?: Omit<MenuProps, 'open'>
  wrapperStyle?: React.CSSProperties
  disabled?: boolean
  textButtonVariant?: ButtonProps['variant']
  buttonStyles?: ButtonProps['sx']
}

export default function DropdownV2({
  menuIcon,
  title,
  children,
  menuProps,
  customComponent,
  wrapperStyle,
  disabled,
  textButtonVariant = 'outlined',
  buttonStyles,
}: DropDownV2Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const _renderComponent = () => {
    if (React.isValidElement(customComponent)) {
      return (
        <IconButton
          aria-label={title ?? 'toggle dropdown'}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          disabled={disabled}>
          {customComponent}
        </IconButton>
      )
    }
    if (title) {
      return (
        <ButtonV2
          aria-label={title ?? 'toggle dropdown'}
          style={{ width: '100%' }}
          sx={buttonStyles}
          variant={textButtonVariant}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={menuIcon}
          disabled={disabled}>
          {title}
        </ButtonV2>
      )
    }
    if (React.isValidElement(menuIcon)) {
      return (
        <IconButton
          aria-label={title ?? 'toggle dropdown'}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{ height: 40, width: 40, borderRadius: 8 }}
          disabled={disabled}>
          {menuIcon}
        </IconButton>
      )
    }
  }

  return (
    <div style={wrapperStyle}>
      {/* <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {menuIcon}
            </Button> */}
      {_renderComponent()}
      <Menu
        {...menuProps}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <div onClick={handleClose}>{children}</div>
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
  )
}
