import { GlobalStyles, SpaceType, StackV2, View } from '@andromeda'
import { ReactNode } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
interface SectionProps {
  icon?: JSX.Element
  title?: string | JSX.Element
  children?: ReactNode
  style?: StyleProp<ViewStyle>
  iconWrapperStyle?: StyleProp<ViewStyle>
  titleWrapperStyle?: StyleProp<ViewStyle>
  dashedWrapperStyle?: StyleProp<ViewStyle>
  iconTitleWrapperStyle?: StyleProp<ViewStyle>
  space?: SpaceType
  showContentDashedLine?: boolean
}

const Section = ({ icon, title, children, titleWrapperStyle, showContentDashedLine = true }: SectionProps) => {
  return (
    <StackV2 direction="column" className="w-full">
      <StackV2 flex={1} direction="row" height={'auto'} alignItems={'center'} style={{ marginBottom: 30 }}>
        {icon}
        {title}
      </StackV2>
      <StackV2 direction="row" alignItems={'center'}>
        {showContentDashedLine && (
          <div className={`md:visible xxs:invisible md:w-[55px] align-middle`}>
            <View
              style={[
                {
                  width: 2,
                  borderRightWidth: 2,
                  borderRightColor: GlobalStyles.SLATE_200,
                  borderStyle: 'dashed',
                  height: '100%',
                },
              ]}
            />
          </div>
        )}
        <View style={{ flex: 1 }}>{children}</View>
      </StackV2>
    </StackV2>
  )
}

export { Section }
