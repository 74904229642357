import { useContext, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { fetchDeliverableUsers, selectJobs, setDraftJobUserPayload, syncDeliverableUsers } from '@redux/reducers/jobsReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import AddUsersModalContent from './add-users-content'
import { hasPermission } from '@utils/functions/permission'
import { DeliverablePermission } from '@utils/enum/permissions'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { Types } from '@orbit'
import { toast } from 'react-toastify'
import { selectRoles } from '@redux/reducers/rolesReducer'
import { useRouter } from 'next/router'
import { AddUsersModalContentProps } from './AddUsersModal.context'

const AddUserToDeliverableModal = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()

  const { roles } = useAppSelector(selectRoles)
  const { viewedDeliverableUsers, viewedJob, syncDeliverableUsersStatus } = useAppSelector(selectJobs)

  const { options, closeModal } = useContext(ModalContext)
  const { workspace } = useAppSelector(selectWorkspace)
  const modalProps = options?.modalProps?.[ModalPages.ADD_USERS_TO_DELIVERABLE] || {}

  const guestRole = useMemo(() => (roles ? (roles.find((role) => role.name === 'guest') as Types.Roles.iRole) : undefined), [roles])

  const [newInvitedUsers, setNewInvitedUsers] = useState<Array<Types.Invite.iInviteCreatePayload>>([])

  /**
   * Permissions
   */
  const hasUpdatePermissions = hasPermission({
    permissions: viewedJob?.permissions,
    allowedPermissions: [DeliverablePermission.UPDATE],
    checkMethod: 'some',
  })

  const _saveUsers = (draftSelection: Types.Job.iDeliverableUsersResponse) => {
    if (!hasUpdatePermissions || syncDeliverableUsersStatus === 'loading') return false

    const jobSyncPayload: Types.Job.iJobSyncPayload = {
      owners: draftSelection.users.filter((user) => user.ownership_type === 'OWNER').map((user) => user.eid),
      viewers: draftSelection.users.filter((user) => user.ownership_type === 'VIEWER').map((user) => user.eid),
    }

    if (modalProps?.deliverableEid) {
      toast
        .promise(dispatch(syncDeliverableUsers({ eid: modalProps.deliverableEid, payload: jobSyncPayload })), {
          pending: 'Saving user selection...',
        })
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            if (modalProps.deliverableEid) {
              dispatch(fetchDeliverableUsers({ deliverableEid: modalProps.deliverableEid }))
              toast.success('Users saved successfully.', { autoClose: 500 })
            }
            setNewInvitedUsers([])
            closeModal(ModalPages.ADD_USERS_TO_DELIVERABLE)
          }
          if (response.meta.requestStatus === 'rejected') {
            toast.error('An error occured saving the users. Please try again.', { autoClose: 1000 })
          }
        })
    } else {
      dispatch(setDraftJobUserPayload(draftSelection))
      closeModal(ModalPages.ADD_USERS_TO_DELIVERABLE)
    }
  }

  const _handleUserInvite = (
    draftSelection: Types.Job.iDeliverableUsersResponse,
    inviteData: Types.Invite.iInviteCreatePayload,
    onFulfilled: (data: Types.Job.iDeliverableUsersResponse) => void
  ) => {
    if (guestRole && !inviteData?.roles?.some((roleId) => roleId === guestRole?.eid)) {
      inviteData.roles = [...(inviteData.roles ?? []), guestRole?.eid]
    }

    const jobSyncPayload = {
      owners: draftSelection.users.filter((user) => user.ownership_type === 'OWNER').map((user) => user.eid),
      viewers: draftSelection.users.filter((user) => user.ownership_type === 'VIEWER').map((user) => user.eid),
      invites: [inviteData],
    }

    if (modalProps.deliverableEid) {
      toast
        .promise(
          dispatch(
            syncDeliverableUsers({
              eid: modalProps.deliverableEid,
              payload: jobSyncPayload as Types.Job.iJobSyncPayload,
            })
          ),
          {
            pending: 'Creating invite..',
            error: 'Failed to select users.',
          }
        )
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            toast.success('Users saved successfully.')
            setNewInvitedUsers((prevValue) => [...prevValue, inviteData])
            onFulfilled && onFulfilled(response.payload)
          }
        })
    }
  }

  const _handleLeave: AddUsersModalContentProps['handleLeave'] = (user, drafts, setDrafts) => {
    setDrafts({
      ...drafts,
      users: drafts.users.filter((dUser) => dUser.eid !== user.eid),
    })
  }

  return (
    <AddUsersModalContent
      enableCreateUser
      newInvitedUsers={newInvitedUsers}
      requestId={modalProps?.deliverableEid || ''}
      modalContext={ModalPages.ADD_USERS_TO_DELIVERABLE}
      redirectRoute={`/${workspace}/directory/users`}
      currentSelectedUsers={viewedDeliverableUsers!}
      hasUpdatePermissions={hasUpdatePermissions}
      hasAddUserPermissions={hasUpdatePermissions}
      saveUsersStatus={syncDeliverableUsersStatus}
      showInviteRoleSelection={false}
      eventTypes={{
        invite: TrackingEventEnums.Invite.INVITE_USER_TO_DELIVERABLE,
        inviteConfirmed: TrackingEventEnums.Invite.INVITE_USER_TO_DELIVERABLE_CONFIRM,
      }}
      inviteAdditionalInfo={`
        You will receive an alert when this new user has completed the sign up process. 
        They will be added automatically to this deliverable as well.
      `}
      handleSaveUsers={_saveUsers}
      handleInvite={_handleUserInvite}
      handleLeave={_handleLeave}
    />
  )
}

export default AddUserToDeliverableModal
