import { StyleSheet } from 'react-native'
import { GlobalStyles } from '../globalStyles'

export const ActivityStyles = StyleSheet.create({
  main: {
    width: '100%',
    // height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: GlobalStyles.MONO_WHITE,
    // paddingLeft: 20,
    // paddingRight: 40,
  },
  outline: {
    flexGrow: 0,
    // width: 20,
    // borderRightWidth: 1,
    // borderRightColor: GlobalStyles.SLATE_300,
    borderStyle: 'dashed',
    // paddingTop: 25,
  },
  details: {
    width: '100%',
    alignItems: 'flex-start',
    // left: -20,
    // paddingTop: 25,
  },
  activityWrapper: {
    width: '100%',
    backgroundColor: 'none',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15,
    paddingTop: 5,
    borderTopWidth: 1,
    borderTopColor: GlobalStyles.SLATE_100,
  },
  bulletWrapper: {
    width: 8,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 0,
    paddingTop: 5,
  },
  activity: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  activityDetailsWrapper: {
    width: 'calc(100% - 8px)',
    marginLeft: '6px',
  },
  attributesWrapper: {
    width: '100%',
    paddingTop: 10,
    marginBottom: 10,
  },
  starsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  bullet: {
    width: 8,
    height: 8,
    borderRadius: 5,
    backgroundColor: GlobalStyles.PRIMARY_500,
    marginTop: 4,
  },
  activityDate: {
    width: 120,
    textAlign: 'right',
  },
  datetimeValue: {
    backgroundColor: GlobalStyles.SLATE_50,
    fontSize: 12,
    borderRadius: 4,
    paddingHorizontal: 8,
    marginTop: 3,
  },
  stringValue: {
    color: GlobalStyles.MONO_BLACK,
  },
})
