export const CURRENCY_LIST = [
  // { symbol: "Afghan Afghani", value: "AFA" },
  // { symbol: "Albanian Lek", value: "ALL" },
  // { symbol: "Algerian Dinar", value: "DZD" },
  // { symbol: "Angolan Kwanza", value: "AOA" },
  // { symbol: "Argentine Peso", value: "ARS" },
  // { symbol: "Armenian Dram", value: "AMD" },
  // { symbol: "Aruban Florin", value: "AWG" },
  { label: 'Australian Dollar', symbol: '\u0024', value: 'AUD' },
  // { symbol: "Azerbaijani Manat", value: "AZN" },
  // { symbol: "Bahamian Dollar", value: "BSD" },
  // { symbol: "Bahraini Dinar", value: "BHD" },
  // { symbol: "Bangladeshi Taka", value: "BDT" },
  // { symbol: "Barbadian Dollar", value: "BBD" },
  // { symbol: "Belarusian Ruble", value: "BYR" },
  // { symbol: "Belgian Franc", value: "BEF" },
  // { symbol: "Belize Dollar", value: "BZD" },
  // { symbol: "Bermudan Dollar", value: "BMD" },
  // { symbol: "Bhutanese Ngultrum", value: "BTN" },
  // { symbol: "Bitcoin", value: "BTC" },
  // { symbol: "Bolivian Boliviano", value: "BOB" },
  // { symbol: "Bosnia-Herzegovina Convertible Mark", value: "BAM" },
  // { symbol: "Botswanan Pula", value: "BWP" },
  // { symbol: "Brazilian Real", value: "BRL" },
  // { symbol: "British Pound Sterling", value: "GBP" },
  // { symbol: "Brunei Dollar", value: "BND" },
  // { symbol: "Bulgarian Lev", value: "BGN" },
  // { symbol: "Burundian Franc", value: "BIF" },
  // { symbol: "Cambodian Riel", value: "KHR" },
  { label: 'Canadian Dollar', symbol: '\u0024', value: 'CAD' },
  // { symbol: "Cape Verdean Escudo", value: "CVE" },
  // { symbol: "Cayman Islands Dollar", value: "KYD" },
  // { symbol: "CFA Franc BCEAO", value: "XOF" },
  // { symbol: "CFA Franc BEAC", value: "XAF" },
  // { symbol: "CFP Franc", value: "XPF" },
  // { symbol: "Chilean Peso", value: "CLP" },
  // { symbol: "Chilean Unit of Account", value: "CLF" },
  { label: 'Chinese Yuan', symbol: '\u00A5', value: 'CNY' },
  // { symbol: "Colombian Peso", value: "COP" },
  // { symbol: "Comorian Franc", value: "KMF" },
  // { symbol: "Congolese Franc", value: "CDF" },
  // { symbol: "Costa Rican Colón", value: "CRC" },
  // { symbol: "Croatian Kuna", value: "HRK" },
  // { symbol: "Cuban Convertible Peso", value: "CUC" },
  // { symbol: "Czech Republic Koruna", value: "CZK" },
  // { symbol: "Danish Krone", value: "DKK" },
  // { symbol: "Djiboutian Franc", value: "DJF" },
  // { symbol: "Dominican Peso", value: "DOP" },
  // { symbol: "East Caribbean Dollar", value: "XCD" },
  // { symbol: "Egyptian Pound", value: "EGP" },
  // { symbol: "Eritrean Nakfa", value: "ERN" },
  // { symbol: "Estonian Kroon", value: "EEK" },
  // { symbol: "Ethiopian Birr", value: "ETB" },
  { label: 'Euro', symbol: '\u20AC', value: 'EUR' },
  // { symbol: "Falkland Islands Pound", value: "FKP" },
  // { symbol: "Fijian Dollar", value: "FJD" },
  // { symbol: "Gambian Dalasi", value: "GMD" },
  // { symbol: "Georgian Lari", value: "GEL" },
  // { symbol: "German Mark", value: "DEM" },
  // { symbol: "Ghanaian Cedi", value: "GHS" },
  // { symbol: "Gibraltar Pound", value: "GIP" },
  // { symbol: "Greek Drachma", value: "GRD" },
  // { symbol: "Guatemalan Quetzal", value: "GTQ" },
  // { symbol: "Guinean Franc", value: "GNF" },
  // { symbol: "Guyanaese Dollar", value: "GYD" },
  // { symbol: "Haitian Gourde", value: "HTG" },
  // { symbol: "Honduran Lempira", value: "HNL" },
  // { symbol: "Hong Kong Dollar", value: "HKD" },
  // { symbol: "Hungarian Forint", value: "HUF" },
  // { symbol: "Icelandic Króna", value: "ISK" },
  // { symbol: "Indian Rupee", value: "INR" },
  // { symbol: "Indonesian Rupiah", value: "IDR" },
  // { symbol: "Iranian Rial", value: "IRR" },
  // { symbol: "Iraqi Dinar", value: "IQD" },
  // { symbol: "Israeli New Sheqel", value: "ILS" },
  // { symbol: "Italian Lira", value: "ITL" },
  // { symbol: "Jamaican Dollar", value: "JMD" },
  { label: 'Japanese Yen', symbol: '\u00A5', value: 'JPY' },
  // { symbol: "Jordanian Dinar", value: "JOD" },
  // { symbol: "Kazakhstani Tenge", value: "KZT" },
  // { symbol: "Kenyan Shilling", value: "KES" },
  // { symbol: "Kuwaiti Dinar", value: "KWD" },
  // { symbol: "Kyrgystani Som", value: "KGS" },
  // { symbol: "Laotian Kip", value: "LAK" },
  // { symbol: "Latvian Lats", value: "LVL" },
  // { symbol: "Lebanese Pound", value: "LBP" },
  // { symbol: "Lesotho Loti", value: "LSL" },
  // { symbol: "Liberian Dollar", value: "LRD" },
  // { symbol: "Libyan Dinar", value: "LYD" },
  // { symbol: "Litecoin", value: "LTC" },
  // { symbol: "Lithuanian Litas", value: "LTL" },
  // { symbol: "Macanese Pataca", value: "MOP" },
  // { symbol: "Macedonian Denar", value: "MKD" },
  // { symbol: "Malagasy Ariary", value: "MGA" },
  // { symbol: "Malawian Kwacha", value: "MWK" },
  // { symbol: "Malaysian Ringgit", value: "MYR" },
  // { symbol: "Maldivian Rufiyaa", value: "MVR" },
  // { symbol: "Mauritanian Ouguiya", value: "MRO" },
  // { symbol: "Mauritian Rupee", value: "MUR" },
  // { symbol: "Mexican Peso", value: "MXN" },
  // { symbol: "Moldovan Leu", value: "MDL" },
  // { symbol: "Mongolian Tugrik", value: "MNT" },
  // { symbol: "Moroccan Dirham", value: "MAD" },
  // { symbol: "Mozambican Metical", value: "MZM" },
  // { symbol: "Myanmar Kyat", value: "MMK" },
  // { symbol: "Namibian Dollar", value: "NAD" },
  // { symbol: "Nepalese Rupee", value: "NPR" },
  // { symbol: "Netherlands Antillean Guilder", value: "ANG" },
  // { symbol: "New Taiwan Dollar", value: "TWD" },
  { label: 'New Zealand Dollar', symbol: '\u0024', value: 'NZD' },
  // { symbol: "Nicaraguan Córdoba", value: "NIO" },
  // { symbol: "Nigerian Naira", value: "NGN" },
  // { symbol: "North Korean Won", value: "KPW" },
  // { symbol: "Norwegian Krone", value: "NOK" },
  // { symbol: "Omani Rial", value: "OMR" },
  // { symbol: "Pakistani Rupee", value: "PKR" },
  // { symbol: "Panamanian Balboa", value: "PAB" },
  // { symbol: "Papua New Guinean Kina", value: "PGK" },
  // { symbol: "Paraguayan Guarani", value: "PYG" },
  // { symbol: "Peruvian Nuevo Sol", value: "PEN" },
  { label: 'Philippine Peso', symbol: '\u20B1', value: 'PHP' },
  { label: 'Points', symbol: 'pts', value: 'points' },
  { label: 'Hours', symbol: 'hrs', value: 'hours' },
  { label: 'Polish Zloty', symbol: 'złoty', value: 'PLN' },
  // { symbol: "Qatari Rial", value: "QAR" },
  // { symbol: "Romanian Leu", value: "RON" },
  { label: 'Russian Ruble', symbol: '\u20BD', value: 'RUB' },
  // { symbol: "Rwandan Franc", value: "RWF" },
  // { symbol: "Salvadoran Colón", value: "SVC" },
  // { symbol: "Samoan Tala", value: "WST" },
  // { symbol: "São Tomé and Príncipe Dobra", value: "STD" },
  // { symbol: "Saudi Riyal", value: "SAR" },
  // { symbol: "Serbian Dinar", value: "RSD" },
  // { symbol: "Seychellois Rupee", value: "SCR" },
  // { symbol: "Sierra Leonean Leone", value: "SLL" },
  // { symbol: "Singapore Dollar", value: "SGD" },
  // { symbol: "Slovak Koruna", value: "SKK" },
  // { symbol: "Solomon Islands Dollar", value: "SBD" },
  // { symbol: "Somali Shilling", value: "SOS" },
  // { symbol: "South African Rand", value: "ZAR" },
  // { symbol: "South Korean Won", value: "KRW" },
  // { symbol: "South Sudanese Pound", value: "SSP" },
  // { symbol: "Special Drawing Rights", value: "XDR" },
  // { symbol: "Sri Lankan Rupee", value: "LKR" },
  // { symbol: "St. Helena Pound", value: "SHP" },
  // { symbol: "Sudanese Pound", value: "SDG" },
  // { symbol: "Surisymbolse Dollar", value: "SRD" },
  // { symbol: "Swazi Lilangeni", value: "SZL" },
  // { symbol: "Swedish Krona", value: "SEK" },
  // { symbol: "Swiss Franc", value: "CHF" },
  // { symbol: "Syrian Pound", value: "SYP" },
  // { symbol: "Tajikistani Somoni", value: "TJS" },
  // { symbol: "Tanzanian Shilling", value: "TZS" },
  // { symbol: "Thai Baht", value: "THB" },
  // { symbol: "Tongan Pa'anga", value: "TOP" },
  // { symbol: "Trinidad & Tobago Dollar", value: "TTD" },
  // { symbol: "Tunisian Dinar", value: "TND" },
  // { symbol: "Turkish Lira", value: "TRY" },
  // { symbol: "Turkmenistani Manat", value: "TMT" },
  // { symbol: "Ugandan Shilling", value: "UGX" },
  // { symbol: "Ukrainian Hryvnia", value: "UAH" },
  { label: 'United Arab Emirates Dirham', symbol: 'د. إ', value: 'AED' },
  // { symbol: "Uruguayan Peso", value: "UYU" },
  { label: 'US Dollar', symbol: '\u0024', value: 'USD' },
  // { symbol: "Uzbekistan Som", value: "UZS" },
  // { symbol: "Vanuatu Vatu", value: "VUV" },
  // { symbol: "Venezuelan BolÃvar", value: "VEF" },
  // { symbol: "Vietsymbolse Dong", value: "VND" },
  // { symbol: "Yemeni Rial", value: "YER" },
  // { symbol: "Zambian Kwacha", value: "ZMK" },
  // { symbol: "Zimbabwean dollar", value: "ZWL" }
]
