import { useEffect, useState } from 'react'

export const useInstalled = () => {
  const [installed, setInstalled] = useState(false)
  useEffect(() => {
    const UA = navigator.userAgent

    const IOS = UA.match(/iPhone|iPad|iPod/)
    const ANDROID = UA.match(/Android/)

    const PLATFORM = IOS ? 'ios' : ANDROID ? 'android' : 'unknown'

    const standalone = window.matchMedia('(display-mode: standalone)').matches

    const INSTALLED = !!(standalone || (IOS && !UA.match(/Safari/)))
    setInstalled(INSTALLED)
  }, [])

  return { installed, height: installed ? '100vh' : `${window.innerHeight}px` }
}
