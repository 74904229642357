import { StackV2 } from '@andromeda'
import { forwardRef, useState } from 'react'
import { TouchableOpacity as NativeTouchableOpacity } from 'react-native'
import { Loading } from '../Loading'
import { Text } from '../Text'
import { View } from '../View'
import { isWeb } from '../helpers'
import {
  ButtonDisabledStyles,
  ButtonDisabledTextStyles,
  ButtonHoverStyles,
  ButtonHoverTextStyles,
  ButtonSelectedStyles,
  ButtonSelectedTextStyles,
  ButtonStyles,
  ButtonTextStyles,
  ButtonTransparentStyles,
  ButtonTransparentTextStyles,
  ButtonVariantStyles,
  RoundedBorder,
} from './styles'
import { ButtonDefaults, ButtonProps } from './types'

const Button = (
  {
    children,
    style,
    textStyle,
    type = ButtonDefaults.TYPE,
    space = ButtonDefaults.SPACE,
    variant,
    disabled = false,
    transparent = false,
    selected = false,
    rounded = true,
    loading = false,
    loadingType = 'white',
    justify = 'center',
    ...props
  }: ButtonProps,
  ref?: any
) => {
  const [hovered, setHovered] = useState<boolean>(false)

  return (
    <View
      accessibilityRole="button"
      accessibilityLabel={props.text ?? props.accessibilityLabel}
      onMouseEnter={() => (props.hover && isWeb() ? setHovered(true) : null)}
      onMouseLeave={() => (props.hover && isWeb() ? setHovered(false) : null)}
      ref={ref}>
      <NativeTouchableOpacity
        style={[
          rounded && RoundedBorder,
          ButtonStyles[type],
          variant && ButtonVariantStyles[variant],
          selected && ButtonSelectedStyles[type],
          hovered && ButtonHoverStyles[type],
          disabled && ButtonDisabledStyles[type],
          transparent && ButtonTransparentStyles[type],
          style,
        ]}
        disabled={disabled || loading}
        {...props}>
        {loading ? (
          <StackV2 justifyContent="center">
            <Loading size={'small'} animating={true} type={loadingType} />
          </StackV2>
        ) : (
          <StackV2 direction={'row'} alignItems={'center'} justifyContent={justify} spacing={space}>
            {props.leftElement ?? null}
            {children ?? (
              <Text
                type="paragraph"
                style={[
                  ButtonTextStyles[type],
                  selected && ButtonSelectedTextStyles[type],
                  hovered && ButtonHoverTextStyles[type],
                  disabled && ButtonDisabledTextStyles[type],
                  transparent && ButtonTransparentTextStyles[type],
                  textStyle,
                ]}>
                {props.text}
              </Text>
            )}
            {props.rightElement ?? null}
          </StackV2>
        )}
      </NativeTouchableOpacity>
    </View>
  )
}

export default forwardRef(Button)
