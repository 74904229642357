import { GlobalStyles, Icon } from '@andromeda'

const iconStyles = { color: GlobalStyles.SLATE_500, size: 20 }

export const getIcon = ({ platform }: { platform: String }) => {
  switch (platform) {
    case 'facebook':
      return <Icon.Facebook {...iconStyles} />
    case 'youtube':
      return <Icon.Youtube {...iconStyles} />
    case 'instagram':
      return <Icon.Instagram {...iconStyles} />
    case 'twitter':
      return <Icon.Twitter {...iconStyles} />
    case 'tiktok':
      return <Icon.Music {...iconStyles} />
  }
}
