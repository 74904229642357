import { iPermissionProps } from '@utils/types/auth'
import React from 'react'
import { useAppSelector } from 'src/redux/hooks'
import { selectPermissions } from 'src/redux/reducers/authReducer'

const PermissionWrapper: React.FC<iPermissionProps> = ({
  permissions = [], // list of user's permissions
  allowedPermissions, // permissions needed to render children
  readonly = false, // if true, will disable all children
  checkMethod = 'every', // 'every' or 'some'
  mergePermissions = true, // if true, will merge user's permissions with permissions prop
  children,
  escape = false, // if true, will not check permissions
  fallbackComponent,
}: iPermissionProps) => {
  const userPermissions = useAppSelector(selectPermissions)
  if (escape) return <>{children}</>
  let allPermissions: Array<string> = []
  if (mergePermissions) {
    allPermissions = Array.from(new Set(userPermissions.concat(permissions)))
  } else {
    allPermissions = permissions ?? []
  }
  if (allPermissions == null) allPermissions = []
  if (!allowedPermissions) allowedPermissions = []
  let hasPermission = false
  if (checkMethod == 'every') {
    if (allowedPermissions.every((perm) => allPermissions.includes(perm))) hasPermission = true
  } else {
    if (allowedPermissions.some((perm) => allPermissions.includes(perm))) hasPermission = true
  }

  // console.log('allowedPermissions ', allowedPermissions)
  // console.log('hasPermission ', hasPermission)

  if (hasPermission) {
    return <>{children}</>
  } else if (readonly) {
    const childLen = React.Children.toArray(children).length
    if (childLen) {
      const readonlyChildren = React.Children.map(children, (child: React.ReactElement) => {
        return React.cloneElement(child, { disabled: true, pointerEvents: 'none' })
      })
      return <>{readonlyChildren}</>
    }
  } else if (fallbackComponent) {
    return <>{fallbackComponent}</>
  }
  return null
}

export default PermissionWrapper
