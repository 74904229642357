import { ActivityIndicatorProps, StyleProp, ViewStyle } from 'react-native'

export enum LoadingDefaults {
  TYPE = 'blue',
  SIZE = 'large',
}

export enum BarDefaults {
  SIZE = 'small',
  WIDTH = 130,
  PROGRESS_COUNT = 0,
  MAX_PROGRESS_COUNT = 1,
}

export type LoadingType = 'blue' | 'yellow' | 'slate' | 'white' | 'danger' | 'grey'

export type LoadingSize = 'small' | 'large'

export interface LoadingProps extends ActivityIndicatorProps {
  /**
   * The type of loading color to use
   */
  type?: LoadingType
  /**
   * The size of the loading indicator
   */
  size?: LoadingSize
  /**
   * Custom styles to add to the loading indicator
   */
  style?: StyleProp<ViewStyle>
}

export interface BarProps {
  /**
   * The type of loading color to use
   */
  type?: LoadingType

  /**
   * Size of the Loading indicator
   */
  size?: LoadingSize

  /**
   * Set bar to indeterminate state
   */
  indeterminate?: boolean

  /**
   * Width of the bar track
   */
  barWidth?: number | string

  /**
   * Style for the inner bar
   */
  innerBarStyle?: StyleProp<ViewStyle>
  /**
   * Custom styles
   */
  style?: StyleProp<ViewStyle>
  /**
   * Current progress count
   */
  progressCount: number | undefined
  /**
   * Maximum attainable progress
   */
  maxProgressCount: number | undefined
  accessibilityLabel?: string
}

export type LoadingComponentType = ((props: LoadingProps) => JSX.Element) & {
  Bar: React.MemoExoticComponent<(props: BarProps) => JSX.Element>
}
