import { API, Workspaces } from '../types'
import { Search } from '../types/search'
import { HttpClient } from './api.service'

export const searchService = {
  search: (queryParams: Search.iSearchPayload) => {
    return HttpClient.get<Search.iSearchPayload, Search.iSearchResponse>({
      endpoint: API.SearchEndpoints.SEARCH.replace(':query', queryParams.keyword)
        .replace(':page', queryParams.page ?? '')
        .replace(':per_page', queryParams.per_page ?? '')
        .concat(queryParams.models?.length ? queryParams.models.reduce((acc, model) => acc.concat(`&models[]=${model}`), '') : ''),
    })
  },
}
