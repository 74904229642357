import { Button, GlobalStyles, Icon, Stack, Text, View } from '@andromeda'
import { AppStyles } from '@styles'

export const NoMatchResult = ({ onPress }: { onPress: () => void }): JSX.Element => {
  return (
    <Stack
      direction={'column'}
      flex={1}
      height={'auto'}
      align={'center'}
      style={{
        padding: 80,
        backgroundColor: GlobalStyles.SLATE_50,
      }}
      space={'lg'}>
      <Text bold style={{ fontSize: AppStyles.FONT_SIZES.MODAL_TITLE, color: GlobalStyles.SLATE_700 }}>
        {'No users match your criteria'}
      </Text>
      <Text style={{ fontSize: GlobalStyles.FONT_SIZES.MEDIUM, color: GlobalStyles.SLATE_500 }}>
        {'To create a new user, please visit the workspace directory'}
      </Text>
      <Button
        type={'primary'}
        text={'Go to Directory'}
        textStyle={{ fontWeight: '500', fontSize: GlobalStyles.FONT_SIZES.BASE }}
        rightElement={
          <View>
            <Icon.ChevronRight color={GlobalStyles.MONO_WHITE} size={GlobalStyles.FONT_SIZES.BASE} />
          </View>
        }
        style={{ marginTop: AppStyles.MARGIN_SIZES.MD }}
        onPress={() => onPress()}
      />
    </Stack>
  )
}
