import { ModalHeader } from '@components'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { Orbit } from '@orbit'
import { useAppDispatch } from '@redux/hooks'
import { logout } from '@redux/reducers/authReducer'
import { useMutation } from '@tanstack/react-query'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

export const DeleteAccount = () => {
  const dispatch = useAppDispatch()

  const { closeModal } = useContext(ModalContext)

  const [confirmText, setConfirmText] = useState('')

  const { mutate, isPending } = useMutation({
    mutationKey: ['deleteAccount'],
    mutationFn: async () => {
      const response = await Orbit.Services.userService.deleteUser()
      return response.data
    },
    onMutate() {
      toast.loading('Deleting account...', { toastId: 'deleteAccount', autoClose: 5000 })
    },
    onSuccess() {
      closeModal(ModalPages.DELETE_ACCOUNT)
      dispatch(logout())
      localStorage.removeItem('persist:workspace')
    },
    onError(e) {
      toast.update('deleteAccount', {
        // @ts-expect-error
        render: e?.response?.data?.message || 'Error deleting account',
        type: 'error',
        autoClose: 5000,
        isLoading: false,
      })
    },
  })

  return (
    <Stack minWidth={{ sm: '500px' }} maxWidth="500px">
      <ModalHeader
        modalContext={ModalPages.DELETE_ACCOUNT}
        modalTitle="Delete Account"
        onCancel={() => closeModal(ModalPages.DELETE_ACCOUNT)}
      />
      <Stack className="dialog-content" flexWrap="wrap" rowGap="8px">
        <Typography>Are you sure you want to delete your account? </Typography>
        <Typography>
          All your personal data and relationships will be removed. Any data belonging to the workspace will be masked and marked as by{' '}
          <strong>Deleted User</strong>.{' '}
        </Typography>
        <Typography>This is a final action and cannot be undone.</Typography>

        <Stack direction="row">
          <Typography>Type&nbsp;</Typography>
          <Typography color="red">{`"Delete my account”`}</Typography>
          <Typography>&nbsp;to confirm</Typography>
        </Stack>
        <TextField
          onChange={(e) => setConfirmText(e.target.value)}
          inputProps={{
            'aria-label': 'delete account',
          }}
        />
      </Stack>
      <Stack className="dialog-footer" justifyContent="end" direction="row" columnGap="8px">
        <Button variant="outlined" disabled={isPending} onClick={() => closeModal(ModalPages.DELETE_ACCOUNT)}>
          Cancel
        </Button>
        <Button variant="contained" disabled={isPending || confirmText.toLowerCase() !== 'delete my account'} onClick={() => mutate()}>
          Confirm
        </Button>
      </Stack>
    </Stack>
  )
}
