import { Button, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import { OVERFLOW_TEXT } from '../job-card/job-card'
import { Avatar, AvatarGroup, GlobalStyles, Icon } from '@andromeda'
import { Types } from '@orbit/index'
import { QuickLook } from '../QuickLook'
import { breakpoints } from 'src/andromeda/helpers/theme'

export const StartEndDate = ({
  deliverable_end_datetime,
  deliverable_start_datetime,
  selectedDeliverableEid,
}: {
  deliverable_start_datetime: string
  deliverable_end_datetime: string
  selectedDeliverableEid?: string
}) => (
  <Typography
    {...OVERFLOW_TEXT}
    whiteSpace={{ xxs: 'normal', md: selectedDeliverableEid ? 'normal' : 'nowrap' }}
    variant="subtitle2"
    sx={{ display: { sm: 'none' } }}
    fontSize={'12px'}
    color={GlobalStyles.SLATE_700}>
    {`${deliverable_start_datetime} - ${deliverable_end_datetime}`}
  </Typography>
)

export const AvatarEl = ({
  isExpanded,
  users,
  label,
}: {
  isExpanded?: boolean
  users?: Array<Types.User.iUser | Types.Talent.iTalent> | null
  label: string
}) => {
  const isMobile = useMediaQuery(`(max-width:${breakpoints.values.md}px)`)

  return users?.length ? (
    <QuickLook
      data={{
        [label]: users.map((user) => ({
          eid: user.eid,
          name: (user as Types.Talent.iTalent).display_name || (user as Types.User.iUser)['name'],
          profile_image: user.profile_image?.full_url,
        })),
      }}>
      <Button variant="text" size="small" sx={{ padding: 0, width: 'fit-content' }} aria-label="toggle deliverable panel status tab">
        <AvatarGroup offset={isExpanded || isMobile ? 1 : 3} extraAvatarProps={{ size: 'xs' }}>
          {users?.map((user) => {
            if (!user) return <></>
            return (
              <Avatar
                key={user.eid}
                size="xs"
                image={user.profile_image?.full_url ?? undefined}
                placeHolder={<Icon.User size={GlobalStyles.AVATAR_SIZES.XS - 5} color={GlobalStyles.SLATE_200} strokeWidth={1} />}
                style={{
                  borderWidth: 2,
                  borderColor: GlobalStyles.SLATE_200,
                }}
              />
            )
          })}
        </AvatarGroup>
      </Button>
    </QuickLook>
  ) : (
    <></>
  )
}

export const ChatEl = ({ onClickDeliverable }: { onClickDeliverable: (e: any) => void }) => (
  <Stack direction="row" justifyContent="space-between" onClick={onClickDeliverable}>
    <IconButton
      size="small"
      sx={{ minWidth: 15, color: GlobalStyles.SLATE_700, padding: 0 }}
      aria-label="toggle deliverable panel comments tab">
      <Icon.MessageSquare size={20} color={GlobalStyles.PRIMARY_500} />
    </IconButton>
  </Stack>
)

export const StatusDeliverableContainer = ({ children }: { children: JSX.Element[] }) => (
  <Stack direction="row" alignItems="center" columnGap="6px">
    {children}
  </Stack>
)
