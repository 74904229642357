import React, { ChangeEventHandler, MouseEventHandler, useState } from 'react'
import { useSlateStatic } from 'slate-react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material'
import { isLinkActive, unwrapLink, wrapLink } from './utils'
import { GlobalStyles, Icon } from '@andromeda'
import { Editor, Element, Transforms } from 'slate'
import { AppStyles } from '@styles'

type RichEditorLinkDialogProps = {
  text?: string
  link?: string
  isOpen: boolean
  onClose: () => void
}

const RichEditorLinkDialog = ({ link: pLink, text: pText, isOpen, onClose }: RichEditorLinkDialogProps) => {
  const editor = useSlateStatic()
  const [link, setLink] = useState(pLink || '')
  const [text, setText] = useState(pText || '')

  const handleSubmit: MouseEventHandler = () => {
    if (link) {
      if (isLinkActive(editor)) {
        const [linkNodeEntry] = Array.from(
          Editor.nodes(editor, {
            match: (n) => Element.isElement(n) && n.type === 'link',
          })
        )

        if (linkNodeEntry) {
          const [_linkNode, path] = linkNodeEntry

          // Update the URL
          Transforms.setNodes(editor, { link }, { at: path })

          // Update the text within the link node
          Transforms.insertText(editor, text || link, { at: path.concat([0]) })
        }
      } else {
        wrapLink(editor, link, text || link)
      }

      handleClose()
    }
  }

  const handleUrlChange: ChangeEventHandler = (event) => {
    setLink((event.target as HTMLInputElement)?.value)
  }

  const handleTextChange: ChangeEventHandler = (event) => {
    setText((event.target as HTMLInputElement)?.value)
  }

  const handleClose = () => {
    setText('')
    setLink('')
    onClose && onClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} style={{ width: '100%' }} maxWidth="sm">
      <DialogTitle display="flex" alignItems="center" justifyContent="space-between" columnGap="8px">
        <Typography variant="h2" style={{ fontSize: '24px', fontWeight: 500 }}>
          Edit Link
        </Typography>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{ height: 40, width: 40, borderRadius: 8, padding: 0 }}
          sx={{ borderColor: GlobalStyles.SLATE_100 }}
          aria-label="Close Dialog">
          <Icon.X strokeWidth={2} size={AppStyles.ICON_SIZES.SM} color={GlobalStyles.PRIMARY_500} />
        </Button>
      </DialogTitle>

      <DialogContent style={{ paddingTop: GlobalStyles.PADDING_SIZES.xxs + 'px' }}>
        <Stack rowGap={GlobalStyles.PADDING_SIZES.sm + 'px'}>
          <Stack direction="row" alignItems="center" columnGap={GlobalStyles.PADDING_SIZES.sm + 'px'}>
            <Typography variant="body2" minWidth={'100px'}>
              URL
            </Typography>
            <TextField variant="outlined" type="text" fullWidth value={link} onChange={handleUrlChange} />
          </Stack>

          <Stack direction="row" alignItems="center" columnGap={GlobalStyles.PADDING_SIZES.sm + 'px'}>
            <Typography variant="body2" minWidth={'100px'}>
              Display text (optional)
            </Typography>
            <TextField variant="outlined" type="text" fullWidth value={text} onChange={handleTextChange} />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button type="button" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" disabled={!link.length} style={{ minWidth: '80px' }} onClick={handleSubmit}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RichEditorLinkDialog
