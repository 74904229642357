import { Button, Stack, Text } from '@andromeda'
import { useAppSelector } from '@redux/hooks'
import { selectRecovery } from '@redux/reducers/passwordRecovery'
import { AppStyles } from '@styles'
import { ModalContext } from '@utils/context/Modal.context'
import { useContext } from 'react'

const EmailResetConfirmation = () => {
  const { toggleModal } = useContext(ModalContext)
  const { email } = useAppSelector(selectRecovery)

  return (
    <Stack
      direction="column"
      style={{ paddingHorizontal: AppStyles.MODAL_PADDING_HORIZONTAL, paddingBottom: AppStyles.MODAL_PADDING_HORIZONTAL }}
      align="flex-start">
      <Text type={'paragraph'}>If your email is correct your will receive an email from us with a password reset link.</Text>
      <Text type={'paragraph'}>Email sent to: {email}</Text>
      <Stack height={'auto'} justify={'flex-end'}>
        <Button
          onPress={() => {
            toggleModal('resetPasswordForm')
          }}
          style={{ borderRadius: 6 }}
          type="primary"
          text="OK"
        />
      </Stack>
    </Stack>
  )
}

export { EmailResetConfirmation }
