import { Card, GlobalStyles, Icon } from '@andromeda'

const JobNotFound = () => {
  return (
    <Card.Error
      errorIcon={<Icon.AlertCircle size={150} strokeWidth={1} color={GlobalStyles.SLATE_300} />}
      heading="You don't have permission to view this Job, or it no longer exists"
      subHeading="Please contact an Administrator if this is an error."
    />
  )
}

export default JobNotFound
