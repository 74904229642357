import { GlobalStyles, Icon } from '@andromeda'
import { IconButton, Menu, MenuItem, SxProps, Theme } from '@mui/material'
import { MouseEvent, MouseEventHandler } from 'react'

export const ActionMenu = ({
  handleClickJobMenu,
  jobMenuAnchorEl,
  isJobMenuOpen,
  handleCloseJobMenu,
  goToRequestById,
  eid,
  sx,
  ...props
}: {
  handleClickJobMenu: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void
  handleCloseJobMenu: MouseEventHandler<HTMLLIElement>
  jobMenuAnchorEl: HTMLElement | null
  isJobMenuOpen: boolean
  goToRequestById: (requestId: string) => void
  eid: string
  sx?: SxProps<Theme>
}) => (
  <>
    <IconButton
      {...props}
      size="small"
      sx={{ minWidth: { xs: 16 }, color: GlobalStyles.SLATE_800, padding: 0, ...sx }}
      onClick={handleClickJobMenu}>
      <Icon.MoreVertical width={12} height={12} />
    </IconButton>
    <Menu
      id="job-menu"
      anchorEl={jobMenuAnchorEl}
      open={isJobMenuOpen}
      onClose={handleCloseJobMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}>
      <MenuItem
        onClick={(e) => {
          handleCloseJobMenu(e)
          goToRequestById(eid)
        }}>
        View / Edit
      </MenuItem>
      <MenuItem onClick={handleCloseJobMenu}>Duplicate</MenuItem>
      <MenuItem onClick={handleCloseJobMenu}>Archive</MenuItem>
    </Menu>
  </>
)
