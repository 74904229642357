import { useEffect, useState } from 'react'

export const useDocHeight = () => {
  const [innerHeight, setInnerHeight] = useState('100vh')

  const setDocHeight = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`)
    setInnerHeight(`${window.innerHeight}px`)
  }

  useEffect(() => {
    setDocHeight()
    setInnerHeight(`${window.innerHeight}px`)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', setDocHeight)

    return () => {
      window.removeEventListener('resize', setDocHeight)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('orientationchange', setDocHeight)

    return () => {
      window.removeEventListener('orientationchange', setDocHeight)
    }
  }, [])

  return { innerHeight }
}
