import { Stack } from '../Stack'
import { GlobalStyles } from '../globalStyles'
import { AlertCardProps, AlertDefaults } from './types'
import { Text } from '../Text'
import { View } from '../View'
import { Button } from '../Button'
import { Icon } from '../Icon'

const CardAlert = ({
  title,
  text,
  confirmBtnText,
  type = AlertDefaults.type,
  list,
  listItems,
  onPress,
  onCancel,
  onExit,
  ...stackProps
}: AlertCardProps) => {
  const _handleSubmit = () => {
    onPress()
  }

  const _handleCancel = () => {
    onCancel()
  }

  const _handleExit = () => {
    if (onExit) {
      onExit()
    } else {
      onCancel()
    }
  }

  return (
    <Stack
      rounded
      height="auto"
      direction="column"
      space="sm"
      align="flex-start"
      style={{
        backgroundColor: GlobalStyles.MONO_WHITE,
        borderWidth: 2,
        borderColor: GlobalStyles.SLATE_100,
        paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
        paddingVertical: GlobalStyles.PADDING_SIZES.md,
      }}
      {...stackProps}>
      <Stack align="center" justify="space-between" space="none">
        <Text type="heading" size="sm" bold>
          {title}
        </Text>

        <Button
          type="secondary"
          onPress={_handleExit}
          style={{ backgroundColor: GlobalStyles.SLATE_50, borderRadius: GlobalStyles.MAX_BORDER_RADIUS }}>
          <View>
            <Icon.X size={GlobalStyles.FONT_SIZES.MEDIUM} style={{ color: GlobalStyles.SLATE_700 }} />
          </View>
        </Button>
      </Stack>

      <Text type="paragraph">{text}</Text>

      {list && (
        <Stack direction="column" align="flex-start" space="sm">
          <Text type="paragraph" size="sm">
            This will:
          </Text>

          {listItems?.map((item, index) => (
            <Stack key={index} direction="row" align="center" justify="flex-start" space="2xs">
              <View>
                <Icon.Check size={GlobalStyles.FONT_SIZES.XTRA_LARGE} style={{ color: GlobalStyles.SLATE_300 }} />
              </View>

              <Text type="paragraph" size="sm">
                {item}
              </Text>
            </Stack>
          ))}
        </Stack>
      )}

      <Stack
        direction="row"
        justify="flex-end"
        style={{
          paddingTop: GlobalStyles.PADDING_SIZES.sm,
          borderTopWidth: GlobalStyles.BORDER_WIDTH,
          borderColor: GlobalStyles.SLATE_100,
          borderRadius: 0,
        }}>
        <Button type="secondary" onPress={_handleCancel} text="Cancel" />

        <Button type="primary" text={confirmBtnText} variant={type} onPress={_handleSubmit} />
      </Stack>
    </Stack>
  )
}

export default CardAlert
