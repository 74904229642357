import { ButtonV2, GlobalStyles, Loading, StackV2, Status, Text } from '@andromeda'
import { Types } from '@orbit'
import { capitalize } from '@utils/functions/helperFunctions'
import { ScrollView, StyleProp, ViewStyle } from 'react-native'
import { StatusDefaults } from 'src/andromeda/Status/types'
import { JobProgressProps } from '.'
import { getDashedDate } from '../../helpers/date'
import { Skeleton, Stack, Typography } from '@mui/material'

interface JobProgressListProps extends Pick<JobProgressProps, 'lazyLoad' | 'onClickItem'> {
  items: Array<Types.Job.iJobList>
  style: StyleProp<ViewStyle>
  isLoading: boolean
  filteredStatus?: string
}

const JobProgressList = ({ items, style, filteredStatus, isLoading, onClickItem = () => {}, ...props }: JobProgressListProps) => {
  if (isLoading) {
    return (
      <>
        {new Array(5).fill('').map((_, index) => (
          <Stack key={`skeleton-${index}`} paddingY="12px" borderTop={`1px solid ${GlobalStyles.SLATE_100}`}>
            <Stack key={`skeleton-${index}`} paddingY="17px" paddingRight="12px" columnGap="24px" direction="row">
              <Skeleton variant="rounded" width="90%" height={20} />
              <Skeleton variant="rounded" width="10%" height={20} />
            </Stack>
          </Stack>
        ))}
      </>
    )
  }

  return (
    <ScrollView
      style={[{ width: '100%' }, style]}
      onScroll={({ nativeEvent }) => {
        const { layoutMeasurement, contentOffset, contentSize } = nativeEvent
        if (layoutMeasurement.height + contentOffset.y + 5 >= contentSize.height) {
          props.lazyLoad && props.lazyLoad()
        }
      }}
      scrollEventThrottle={1000}>
      {items.length ? (
        items.map((item, index) => {
          const { name, organisation, numberOfDeliverables, numberOfDeliverablesCompleted, end_datetime, status } = item
          let isDue = false
          if (end_datetime) {
            const [day, month, year] = end_datetime.split('/')
            isDue = new Date(Date.now()) >= new Date(`${month}/${day}/${year}`)
          }
          return (
            <ButtonV2
              key={index}
              className="w-full"
              style={{
                textTransform: 'none',
                borderBottomWidth: 1,
                borderBottomColor: GlobalStyles.SLATE_100,
                borderBottomStyle: 'solid',
                paddingBottom: 15,
              }}
              onClick={() => {
                onClickItem(item)
              }}>
              <StackV2
                key={index}
                direction={'row'}
                justifyContent={'space-between'}
                className="w-full"
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: GlobalStyles.SLATE_100,
                }}>
                <StackV2 style={{ flex: 3 }}>
                  <Text
                    style={{
                      textAlign: 'left',
                      color: GlobalStyles.MONO_BLACK,
                      lineHeight: 24,
                    }}
                    bold>
                    {name}
                  </Text>
                  <Text size="sm" style={{ color: GlobalStyles.SLATE_700, lineHeight: 24, textAlign: 'left' }}>
                    {organisation?.name}
                  </Text>

                  <StackV2 direction={'row'}>
                    <StackV2 spacing={1} style={{ flexWrap: 'wrap' }} direction={'row'} flex={1}>
                      <Text
                        size="sm"
                        style={{
                          color: GlobalStyles.MONO_BLACK,
                        }}>{`${numberOfDeliverablesCompleted} / ${numberOfDeliverables}`}</Text>
                      <Text size="sm" style={{ color: GlobalStyles.SLATE_500 }}>
                        Deliverables complete
                      </Text>

                      <Loading.Bar
                        type={isDue ? 'danger' : undefined}
                        progressCount={numberOfDeliverablesCompleted}
                        maxProgressCount={numberOfDeliverables}
                        accessibilityLabel={`${name} progress`}
                      />
                    </StackV2>
                  </StackV2>
                </StackV2>
                <StackV2 flex={2} justifyContent={'center'} alignItems={'flex-end'}>
                  <Status
                    textStyle={{
                      fontSize: GlobalStyles.FONT_SIZES.TINY,
                      fontWeight: GlobalStyles.FONT_WEIGHT_MEDIUM,
                    }}
                    variant="rounded"
                    type={status.toUpperCase() as StatusDefaults.TYPE}
                    text={capitalize(status)}
                  />
                  <StackV2 direction={'row'} width={'auto'} spacing={1}>
                    <Text
                      size="sm"
                      style={{
                        color: isDue ? GlobalStyles.STATUS_NEGATIVE : GlobalStyles.MONO_BLACK,
                      }}>
                      Due:
                    </Text>
                    <Text
                      size="sm"
                      style={{
                        color: isDue ? GlobalStyles.STATUS_NEGATIVE : GlobalStyles.SLATE_700,
                      }}>
                      {end_datetime ? getDashedDate(end_datetime) : ''}
                    </Text>
                  </StackV2>
                </StackV2>
              </StackV2>
            </ButtonV2>
          )
        })
      ) : (
        <Stack>
          <Stack paddingY="12px" alignItems="center" borderTop={`1px solid ${GlobalStyles.SLATE_100}`}>
            <Typography fontSize="14px" fontWeight="500" color={GlobalStyles.MONO_BLACK}>
              {filteredStatus !== ''
                ? `You currently have no jobs that are ${filteredStatus?.toLowerCase()}`
                : 'You currently have no jobs'}
            </Typography>
          </Stack>
        </Stack>
      )}
    </ScrollView>
  )
}

export default JobProgressList
