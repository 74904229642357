import { GlobalStyles, Icon, Input, Stack, Text, View } from '@andromeda'
import { ModalFooter, ModalHeader } from '@components'
import { AppStyles } from '@styles'
import { ModalContext } from '@utils/context/Modal.context'
import { useScreenDimensions } from '@utils/hooks/dimensions'
import { validateEmail } from '@utils/index'
import { useContext, useState } from 'react'

interface iProposal {
  organisation: string
  name: string
  email: string
  comments: string
}

const SendProposal = () => {
  const { toggleModal } = useContext(ModalContext)
  const { screenSize } = useScreenDimensions()

  const [proposal, setProposal] = useState<iProposal>({
    organisation: '',
    name: '',
    email: '',
    comments: '',
  })
  const [valid, setValid] = useState<boolean>(false)
  const [textAreaHeight, setTextAreaHeight] = useState<number>(20)
  const [emailError, setEmailError] = useState<string>('')

  const _handleSend = () => {
    toggleModal('sentProposals')
  }

  const _updateProposal = (key: string, value: string) => {
    setProposal({
      ...proposal,
      [key]: value,
    })

    if (key === 'email') {
      const invalidEmail = value === '' || !validateEmail(value)
      if (invalidEmail) setEmailError('Invalid email')
      else setEmailError('')
    }

    if (proposal.name !== '' && proposal.email !== '') setValid(true)
  }

  return (
    <Stack rounded height="auto" direction="column" space="2xs" align="flex-start" style={{ backgroundColor: GlobalStyles.MONO_WHITE }}>
      <ModalHeader
        leftIcon={
          <Text>
            <Icon.Layers strokeWidth={1.5} size={32} color={GlobalStyles.MONO_BLACK} />
          </Text>
        }
        modalTitle="Send Proposal"
        rightText="Adidas Predator Ambassador"
        modalContext={'sendProposal'}
      />

      <Stack
        height="auto"
        direction="column"
        align="flex-start"
        style={{
          paddingVertical: AppStyles.MODAL_PADDING_VERTICAL,
          paddingHorizontal: AppStyles.MODAL_PADDING_HORIZONTAL,
        }}>
        <Stack direction="column" space="none" border rounded style={{ width: '100%', backgroundColor: GlobalStyles.MONO_WHITE }}>
          <Stack
            direction={!screenSize.md ? 'column' : 'row'}
            space={'none'}
            divider={screenSize.md}
            childrenStyle={{
              borderColor: GlobalStyles.SLATE_100,
              paddingHorizontal: screenSize.md ? GlobalStyles.PADDING_HORIZONTAL : 0,
            }}
            style={{ paddingHorizontal: !screenSize.md ? GlobalStyles.PADDING_HORIZONTAL : 0 }}>
            <Stack flex={1} justify="space-between" style={{ paddingVertical: !screenSize.md ? 12 : 0 }}>
              <Text type="paragraph">Organisation</Text>
            </Stack>
            <View style={[screenSize.md && { flex: 3 }, !screenSize.md && { width: '100%', borderTopWidth: 1, padding: 0 }]}>
              <Input
                size={'base'}
                placeholder={'Company or organisation name'}
                style={[{ borderColor: GlobalStyles.COLOR_TRANSPARENT }, !screenSize.md && { paddingHorizontal: 0 }]}
                wrapperStyle={{ padding: 0 }}
                value={proposal.organisation}
                onChange={(e: any) => _updateProposal('organisation', e.target.value)}
              />
            </View>
          </Stack>

          <Stack
            direction={!screenSize.md ? 'column' : 'row'}
            space={'none'}
            divider={screenSize.md}
            childrenStyle={{
              borderColor: GlobalStyles.SLATE_100,
              paddingHorizontal: screenSize.md ? GlobalStyles.PADDING_HORIZONTAL : 0,
            }}
            style={{ paddingHorizontal: !screenSize.md ? GlobalStyles.PADDING_HORIZONTAL : 0 }}>
            <Stack flex={1} justify="space-between" style={{ paddingVertical: !screenSize.md ? 12 : 0 }}>
              <Text type="paragraph">Client Name*</Text>
            </Stack>
            <View style={[screenSize.md && { flex: 3 }, !screenSize.md && { width: '100%', borderTopWidth: 1, padding: 0 }]}>
              <Input
                size={'base'}
                placeholder={'Jane Doe'}
                style={[{ borderColor: GlobalStyles.COLOR_TRANSPARENT }, !screenSize.md && { paddingHorizontal: 0 }]}
                wrapperStyle={{ padding: 0 }}
                value={proposal.name}
                onChange={(e: any) => _updateProposal('name', e.target.value)}
              />
            </View>
          </Stack>

          <Stack
            direction={!screenSize.md ? 'column' : 'row'}
            space={'none'}
            divider={screenSize.md}
            childrenStyle={{
              borderColor: GlobalStyles.SLATE_100,
              paddingHorizontal: screenSize.md ? GlobalStyles.PADDING_HORIZONTAL : 0,
            }}
            style={{ paddingHorizontal: !screenSize.md ? GlobalStyles.PADDING_HORIZONTAL : 0 }}>
            <Stack flex={1} justify="space-between" style={{ paddingVertical: !screenSize.md ? 12 : 0 }}>
              <Text type="paragraph">Client Email*</Text>
            </Stack>
            <View style={[screenSize.md && { flex: 3 }, !screenSize.md && { width: '100%', borderTopWidth: 1, padding: 0 }]}>
              <Input
                size={'base'}
                placeholder={'client@email.com'}
                style={[{ borderColor: GlobalStyles.COLOR_TRANSPARENT }, !screenSize.md && { paddingHorizontal: 0 }]}
                wrapperStyle={{ padding: 0 }}
                value={proposal.email}
                onChange={(e: any) => _updateProposal('email', e.target.value)}
                errorMessage={emailError}
              />
            </View>
          </Stack>

          <Stack
            direction={!screenSize.md ? 'column' : 'row'}
            space={'none'}
            divider={screenSize.md}
            childrenStyle={{
              borderColor: GlobalStyles.SLATE_100,
              paddingHorizontal: screenSize.md ? GlobalStyles.PADDING_HORIZONTAL : 0,
            }}
            style={{ paddingHorizontal: !screenSize.md ? GlobalStyles.PADDING_HORIZONTAL : 0 }}>
            <Stack flex={1} justify="space-between" style={{ paddingVertical: !screenSize.md ? 12 : 0 }}>
              <Text type="paragraph">Comments</Text>
            </Stack>
            <View style={[screenSize.md && { flex: 3 }, !screenSize.md && { width: '100%', borderTopWidth: 1, padding: 0 }]}>
              <Input
                multiline
                placeholder={'Add an optional comment for the recipient(s)'}
                style={[
                  { borderColor: GlobalStyles.COLOR_TRANSPARENT, height: textAreaHeight },
                  !screenSize.md && { paddingHorizontal: 0 },
                ]}
                wrapperStyle={{ padding: 0 }}
                value={proposal.comments}
                onChange={(e: any) => _updateProposal('comments', e.target.value)}
                onContentSizeChange={(e) => setTextAreaHeight(e.nativeEvent.contentSize.height)}
              />
            </View>
          </Stack>
        </Stack>
      </Stack>

      <ModalFooter
        backgroundColor={GlobalStyles.MONO_WHITE}
        buttonText={'Send'}
        onButtonPress={_handleSend}
        buttonStyles={{
          paddingHorizontal: 20,
        }}
        disabled={!valid}
      />
    </Stack>
  )
}

export { SendProposal }
