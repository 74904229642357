import { GlobalStyles } from '@andromeda'
import { ModalHeader } from '@components'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext, useState } from 'react'

const ConfirmSavePublishModal = () => {
  const { closeModal, options } = useContext(ModalContext)
  const [isLoading, setIsLoading] = useState(false)
  const props = options?.modalProps?.confimSavePublish

  const _handleSaveAndPublish = () => {
    if (props && props.saveAndPublish) {
      props.saveAndPublish({
        isLoading,
        setIsLoading,
        close: () => closeModal(ModalPages.CONFIRM_SAVE_PUBLISH),
      })
    }
  }

  return (
    <Stack className="dialog-container" style={{ backgroundColor: GlobalStyles.MONO_WHITE }} width={{ xxs: '100%', sm: '500px' }}>
      <ModalHeader modalTitle="Publish changes?" modalContext={ModalPages.CONFIRM_SAVE_PUBLISH} />
      <Stack className="dialog-content" rowGap="8px">
        <Typography>
          {'You have unsaved changes. By publishing, any unsaved changes will be saved. Are you sure you want to proceed?'}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="end" columnGap="8px" className="dialog-footer">
        <Button variant="outlined" disabled={isLoading} onClick={() => closeModal(ModalPages.CONFIRM_SAVE_PUBLISH)}>
          Cancel
        </Button>
        <Button variant="contained" onClick={_handleSaveAndPublish} disabled={isLoading}>
          {isLoading ? <CircularProgress size={12} style={{ marginRight: `${GlobalStyles.MARGIN_SIZES['2xs']}px` }} /> : undefined}
          {isLoading ? 'Publishing...' : 'Save and Publish'}
        </Button>
      </Stack>
    </Stack>
  )
}

export default ConfirmSavePublishModal
