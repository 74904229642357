import { Avatar, GlobalStyles, Icon, Stack, Text } from '@andromeda'
import { AppStyles } from '@styles'

const ProfilePicture = ({
  avatar,
  description = 'A picture helps people recognise you and lets you know when you’re signed in to your account.',
}: {
  avatar: string | null
  description?: string
}) => {
  return (
    <Stack
      direction="column"
      space="lg"
      style={{ paddingHorizontal: AppStyles.MODAL_PADDING_HORIZONTAL, paddingBottom: AppStyles.MODAL_PADDING_HORIZONTAL }}
      align="flex-start">
      <Text type="paragraph" size="base">
        {description}
      </Text>
      <Stack direction="column">
        <Avatar
          placeHolder={<Icon.User size={AppStyles.AVATAR_SIZES.MODAL} color={GlobalStyles.SLATE_200} strokeWidth={1} />}
          image={avatar || undefined}
          style={{
            width: AppStyles.AVATAR_SIZES.MODAL,
            height: AppStyles.AVATAR_SIZES.MODAL,
            borderRadius: AppStyles.AVATAR_SIZES.MODAL,
          }}
          imageStyle={{ width: AppStyles.AVATAR_SIZES.MODAL, height: AppStyles.AVATAR_SIZES.MODAL }}
        />
      </Stack>
    </Stack>
  )
}

export { ProfilePicture }
