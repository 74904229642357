import { Button, Card, Dropdown, GlobalStyles, Icon, Stack, Text, View } from '@andromeda'
import { Accordion } from '@components'
import { AppStyles } from '@styles'
import { useScreenDimensions } from '@utils/hooks/dimensions'
import { useState } from 'react'

type PreferenceType = {
  initialValue: boolean
  isEditable: boolean
  prefId: string
}

type Preference = {
  id: string
  prefName: string
  sms: PreferenceType
  pushNotif: PreferenceType
  notificationFeed: PreferenceType
  email: PreferenceType
}

interface iNotificationPreferencesProps {
  generalPreferences: {
    userAccount: {
      [key: string]: any
      paginated: boolean
      items: Preference[]
    }
    messageCentre: {
      [key: string]: any
      paginated: boolean
      items: Preference[]
    }
  }
  rolePreferences: {
    roleViewAs: any
    workspace: {
      [key: string]: any
      paginated: boolean
      items: Preference[]
    }
    talentProfileManage: {
      [key: string]: any
      paginated: boolean
      items: Preference[]
    }
  }
}

const DropdownOptions = [
  { id: 0, value: 'Profile Manager' },
  { id: 1, value: 'Talent' },
]

const NotificationPreferences = (props: iNotificationPreferencesProps) => {
  const { screenSize } = useScreenDimensions()
  // Dropdown
  const [selectedRole, setSelectedRole] = useState(
    DropdownOptions.find((option) => option.value === props.rolePreferences.roleViewAs)?.id || 0
  )

  // Accordions
  const [toggleUserAccount, setToggleUserAccount] = useState(false)
  const [toggleMessageCentre, setToggleMessageCentre] = useState(false)
  const [toggleWorkspace, setToggleWorkspace] = useState(false)
  const [toggleTalentMgmt, setToggleTalentMgmt] = useState(false)

  const _handleDropdownSelect = (data: any) => {
    setSelectedRole(data.id)
  }

  const CellComponent = ({ data, preferenceId, index }: { data: PreferenceType; preferenceId: string; index: number }) => {
    const _handleSwitch = (switchData: any) => {}

    if (data.isEditable) {
      // TODO - add switch
      return null
    }

    return (
      <Text key={preferenceId} size="sm">
        {data.initialValue ? 'Yes' : 'No'}
      </Text>
    )
  }

  const _renderPreferencesTable = (preferences: any) => {
    return (
      <Stack direction="column" style={{ paddingBottom: GlobalStyles.PADDING_SIZES.sm }}>
        {/* Headers */}
        <Stack
          align="flex-start"
          space="none"
          style={{ marginBottom: 8 }}
          childrenStyle={{
            backgroundColor: GlobalStyles.SLATE_50,
            paddingHorizontal: 32,
            paddingVertical: 8,
          }}>
          <Stack align="flex-start" flex={3}>
            <View style={{ height: 15, borderColor: 'transparent' }} />
          </Stack>

          <Stack align="flex-start" justify="center" flex={0.5}>
            <Text size="tiny" style={{ color: GlobalStyles.SLATE_500, textTransform: 'uppercase', fontWeight: '600' }}>
              SMS
            </Text>
          </Stack>
          <Stack align="flex-start" justify="center" flex={2}>
            <Text size="tiny" style={{ color: GlobalStyles.SLATE_500, textTransform: 'uppercase', fontWeight: '600' }}>
              {screenSize['2xl'] ? 'Push Notifications' : 'P. N.'}
            </Text>
          </Stack>

          <Stack align="flex-start" justify="center" flex={2}>
            <Text size="tiny" style={{ color: GlobalStyles.SLATE_500, textTransform: 'uppercase', fontWeight: '600' }}>
              {screenSize['2xl'] ? 'Notification Feed' : 'N. F.'}
            </Text>
          </Stack>

          <Stack align="flex-start" justify="center" flex={0.5}>
            <Text size="tiny" style={{ color: GlobalStyles.SLATE_500, textTransform: 'uppercase', fontWeight: '600' }}>
              Email
            </Text>
          </Stack>
        </Stack>
        {/* inner table */}
        {preferences.items.map((pref: any, index: number) => (
          <Stack
            key={`${pref.id}-${index}`}
            align="flex-start"
            space="none"
            childrenStyle={{
              height: 40,
              paddingHorizontal: 32,
            }}>
            <Stack align="flex-start" flex={3} space={'2xs'} style={{ flexWrap: screenSize['2xl'] ? 'nowrap' : 'wrap' }}>
              <Text size="sm">{pref.prefName}</Text>
              <View>
                <Icon.Info size={18} style={{ color: GlobalStyles.SLATE_200 }} />
              </View>
            </Stack>

            <Stack align="flex-start" justify="center" flex={0.5} style={{ flexWrap: 'wrap' }}>
              <CellComponent data={pref.sms} preferenceId={pref.id} index={index} />
            </Stack>

            <Stack align="flex-start" justify="center" flex={2} style={{ flexWrap: 'wrap' }}>
              <CellComponent data={pref.pushNotif} preferenceId={pref.id} index={index} />
            </Stack>

            <Stack align="flex-start" justify="center" flex={2} style={{ flexWrap: 'wrap' }}>
              <CellComponent data={pref.notificationFeed} preferenceId={pref.id} index={index} />
            </Stack>

            <Stack align="flex-start" justify="center" flex={0.5} style={{ flexWrap: 'wrap' }}>
              <CellComponent data={pref.email} preferenceId={pref.id} index={index} />
            </Stack>
          </Stack>
        ))}

        {preferences.paginated && (
          <View style={{ justifyContent: 'center', width: '100%' }}>
            <Button hover transparent type="secondary" style={{ width: '100%' }} disabled>
              <Stack justify="center" style={{ paddingBottom: 12 }}>
                <Text size="sm" style={{ color: GlobalStyles.PRIMARY_500 }}>
                  Show More
                </Text>

                <View>
                  <Icon.ChevronDown size={16} style={{ color: GlobalStyles.PRIMARY_500 }} />
                </View>
              </Stack>
            </Button>
          </View>
        )}
      </Stack>
    )
  }

  const _renderMobileLayout = (preference: any) => {
    return preference.items.map((pref: any, index: number) => (
      <Stack
        key={`${pref.id}-${index}`}
        direction="column"
        style={{
          paddingHorizontal: AppStyles.CARD_VERTICAL_PADDING,
          paddingVertical: GlobalStyles.PADDING_SIZES.sm,
          borderBottomWidth: GlobalStyles.BORDER_WIDTH,
        }}>
        <Stack align="flex-start" justify="space-between" style={{ flexWrap: 'wrap' }}>
          <Text type="heading" bold size="tiny">
            {pref.prefName}
          </Text>
          <View>
            <Icon.Info size={18} style={{ color: GlobalStyles.SLATE_200 }} />
          </View>
        </Stack>

        <Stack align="flex-start" justify="space-between" style={{ flexWrap: 'wrap' }}>
          <Text size="sm">SMS</Text>
          <CellComponent data={pref.sms} preferenceId={pref.id} index={index} />
        </Stack>

        <Stack align="flex-start" justify="space-between" style={{ flexWrap: 'wrap' }}>
          <Text size="sm">Push Notifications</Text>

          <CellComponent data={pref.pushNotif} preferenceId={pref.id} index={index} />
        </Stack>

        <Stack align="flex-start" justify="space-between" style={{ flexWrap: 'wrap' }}>
          <Text size="sm">Notification Feed</Text>
          <CellComponent data={pref.notificationFeed} preferenceId={pref.id} index={index} />
        </Stack>

        <Stack align="flex-start" justify="space-between" style={{ flexWrap: 'wrap' }}>
          <Text size="sm">Email</Text>
          <CellComponent data={pref.email} preferenceId={pref.id} index={index} />
        </Stack>
      </Stack>
    ))
  }

  return (
    <Stack
      align="flex-start"
      direction="column"
      space="lg"
      style={{
        paddingVertical: AppStyles.CARD_VERTICAL_PADDING,
        paddingHorizontal: screenSize.md ? 0 : GlobalStyles.PADDING_SIZES.sm,
      }}>
      <Text size="sm">Manage your preferences for this workspace and your roles within it</Text>

      <Stack direction="column" align="flex-start" space="md">
        <Text bold style={{ textAlign: 'left', color: GlobalStyles.MONO_BLACK }}>
          General Preferences
        </Text>

        <Card style={{ padding: 0, width: '100%' }}>
          {/* <Accordion
                        onToggle={() => setToggleUserAccount(!toggleUserAccount)}
                        isOpen={toggleUserAccount}
                        activeBackgroundColor={GlobalStyles.COLOR_TRANSPARENT}
                        titleComponent={
                            <Stack
                                align="center"
                                justify="space-between"
                                style={{
                                    paddingVertical: AppStyles.HEADER_PADDING_VERTICAL,
                                    paddingHorizontal: AppStyles.CARD_VERTICAL_PADDING,
                                }}>
                                <Text size={screenSize.md ? 'sm' : 'lg'} bold>
                                    User Account
                                </Text>
                                <View
                                    style={{
                                        padding: AppStyles.BUTTON_SIZE_TINY,
                                        backgroundColor: GlobalStyles.MONO_WHITE,
                                        borderRadius: AppStyles.BUTTON_RADIUS,
                                        borderWidth: 1,
                                        borderColor: GlobalStyles.SLATE_100,
                                        shadowColor: 'rgba(0, 0, 0, 0.05)',
                                        shadowOffset: { width: 0, height: 2 },
                                        shadowOpacity: 0.5,
                                        shadowRadius: 2,
                                    }}>
                                    {toggleUserAccount ? (
                                        <Icon.ChevronUp size={16} color={GlobalStyles.PRIMARY_500} />
                                    ) : (
                                        <Icon.ChevronDown size={16} color={GlobalStyles.PRIMARY_500} />
                                    )}
                                </View>
                            </Stack>
                        }>
                        {screenSize.md
                            ? _renderPreferencesTable(props.generalPreferences.userAccount)
                            : _renderMobileLayout(props.generalPreferences.userAccount)}
                    </Accordion> 
                    <Divider style={{ borderWidth: 1, marginTop: 0, marginBottom: 0, borderColor: GlobalStyles.SLATE_50 }} />
                    */}
          <Accordion
            onToggle={() => setToggleMessageCentre(!toggleMessageCentre)}
            isOpen={toggleMessageCentre}
            activeBackgroundColor={GlobalStyles.COLOR_TRANSPARENT}
            titleComponent={
              <Stack
                align="center"
                justify="space-between"
                style={{
                  paddingVertical: AppStyles.HEADER_PADDING_VERTICAL,
                  paddingHorizontal: AppStyles.CARD_VERTICAL_PADDING,
                }}>
                <Text size={screenSize.md ? 'sm' : 'lg'} bold>
                  Message Centre
                </Text>
                <View
                  style={{
                    padding: AppStyles.BUTTON_SIZE_TINY,
                    backgroundColor: GlobalStyles.MONO_WHITE,
                    borderRadius: AppStyles.BUTTON_RADIUS,
                    borderWidth: 1,
                    borderColor: GlobalStyles.SLATE_100,
                    shadowColor: 'rgba(0, 0, 0, 0.05)',
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.5,
                    shadowRadius: 2,
                  }}>
                  {toggleMessageCentre ? (
                    <Icon.ChevronUp size={16} color={GlobalStyles.PRIMARY_500} />
                  ) : (
                    <Icon.ChevronDown size={16} color={GlobalStyles.PRIMARY_500} />
                  )}
                </View>
              </Stack>
            }>
            {screenSize.md
              ? _renderPreferencesTable(props.generalPreferences.messageCentre)
              : _renderMobileLayout(props.generalPreferences.messageCentre)}
          </Accordion>
        </Card>

        <Text bold style={{ textAlign: 'left', color: GlobalStyles.MONO_BLACK }}>
          Role Specific Preferences
        </Text>
        <Text size="sm" style={{ textAlign: 'left' }}>
          Please select the workspace role you want to view and manage:
        </Text>
        <Dropdown
          type="primary"
          options={DropdownOptions}
          selectedItem={DropdownOptions.find((x) => x.id === selectedRole)}
          optionLabel={'value'}
          placeholder={'Select Role'}
          dropdownWrapperStyle={{
            width: '50%',
          }}
          dropdownContainerStyle={{
            borderRadius: 12,
            paddingHorizontal: 12,
            flex: 1,
          }}
          dropdownStyle={{
            maxHeight: 284,
            padding: '12px, 0px, 16px, 0px',
            borderRadius: 12,
            marginTop: 8,
          }}
          onSelect={_handleDropdownSelect}
          iconContainerStyle={{
            width: 40,
            height: 40,
            backgroundColor: GlobalStyles.MONO_WHITE,
            borderRadius: AppStyles.BUTTON_RADIUS,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />

        <Text size="sm" style={{ textAlign: 'left' }}>
          Set how you want to receive updates and notifications relating to the talent you manage.
        </Text>
        <Card style={{ padding: 0, width: '100%' }}>
          <Accordion
            onToggle={() => setToggleWorkspace(!toggleWorkspace)}
            isOpen={toggleWorkspace}
            activeBackgroundColor={GlobalStyles.COLOR_TRANSPARENT}
            titleComponent={
              <Stack
                align="center"
                justify="space-between"
                style={{
                  paddingVertical: AppStyles.HEADER_PADDING_VERTICAL,
                  paddingHorizontal: AppStyles.CARD_VERTICAL_PADDING,
                }}>
                <Text size={screenSize.md ? 'sm' : 'lg'} bold>
                  Workspace
                </Text>
                <View
                  style={{
                    padding: AppStyles.BUTTON_SIZE_TINY,
                    backgroundColor: GlobalStyles.MONO_WHITE,
                    borderRadius: AppStyles.BUTTON_RADIUS,
                    borderWidth: 1,
                    borderColor: GlobalStyles.SLATE_100,
                    shadowColor: 'rgba(0, 0, 0, 0.05)',
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.5,
                    shadowRadius: 2,
                  }}>
                  {toggleWorkspace ? (
                    <Icon.ChevronUp size={16} color={GlobalStyles.PRIMARY_500} />
                  ) : (
                    <Icon.ChevronDown size={16} color={GlobalStyles.PRIMARY_500} />
                  )}
                </View>
              </Stack>
            }>
            {screenSize.md
              ? _renderPreferencesTable(props.rolePreferences.workspace)
              : _renderMobileLayout(props.rolePreferences.workspace)}
          </Accordion>
          <Accordion
            onToggle={() => setToggleTalentMgmt(!toggleTalentMgmt)}
            isOpen={toggleTalentMgmt}
            activeBackgroundColor={GlobalStyles.COLOR_TRANSPARENT}
            titleComponent={
              <Stack
                align="center"
                justify="space-between"
                style={{
                  paddingVertical: AppStyles.HEADER_PADDING_VERTICAL,
                  paddingHorizontal: AppStyles.CARD_VERTICAL_PADDING,
                }}>
                <Text size={screenSize.md ? 'sm' : 'lg'} bold>
                  Talent Profile Management
                </Text>
                <View
                  style={{
                    padding: AppStyles.BUTTON_SIZE_TINY,
                    backgroundColor: GlobalStyles.MONO_WHITE,
                    borderRadius: AppStyles.BUTTON_RADIUS,
                    borderWidth: 1,
                    borderColor: GlobalStyles.SLATE_100,
                    shadowColor: 'rgba(0, 0, 0, 0.05)',
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.5,
                    shadowRadius: 2,
                  }}>
                  {toggleTalentMgmt ? (
                    <Icon.ChevronUp size={16} color={GlobalStyles.PRIMARY_500} />
                  ) : (
                    <Icon.ChevronDown size={16} color={GlobalStyles.PRIMARY_500} />
                  )}
                </View>
              </Stack>
            }>
            {screenSize.md
              ? _renderPreferencesTable(props.rolePreferences.talentProfileManage)
              : _renderMobileLayout(props.rolePreferences.talentProfileManage)}
          </Accordion>
        </Card>
      </Stack>
    </Stack>
  )
}

export default NotificationPreferences
