export enum BUDGET_GUIDE_TYPE {
  FIXED = 'FIXED',
  FLEXIBLE = 'FLEXIBLE',
  NO_FEE = 'NO_FEE',
}

export enum DATE_GUIDE_TYPE {
  FIXED = 'FIXED',
  FLEXIBLE = 'FLEXIBLE',
}
