import { GlobalStyles, Icon } from '@andromeda'
import { Box, Button, Grid, IconButton, MobileStepper, Modal, Stack, Stepper, Typography } from '@mui/material'
import Image from 'next/image'
import { useState } from 'react'
import Welcome1 from 'public/welcome_1.gif'
import Welcome2 from 'public/welcome_2.gif'
import Welcome3 from 'public/welcome_3.gif'
import Welcome4 from 'public/welcome_4.gif'
import Welcome5 from 'public/welcome_5.gif'
import Welcome6 from 'public/welcome_6.gif'
import Welcome7 from 'public/welcome_7.gif'
import { introCompleted } from '@redux/reducers/authReducer'
import { useAppDispatch } from '@redux/hooks'
import useInterval from 'use-interval'
import { useMe } from '@utils/query/useMe'

export const OnboardingModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const dispatch = useAppDispatch()

  const [step, setStep] = useState(0)

  const { data: me } = useMe()

  useInterval(() => {
    if (step < 6) {
      setStep((prev) => prev + 1)
    }
  }, 4000)

  return (
    <Modal open={open} sx={{ display: { xxs: 'none', md: 'initial' } }}>
      <Box
        bgcolor={GlobalStyles.PRIMARY_500}
        width="1000px"
        height="570px"
        padding="12px"
        boxSizing="border-box"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        <Grid container height="100%">
          <Grid item xxs={8}>
            <IconButton
              onClick={() => {
                !me?.is_intro_completed && dispatch(introCompleted())
                onClose()
              }}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                height: '40px',
                width: '40px',
              }}>
              <Icon.X color={GlobalStyles.MONO_WHITE} size={16} />
            </IconButton>
            <Stack height="100%">
              {
                [
                  <Image height={546} width={655} key="welcome 1" src={Welcome1} alt="Manage talent jobs easily " />,
                  <Image height={546} width={655} key="welcome 2" src={Welcome2} alt="View talent deliverables in one spot" />,
                  <Image height={546} width={655} key="welcome 3" src={Welcome3} alt="Change or edit deliverable details" />,
                  <Image height={546} width={655} key="welcome 4" src={Welcome4} alt="Chat with stakeholders about the deliverable" />,
                  <Image height={546} width={655} key="welcome 5" src={Welcome5} alt="Add or remove talent and users" />,
                  <Image height={546} width={655} key="welcome 6" src={Welcome6} alt="One or many deliverables make up a job" />,
                  <Image height={546} width={655} key="welcome 7" src={Welcome7} alt="Manage all the job information in one spot" />,
                ][step]
              }
            </Stack>
          </Grid>
          <Grid item xxs={4}>
            <Stack height="100%" rowGap="10px">
              <Stack flex={1} marginTop="240px" padding="12px">
                <Typography fontSize="24px" color={GlobalStyles.MONO_WHITE} fontWeight={500}>
                  {
                    [
                      'Manage talent jobs easily',
                      'View talent deliverables in one spot',
                      'Change or edit deliverable details',
                      'Chat with stakeholders about the deliverable',
                      'Add or remove talent and users',
                      'One or many deliverables make up a job',
                      'Manage all the job information in one spot',
                    ][step]
                  }
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" padding="12px" columnGap="24px">
                <MobileStepper
                  position="static"
                  variant="dots"
                  steps={7}
                  activeStep={step}
                  sx={{
                    padding: 0,
                    '&.MuiMobileStepper-root': {
                      backgroundColor: GlobalStyles.PRIMARY_500,
                    },
                    '.MuiMobileStepper-dot': {
                      backgroundColor: GlobalStyles.SLATE_300,
                      height: '10px',
                      width: '10px',
                      marginRight: '10px',
                    },
                    '.MuiMobileStepper-dot:first-child': {
                      marginLeft: '10px',
                    },
                    '.MuiMobileStepper-dotActive ': {
                      backgroundColor: GlobalStyles.MONO_WHITE,
                    },
                    '.MuiButtonBase-root svg': {
                      stroke: GlobalStyles.MONO_WHITE,
                      scale: '1.2',
                    },
                  }}
                  backButton={
                    <IconButton onClick={() => setStep((prev) => (prev === 0 ? prev : prev - 1))} sx={{ padding: 0 }}>
                      <Icon.ChevronLeft size={14} color={GlobalStyles.PRIMARY_500} />
                    </IconButton>
                  }
                  nextButton={
                    <IconButton onClick={() => setStep((prev) => (prev === 6 ? prev : prev + 1))} sx={{ padding: 0 }}>
                      <Icon.ChevronRight size={14} color={GlobalStyles.PRIMARY_500} />
                    </IconButton>
                  }
                />
                <Button
                  variant="contained"
                  style={{ backgroundColor: 'white', color: GlobalStyles.PRIMARY_500 }}
                  onClick={() => {
                    if (step === 6) {
                      !me?.is_intro_completed && dispatch(introCompleted())
                      onClose()
                    } else {
                      setStep((prev) => prev + 1)
                    }
                  }}
                  sx={{ height: '40px', width: '79px' }}>
                  {step === 6 ? 'Finish' : 'Next'}
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
