import { KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { BasePoint, Descendant, Editor, Range, Transforms } from 'slate'
import { insertMention } from '..'
import { ReactEditor } from 'slate-react'
import { Types } from '@orbit'
import { getScrollParent } from '@utils/functions/helperFunctions'
import { trackEvent } from '@utils/tracking/helpers'
import { useAppSelector } from '@redux/hooks'
import { selectJobs } from '@redux/reducers/jobsReducer'
import { selectDeliverable } from '@redux/reducers/deliverableReducer'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { useMe } from '@utils/query/useMe'
import { isLinkActive } from 'src/components/rich-editor-plugin/link/utils'
import useDebounce from '@utils/hooks/useDebounce'
import { MentionItem, MentionType } from '../mention-types'
import { useWorkspace } from '@utils/query/useWorkspace'

const getMatch = (editor: Editor, start: BasePoint, before: BasePoint, matchPattern: RegExp) => {
  const beforeRange = before && Editor.range(editor, before, start)
  const beforeText = beforeRange && Editor.string(editor, beforeRange)

  return {
    range: beforeRange,
    text: beforeText,
    match: beforeText && beforeText.match(matchPattern),
  }
}

type MentionSelectHook = {
  editor: Editor
  users: Types.User.iUser[]
  talents: Types.Talent.iTalent[]
  type?: 'job' | 'deliverable'
}

const useMentionSelect = ({ editor, users = [], talents = [], type }: MentionSelectHook) => {
  const {
    activeWorkspace: { slug },
  } = useWorkspace()
  const { viewedJob } = useAppSelector(selectJobs)
  const { viewedDeliverable } = useAppSelector(selectDeliverable)

  const [target, setTarget] = useState<Range | undefined>()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const [mentionType, setMentionType] = useState<MentionType>()
  const [dropdownPosition, setDropdownPosition] = useState({ top: '-99999px', left: '-99999px' })

  const debounceSearch = useDebounce(search, 100)

  const collection = useMemo(() => {
    let mentionCollection: MentionItem[] = []

    switch (mentionType) {
      case 'user':
        mentionCollection = users.map((user) => ({
          eid: user.eid,
          type: 'user',
          name: user.name,
          profile_image: user.profile_image,
        }))
        break
      case 'talent':
        mentionCollection = talents.map((talent) => ({
          eid: talent.eid,
          type: 'talent',
          name: talent.display_name,
          profile_image: talent.profile_image,
        }))
        break
      default:
        mentionCollection = []
    }

    return mentionCollection
  }, [mentionType, users, talents])

  const dataToMention = useMemo(() => {
    return collection.filter((c) => c.name.toLowerCase().includes(debounceSearch.toLowerCase())).slice(0, 10)
  }, [debounceSearch, collection])

  const { data: currentUser } = useMe()

  const selectMention = useCallback(
    (item: MentionItem) => {
      Transforms.select(editor, target!)
      insertMention(editor, item, mentionType!, slug)
      setTarget(undefined)
      trackEvent({
        event: TrackingEventEnums.Mention.USER_MENTION,
        eventProperties: {
          current_mention_id: currentUser?.eid,
          current_mention_name: currentUser?.name,
          clicked_mention_id: item.eid,
          clicked_mention_name: item.name,
          mentionType,
          job_id: viewedJob.eid,
          ...(type === 'deliverable' && viewedDeliverable?.eid ? { deliverable_id: viewedDeliverable.eid } : {}),
        },
      })
    },
    [editor, type, currentUser, mentionType, target, viewedJob, viewedDeliverable, slug]
  )

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (target && dataToMention.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            const prevIndex = index >= dataToMention.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case 'ArrowUp':
            event.preventDefault()
            const nextIndex = index <= 0 ? dataToMention.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            selectMention(dataToMention[index])
            break
          case 'Escape':
            event.preventDefault()
            setTarget(undefined)
            break
        }
      }
    },
    [target, dataToMention, index, selectMention]
  )

  const onChange: (value: Descendant[]) => void = useCallback(() => {
    const { selection } = editor
    let timeOut: NodeJS.Timeout

    if (isLinkActive(editor)) {
      return
    }

    setTarget(undefined)

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection)
      const before = Editor.before(editor, start)
      const mentionTypes = [
        { type: 'user', regex: /@(\w*)$/, exactMatchRegex: /^@(\w+)$/ },
        { type: 'talent', regex: /%(\w*)$/, exactMatchRegex: /^%(\w+)$/ },
        { type: 'job', regex: /#(\w*)$/, exactMatchRegex: /^#(\w+)$/ },
      ]
      const findWordBefore = (matchRegex: RegExp, exactMatchRegex: RegExp) => {
        const beforeResult = before && getMatch(editor, start, before, matchRegex)

        const wordBeforeStart = Editor.before(editor, start, { unit: 'word' })
        const wordBeforePoint = wordBeforeStart && Editor.before(editor, wordBeforeStart)
        const wordBeforeResult = wordBeforePoint && getMatch(editor, start, wordBeforePoint, exactMatchRegex)

        return { beforeResult, wordBeforeResult }
      }
      let wordResult: ReturnType<typeof findWordBefore> | undefined

      for (const mentionType of mentionTypes) {
        const result = findWordBefore(mentionType.regex, mentionType.exactMatchRegex)
        const { beforeResult, wordBeforeResult } = result

        if (beforeResult?.match || wordBeforeResult?.match) {
          wordResult = result
          setMentionType(mentionType.type as any)
          break
        }
      }

      if (wordResult) {
        const { beforeResult, wordBeforeResult } = wordResult
        timeOut = setTimeout(() => {
          setTarget(wordBeforeResult?.match ? wordBeforeResult.range : beforeResult?.range)
          setSearch(wordBeforeResult?.match ? wordBeforeResult.match[1] : '')
          setIndex(0)
        }, 0)
      }
    }

    return () => {
      if (timeOut) clearTimeout(timeOut)
    }
  }, [editor])

  useEffect(() => {
    const domRange = target ? ReactEditor.toDOMRange(editor, target) : null
    const scrollElement = domRange ? getScrollParent(domRange.commonAncestorContainer as HTMLElement) : null
    const updatePosition = () => {
      if (target && dataToMention.length > 0) {
        const domRange = ReactEditor.toDOMRange(editor, target)
        const rect = domRange.getBoundingClientRect()

        setDropdownPosition({
          top: `${rect.top + window.pageYOffset - 10}px`, // + 24 if you want to show the dropdown below the mention
          left: `${rect.left + window.pageXOffset}px`,
        })
      }
    }

    updatePosition()

    // fix dropdown position when scrolling, needs to adjust when the scrolling element is changed
    scrollElement?.parentElement?.addEventListener('scroll', updatePosition)

    return () => {
      scrollElement?.parentElement?.removeEventListener('scroll', updatePosition)
    }
  }, [target, dataToMention, editor])

  return {
    // variables
    target,
    dropdownPosition,
    mentionType,
    selectedIndex: index,
    filteredSearch: dataToMention,

    // functions
    setTarget,
    selectMention,
    onKeyDown,
    onChange,
    setSelectedIndex: setIndex,
  }
}

export default useMentionSelect
