import { Orbit, Types } from '@orbit'
import { AppState } from '@redux/store'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { iAuditInitialState } from '@utils/types/audit'
import { ApiResponseError } from '@utils/types/auth'

const auditInitialState: Array<Types.Audits.iAudit> = [
  {
    id: -1,
    event: '',
    new_values: {},
    old_values: {},
    date_time: '',
    user: {
      eid: '',
      name: '',
      profile_image: null,
      management: null,
    },
  },
]

const auditRequestStatus = {
  read: 'idle',
}
const initialState: iAuditInitialState = {
  job: auditInitialState,
  deliverable: auditInitialState,
  types: null,
  requestStatus: auditRequestStatus,
  errorMessages: null,
}

export const getAudits = createAsyncThunk(
  'audit/get',
  async ({ eid, type }: { eid: string; type: string }, { rejectWithValue }): Promise<Array<Types.Audits.iAudit> | any> => {
    try {
      const response = await Orbit.Services.auditService.list({ type, eid })
      return { data: response.data, type }
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    resetAuditSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAudits.pending, (state) => {
        state.requestStatus.read = 'loading'
      })
      .addCase(
        getAudits.fulfilled,
        (state: iAuditInitialState, action: PayloadAction<{ data: Array<Types.Audits.iAudit>; type: 'job' | 'deliverable' }>) => {
          state.requestStatus.read = 'success'
          state[action.payload.type] = action.payload.data
        }
      )
      .addCase(getAudits.rejected, (state) => {
        state.requestStatus.read = 'failed'
      })
  },
})

export const { resetAuditSlice } = auditSlice.actions
export const { reducer } = auditSlice
export const selectAudit = (state: AppState) => state.audit
