import { GlobalStyles, Icon, SpaceType } from '@andromeda'
import { Chip as MUIChip, ChipProps as MUIChipProps } from '@mui/material'
import { AppStyles } from '@styles'
import { CSSProperties } from 'react'
import { StyleProp, TextStyle, ViewStyle } from 'react-native'

export type ChipProps = MUIChipProps & {
  text: string
  style?: CSSProperties
  textStyle?: StyleProp<TextStyle>
  stackStyle?: StyleProp<ViewStyle>
  space?: SpaceType
  leftElement?: JSX.Element | JSX.Element[]
  rightElement?: JSX.Element | JSX.Element[]
}

const Chip = ({ text, style, textStyle, stackStyle, space = '2xs', leftElement, rightElement, onDelete, onClick, ...props }: ChipProps) => {
  const chipStyle = {
    borderRadius: AppStyles.BORDER_RADIUS.XL,
  }
  return (
    <MUIChip
      variant={'filled'}
      {...props}
      deleteIcon={<Icon.XCircle color={GlobalStyles.MONO_WHITE} size={AppStyles.ICON_SIZES.MD} />}
      label={text}
      style={chipStyle}
      onDelete={onDelete}
      onClick={onClick}
    />
  )
}

export { Chip }
