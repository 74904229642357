import LoadingMain from './Loading'
import Bar from './Bar'
import type { LoadingComponentType } from './types'

const LoadingTemp: any = LoadingMain
LoadingTemp.Bar = Bar

const Loading = LoadingTemp as LoadingComponentType

export { Loading }
