import { Button, GlobalStyles, Icon, Input, Label, Stack, Text } from '@andromeda'
import { AppStyles } from '@styles'
import { ModalContext } from '@utils/context/Modal.context'
import { iLoadingState } from '@utils/types/common'
import { useContext, useEffect, useState } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { passwordStrengthCalculate } from 'src/utils'

const CreatePasswordForm = ({
  onSubmit,
  loading,
  style,
}: {
  onSubmit: (password: { password: string; passwordConfirmation: string }) => void
  style?: StyleProp<ViewStyle>
  loading?: iLoadingState
}): JSX.Element => {
  const { closeModal } = useContext(ModalContext)

  /**
   * Input field values
   */
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')

  /**
   * Custom Error message hooks
   */
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | undefined>()
  const [passwordConfirmationErrorMessage, setPasswordConfirmationErrorMessage] = useState<string | undefined>()

  /**
   * Turn secureTextEntry on/off hook
   */
  const [passwordUnmasked, setPasswordUnmasked] = useState<boolean>(false)
  const [passwordConfirmationUnmasked, setPasswordConfirmationUnmasked] = useState<boolean>(false)

  /**
   * Password strength
   */
  const [passwordStrengthLabel, setPasswordStrengthLabel] = useState<string>('')
  const [passwordStregthColor, setPasswordStregthColor] = useState<string>(GlobalStyles.STATUS_NEGATIVE)

  const [passwordConfirmationTouched, setpasswordConfirmationTouched] = useState<boolean>(false)

  useEffect(() => {
    if (password !== passwordConfirmation && passwordConfirmationTouched) {
      setPasswordConfirmationErrorMessage('Password confirmation does not match')
    } else if (password === passwordConfirmation) {
      setPasswordConfirmationErrorMessage('')
    }

    const strength = passwordStrengthCalculate(password)
    setPasswordStrengthLabel(strength.text)
    setPasswordStregthColor(strength.color)
  }, [password, passwordConfirmation, passwordConfirmationTouched, loading])

  const doSubmit = () => {
    setPasswordErrorMessage('')
    setPasswordConfirmationErrorMessage('')

    if (!password || !passwordConfirmation) {
      if (!password) {
        setPasswordErrorMessage('Password field cannot be empty')
      }
      if (!passwordConfirmation) {
        setPasswordConfirmationErrorMessage('Password confirm field cannot be empty')
      }
      return
    }

    if (password !== passwordConfirmation) {
      setPasswordConfirmationErrorMessage('Password confirmation does not match')
    } else {
      onSubmit && onSubmit({ password, passwordConfirmation })
    }
  } // For dispatching Actions

  const _handlePaswordConfirm = (value: string) => {
    setpasswordConfirmationTouched(true)
    setPasswordConfirmation(value)
  }

  return (
    <Stack
      direction="column"
      height={'auto'}
      style={[{ paddingHorizontal: AppStyles.MODAL_PADDING_HORIZONTAL, paddingBottom: AppStyles.MODAL_PADDING_HORIZONTAL }, style]}
      align="flex-start">
      <Text type={'paragraph'}>
        Create a new password that’s at least 8 characters long. A strong password has a combination of letters, digits and punctuation
        marks.
      </Text>
      <Text type={'paragraph'}>New Password</Text>
      <Input
        secureTextEntry={!passwordUnmasked}
        value={password}
        errorMessage={passwordErrorMessage}
        placeholder="******************"
        style={{ backgroundColor: GlobalStyles.MONO_WHITE, borderWidth: 0 }}
        wrapperStyle={{ height: 'auto', borderColor: GlobalStyles.SLATE_100, paddingRight: 10 }}
        rightElement={
          <Button onPress={() => setPasswordUnmasked(!passwordUnmasked)} style={{ backgroundColor: GlobalStyles.SLATE_100 }}>
            <Text>
              <Icon.Eye color={GlobalStyles.SLATE_500} />
            </Text>
          </Button>
        }
        onChangeText={(value: string) => {
          setPassword(value)
          if (!value) setPasswordErrorMessage('Password field cannot be empty')
          else setPasswordErrorMessage('')
        }}
      />
      <Text type={'paragraph'}>Confirm New Password</Text>
      <Input
        secureTextEntry={!passwordConfirmationUnmasked}
        value={passwordConfirmation}
        errorMessage={passwordConfirmationErrorMessage}
        placeholder="******************"
        style={{ backgroundColor: GlobalStyles.MONO_WHITE, borderWidth: 0 }}
        wrapperStyle={{ height: 'auto', borderColor: GlobalStyles.SLATE_100, paddingRight: 10 }}
        rightElement={
          <Button
            onPress={() => setPasswordConfirmationUnmasked(!passwordConfirmationUnmasked)}
            style={{ backgroundColor: GlobalStyles.SLATE_100 }}>
            <Text>
              <Icon.Eye color={GlobalStyles.SLATE_500} />
            </Text>
          </Button>
        }
        onChangeText={(value: string) => _handlePaswordConfirm(value)}
      />
      <Stack height={'auto'}>
        <Text type={'paragraph'}>Password strength:</Text>
        {passwordStrengthLabel !== '' && (
          <Label
            type={'DEFAULT'}
            text={passwordStrengthLabel}
            style={{ backgroundColor: passwordStregthColor }}
            textStyle={{ fontSize: GlobalStyles.FONT_SIZES.TINY }}
          />
        )}
      </Stack>
      <Stack height={'auto'} justify={'space-between'}>
        <Button
          onPress={() => {
            closeModal('resetPasswordForm')
          }}
          style={{ padding: 0 }}
          transparent>
          <Text size="sm" style={{ color: GlobalStyles.PRIMARY_500 }}>
            Cancel
          </Text>
        </Button>
        <Button
          loading={loading == 'loading'}
          onPress={() => {
            doSubmit()
          }}
          style={{ borderRadius: 6 }}
          type="primary"
          text="Continue">
          {loading === 'success' ? (
            <Text>
              <Icon.CheckCircle color={GlobalStyles.MONO_WHITE} />
            </Text>
          ) : null}
        </Button>
      </Stack>
    </Stack>
  )
}

CreatePasswordForm.displayName = 'CreatePasswordForm'

export { CreatePasswordForm }
