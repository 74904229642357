import { ModalHeader } from '@components'
import { Button, Stack, Typography } from '@mui/material'
import { Orbit } from '@orbit'
import { useAppDispatch } from '@redux/hooks'
import { fetchAllWorkspaces, leaveWorkspace } from '@redux/reducers/workspaceReducer'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { toast } from 'react-toastify'

export const LeaveWorkspace = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const { closeModal } = useContext(ModalContext)

  const { mutate, isPending } = useMutation({
    mutationKey: ['leaveWorkspace'],
    mutationFn: async () => {
      const response = await Orbit.Services.workspaceService.leaveWorkspace(router.query.workspace as string)
      return response.data
    },
    onMutate() {
      toast.loading('Leaving workspace...', { toastId: 'leaveWorkspace' })
    },
    onSuccess() {
      toast.update('leaveWorkspace', { render: 'Successfully left workspace.', type: 'success', autoClose: 5000, isLoading: false })
      localStorage.removeItem('persist:workspace')
      dispatch(fetchAllWorkspaces())
      router.replace('/workbench')
      queryClient.invalidateQueries({ queryKey: ['workspaces'], type: 'active' })
      closeModal(ModalPages.LEAVE_WORKSPACE)
    },
    onError(e) {
      toast.update('leaveWorkspace', {
        // @ts-expect-error
        render: e?.response?.data?.message || 'Error leaving workspace.',
        type: 'error',
        autoClose: 5000,
        isLoading: false,
      })
    },
  })

  return (
    <Stack minWidth={{ sm: '500px' }} maxWidth="500px">
      <ModalHeader
        modalContext={ModalPages.SOCIAL_CAMPAIGNS}
        modalTitle="Leave Workspace"
        onCancel={() => closeModal(ModalPages.LEAVE_WORKSPACE)}
      />
      <Stack className="dialog-content" direction="row" flexWrap="wrap">
        <Typography>
          Are you sure you want to leave this workspace? Doing so will remove any access to any aspect of this workspace. Any entered jobs
          and deliverables will remain, but your details will be replaced with <strong>Deactivated User</strong>
        </Typography>
      </Stack>
      <Stack className="dialog-footer" justifyContent="end" direction="row" columnGap="8px">
        <Button variant="outlined" disabled={isPending} onClick={() => closeModal(ModalPages.LEAVE_WORKSPACE)}>
          Cancel
        </Button>
        <Button variant="contained" disabled={isPending} onClick={() => mutate()}>
          Yes
        </Button>
      </Stack>
    </Stack>
  )
}
