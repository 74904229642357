import { Avatar, AvatarGroup, Badge, Card, GlobalStyles, Icon, Tooltip } from '@andromeda'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Modal,
  Pagination,
  Popover,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { STATUS_BG_COLORS, TYPE_CHIP_STYLES } from 'src/components/job-card/job-card'
import useDebouncedCallback from './useDebouncedCallback'
import { Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import {
  globalPageSearch,
  globalSearch,
  resetSearch,
  resetSearchPageResults,
  selectSearch,
  setSearchKeyword,
} from '@redux/reducers/searchReducer'
import Link from 'next/link'
import { PathEnums } from '@utils/context/Navigation.context'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import useDebounce from './useDebounce'
import { trackEvent } from '@utils/tracking/helpers'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { useRouter } from 'next/router'

type IconTypeProps = { model: 'job' | 'deliverable' | 'talent' | 'user'; status?: string; profile_image: string | null }
const IconType = ({ model, status, profile_image }: IconTypeProps) => {
  if (model === 'job' && status) {
    return <Icon.Layers color={STATUS_BG_COLORS[status]} />
  }

  if (model === 'deliverable' && status)
    return <Badge style={{ width: '16px', height: '16px', backgroundColor: STATUS_BG_COLORS[status] }} />

  if (model === 'talent' || model === 'user')
    return (
      <Avatar
        size="xs"
        image={profile_image === '' || !profile_image ? undefined : profile_image}
        placeHolder={<Icon.User size={GlobalStyles.AVATAR_SIZES.XS} color={GlobalStyles.SLATE_200} strokeWidth={1} />}
      />
    )

  return <></>
}

const GLOBAL_SEARCH_INPUT_ID = 'header-global-search-input'
export const useGlobalSearch = (context: 'page' | 'popup') => {
  const router = useRouter()

  const dispatch = useAppDispatch()
  const { results, meta, pageMeta, searchKeyword: searchKeywordFromReducer, searchStatus, pageResults } = useAppSelector(selectSearch)
  const { workspace } = useAppSelector(selectWorkspace)

  const [searchString, setSearchString] = useState('')
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | HTMLDivElement | null>(null)
  const [hoverCoordinates, setHoverCoordinates] = useState<{ x: number; y: number } | null>(null)
  const [hoverData, setHoverData] = useState<Types.Search.iSearchResult | null>(null)
  const [selectedTab, setSelectedTab] = useState('all')
  const [globalSearchModalOpen, setGlobalSearchModalOpen] = useState(false)
  const [page, setPage] = useState(1)
  const debouncedSearchStatus = useDebounce(searchStatus, 500)

  const searchResults = useMemo(() => {
    if (context === 'page') {
      return pageResults
    }
    return results
  }, [results, pageResults, context])
  const pagination = useMemo(() => {
    let currentPage = 0
    let totalPage = 0

    if (context === 'page') {
      if (pageMeta?.pagination) {
        currentPage = pageMeta?.pagination.current_page || 0
        totalPage = pageMeta?.pagination.total_number_of_pages || 0
      }
    } else {
      if (meta?.pagination) {
        currentPage = meta?.pagination.current_page || 0
        totalPage = meta?.pagination.total_number_of_pages || 0
      }
    }

    return {
      currentPage,
      totalPage,
      nextPage: currentPage + 1 > totalPage ? 0 : currentPage + 1,
      previousPage: currentPage - 1 <= 0 ? 0 : currentPage - 1,
    }
  }, [context, meta?.pagination, pageMeta?.pagination])

  const modelCount = useMemo(() => {
    let job = 0,
      deliverable = 0,
      user = 0,
      talent = 0
    ;(context === 'page' ? pageMeta : meta)?.aggregations?.model?.buckets.forEach(({ key, doc_count }) => {
      switch (key.toLowerCase()) {
        case 'job':
          job = doc_count
          break
        case 'deliverable':
          deliverable = doc_count
          break
        case 'user':
          user = doc_count
          break
        case 'talent':
          talent = doc_count
          break
      }
    })
    return { job, deliverable, user, talent }
  }, [meta?.aggregations?.model?.buckets, pageMeta?.aggregations?.model?.buckets])

  useEffect(() => {
    setSelectedTab('all')
  }, [searchKeywordFromReducer])

  useEffect(() => {
    return () => {
      if (context === 'page') dispatch(resetSearchPageResults())
    }
  }, [context, dispatch])

  useEffect(() => {
    if (searchKeywordFromReducer)
      dispatch(
        globalPageSearch({
          keyword: searchKeywordFromReducer,
          models: selectedTab === 'all' ? [] : [selectedTab.toUpperCase()],
          page: page.toString(),
        })
      )
  }, [dispatch, page, searchKeywordFromReducer, selectedTab, context])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const globalSearchFn = useDebouncedCallback((keyword) => {
    if (context === 'page') {
      dispatch(
        globalPageSearch({
          keyword,
          models: [],
          page: '1',
        })
      )
    } else {
      dispatch(globalSearch({ keyword, models: [], page: page.toString() }))
    }
    trackEvent({ event: TrackingEventEnums.Others.GLOBAL_SEARCH, eventProperties: { workspace, keyword } })
  }, 500)

  // https://stackoverflow.com/a/67244182
  const searchStringRef = useRef('')
  useEffect(() => {
    searchStringRef.current = searchString
  }, [searchString])

  useEffect(() => {
    const globalSearchShortcuts = (event: KeyboardEvent) => {
      if (document.activeElement?.tagName === 'BODY' && event.key === '/') {
        setGlobalSearchModalOpen(true)
      } else if (document.activeElement?.id === GLOBAL_SEARCH_INPUT_ID && event.key === 'Enter') {
        dispatch(
          globalPageSearch({
            keyword: searchStringRef.current,
            models: [],
            page: '1',
          })
        )
        setAnchorEl(null)
        if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
        trackEvent({ event: TrackingEventEnums.Others.GLOBAL_SEARCH_SHOW_MORE_RESULTS, eventProperties: { workspace } })
        if (!router.pathname.includes('search')) {
          router.push({ query: { workspace }, pathname: PathEnums.SEARCH })
        }
      } else {
        switch (event.key) {
          case 'Escape':
            setGlobalSearchModalOpen(false)
            setSearchString('')
            if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
        }
      }
    }

    document.addEventListener('keydown', globalSearchShortcuts, false)

    return () => {
      document.removeEventListener('keydown', globalSearchShortcuts, false)
    }
  }, [dispatch, router, workspace])

  useEffect(() => {
    if (hoverData) {
      trackEvent({ event: TrackingEventEnums.Others.GLOBAL_SEARCH_HOVER, eventProperties: { workspace } })
    }
  }, [hoverData, workspace])

  const filterResultsByCategory = (model: string) => {
    if (context === 'page') {
      dispatch(
        globalPageSearch({
          page: '1',
          keyword: searchString.length ? searchString : searchKeywordFromReducer,
          models: model === 'all' ? [] : [model.toUpperCase()],
        })
      )
    } else {
      dispatch(
        globalSearch({
          page: '1',
          keyword: searchString.length ? searchString : searchKeywordFromReducer,
          models: model === 'all' ? [] : [model.toUpperCase()],
        })
      )
    }
    trackEvent({ event: TrackingEventEnums.Others.GLOBAL_SEARCH_SWITCH_TAB, eventProperties: { workspace, model } })
  }

  const debouncedFilterByCategory = useDebouncedCallback(filterResultsByCategory, 500)

  const renderHoverDetails = () =>
    hoverCoordinates &&
    hoverData && (
      <Stack
        width="334px"
        zIndex={10000}
        position="absolute"
        top={hoverCoordinates?.y + 5}
        left={hoverCoordinates.x + 20}
        style={{
          backgroundColor: 'white',
          padding: '8px 24px',
          borderRadius: '6px',
          boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.25)',
        }}>
        <Stack direction="row" alignItems="center" columnGap="10px">
          <Badge
            style={{
              width: '16px',
              height: '16px',
              backgroundColor: STATUS_BG_COLORS[hoverData.status ? hoverData.status.toLowerCase() : 0],
            }}
          />
          <Stack flex={1}>
            <Typography color={GlobalStyles.MONO_BLACK} fontSize="14px" fontWeight={500}>
              {hoverData.name}
            </Typography>
          </Stack>

          {!!hoverData.types?.length && <Chip size="small" label={hoverData.types?.[0]} style={TYPE_CHIP_STYLES} />}
        </Stack>
        <Stack direction="row" alignItems="center" columnGap="10px">
          <Box width="16px" />
          <Stack flex={1}>
            {hoverData.start_datetime && (
              <Typography color={GlobalStyles.SLATE_700} fontWeight={500} fontSize="12px">{`${moment(hoverData.start_datetime).format(
                'DD MMM YY'
              )}${hoverData.end_datetime ? ` - ${moment(hoverData.end_datetime).format('DD MMM YY')}` : ''}`}</Typography>
            )}
          </Stack>
          {!!hoverData.talents?.length && (
            <AvatarGroup offset={3} extraAvatarProps={{ size: 'xs' }}>
              {hoverData.talents?.map((talent) => (
                <Tooltip key={talent?.eid} text={talent?.name ?? ''} showIcon={false}>
                  <Avatar
                    key={talent?.eid}
                    size="xs"
                    image={talent?.profile_image === '' ? undefined : talent?.profile_image}
                    placeHolder={<Icon.User size={GlobalStyles.AVATAR_SIZES.XS - 5} color={GlobalStyles.SLATE_200} strokeWidth={1} />}
                    style={{
                      borderWidth: 2,
                      borderColor: GlobalStyles.SLATE_200,
                    }}
                  />
                </Tooltip>
              ))}
            </AvatarGroup>
          )}
        </Stack>
      </Stack>
    )

  const renderResults = (isFullPage: boolean, inputId: string = 'full-page-results') => (
    <Stack
      width={isFullPage ? '100%' : { sm: '800px' }}
      height={isFullPage ? 'auto' : { sm: '530px' }}
      maxHeight={{ xxs: '80vh' }}
      paddingX={{ xxs: isFullPage ? 0 : '12px', sm: '32px' }}
      rowGap="24px"
      boxSizing="border-box">
      <Stack display={{ xxs: 'flex', sm: 'none' }}>
        <TextField
          sx={{ backgroundColor: 'white' }}
          onChange={(e) => {
            globalSearchFn(e.target.value)
          }}
          InputProps={{
            startAdornment: <Icon.Search size={16} color={GlobalStyles.SLATE_300} style={{ paddingRight: '4px' }} />,
            endAdornment: searchString ? (
              <Button
                variant="text"
                onClick={(e) => {
                  setSearchString('')
                  dispatch(resetSearch())
                  dispatch(setSearchKeyword(''))
                }}>
                <Stack columnGap="16px" direction="row" alignItems="center">
                  <Typography display={{ xxs: 'none', sm: 'initial' }} color={GlobalStyles.PRIMARY_500}>
                    Clear
                  </Typography>
                  <Icon.X size={14} color={GlobalStyles.PRIMARY_500} />
                </Stack>
              </Button>
            ) : (
              <Typography fontSize="12px" style={{ paddingLeft: '16px' }}>
                /
              </Typography>
            ),
          }}
        />
      </Stack>
      {searchResults?.length ? (
        <Stack paddingTop="24px" alignItems="center" direction="row" justifyContent="space-between" display={{ xxs: 'flex', sm: 'none' }}>
          <Stack direction="row" columnGap="10px" paddingX={{ xxs: '12px', sm: '0' }}>
            {`${pageMeta?.pagination.total_results ?? 0} results found for "${
              searchString.length ? searchString : searchKeywordFromReducer
            }"`}
            {!isFullPage && searchStatus === 'loading' && <CircularProgress size={23} color="info" />}
          </Stack>
        </Stack>
      ) : (
        <></>
      )}
      <Stack paddingTop="24px" alignItems="center" direction="row" justifyContent="space-between" display={{ xxs: 'none', sm: 'flex' }}>
        <Stack direction="row" columnGap="10px">
          {`${meta?.pagination.total_results ?? 0} results found for "${searchString.length ? searchString : searchKeywordFromReducer}"`}
          {!isFullPage && searchStatus === 'loading' && <CircularProgress size={23} color="info" />}
        </Stack>
        {!isFullPage && (
          <Button sx={{ display: { xxs: 'flex', sm: 'none' } }} variant="text" onClick={handleClose}>
            <Icon.X size={16} />
          </Button>
        )}
      </Stack>
      <Stack marginLeft={isFullPage ? 'auto' : 0}>
        <Tabs
          sx={{ '.MuiTabs-scroller': { overflowX: 'auto !important' } }}
          value={selectedTab}
          onChange={(_, val) => {
            setPage(1)
            setSelectedTab(val)
            debouncedFilterByCategory(val)
            document.getElementById(inputId as string)?.focus()
          }}>
          <Tab
            color={GlobalStyles.MONO_BLACK}
            label={`All Results (${(context === 'page' ? pageMeta?.pagination.total_results : meta?.pagination.total_results) ?? 0})`}
            value="all"
          />
          <Tab label={`Jobs (${modelCount.job})`} value="job" />
          <Tab label={`Deliverables (${modelCount.deliverable})`} value="deliverable" />
          <Tab label={`Users (${modelCount.user})`} value="user" />
          <Tab label={`Talent (${modelCount.talent})`} value="talent" />
        </Tabs>
      </Stack>
      <Stack flex={1} rowGap="6px" style={{ ...(isFullPage ? { backgroundColor: 'white', padding: '12px', borderRadius: '6px' } : {}) }}>
        {!searchResults?.length ? (
          <Box display={{ xxs: 'block', sm: 'none' }}>
            <Card.Error
              errorIcon={<Icon.Search color={GlobalStyles.PRIMARY_500} size={60} />}
              heading="Search jobs, deliverables and users within the workspace"
              subHeading=" "
            />
          </Box>
        ) : (
          <></>
        )}
        {searchResults?.length ? (
          searchResults.map((data) => (
            <Link
              key={data.eid}
              href={data.model.toLowerCase() === 'user' ? `/${workspace}/directory/users/${data.eid}` : data.redirect_url}
              style={{ textDecoration: 'none' }}
              onClick={() => {
                trackEvent({ event: TrackingEventEnums.Others.GLOBAL_SEARCH_RESULT_CLICK, eventProperties: { workspace } })
              }}>
              <Stack
                aria-describedby={`search-item-details-${data.eid}`}
                direction="row"
                alignItems="center"
                columnGap="12px"
                paddingY="6px">
                <Stack height="30px" width="30px" justifyContent="center" alignItems="center">
                  <IconType
                    model={data.model.toLowerCase() as unknown as IconTypeProps['model']}
                    status={data.status?.toLowerCase()}
                    profile_image={data.profile_image}
                  />
                </Stack>
                <Stack
                  width={isFullPage ? '310px' : '225px'}
                  onMouseEnter={(e) => {
                    setHoverCoordinates({ x: e.clientX, y: e.clientY })
                    if (['job', 'deliverable'].some((model) => model === data.model.toLowerCase())) {
                      setHoverData(data)
                    }
                  }}
                  onMouseLeave={() => {
                    setHoverCoordinates(null)
                    setHoverData(null)
                  }}>
                  <Typography fontSize="14px" color={GlobalStyles.MONO_BLACK}>
                    {data.name}
                  </Typography>
                  {data.model !== 'talent profile' && (
                    <Typography fontSize="10px" color={GlobalStyles.SLATE_700}>
                      {data.eid}
                    </Typography>
                  )}
                </Stack>
                <Typography fontSize="10px" color={GlobalStyles.SLATE_400} display={{ xxs: 'none', sm: 'initial' }}>
                  {data.model.toUpperCase()}
                </Typography>
                <Box marginLeft="auto">
                  <Typography fontSize="12px" color={GlobalStyles.SLATE_400}>
                    {data.model === 'talent profile'
                      ? 'Last active 10 Feb 2022'
                      : data.updated_at
                      ? `Last updated at ${moment(data.updated_at).format('DD MMM YYYY')}`
                      : ''}
                  </Typography>
                </Box>
              </Stack>
            </Link>
          ))
        ) : (
          <></>
        )}
        {debouncedSearchStatus !== 'loading' && searchResults?.length === 0 && (
          <Box display={{ xxs: 'none', sm: 'block' }}>
            <Card.Error heading="No Results" subHeading="" errorIcon={<Icon.Search color={GlobalStyles.PRIMARY_500} size={60} />} />
          </Box>
        )}
      </Stack>
      {isFullPage ? (
        <Stack alignItems="center" width="100%">
          <Pagination
            page={page}
            size="large"
            count={pagination?.totalPage}
            shape="rounded"
            onChange={(_, page) => {
              setPage(page)
            }}
          />
        </Stack>
      ) : (
        <Stack alignItems="flex-end" paddingBottom="24px">
          {!!results?.length ? (
            <Link
              href={{ query: { workspace }, pathname: PathEnums.SEARCH }}
              style={{ textDecoration: 'none' }}
              onClick={() => {
                dispatch(setSearchKeyword(searchString))
                dispatch(
                  globalPageSearch({
                    keyword: searchString,
                    models: [],
                    page: '1',
                  })
                )
                setAnchorEl(null)
                trackEvent({ event: TrackingEventEnums.Others.GLOBAL_SEARCH_SHOW_MORE_RESULTS, eventProperties: { workspace } })
              }}>
              <Button variant="outlined">Show more results</Button>
            </Link>
          ) : (
            <></>
          )}
        </Stack>
      )}
    </Stack>
  )

  const renderSearchPopover = (params?: { inputId?: string; isHeader?: boolean }) => {
    const { inputId, isHeader } = params || { inputId: GLOBAL_SEARCH_INPUT_ID, isHeader: true }

    return (
      <>
        <Link href={{ query: { workspace }, pathname: PathEnums.SEARCH }} style={{ textDecoration: 'none' }}>
          <IconButton sx={{ display: { sm: 'none' }, padding: 0 }}>
            <Icon.Search color="white" size={20} />
          </IconButton>
        </Link>
        <TextField
          sx={{
            display: { xxs: 'none', sm: 'inline-flex' },
            width: '100%',
            borderRadius: '8px',
            '.MuiInputBase-input::placeholder': {
              color: `white !important`,
            },
            '.MuiInputBase-input': {
              padding: '10px 0',
              maxHeight: '40px',
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            ...(searchString
              ? {
                  backgroundColor: 'white',
                  color: GlobalStyles.SLATE_300,
                }
              : {
                  backgroundColor: GlobalStyles.SLATE_700,
                  color: GlobalStyles.SLATE_300,
                }),
            '.Mui-focused': {
              backgroundColor: 'white',
              color: GlobalStyles.SLATE_300,
              '.MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${GlobalStyles.SLATE_300}`,
              },
              svg: {
                stroke: GlobalStyles.SLATE_300,
              },
            },
          }}
          onClick={handleClick}
          onChange={(e) => {
            setSearchString(e.target.value)
            globalSearchFn(e.target.value)
          }}
          InputProps={{
            inputProps: {
              'aria-label': 'global search',
            },
            id: inputId,
            style: { color: GlobalStyles.SLATE_300 },
            startAdornment: (
              <Icon.Search size={16} color={searchString ? GlobalStyles.SLATE_300 : 'white'} style={{ paddingRight: '4px' }} />
            ),
            endAdornment: searchString ? (
              <Button
                variant="text"
                onClick={(e) => {
                  setSearchString('')
                  dispatch(resetSearch())
                  dispatch(setSearchKeyword(''))
                }}>
                <Stack columnGap="16px" direction="row" alignItems="center">
                  <Typography display={{ xxs: 'none', sm: 'initial' }} color={GlobalStyles.PRIMARY_500}>
                    Clear
                  </Typography>
                  <Icon.X size={14} color={GlobalStyles.PRIMARY_500} />
                </Stack>
              </Button>
            ) : (
              <Typography fontSize="12px" style={{ paddingLeft: '16px' }}>
                /
              </Typography>
            ),
          }}
          placeholder={'Search jobs, deliverables and users within the workspace'}
        />
        <Popover
          id="global-search"
          open={!!anchorEl && !!searchString.length}
          anchorEl={anchorEl}
          onClose={() => {
            handleClose()
            setSelectedTab('all')
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{ marginTop: '4px', marginLeft: '' }}
          disableAutoFocus
          disableEnforceFocus>
          {renderResults(false, inputId)}
        </Popover>
        {isHeader && renderHoverDetails()}
      </>
    )
  }

  const renderSearchModal = () => (
    <>
      <Modal
        open={globalSearchModalOpen}
        onClose={() => {
          setGlobalSearchModalOpen(false)
          setSearchString('')
        }}>
        <Box
          sx={{
            width: { sm: '800px' },
            position: 'absolute',
            top: '100px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          {renderSearchPopover({ inputId: 'modal-global-search-input', isHeader: false })}
        </Box>
      </Modal>
      {renderHoverDetails()}
    </>
  )

  return {
    renderSearchPopover,
    renderSearchModal,
    renderResults,
    renderHoverDetails,
  }
}
