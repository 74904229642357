import { GlobalStyles } from '@andromeda'
import { TextField as MUITextField, TextFieldProps } from '@mui/material'
import { Controller, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form'

export type tTextfield = TextFieldProps & { name: string; registerOptions?: RegisterOptions<FieldValues, string> }
export const Textfield = ({ name, registerOptions, ...props }: tTextfield) => {
  const { formState, control } = useFormContext()
  const error = Object.keys(formState.errors).length
    ? name.split('.').length === 1
      ? formState.errors[name]
      : // @ts-expect-error
        name.split('.').reduce((acc, curr) => {
          return acc?.[curr]
        }, formState.errors)
    : undefined

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true, ...registerOptions }}
      render={({ field: { ref, value, onChange } }) => (
        <MUITextField
          inputProps={{ 'aria-label': name, role: 'textbox' }}
          error={!!error}
          sx={{
            input: {
              color: `${GlobalStyles.SLATE_700} `,
            },
            'input::placeholder': {
              color: `${GlobalStyles.SLATE_500} !important`,
            },
            '.MuiFormHelperText-root': {
              marginX: '6px',
            },
            '.MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
            },
            ...(props.InputProps?.startAdornment ? { '.MuiOutlinedInput-root': { paddingLeft: 0 } } : {}),
            '.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiInputBase-root>.MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent !important',
            },
            ...props.sx,
          }}
          // @ts-expect-error
          helperText={error?.message ?? ''}
          inputRef={ref}
          {...props}
          value={value}
          onChange={onChange}
        />
      )}
    />
  )
}
