import { API, Job } from '../types'
import { Importers } from '../types/importers'
import { HttpClient } from './api.service'

export const importService = {
  listImporters: ({ page }: { page: string }) => {
    return HttpClient.get<null, Importers.iListImporterResponse>({
      endpoint: API.ImportEndpoints.IMPORTER.concat(`?page=${page}`),
      payload: null,
    })
  },
  createImporter: ({ type, files }: { type: string } & Importers.iCreateImporterPayload) => {
    return HttpClient.put<Importers.iCreateImporterPayload, null>({
      endpoint: API.ImportEndpoints.IMPORTER.concat(`/${type.toLowerCase()}`),
      payload: { files },
    })
  },
  readImporter: (eid: string, { page }: { page: string }) => {
    return HttpClient.get<null, Importers.iImporter>({
      endpoint: API.ImportEndpoints.READ.replace(':eid', eid).concat(`?page=${page}`),
      payload: null,
    })
  },
  startImporter: (eid: string) => {
    return HttpClient.get<null, Importers.iImporter>({
      endpoint: API.ImportEndpoints.START.replace(':eid', eid),
      payload: null,
    })
  },
  generateFileUploadUrl: ({ eid, file_names, type }: { eid?: string; type: string } & Importers.iGenerateFileUploadUrlPayload) => {
    return HttpClient.post<Importers.iGenerateFileUploadUrlPayload, Job.iFile[]>({
      endpoint: `${API.ImportEndpoints.GENERATE_FILE_UPLOAD_URL}`
        .replace(API.ImportEndpoints.IMPORTER, eid ? `${API.ImportEndpoints.IMPORTER}/${eid}` : API.ImportEndpoints.IMPORTER)
        .concat(`/${type.toLowerCase()}`),
      payload: { file_names },
    })
  },
}
