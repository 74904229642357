import { GlobalStyles, Icon } from '@andromeda'
import { DevTool } from '@hookform/devtools'
import { Autocomplete, Button, Chip, FormControl, Stack, TextField, Typography } from '@mui/material'
import { Types } from '@orbit'
import { useAppDispatch } from '@redux/hooks'
import { listWorkspaceTalents } from '@redux/reducers/talentReducer'
import useDebouncedCallback from '@utils/hooks/useDebouncedCallback'
import { FilterOptions } from '@utils/types/filter'
import { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

const FILTERS = [
  {
    eid: '1',
    label: 'Industry',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '1',
        value: 'Industry 1',
        parent_eid: '1',
      },
      {
        eid: '2',
        value: 'Industry 2',
        parent_eid: '1',
      },
    ],
  },
  {
    eid: '2',
    label: 'Location',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '3',
        value: 'Location 1',
        parent_eid: '2',
      },
      {
        eid: '4',
        value: 'Location 2',
        parent_eid: '2',
      },
    ],
  },
  {
    eid: '3',
    label: 'Gender',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '5',
        value: 'Male',
        parent_eid: '3',
      },
      {
        eid: '6',
        value: 'Female',
        parent_eid: '3',
      },
    ],
  },
  {
    eid: '4',
    label: 'Topics',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '7',
        value: 'Topics 1',
        parent_eid: '4',
      },
      {
        eid: '8',
        value: 'Topics 2',
        parent_eid: '4',
      },
    ],
  },
  {
    eid: '5',
    label: 'Agent',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '9',
        value: 'Agent 1',
        parent_eid: '5',
      },
      {
        eid: '10',
        value: 'Agent 2',
        parent_eid: '5',
      },
    ],
  },
  {
    eid: '6',
    label: 'Job Categories',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '11',
        value: 'Job Categories 1',
        parent_eid: '6',
      },
      {
        eid: '12',
        value: 'Job Categories 2',
        parent_eid: '6',
      },
    ],
  },
  {
    eid: '7',
    label: 'Social Followers',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '13',
        value: 'Social Followers 1',
        parent_eid: '7',
      },
      {
        eid: '14',
        value: 'Social Followers 2',
        parent_eid: '7',
      },
    ],
  },
  {
    eid: '8',
    label: 'Interests',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '15',
        value: 'Agent 1',
        parent_eid: '8',
      },
      {
        eid: '16',
        value: 'Agent 2',
        parent_eid: '8',
      },
    ],
  },
]

export const SearchFilter = ({
  SearchLeftSide,
  searchPlaceholder,
  onChangeFilter,
  centered = false,
}: {
  SearchLeftSide?: JSX.Element
  searchPlaceholder?: string
  onChangeFilter: (params: any) => void
  centered?: boolean
}) => {
  const dispatch = useAppDispatch()

  const [showFilters, setShowFilters] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<FilterOptions[]>([])
  const [searchKeys, setSearchKeys] = useState<string[]>([])
  const [queryString, setQueryString] = useState<string>('')

  const form = useForm()

  const filters = useMemo(() => (showMore ? FILTERS : FILTERS.slice(0, 3)), [showMore])

  const handleSearch = (e: any) => {
    const searchPillKey = queryString.toLowerCase().replace(/[^a-z-A-Z0-9]/g, '')
    const searchPill = { eid: searchPillKey, value: queryString }
    const key: string = queryString.trim()

    if (key && !selectedFilters.find((selected) => selected.eid === searchPillKey)) {
      setSelectedFilters([...selectedFilters, searchPill])
      const newSearchKeys: Array<string> = [...searchKeys, key]
      setSearchKeys(newSearchKeys)

      const params: Types.Talent.iTalentListParams = { page: '1' }
      if (newSearchKeys.length) params.query = newSearchKeys
      onChangeFilter(params)
    }
  }

  const debouncedHandleSearch = useDebouncedCallback(handleSearch, 500)

  useEffect(() => {
    debouncedHandleSearch()
  }, [debouncedHandleSearch, queryString])

  const _setFilterFromDropdown = (e: any, parentId: string) => {
    if (e.eid === 'all') {
      const newSelectedFilters = selectedFilters.filter((filter) => filter.parent_eid !== parentId)
      setSelectedFilters(newSelectedFilters)
    } else {
      if (selectedFilters.indexOf(e) === -1) setSelectedFilters([...selectedFilters, e])
    }
  }
  const _removeFilterFromDropdown = (index: number) => {
    const newSelectedFitlers: FilterOptions[] = []
    let key: FilterOptions | null = null
    selectedFilters.forEach((pill: any, pillIndex: number) => {
      if (index !== pillIndex) newSelectedFitlers.push(pill)
      else key = pill
    })

    let newParams: Types.Talent.iTalentListParams = { page: '1' }
    if (key) {
      const newSearchKeys: string[] = []
      searchKeys.forEach((str) => {
        if (str !== key?.value) newSearchKeys.push(str)
      })
      setSearchKeys(newSearchKeys)
      if (newSearchKeys.length) newParams.query = newSearchKeys
    }

    if (selectedFilters[index] && queryString === selectedFilters[index].value) {
      setQueryString('')
    }

    setSelectedFilters(newSelectedFitlers)
    onChangeFilter({ query: '', ...newParams })
    dispatch(listWorkspaceTalents({ params: newParams }))
  }

  const clearFilters = () => {
    onChangeFilter({ query: '' })
    dispatch(listWorkspaceTalents({ params: { page: '1' } }))
    setQueryString('')
    setSelectedFilters([])
    form.reset(FILTERS.map(({ label }) => ({ [label]: { eid: 'all', value: 'All' } })))
  }

  return (
    <Stack padding="24px" rowGap="24px">
      <Stack direction="row" justifyContent="space-between" marginTop="10px">
        {SearchLeftSide}
        <TextField
          placeholder={searchPlaceholder}
          InputProps={{ startAdornment: <Icon.Search size={18} /> }}
          sx={centered ? { width: '100%' } : { marginLeft: 'auto', minWidth: '300px' }}
          onChange={(e) => setQueryString(e.target.value)}
        />
      </Stack>
      <Stack direction="row" justifyContent="end">
        <Stack direction="row" justifyContent="space-between" width={centered ? '100%' : '300px'}>
          <Button variant="outlined" color="primary" onClick={clearFilters}>
            Clear Filters
          </Button>
          <Button variant="outlined" color="primary" onClick={() => setShowFilters((prev) => !prev)} startIcon={<Icon.Sliders size={15} />}>
            Filters
          </Button>
        </Stack>
      </Stack>
      {showFilters && (
        <Stack direction="row" justifyContent={centered ? 'start' : 'end'} gap="8px" flexWrap="wrap">
          <form>
            <FormProvider {...form}>
              <Stack direction="row" justifyContent={centered ? 'start' : 'end'} gap="8px" flexWrap="wrap">
                <DevTool control={form.control} placement="top-left" />
                {filters.map((filter) => (
                  <Stack direction="row" key={filter.label} alignItems="center" columnGap="8px">
                    <Typography variant="h6">{filter.label}</Typography>
                    <FormControl variant="standard">
                      <Controller
                        name={filter.label}
                        control={form.control}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            size="small"
                            sx={{ minWidth: '80px' }}
                            disableClearable
                            options={filter.options}
                            key={value}
                            renderInput={(params) => <TextField {...params} variant="standard" />}
                            defaultValue={filter.options[0]}
                            value={value}
                            getOptionLabel={(option) => option.value}
                            onChange={(e: any, val) => {
                              onChange(val)
                              _setFilterFromDropdown(val, filter.eid as string)
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                ))}
              </Stack>
            </FormProvider>
          </form>
          <Button
            variant="text"
            startIcon={showMore ? <Icon.Minus /> : <Icon.Plus />}
            onClick={() => setShowMore((prev) => !prev)}
            sx={centered ? { width: '100%' } : {}}>
            {showMore ? 'Show Less' : 'Show More'}
          </Button>
        </Stack>
      )}

      <Stack direction="row" flexWrap="wrap" rowGap="14px" columnGap="8px" justifyContent="end">
        {selectedFilters.map(({ eid, parent_eid, value }, index) => {
          const filterCategory = FILTERS.find((filter) => filter.eid === parent_eid)

          return (
            <Chip
              style={{
                color: GlobalStyles.MONO_BLACK,
                backgroundColor: GlobalStyles.SLATE_100,
                fontSize: `${GlobalStyles.FONT_SIZES.TINY}px`,
                padding: '6px',
                borderRadius: '12px',
              }}
              key={eid}
              size="small"
              label={
                <Stack alignItems="center" direction="row" gap="5px">
                  {`${filterCategory ? filterCategory.label + ':' : ''} ${value}`}
                  <Icon.XCircle size={13} cursor="pointer" onClick={() => _removeFilterFromDropdown(index)} />
                </Stack>
              }
            />
          )
        })}
      </Stack>
    </Stack>
  )
}
