import { Avatar, Button, GlobalStyles, Icon, Stack, Status, Text, View } from '@andromeda'
import { ModalHeader } from '@components'
import { AppStyles } from '@styles'
import { ModalContext } from '@utils/context/Modal.context'
import { TALENT_STATUS } from '@utils/enum/status/talent'
import { useRouter } from 'next/router'
import { useContext } from 'react'

const UserProfile = (): JSX.Element => {
  const router = useRouter()
  const { workspace } = router.query
  const { closeModal, options } = useContext(ModalContext)

  const { userDetails = null, avatar, placeholderName } = options?.modalProps?.userProfile

  const placeHolder = (
    <View
      style={{
        width: AppStyles.AVATAR_SIZES.MODAL,
        height: AppStyles.AVATAR_SIZES.MODAL,
        borderRadius: AppStyles.AVATAR_SIZES.MODAL,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Text style={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.XTRA_LARGE }}>{placeholderName}</Text>
    </View>
  )
  return (
    <Stack
      rounded
      direction="column"
      space="none"
      style={{
        backgroundColor: GlobalStyles.MONO_WHITE,
        height: 'fit-content',
      }}
      align="flex-start">
      <ModalHeader modalTitle={''} modalContext={'userProfile'} />

      <Stack
        direction="column"
        space="lg"
        style={{
          paddingBottom: AppStyles.MODAL_PADDING_HORIZONTAL,
          paddingHorizontal: AppStyles.MODAL_PADDING_VERTICAL,
        }}>
        <Avatar
          placeHolder={placeHolder}
          image={options?.modalProps?.userProfile.avatar || undefined}
          style={{
            width: AppStyles.AVATAR_SIZES.MODAL,
            height: AppStyles.AVATAR_SIZES.MODAL,
            borderRadius: AppStyles.AVATAR_SIZES.MODAL,
          }}
          imageStyle={{ width: AppStyles.AVATAR_SIZES.MODAL, height: AppStyles.AVATAR_SIZES.MODAL }}
        />

        <Stack direction="column" space="2xs">
          <Stack direction="row" space="2xs" align="center" justify="center">
            {userDetails.status ? (
              <Status variant="rounded" type={userDetails.status === TALENT_STATUS.ACTIVE ? 'LIVE' : 'DRAFT'} />
            ) : (
              <></>
            )}
            <Text type="heading" bold>
              {userDetails?.display_name || ''}
            </Text>
          </Stack>

          <Text>{userDetails?.role || ''}</Text>
        </Stack>

        <Stack direction="column" space="2xs">
          <Text bold>Primary Manager</Text>

          <Stack direction="row" space="2xs" align="center" justify="center">
            <Status variant="rounded" type={'LIVE'} />
            <Text>{`${userDetails.active_managers?.map((manager: { name: string }) => manager.name)}`}</Text>
          </Stack>
        </Stack>
      </Stack>

      <View
        style={{
          width: '100%',
          shadowColor: 'rgba(0, 0, 0, 0.05)',
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.9,
          shadowRadius: 30,
          elevation: 5,
        }}>
        <Stack
          direction="row"
          height={'auto'}
          align={'center'}
          justify={userDetails.messagingAllowd ? 'space-between' : 'center'}
          style={{
            paddingVertical: GlobalStyles.PADDING_SIZES.md,
            paddingHorizontal: GlobalStyles.PADDING_SIZES.lg,
          }}>
          {userDetails.messagingAllowd && (
            <Button
              type="outlined"
              style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
              textStyle={{ color: GlobalStyles.PRIMARY_500 }}
              leftElement={
                <View>
                  <Icon.MessageSquare color={GlobalStyles.PRIMARY_500} />
                </View>
              }
              text="Message"
            />
          )}
          <Button
            type="outlined"
            style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
            onPress={() => {
              router.push(`/${workspace}/talent-profile/${userDetails.eid}`)
              closeModal('userProfile')
            }}
            textStyle={{ color: GlobalStyles.PRIMARY_500 }}
            leftElement={
              <View>
                <Icon.User color={GlobalStyles.PRIMARY_500} />
              </View>
            }
            text="View Profile"
          />
        </Stack>
      </View>
    </Stack>
  )
}

export { UserProfile }
