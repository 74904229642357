import moment from 'moment'
import { useRef, useState } from 'react'
import { DayPickerSingleDateController } from 'react-dates'
import 'react-dates/initialize'
import { Modal as NativeModal, Pressable, TouchableOpacity } from 'react-native'
import Input from '../Input/Input'
import { Stack } from '../Stack'
import { View } from '../View'
import { Text } from '../Text'
import { withModal } from './ModalHOC'
import { PickrStyle } from './style'
import { DateRangeDefaults, ModalPositionProps, SingleDatePickerProps } from './types'
import { GlobalStyles } from '../globalStyles'
import { Button } from '../Button'

const SingleDatePicker = ({
  dateId = DateRangeDefaults.DATE_ID,
  placeholder = DateRangeDefaults.START_PLACEHOLDER,
  daySize = DateRangeDefaults.DAY_SIZE,
  numberOfMonths = 1,
  openDirection = DateRangeDefaults.DIRECTION_DOWN,
  displayFormat = DateRangeDefaults.FORMAT,
  showClearDate = true,
  textInputProps,
  element,
  menuPosition,
  visible,
  didEnter,
  didExit = () => {},
  _onLayout,
  ...props
}: SingleDatePickerProps & ModalPositionProps) => {
  const [date, setDate] = useState<moment.Moment | null>(props.initialDate ? moment(props.initialDate, props.inputDisplayFormat) : null)
  const [, setFocusedIput] = useState(false)
  const elemRef = useRef<TouchableOpacity>(null)

  const _handleDatesChange = (changedDate: any) => {
    if (changedDate) {
      const formattedDate = changedDate.format(displayFormat)
      setDate(changedDate)
      if (props.onDateChange) {
        props.onDateChange(formattedDate)
      }
    }
  }

  const _handleFocusChange = ({ focused }: any) => {
    setFocusedIput(focused)
  }

  const _reset = () => {
    setDate(null)
    props.onClear && props.onClear()
  }

  const _confirm = () => {
    if (props.onConfirm) {
      props.onConfirm(date ? date.format(displayFormat) : null)
    }
    didExit && didExit()
  }

  return (
    <View style={[props.wrapperStyle]}>
      <Stack {...props.stackProps}>
        {props.leftElement ?? null}
        <TouchableOpacity
          ref={elemRef}
          disabled={props.disabled}
          onPress={(e) => didEnter && didEnter(elemRef)}
          style={[{ flex: 1 }, props.style]}>
          <Input
            placeholder={placeholder}
            value={date ? `${date.format(displayFormat)}` : ''}
            wrapperStyle={{ padding: 0 }}
            disabled={props.disabled}
            {...textInputProps}
          />
        </TouchableOpacity>
        {props.rightElement ?? null}
      </Stack>
      {props.errorMessage ? (
        <Text size={'sm'} style={[{ color: 'red' }, props.errorMessageStyle]}>
          {props.errorMessage}
        </Text>
      ) : null}
      <NativeModal visible={visible} animationType={'fade'} transparent>
        <Pressable onPress={_confirm} style={[{ flex: 1 }]}>
          <View style={[PickrStyle.container, props.containerStyle, menuPosition]} onLayout={_onLayout}>
            <Stack height={'auto'} direction={'column'} space={'none'}>
              <DayPickerSingleDateController
                initialVisibleMonth={null}
                date={date}
                focused={true}
                onFocusChange={_handleFocusChange}
                onDateChange={_handleDatesChange}
                numberOfMonths={numberOfMonths}
                daySize={daySize}
                hideKeyboardShortcutsPanel
                enableOutsideDays
              />
              <Stack
                height={'auto'}
                justify={'space-between'}
                style={{
                  paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
                  paddingBottom: GlobalStyles.PADDING_SIZES.md,
                }}>
                <Button
                  type={'primary'}
                  text={'Clear'}
                  textStyle={{ fontWeight: '500', color: GlobalStyles.PRIMARY_500 }}
                  transparent
                  onPress={_reset}
                />
                <Button type={'primary'} text={'Confirm'} textStyle={{ fontWeight: '500' }} onPress={_confirm} />
              </Stack>
            </Stack>
          </View>
        </Pressable>
      </NativeModal>
    </View>
  )
}
export default withModal(SingleDatePicker) as (props: SingleDatePickerProps) => JSX.Element
