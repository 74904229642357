import { autocompleteClasses } from '@mui/material'
import { Textfield, tTextfield } from './Textfield'
import { FormAutoComplete, tFormAutocomplete } from './AutoComplete'
import { convertUnicode } from '@utils/functions/helperFunctions'

export const CurrencyTextfield = ({ currency, currencyCode }: { currency: tTextfield; currencyCode: tFormAutocomplete }) => (
  <Textfield
    {...currency}
    InputProps={{
      startAdornment: (
        <FormAutoComplete
          disableClearable
          getOptionLabel={(option) => `${convertUnicode(option.symbol)} ${option.value}`}
          {...currencyCode}
          sx={{
            [`&.${autocompleteClasses.root} .${autocompleteClasses.inputRoot}`]: {
              width: '85px',
            },
          }}
        />
      ),
      'aria-label': 'Job Cost',
    }}
  />
)
