import { Orbit, Types } from '@orbit'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiResponseError } from '@utils/types/auth'
import { AvailabilityRange, iAccountsInitialState, iAvailability } from '@utils/types/my-accounts'
import { AppState } from '../store'

const initialState: iAccountsInitialState = {
  sendLinkStatus: 'idle',
  passwordResetStatus: 'idle',
  availability: {
    status: 'available',
    displayText: 'Currently Available for All Times',
    startDate: null,
    endDate: null,
    selectedRange: AvailabilityRange.CUSTOM,
  },
}

export const sendLink = createAsyncThunk('password/send-link', async (email: string, { rejectWithValue }): Promise<any> => {
  try {
    let response = await Orbit.Services.authService.sendLink(email)
    return response
  } catch (e) {
    return rejectWithValue((e as ApiResponseError).response.data)
  }
})

export const passwordReset = createAsyncThunk(
  'password/reset',
  async (passwordResetFields: Types.Authentication.iPasswordResetFields, { rejectWithValue }): Promise<any> => {
    try {
      let response = await Orbit.Services.authService.passwordReset(passwordResetFields)
      return response
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const accountsSlice = createSlice({
  name: 'myAccountsSlice',
  initialState,
  reducers: {
    resetMyAccountSlice: () => initialState,
    setAvailability: (state: iAccountsInitialState, action: PayloadAction<iAvailability>) => {
      state.availability = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendLink.fulfilled, (state, action) => {
        state.sendLinkStatus = 'success'
      })
      .addCase(sendLink.pending, (state, action) => {
        state.sendLinkStatus = 'loading'
      })
      .addCase(sendLink.rejected, (state, action) => {
        state.sendLinkStatus = 'failed'
      })
      .addCase(passwordReset.fulfilled, (state, action) => {
        state.passwordResetStatus = 'success'
      })
      .addCase(passwordReset.pending, (state, action) => {
        state.passwordResetStatus = 'loading'
      })
      .addCase(passwordReset.rejected, (state, action) => {
        state.passwordResetStatus = 'failed'
      })
  },
})

export const { setAvailability, resetMyAccountSlice } = accountsSlice.actions

export const { reducer } = accountsSlice
export const selectAccounts = (state: AppState) => state.accounts
