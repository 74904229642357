import { memo } from 'react'
import { ActivityIndicator } from 'react-native'
import { GlobalStyles } from '../globalStyles'
import { LoadingDefaults, LoadingProps } from './types'

const Loading = ({ style, type = LoadingDefaults.TYPE, size = LoadingDefaults.SIZE, ...props }: LoadingProps) => {
  const color = () => {
    let c: string

    switch (type) {
      case 'blue':
        c = GlobalStyles.PRIMARY_500
        break
      case 'yellow':
        c = GlobalStyles.STATUS_YIELD
        break
      case 'slate':
        c = GlobalStyles.MONO_BLACK
        break
      case 'white':
        c = GlobalStyles.MONO_WHITE
        break

      case 'danger':
        c = GlobalStyles.STATUS_NEGATIVE
        break

      default:
        c = GlobalStyles.PRIMARY_500
    }

    return c
  }

  return <ActivityIndicator size={size} color={color()} {...props} />
}

export default memo(Loading)
