import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { DayPickerRangeController, FocusedInputShape } from 'react-dates'
import 'react-dates/initialize'
import { Modal as NativeModal, Pressable, TouchableOpacity } from 'react-native'
import Input from '../Input/Input'
import { Stack } from '../Stack'
import { Text } from '../Text'
import { View } from '../View'
import { withModal } from './ModalHOC'
import { PickrStyle } from './style'
import { DateRangeDefaults, DateRangeProps, ModalPositionProps } from './types'
import { Button } from '../Button'
import { GlobalStyles } from '../globalStyles'
import { useScreenDimensions } from '../hooks/dimensions'

const DateRangePicker = ({
  dateId = DateRangeDefaults.DATE_ID,
  startDatePlaceholderText,
  endDatePlaceholderText,
  daySize = DateRangeDefaults.DAY_SIZE,
  numberOfMonths = DateRangeDefaults.NUM_OF_MONTHS,
  openDirection = DateRangeDefaults.DIRECTION_DOWN,
  displayFormat = DateRangeDefaults.FORMAT,
  showClearDates = true,
  textInputProps,
  element,
  menuPosition,
  visible,
  didEnter,
  didExit,
  _computePosition,
  _onLayout,
  ...props
}: DateRangeProps & ModalPositionProps) => {
  const { windowWidth } = useScreenDimensions()
  /**
   * Picker related states
   */
  const [savedStartDate, setSavedStartDate] = useState<moment.Moment | null>(
    props.initialStartDate ? moment(props.initialStartDate, props.inputDisplayFormat) : null
  )
  const [savedEndDate, setSavedEndDate] = useState<moment.Moment | null>(
    props.initialEndDate ? moment(props.initialEndDate, props.inputDisplayFormat) : null
  )
  const [focusedInput, setFocusedIput] = useState<FocusedInputShape | null>('startDate')
  const elemRef = useRef<TouchableOpacity>(null)

  const _handleDatesChange = ({ startDate, endDate }: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
    if (startDate !== null && endDate == null) {
      setSavedStartDate(startDate)
      setSavedEndDate(startDate)
      setFocusedIput('endDate')
    }
    if (startDate && endDate) {
      if (startDate <= endDate) {
        setSavedStartDate(startDate)
        setSavedEndDate(endDate)
      } else {
        setSavedStartDate(endDate)
        setSavedEndDate(null)
      }
    }

    if (props.onDateChange) {
      props.onDateChange({
        startDate: startDate && startDate.format(displayFormat),
        endDate: endDate && endDate.format(displayFormat),
      })
    }
  }

  useEffect(() => {
    setSavedStartDate(
      props.initialStartDate
        ? moment(props.initialStartDate, props.inputDisplayFormat)
        : props.initialEndDate
        ? moment(props.initialEndDate, props.inputDisplayFormat)
        : null
    )
    setSavedEndDate(
      props.initialEndDate
        ? moment(props.initialEndDate, props.inputDisplayFormat)
        : props.initialStartDate
        ? moment(props.initialStartDate, props.inputDisplayFormat)
        : null
    )
    if (!props.initialStartDate && !props.initialEndDate) setFocusedIput('startDate')
    else setFocusedIput('endDate')
  }, [props.initialStartDate, props.initialEndDate])

  const _reset = () => {
    setSavedStartDate(null)
    setSavedEndDate(null)
    setFocusedIput('startDate')
    props.onClear && props.onClear()
  }

  const _confirm = () => {
    if (props.onConfirm) {
      props.onConfirm({
        startDate: savedStartDate && savedStartDate.format(displayFormat),
        endDate: savedEndDate && savedEndDate.format(displayFormat),
      })
    }
    didExit && didExit()
  }

  const _openPikcer = () => {
    if (savedStartDate) setFocusedIput('endDate')
    else setFocusedIput('startDate')
    didEnter && didEnter(elemRef)
  }

  return (
    <View style={[props.wrapperStyle]}>
      <Stack {...props.stackProps}>
        {props.leftElement ?? null}
        <TouchableOpacity ref={elemRef} disabled={props.disabled} onPress={_openPikcer} style={[{ flex: 1 }, props.style]}>
          <Input
            placeholder={
              startDatePlaceholderText && endDatePlaceholderText
                ? `${startDatePlaceholderText} → ${endDatePlaceholderText}`
                : `${moment(new Date()).format(displayFormat)} → ${moment(new Date()).format(displayFormat)}`
            }
            disabled={props.disabled}
            value={savedStartDate && savedEndDate ? `${savedStartDate.format(displayFormat)} → ${savedEndDate.format(displayFormat)}` : ''}
            wrapperStyle={{ padding: 0 }}
            {...textInputProps}
          />
        </TouchableOpacity>
        {props.rightElement ?? null}
      </Stack>
      {props.errorMessage ? (
        <Text size={'sm'} style={[{ color: 'red' }, props.errorMessageStyle]}>
          {props.errorMessage}
        </Text>
      ) : null}

      <NativeModal visible={visible} animationType={'fade'} transparent>
        <Pressable onPress={_confirm} style={[{ flex: 1 }]}>
          <View style={[PickrStyle.container, props.containerStyle, menuPosition]} onLayout={_onLayout}>
            <Stack height={'auto'} direction={'column'} space={'none'}>
              <DayPickerRangeController
                initialVisibleMonth={null}
                focusedInput={focusedInput}
                onFocusChange={() => {}}
                startDate={savedStartDate}
                endDate={savedEndDate}
                onDatesChange={_handleDatesChange}
                numberOfMonths={windowWidth > 680 ? numberOfMonths : 1}
                daySize={daySize}
                hideKeyboardShortcutsPanel
                minimumNights={props.minimumNights !== undefined ? props.minimumNights : 1}
                enableOutsideDays
              />
              <Stack
                height={'auto'}
                justify={'space-between'}
                style={{
                  paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
                  paddingBottom: GlobalStyles.PADDING_SIZES.md,
                }}>
                <Button
                  type={'primary'}
                  text={'Clear'}
                  textStyle={{ fontWeight: '500', color: GlobalStyles.PRIMARY_500 }}
                  transparent
                  onPress={_reset}
                />
                <Button type={'primary'} text={'Confirm'} textStyle={{ fontWeight: '500' }} onPress={_confirm} />
              </Stack>
            </Stack>
          </View>
        </Pressable>
      </NativeModal>
    </View>
  )
}

export default withModal(DateRangePicker) as (props: DateRangeProps) => JSX.Element
