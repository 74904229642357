import { useCallback, useEffect } from 'react'

export const useKeyDown = () => {
  const keyDownFn = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      console.info('Active Element: ', document.activeElement?.ariaLabel)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', keyDownFn, false)

    return () => {
      document.removeEventListener('keydown', keyDownFn, false)
    }
  }, [keyDownFn])
}
