// @ts-nocheck
import { DrawerProvider, ErrorBoundary } from '@components'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { persistor, store } from '@redux/store'
import { FeatureFlagsContextProvider } from '@utils/context/FeatureFlag.context'
import { ModalProvider } from '@utils/context/Modal.context'
import { NavigationProvider } from '@utils/context/Navigation.context'
import { identifyUser } from '@utils/tracking/helpers'
import { iConsentRes } from '@utils/types/auth'
import { AnimatePresence } from 'framer-motion'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { Inter } from 'next/font/google'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ReactElement, ReactNode, useEffect } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import TagManager from 'react-gtm-module'
import 'react-loading-skeleton/dist/skeleton.css'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'
import { theme } from 'src/andromeda/helpers/theme'
import '../andromeda/Widgets/Calendar/styles.css'
import '../styles/globals.css'

import { DeliverableActionsProvider } from '@utils/context/DeliverableActions.context'
import { useDocHeight } from '@utils/hooks/useDocHeight'
import { useKeyDown } from '@utils/hooks/useKeydown'
import GoogleMapsApiProvider from '@utils/context/GoogleMapApi.context'
import { LicenseInfo } from '@mui/x-license-pro'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import AppUrlListener from '@utils/functions/AppUrlListener'
import { BrowserRefProvider } from '@utils/context/BrowserRef.context'
import { WorkspaceThemeContext, WorkspaceThemeProvider } from '@utils/context/Theme.context'
import { CapacitorSwipeBackPlugin } from 'capacitor-swipe-back-plugin'

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY as string)

const inter = Inter({ subsets: ['latin'], weight: '400' })
const GTM_APPLICATION_ID = process.env.GTMApplicationId

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const tagManagerArgs = {
  gtmId: `${GTM_APPLICATION_ID}`,
}

const queryClient = new QueryClient()

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()
  const { isReady, pathname, asPath } = router

  useDocHeight()
  useKeyDown()

  useEffect(() => {
    CapacitorSwipeBackPlugin.enable()
    const state = store.getState()
    const consent = JSON.parse(localStorage.getItem('consent') ?? '{}') as iConsentRes
    if (!consent || !state) return
    identifyUser({ consent })
    TagManager.initialize(tagManagerArgs)
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Head>
            <meta name="viewport" content="initial-scale=1, viewport-fit=cover, width=device-width, maximum-scale=1"></meta>
          </Head>
          {/* <Script src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`} strategy="afterInteractive" /> */}
          {/* <Script id="google-analytics" strategy="afterInteractive">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){window.dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${GA_MEASUREMENT_ID}');
                    `}
                </Script> */}
          <style jsx global>{`
            html {
              font-family: ${inter.style.fontFamily};
            }
          `}</style>
          {/* Skip Links */}
          <a href="#content" className="skip">
            Skip to Content
          </a>
          <a href="#global-menu" className="skip">
            Skip to Global Menu
          </a>
          <a href="#workspace-menu" className="skip">
            Skip to Workspace Menu
          </a>
          <a href="#top-links" className="skip">
            Skip to Top Links
          </a>
          {/* Skip Links */}
          <ToastContainer
            style={{ zIndex: 1500 }} // I know its crazy
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            className="react-toastify-custom-class"
          />
          <WorkspaceThemeProvider>
            <WorkspaceThemeContext.Consumer>
              {(customThemes) => (
                <ThemeProvider theme={theme(customThemes)}>
                  <NavigationProvider>
                    <DrawerProvider>
                      <FeatureFlagsContextProvider>
                        <GoogleMapsApiProvider>
                          <ModalProvider>
                            <DeliverableActionsProvider>
                              <BrowserRefProvider>
                                <AnimatePresence mode="wait">
                                  <AppUrlListener></AppUrlListener>
                                  {isReady && (
                                    <ErrorBoundary>
                                      <LocalizationProvider dateAdapter={AdapterMoment}>
                                        {getLayout(<Component {...pageProps} />)}
                                      </LocalizationProvider>
                                    </ErrorBoundary>
                                  )}
                                </AnimatePresence>
                              </BrowserRefProvider>
                            </DeliverableActionsProvider>
                          </ModalProvider>
                        </GoogleMapsApiProvider>
                      </FeatureFlagsContextProvider>
                    </DrawerProvider>
                  </NavigationProvider>
                </ThemeProvider>
              )}
            </WorkspaceThemeContext.Consumer>
          </WorkspaceThemeProvider>

          {/* <Box sx={{ position: 'relative' }}>
                    <Box sx={{ position: 'absolute', height: 30, width: '100vw', bottom: 0, backgroundColor: 'red', zIndex: 999 }}>
                        <Icon.Airplay />
                    </Box>
                </Box> */}
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  )
}
