import InputMain from './Input'
import PhoneInput from './InputPhone'
export { default as SearchInput } from './SearchInput'

import type { InputComponentType } from './types'

const InputTemp: any = InputMain
InputTemp.Phone = PhoneInput

const Input = InputTemp as InputComponentType

export { Input }
