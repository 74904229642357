import { TouchableOpacity as NativeTouchableOpacity } from 'react-native'
import { GlobalStyles } from '../globalStyles'
import { Icon } from '../Icon'
import { Stack, Typography } from '@mui/material'
import { Text } from '../Text'
import { View } from '../View'
import { iNotificationFeedProps } from './types'
import moment from 'moment'
import { useState } from 'react'
import { Types } from '@orbit'
import { getInterval } from '../helpers'

const NotificationFeed = ({
  notification,
  showReadIndicatorSection = true,
  timestampInMessage = true,
  ...props
}: iNotificationFeedProps) => {
  const [hovered, setHovered] = useState<boolean>(false)

  const _notificationPress = (notif: Types.Notification.iNotification) => {
    if (props.onNotificationPress) {
      props.onNotificationPress(notif)
    }
  }

  const _computeTimeline = (datetime: string) => {
    const dateTime = new Date(datetime)
    const diff = getInterval(dateTime)
    const format = diff.interval.years > 0 ? 'D MMM YY [at] h:mm a' : 'D MMM [at] h:mm a'
    return (
      <Text style={{ color: GlobalStyles.SLATE_500, fontSize: 10 }}>
        {timestampInMessage ? diff.message : diff.interval.days < 1 ? diff.message : moment(dateTime).format(format)}
      </Text>
    )
  }

  const _renderReadIndicator = () => {
    if (props.readIndicatorElement) {
      return (
        <View style={[{ paddingTop: 3, width: 15, display: 'flex', alignItems: 'flex-end' }, props.readIndicatorWrapperStyle]}>
          {props.readIndicatorElement}
        </View>
      )
    } else if (!notification.read_at) {
      return (
        <View style={[{ paddingTop: 3, width: 15, display: 'flex', alignItems: 'flex-end' }, props.readIndicatorWrapperStyle]}>
          <Icon.Circle size={10} fill={GlobalStyles.STATUS_NEGATIVE} color={GlobalStyles.STATUS_NEGATIVE} />
        </View>
      )
    }
    return <></>
  }

  return (
    <NativeTouchableOpacity
      key={`notification-${notification.eid}`}
      style={[
        { padding: 0, margin: 0, width: '100%' },
        props.feedStyle,
        hovered && (props.hoverStyle || { backgroundColor: GlobalStyles.SLATE_50 }),
      ]}
      onPress={() => _notificationPress(notification)}>
      <View
        style={[{ width: '100%' }, props.feedWrapperStyle]}
        onMouseEnter={() => props.hover && setHovered(true)}
        onMouseLeave={() => props.hover && setHovered(false)}>
        <Stack flex={1} direction={'row'} height={'auto'} alignItems={'flex-start'} padding="12px" {...props.feedStackProps}>
          <Stack
            flex={1}
            direction={'column'}
            alignItems={'flex-start'}
            height={'auto'}
            flexWrap={'wrap'}
            {...props.feedDetailsStackProps}
            sx={{ p: { color: GlobalStyles.SLATE_700 } }}>
            {notification.title && (
              <Typography fontSize="12px" fontWeight="500" lineHeight="20px">
                {notification.title}
              </Typography>
            )}
            <Typography fontSize="16px" lineHeight="27px" fontWeight={notification.read_at ? '400' : '500'}>
              {notification.text}
            </Typography>
            <Typography marginBottom="8px" fontSize="12px" fontWeight="500" lineHeight="23px" color={GlobalStyles.SLATE_500}>
              {_computeTimeline(notification.created_at)}
            </Typography>
          </Stack>
          {_renderReadIndicator()}
        </Stack>
      </View>
    </NativeTouchableOpacity>
  )
}

export default NotificationFeed
