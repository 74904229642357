import { forwardRef, memo } from 'react'
import Card from './Card'
import { Label } from '../Label'
import { View } from '../View'
import { Text } from '../Text'
import { CardJobStyles } from './styles'
import { CardProposalProps, JobsLabelProps } from './types'
import { GlobalStyles } from '../globalStyles'
import { useDimensions } from '../hooks'
import { Stack } from '../Stack'
import { Icon } from '../Icon'

const CardProposal = ({ title, details, status }: CardProposalProps, ref: any) => {
  const [, window] = useDimensions()

  const isXtraSmall = window.width <= GlobalStyles.WINDOW_BREAKPOINTS.XTRA_SMALL
  const isSmall = window.width <= GlobalStyles.WINDOW_BREAKPOINTS.SMALL && window.width >= GlobalStyles.WINDOW_BREAKPOINTS.XTRA_SMALL
  const isMedium = window.width <= GlobalStyles.WINDOW_BREAKPOINTS.MEDIUM

  const _formatDate = (date: Date) => {
    return Intl.DateTimeFormat('au').format(date)
  }

  const _iconExtractor = (iconName: string) => {
    const icons = Object.values(Icon)

    const IconComponent = icons.find((icon) => icon.displayName === iconName)

    if (!IconComponent) {
      return undefined
    }

    return (
      <Text style={{ lineHeight: 24, display: 'flex', alignSelf: 'center' }}>
        <IconComponent size={12} color={GlobalStyles.PRIMARY_600} />
      </Text>
    )
  }

  const _renderLabels = (label: JobsLabelProps, index: number) => {
    return (
      <Label
        key={index}
        type={label.type}
        text={label.text}
        leftElement={label.icon ? _iconExtractor(label.icon) : undefined}
        size={label.size}
        textStyle={{ fontSize: 12, fontWeight: '500', padding: 4 }}
        space="none"
      />
    )
  }

  return (
    <Card style={CardJobStyles.wrapper} ref={ref}>
      <Stack
        direction="column"
        align="flex-start"
        space="sm"
        style={{
          flexWrap: 'wrap',
          paddingVertical: isSmall || isXtraSmall ? 16 : 32,
          paddingHorizontal: isSmall || isXtraSmall ? 16 : 32,
        }}>
        <Stack direction="row" align={isMedium ? 'flex-start' : 'center'} justify="space-between" space="xs">
          {/* Header Labels */}
          <Stack
            align="flex-start"
            space={isSmall || isXtraSmall ? 'none' : 'xs'}
            flex={1}
            style={{ flexWrap: 'wrap' }}
            childrenStyle={{ marginBottom: isSmall || isXtraSmall ? 6 : 0 }}
            direction={isMedium ? 'column' : 'row'}>
            {details.labels.map(_renderLabels)}
          </Stack>

          <Stack align="center" justify="flex-end" flex={1} space="2xs">
            {/* Status */}
            <Label rounded type={status.type} text={status.text} size="lg" textStyle={{ fontSize: GlobalStyles.FONT_SIZES.TINY }} />
          </Stack>
        </Stack>
        {/* Body */}
        <Stack direction={isMedium ? 'column' : 'row'} align="center" justify="space-between" space="none">
          <Stack direction="column" flex={1} align="flex-start" space="sm">
            <View>
              <Text type="heading" style={{ fontSize: GlobalStyles.FONT_SIZES.XTRA_LARGE, color: GlobalStyles.MONO_BLACK }} bold>
                {title}
              </Text>
            </View>

            <Text
              type="heading"
              size="sm"
              style={[{ color: GlobalStyles.MONO_BLACK }, (isSmall || isXtraSmall) && { display: 'flex', flexDirection: 'column' }]}>
              Budget Guide:{' '}
              <Text type="paragraph" size={'sm'}>
                {details.budgetGuide}
              </Text>
            </Text>

            <Stack space="2xs">{details.tags.map(_renderLabels)}</Stack>

            <Stack
              space={isSmall || isXtraSmall ? 'none' : 'sm'}
              direction={isSmall || isXtraSmall ? 'column' : 'row'}
              align={isSmall || isXtraSmall ? 'flex-start' : 'center'}>
              <Text size="sm" style={{ color: GlobalStyles.MONO_BLACK }}>
                Date(s): <Text size={'sm'}>{`${_formatDate(details.dateFrom)} > ${_formatDate(details.dateTo)}`}</Text>
              </Text>

              <Text size="sm" style={{ color: GlobalStyles.MONO_BLACK }}>
                Location: <Text size={'sm'}>{details.location}</Text>
              </Text>
            </Stack>

            <Stack direction={isXtraSmall ? 'column' : 'row'} align="flex-start">
              <Stack
                flex={1}
                space={isSmall || isXtraSmall ? 'none' : 'sm'}
                direction={isSmall || isXtraSmall ? 'column' : 'row'}
                align={isSmall || isXtraSmall ? 'flex-start' : 'center'}>
                {details.organisationName && (
                  <Text size="sm" style={{ color: GlobalStyles.MONO_BLACK }}>
                    {details.organisationName}
                  </Text>
                )}
                <Text size="sm" style={{ color: GlobalStyles.MONO_BLACK }}>
                  Created: <Text size={'sm'}>{_formatDate(details.createdDate)}</Text>
                </Text>

                <Text size="sm" style={{ color: GlobalStyles.MONO_BLACK }}>
                  Stars: <Text size={'sm'}>{`${details.starsCount}/${details.maxStarsCount}`}</Text>
                </Text>
              </Stack>
            </Stack>
          </Stack>
          <View style={{ alignSelf: isMedium ? 'flex-start' : 'flex-end' }}>
            <Text style={{ fontSize: GlobalStyles.FONT_SIZES.XTRA_LARGE }}>{_formatDate(details.proposalDate)}</Text>
          </View>
        </Stack>
      </Stack>
    </Card>
  )
}

export default memo(forwardRef(CardProposal))
