import { FlexStyle, StyleProp, TextStyle, TouchableOpacityProps as NativeTouchableOpacityProps, ViewStyle } from 'react-native'
import { SpaceType } from '../Stack/types'
import { LoadingType } from '../Loading/types'

export enum ButtonDefaults {
  TYPE = 'primary',
  PLATFORM = 'web',
  SPACE = 'sm',
}

export type ButtonTypes = 'primary' | 'secondary' | 'outlined' | 'shadowed'

export type ButtonSpaces = SpaceType
export type ButtonVariant = 'primary' | 'secondary' | 'danger' | 'success'

export interface ButtonProps extends NativeTouchableOpacityProps {
  type?: ButtonTypes
  text?: string
  space?: ButtonSpaces
  hover?: boolean
  disabled?: boolean
  transparent?: boolean
  rounded?: boolean
  selected?: boolean
  variant?: ButtonVariant
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  leftElement?: JSX.Element | JSX.Element[]
  rightElement?: JSX.Element | JSX.Element[]
  loading?: boolean
  loadingType?: LoadingType
  justify?: FlexStyle['justifyContent']
}
