import { GlobalStyles, Icon } from '@andromeda'
import { ModalHeader } from '@components'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { markCompleteDeliverable, selectDeliverable } from '@redux/reducers/deliverableReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext } from 'react'

export const MarkCompleteDeliverable = () => {
  const { closeModal, options } = useContext(ModalContext)
  const props = options?.modalProps?.markCompleteDel

  const { requestStatus } = useAppSelector(selectDeliverable)
  const dispatch = useAppDispatch()

  const _handleSubmit = () => {
    dispatch(
      markCompleteDeliverable({
        payload: {
          eid: props.deliverableEid,
        },
      })
    ).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        props.onConfirm(response)
      }
    })
  }

  return (
    <Stack className="dialog-container" style={{ backgroundColor: GlobalStyles.MONO_WHITE }} width={{ xxs: '100%', sm: '550px' }}>
      <ModalHeader modalTitle="Complete Deliverable?" modalContext={ModalPages.MARK_COMPLETE_DELIVERABLE} />
      <Stack className="dialog-content" rowGap="8px">
        <Typography style={{ fontWeight: '400', color: GlobalStyles.SLATE_600, fontSize: GlobalStyles.FONT_SIZES.BASE }}>
          Completing this deliverable will lock it from further changes.
        </Typography>

        <Stack direction="column" alignItems="flex-start" rowGap={`${GlobalStyles.MARGIN_SIZES.sm}px`}>
          <Typography style={{ color: GlobalStyles.MONO_BLACK, fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
            If you require more talent to perform this deliverable you can choose to:
          </Typography>

          <Stack direction="row" alignItems="flex-start" columnGap={`${GlobalStyles.MARGIN_SIZES['2xs']}px`}>
            <Typography style={{ lineHeight: '15px' }}>
              <Icon.Check color={GlobalStyles.SLATE_300} />
            </Typography>
            <Typography style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>Enable automated reminders</Typography>
          </Stack>

          <Stack direction="row" alignItems="flex-start" columnGap={`${GlobalStyles.MARGIN_SIZES['2xs']}px`}>
            <Typography style={{ lineHeight: '15px' }}>
              <Icon.Check color={GlobalStyles.SLATE_300} />
            </Typography>
            <Typography style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>Enable prompts to complete this job</Typography>
          </Stack>

          <Stack direction="row" alignItems="flex-start" columnGap={`${GlobalStyles.MARGIN_SIZES['2xs']}px`}>
            <Typography style={{ lineHeight: '15px' }}>
              <Icon.Check color={GlobalStyles.SLATE_300} />
            </Typography>
            <Typography style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>Improve job sorting on the listing page</Typography>
          </Stack>

          <Stack direction="row" alignItems="flex-start" columnGap={`${GlobalStyles.MARGIN_SIZES['2xs']}px`}>
            <Typography style={{ lineHeight: '15px' }}>
              <Icon.Check color={GlobalStyles.SLATE_300} />
            </Typography>
            <Typography style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>Improve reporting accuracy</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="end" columnGap="8px" className="dialog-footer">
        <Button variant="outlined" onClick={() => closeModal(ModalPages.MARK_COMPLETE_DELIVERABLE)}>
          Cancel
        </Button>
        <Button variant="contained" onClick={_handleSubmit} disabled={requestStatus.markComplete === 'loading'}>
          {requestStatus.markComplete === 'loading' ? (
            <CircularProgress size={12} style={{ marginRight: `${GlobalStyles.MARGIN_SIZES['2xs']}px` }} />
          ) : undefined}
          Mark Complete
        </Button>
      </Stack>
    </Stack>
  )
}
