import { Avatar, GlobalStyles, Icon, Stack, Text, View } from '@andromeda'
import { AppStyles } from '@styles'

const AddProfilePicture = ({
  avatar,
  description = 'This image will be used in your profile.',
}: {
  avatar: string | null
  description?: string
}): JSX.Element => {
  const placeHolder = (
    <View
      style={{
        width: AppStyles.AVATAR_SIZES.MODAL,
        height: AppStyles.AVATAR_SIZES.MODAL,
        borderRadius: AppStyles.AVATAR_SIZES.MODAL,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Icon.User size={AppStyles.AVATAR_SIZES.MODAL} color={GlobalStyles.SLATE_200} strokeWidth={1} />
    </View>
  )
  return (
    <Stack
      direction="column"
      space="lg"
      style={{ paddingHorizontal: AppStyles.MODAL_PADDING_HORIZONTAL, paddingBottom: AppStyles.MODAL_PADDING_HORIZONTAL }}
      align="flex-start">
      <Text type="paragraph" size="base">
        {description}
      </Text>
      <Stack direction="column">
        <Avatar
          placeHolder={placeHolder}
          image={avatar || undefined}
          style={{
            width: AppStyles.AVATAR_SIZES.MODAL,
            height: AppStyles.AVATAR_SIZES.MODAL,
            borderRadius: AppStyles.AVATAR_SIZES.MODAL,
          }}
          imageStyle={{ width: AppStyles.AVATAR_SIZES.MODAL, height: AppStyles.AVATAR_SIZES.MODAL }}
        />
      </Stack>
    </Stack>
  )
}

export { AddProfilePicture }
