import { Button, Card, DropdownOption, GlobalStyles, Icon, Text, View } from '@andromeda'
import { Stack, Typography } from '@mui/material'
import { Types } from '@orbit'
import { AppStyles } from '@styles'
import { useScreenDimensions as screenDimension } from '@utils/hooks/dimensions'
import { useContext, useEffect, useState } from 'react'
import { getInitials } from '@utils/functions/helperFunctions'
import AddUsersModalContext from '../AddUsersModal.context'
import { useMe } from '@utils/query/useMe'

export type UsersListItemProps = {
  user: Types.User.iUser
  itemIndex: number
  draftSelection: Types.Job.iJobUsersResponse
  requestIsCompleted: boolean
  onUserSelect: (e: { selected: boolean; details: Types.User.iUser; setIsSelected: (selected: boolean) => void }, index: number) => void
  onPermissionSelect: (e: { user: Types.User.iUser; item: DropdownOption }) => void
}

enum JobMembership {
  OWNER = 'OWNER',
  VIEWER = 'VIEWER',
}

interface iPermissionOption {
  eid: JobMembership
  value: string
  label: string
}

const PERMISSION_OPTIONS: Array<iPermissionOption> = [
  {
    eid: JobMembership.OWNER,
    value: 'Owner',
    label: 'Owner',
  },
  // {
  //     eid: JobMembership.OWNER,
  //     value: 'Editor',
  //     label: 'Editor',
  // },
  {
    eid: JobMembership.VIEWER,
    value: 'Viewer',
    label: 'Viewer',
  },
]

const UsersListItem = ({ itemIndex, user, draftSelection, requestIsCompleted, onUserSelect, onPermissionSelect }: UsersListItemProps) => {
  const {
    modalProps: { requestId, hasUpdatePermissions, hasAddUserPermissions },
  } = useContext(AddUsersModalContext)
  const { screenSize: dimensionSize } = screenDimension()
  const screenIsMedium = dimensionSize.md

  const [isSelected, setIsSelected] = useState(false)
  const [isOwner, setIsOwner] = useState(false)
  const [isInvited, setIsInvited] = useState(false)
  const [userRole, setUserRole] = useState<iPermissionOption>(PERMISSION_OPTIONS[0])
  const [permissionOptions, setPermissionOptions] = useState<iPermissionOption[]>([])

  const { data: loggedInUser } = useMe()

  const userHasNoEditAccess = user.eid === loggedInUser?.eid && !hasUpdatePermissions

  useEffect(() => {
    if (draftSelection) {
      const isSelectedIndex = draftSelection.users?.findIndex((owner) => owner.eid === user.eid)

      if (loggedInUser) {
        setIsOwner(user.eid === loggedInUser.eid && draftSelection.users.some((owner) => owner.eid === loggedInUser.eid))
        setIsInvited(user.eid.includes('IN'))
      }

      if (isSelectedIndex > -1) {
        setIsSelected(true)

        switch (draftSelection.users[isSelectedIndex].ownership_type) {
          case 'OWNER':
            setUserRole(PERMISSION_OPTIONS[0])
            break
          case 'VIEWER':
            setUserRole(PERMISSION_OPTIONS[1])
            break
        }
      }

      if (draftSelection.invites?.some((invite) => invite.eid === user.eid)) {
        setIsSelected(true)

        if (user.ownership_type) {
          const ownershipType = user.ownership_type

          setUserRole(
            ownershipType
              ? PERMISSION_OPTIONS.find(({ value }) => value.toLowerCase() === ownershipType.toLowerCase()) ?? PERMISSION_OPTIONS[1]
              : PERMISSION_OPTIONS[1]
          )
        } else {
          setUserRole(PERMISSION_OPTIONS[1])
        }
      }

      if (isOwner || hasUpdatePermissions || hasAddUserPermissions || !requestId) {
        setPermissionOptions(PERMISSION_OPTIONS)
      } else {
        setPermissionOptions(PERMISSION_OPTIONS.filter((opt) => opt.eid !== JobMembership.OWNER))
      }
    }

    return () => {
      setIsOwner(false)
      setIsSelected(false)
      setUserRole(PERMISSION_OPTIONS[0])
    }
  }, [draftSelection, user, hasUpdatePermissions, hasAddUserPermissions, requestId, isOwner, loggedInUser])

  return user ? (
    <Card.User
      key={user.eid}
      userData={{ ...user }}
      onSelect={onUserSelect}
      selected={isSelected}
      type={isOwner ? 'owner' : 'user'}
      avatarPlaceholder={
        isInvited ? (
          <Stack alignItems="center">
            <Icon.Clock size={40} color={GlobalStyles.SLATE_200} />
          </Stack>
        ) : (
          <Text
            style={{
              textAlign: 'center',
              fontSize: GlobalStyles.FONT_SIZES.BASE,
            }}>
            {getInitials(user.name)}
          </Text>
        )
      }
      subDetails={
        isInvited ? (
          <Typography color={GlobalStyles.SLATE_700} fontSize="12px">
            New User - Pending
          </Typography>
        ) : undefined
      }
      showPermissionSelect={!userHasNoEditAccess}
      showContactButton={false}
      dropdownOptions={permissionOptions}
      selectedItem={userRole}
      onLeave={() => onUserSelect({ selected: false, details: user, setIsSelected }, itemIndex)}
      onPermissionSelect={onPermissionSelect}
      disableMessage={true}
      showMessageButton={false}
      nameOverride={user.eid === loggedInUser?.eid ? 'You' : undefined}
      style={{ borderRadius: AppStyles.BORDER_RADIUS_SIZES.XS }}
      disabled={isInvited || ((!hasUpdatePermissions || !hasAddUserPermissions) && requestId) || requestIsCompleted}
      buttonStyle={[
        (!hasUpdatePermissions || !hasAddUserPermissions) &&
          (requestId || requestIsCompleted) && {
            backgroundColor: GlobalStyles.SLATE_100,
          },
      ]}
      preventSelect={false}
      showHiddenComponent={userHasNoEditAccess}
      hiddenComponent={
        <Button
          type={'outlined'}
          text={screenIsMedium ? 'Request Edit Access' : undefined}
          textStyle={{
            color: GlobalStyles.PRIMARY_500,
            fontSize: GlobalStyles.FONT_SIZES.SMALL,
          }}
          space={screenIsMedium ? '2xs' : 'none'}
          style={{
            width: 'auto',
            height: GlobalStyles.BUTTON_SIZES.MEDIUM,
            backgroundColor: GlobalStyles.SLATE_100,
          }}
          leftElement={
            <View>
              <Icon.Unlock color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />
            </View>
          }
          disabled={true}
        />
      }
    />
  ) : (
    <></>
  )
}

export { UsersListItem }
