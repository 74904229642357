import { StyleSheet } from 'react-native'
import { GlobalStyles } from '../globalStyles'

export const PickrStyle = StyleSheet.create({
  focusedContainer: {
    borderWidth: 2,
    borderColor: GlobalStyles.PRIMARY_500,
  },
  inputWrapper: {
    flex: 1,
  },
  inputLabel: {
    fontSize: 14,
    width: 100,
  },
  inputContainer: {
    borderWidth: 0,
    borderRadius: 0,
    fontSize: 14,
    outlineStyle: 'none',
  },
  dropdownContainer: {
    flex: 0,
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: '#EEEEEE',
    marginLeft: 'auto',
  },
  dropdownOptions: {
    width: 122,
    height: 100,
  },
  container: {
    position: 'absolute',
    alignItems: 'center',
    border: '1px solid #E2E4E9',
    boxSizing: 'border-box',
    boxShadow:
      '0px 4px 2px rgba(101, 109, 134, 0.02), 0px 4px 8px rgba(101, 109, 134, 0.04), 0px 4px 16px rgba(101, 109, 134, 0.06), 0px 4px 48px rgba(101, 109, 134, 0.08)',
    borderRadius: 12,
    backgroundColor: GlobalStyles.MONO_WHITE,
  },
})

export const TimePickrStyle = StyleSheet.create({
  inputModal: {
    position: 'absolute',
    width: 320,
    height: 'auto',
    marginTop: 2,
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 0,
    backgroundColor: GlobalStyles.MONO_WHITE,
    borderColor: GlobalStyles.SLATE_100,
  },
  touchableInput: {
    display: 'flex',
    flex: 1,
    position: 'relative',
    fontSize: 18,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 17,
    paddingRight: 17,
    color: GlobalStyles.SLATE_50,
    textAlign: 'left',
    lineHeight: 18,
    outlineStyle: 'none',
  },
  textInputWrapper: {
    paddingLeft: 0,
    paddingRight: 0,
    outlineStyle: 'none',
    borderColor: 'transparent',
  },
  textInputContainer: {
    borderWidth: 0,
    borderRadius: 0,
    paddingLeft: 45,
    paddingRight: 45,
    fontSize: 14,
    outlineStyle: 'none',
  },
  textInputFocused: {
    backgroundColor: GlobalStyles.SLATE_50,
    fontWeight: 'bold',
  },
  innerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputOuterWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 0.4,
  },
  pressableOverlay: {
    flex: 1,
  },
  placeholderText: {
    color: 'rgb(119, 119, 119)',
  },
  inputText: {
    fontSize: 14,
    color: 'rgb(0, 0, 0)',
  },
})
