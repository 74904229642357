import { AlertProps as MUIAlertProps } from '@mui/material/Alert'
import { CSSProperties } from 'react'
import { FlexAlignType, FlexStyle, StyleProp, TextStyle, ViewStyle } from 'react-native'
import { SpaceType } from '..'

export enum AlertDefaults {
  TYPE = 'primary',
  PLATFORM = 'web',
  SPACE = 'sm',
}

export type AlertTypes = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'

export type AlertSpaces = SpaceType
export interface AlertProps extends MUIAlertProps {
  /**
   * The type of Alert
   */
  type?: AlertTypes
  /**
   * Text to display inside the alert
   */
  text?: string
  /**
   * Adding space
   */
  space?: AlertSpaces
  /**
   * We can add a hover affect
   */
  hover?: boolean
  /**
   * Custom styles for the Alert container
   */
  style?: CSSProperties
  /**
   * Custom styles for the Alert text
   */
  textStyle?: StyleProp<TextStyle>
  /**
   * Custom styles for Text Wrapper
   */
  textWrapperStyle?: StyleProp<ViewStyle>
  /**
   * Custom Styles for Stack Wrapper
   */
  innerWrapper?: StyleProp<ViewStyle>
  /**
   * Left Element
   */
  leftElement?: JSX.Element | JSX.Element[]
  /**
   * Right Element
   */
  rightElement?: JSX.Element | JSX.Element[]
  /**
   * Justify content
   */
  justifyContent?: FlexStyle['justifyContent']
  /**
   * Content alignment
   */
  alignContent?: FlexAlignType
}
