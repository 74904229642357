import { API, Notification } from '../types'
import { HttpClient } from './api.service'

type NotificationSettings = { [key: string]: Notification.iUserNotificationSetting }

export const notificationService = {
  listUserNotifications: (params: Notification.iNotificationsQueryParams) => {
    let endpoint = `${API.NotificationEndpoints.NOTIFICATION}`
    if (params) {
      if (params.page) endpoint = endpoint.concat(`?page=${params.page}`)
      if (params.per_page) endpoint = endpoint.concat(`&per_page=${params.per_page}`)
    }
    return HttpClient.get<null, Notification.iNotifications>({
      endpoint,
      payload: null,
    })
  },
  getUserUnreadNotificationsCount: () => {
    return HttpClient.get<null, Notification.iNotificationCount>({
      endpoint: API.NotificationEndpoints.NOTIFICATION_UNREAD,
      payload: null,
    })
  },
  markReadUserNotification: ({ payload }: { payload: Notification.iNotificationMarkReadPayload }) => {
    return HttpClient.put<Notification.iNotificationMarkReadPayload, null>({
      endpoint: API.NotificationEndpoints.NOTIFICATION_READ,
      payload,
    })
  },
  getUserUnseenNotificationsCount: () => {
    return HttpClient.get<null, Notification.iNotificationCount>({
      endpoint: API.NotificationEndpoints.NOTIFICATION_UNSEEN,
      payload: null,
    })
  },
  markReadAllUserNotification: () => {
    return HttpClient.put<null, null>({
      endpoint: API.NotificationEndpoints.NOTIFICATION_READ_ALL,
    })
  },
  markSeenAllUserNotification: () => {
    return HttpClient.put<null, null>({
      endpoint: API.NotificationEndpoints.NOTIFICATION_SEEN_ALL,
    })
  },
  getUserNotificationSettings: () => {
    return HttpClient.get<null, Notification.iUserNotificationSetting[]>({
      endpoint: API.NotificationEndpoints.USER_NOTIFICATION_SETTINGS,
    })
  },
  updateUserNotificationSettings: (payload: NotificationSettings) => {
    return HttpClient.put<NotificationSettings, NotificationSettings>({
      endpoint: API.NotificationEndpoints.USER_NOTIFICATION_SETTINGS,
      payload,
    })
  },
}
