import { GlobalStyles, Icon, Tooltip } from '@andromeda'
import { Button, Stack, Typography } from '@mui/material'
import { Types } from '@orbit'
import { useAppSelector } from '@redux/hooks'
import { selectJobs } from '@redux/reducers/jobsReducer'
import { useEffect, useMemo, useState } from 'react'
import { TalentProfile } from 'src/components/talent/profile'
import { labelStyles } from './Overview'
import { STATUS_OPTIONS, StatusDropdown } from './Status'
import { Attachment } from 'src/components/attachments/attachments'
import { useDeliverables } from '@utils/query/useDeliverables'

export const TalentPanel = ({
  talentId,
  onClose,
  onBack,
  onSuccess,
  costMode,
}: {
  talentId: string
  onBack: () => void
  onClose: () => void
  onSuccess?: () => void
  costMode?: Types.Job.iJob['cost_mode']
}) => {
  const { viewedJob, filteredJobs } = useAppSelector(selectJobs)
  const { data: deliverables } = useDeliverables()

  const filteredAgreements: Array<
    Types.Agreements.iDeliverableAgreement &
      Pick<Types.Deliverable.iDeliverable, 'name' | 'deliverable_cost_per_talent' | 'deliverable_currency_code'> & {
        deliverableEid: string
      }
  > = useMemo(() => {
    return (deliverables || []).reduce((acc, curr) => {
      curr.deliverable_agreements?.forEach((agreement) => {
        if (agreement.jobAgreement?.talent.eid === talentId) {
          // @ts-expect-error
          acc.push({
            ...agreement,
            name: curr.name,
            deliverable_cost_per_talent: curr.deliverable_cost_per_talent,
            deliverable_currency_code: curr.deliverable_currency_code,
            deliverableEid: curr.eid,
          })
        }
      })
      return acc
    }, [])
  }, [talentId, deliverables])
  const isJobCost = useMemo(() => costMode === 'PER_JOB', [costMode])

  const [statuses, setStatuses] = useState<{ [key: string]: string } | undefined>(
    filteredAgreements?.reduce((acc, { eid, status }) => ({ ...acc, [eid]: status }), {})
  )
  const [expanded, setExpanded] = useState<number | null>(null)

  useEffect(() => {
    setStatuses(filteredAgreements?.reduce((acc, { eid, status }) => ({ ...acc, [eid]: status }), {}))
  }, [filteredAgreements])

  return (
    <>
      <Stack
        role="presentation"
        aria-label="talent drawer"
        direction="row"
        justifyContent="space-between"
        zIndex={100}
        width="100%"
        padding="24px"
        boxSizing="border-box"
        marginTop="env(safe-area-inset-top)">
        <Button variant="outlined" sx={{ width: '40px', height: '40px', padding: 0 }} onClick={onBack} aria-label="Go back">
          <Icon.ArrowLeft size={16} />
        </Button>
        <Button variant="outlined" sx={{ width: '40px', height: '40px', padding: 0 }} onClick={onClose} aria-label="close drawer">
          <Icon.X size={16} />
        </Button>
      </Stack>
      <TalentProfile
        talentId={talentId}
        isMobileWidth
        onSuccess={onSuccess}
        agreementEl={
          viewedJob.eid ? (
            <Stack rowGap="38px">
              <Stack className="paper-border" rowGap="14px" padding="12px" role="presentation" aria-label="Deliverables on this job">
                <Stack direction="row" columnGap="8px" alignItems="center" color={GlobalStyles.SLATE_500}>
                  <Icon.Target size={20} />
                  <Typography fontSize="14px">DELIVERABLES ON THIS JOB</Typography>
                </Stack>
                {filteredAgreements?.length ? (
                  filteredAgreements?.map(
                    ({ eid, jobAgreement, name, deliverable_cost_per_talent, deliverable_currency_code, deliverableEid }, index) => (
                      <Stack key={eid} role="row">
                        <Stack direction="row" alignItems="center" className="paper-border" padding="6px 12px" columnGap="8px">
                          {!isJobCost && (
                            <Button
                              variant="text"
                              onClick={() => {
                                setExpanded(expanded === index ? null : index)
                              }}>
                              {expanded === index ? <Icon.ChevronUp size={16} /> : <Icon.ChevronDown size={16} />}
                            </Button>
                          )}
                          <Typography {...labelStyles}>{name}</Typography>
                          <Typography {...labelStyles} marginLeft="auto">
                            {`${deliverable_currency_code ?? ''} ${deliverable_cost_per_talent ?? ''}`}
                          </Typography>

                          <StatusDropdown
                            selectedValue={statuses?.[eid] ? STATUS_OPTIONS.find((option) => option.id === statuses[eid]) : undefined}
                            onClose={() => {}}
                          />
                          <Tooltip text="" />
                        </Stack>
                        {!isJobCost && expanded === index && (
                          <Attachment
                            expandedJobEid={viewedJob.eid}
                            agreementEid={jobAgreement?.eid}
                            deliverableEid={deliverableEid}
                            files={jobAgreement?.files}
                          />
                        )}
                      </Stack>
                    )
                  )
                ) : (
                  <></>
                )}
              </Stack>
              {isJobCost && (
                <Stack className="paper-border" rowGap="14px" padding="12px" role="presentation" aria-label="Deliverables on this job">
                  <Stack direction="row" columnGap="8px" alignItems="center" color={GlobalStyles.SLATE_500}>
                    <Icon.File size={20} />
                    <Typography fontSize="14px">PRIVATE ATTACHMENTS ON THIS JOB</Typography>
                  </Stack>
                  <Attachment
                    expandedJobEid={viewedJob.eid}
                    agreementEid={filteredAgreements[0]?.jobAgreement?.eid}
                    deliverableEid={filteredAgreements[0]?.deliverableEid}
                    files={filteredAgreements[0]?.jobAgreement?.files}
                  />
                </Stack>
              )}
            </Stack>
          ) : undefined
        }
      />
    </>
  )
}
