export { reducer as AuthReducer } from './authReducer'
export { reducer as JobsReducer } from './jobsReducer'
export { reducer as WorkspaceReducer } from './workspaceReducer'
export { reducer as DeliverableReducer } from './deliverableReducer'
export { reducer as PasswordRecovery } from './passwordRecovery'
export { reducer as AccountReducer } from './accountsReducer'
export { reducer as CommentReducer } from './commentsReducer'
export { reducer as TalentProfileReducer } from './talentProfileReducer'
export { reducer as TalentReducer } from './talentReducer'
export { reducer as AgreementsReducer } from './agreementsReducer'
export { reducer as UsersReducer } from './usersReducer'
export { reducer as InviteReducer } from './inviteReducer'
export { reducer as RolesReducer } from './rolesReducer'
export { reducer as NotificationReducer } from './notificationsReducer'
export { reducer as AuditReducer } from './auditReducer'
export { reducer as DashboardReducer } from './dashboardReducer'
export { reducer as SearchReducer } from './searchReducer'
export { reducer as ImporterReducer } from './importerReducer'
export { reducer as IdpReducer } from './idpReducer'
