import { iResponsiveStyles } from '../types/common'

// export const applyResponsiveStyles = (props: iResponsiveStyles) => {
//     return props[useScreenDimensions().size as keyof iResponsiveStyles]
// }

export const applyResponsiveProp = (prop: { default: any; [key: string]: any }, breakpoint: keyof iResponsiveStyles) => {
  // do nothing if prop is undefined or null
  if (prop === undefined || prop === null) return

  const responsiveProp = prop[breakpoint]

  const isNull = typeof prop['default'] === 'undefined' || prop['default'] === null
  const isBoolean = typeof prop['default'] === 'boolean'
  const isString = typeof prop['default'] === 'string'
  const isNumber = typeof prop['default'] === 'number'
  // Handle string props
  if (isNull || isBoolean || isString || isNumber) {
    return responsiveProp || prop['default']
  }

  // Check if prop includes the responsive stuff
  // should be undefined if dont have -- just return it as is
  if (!responsiveProp) {
    return prop
  }

  // override with the active responsive prop
  return { ...(prop['default'] || prop), ...responsiveProp }
}
