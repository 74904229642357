import { Tooltip as MUITooltip, Stack, StackProps } from '@mui/material'
import Box from '@mui/material/Box'
import { Icon } from '../Icon'
import { GlobalStyles } from '../globalStyles'
import { TooltipProps } from './types'

export const Tooltip = ({ text, showIcon = true, children, stackProps, ...props }: { stackProps?: StackProps } & TooltipProps) => {
  return (
    <MUITooltip {...props} title={text} arrow sx={{ zIndex: 9 }}>
      <Box display="flex" alignItems={'center'} justifySelf={'center'} {...stackProps}>
        {showIcon ? (
          <Stack display={{ xxs: 'none', sm: 'flex' }} alignItems="center">
            <Icon.Info size={GlobalStyles.FONT_SIZES.BASE} color={GlobalStyles.SLATE_200} />
          </Stack>
        ) : (
          children
        )}
      </Box>
    </MUITooltip>
  )
}

export default Tooltip
