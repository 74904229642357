import Drawer from '@mui/material/Drawer'
import { iModalProps } from '@utils/types/modal'
import React, { forwardRef, useEffect } from 'react'

type Anchor = 'top' | 'left' | 'bottom' | 'right'

// export default function TemporaryDrawer() {

const RightModal = ({ children, onBackdropPress, containerStyles, ...props }: iModalProps, ref?: any) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return
    }

    setState({ ...state, [anchor]: open })
  }
  const anchor = 'right'
  useEffect(() => {
    setState({ ...state, right: !!props.show })
  }, [props.show])
  return (
    <div key={anchor} onClick={onBackdropPress}>
      <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
        {children}
      </Drawer>
    </div>
    // <Transition
    //     key="modal-bg"
    //     animate={props.show ? ['enter'] : ['exit']}
    //     type="fade"
    //     className="absolute flex justify-end align-end h-full w-full inset-0 overflow-x-hidden">
    //     <div onClick={onBackdropPress} className="absolute inset-0 w-full h-full bg-black/40"></div>
    //     <Transition
    //         key={`slide-component-${ref}`}
    //         animate={props.show ? ['enter'] : ['exit']}
    //         type="slide"
    //         className="h-full lg:w-1/2 md:w-2/3 sm:w-3/4 xs:w-4/5 xxs:w-full z-10">
    //         <div className="bg-white shadow-lg h-screen">{children}</div>
    //     </Transition>
    // </Transition>
  )
}

export default forwardRef(RightModal)
