import { Box, IconButton, Modal, Stack } from '@mui/material'
import { FILE_TYPES } from './attachments'
import { useMemo } from 'react'
import Image from 'next/image'
import { Types } from '@orbit'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { GlobalStyles, Icon } from '@andromeda'

export const AttachmentPreview = ({ file, onClose }: { file: Types.Job.iFile; onClose: () => void }) => {
  const download = async () => {
    await fetch(file.download_presign_url)
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file.original_file_name)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        // @ts-expect-error
        link.parentNode.removeChild(link)
      })
  }

  return (
    <Modal open onClose={onClose}>
      <Stack
        width={{ xxs: '100vw', md: '1100px' }}
        height={{ xxs: '80vh', md: '700px' }}
        padding="12px"
        boxSizing="border-box"
        sx={{
          backgroundColor: GlobalStyles.SLATE_900,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        <Stack width="100%" justifyContent="end" direction="row">
          <IconButton
            onClick={download}
            sx={{
              height: '40px',
              width: '40px',
            }}>
            <Icon.Download size={16} color="white" />
          </IconButton>
          <IconButton
            onClick={() => {
              onClose()
            }}
            sx={{
              height: '40px',
              width: '40px',
            }}>
            <Icon.X size={16} color="white" />
          </IconButton>
        </Stack>
        <DocViewer
          documents={[{ uri: file.download_presign_url, fileType: file.file_format.toLowerCase() }]}
          pluginRenderers={DocViewerRenderers}
          prefetchMethod="GET"
        />
      </Stack>
    </Modal>
  )
}
