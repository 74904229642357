import { GlobalStyles, Icon, StackV2, Text, View } from '@andromeda'
import { getInterval } from '../helpers'
import { ActivityStyles } from './styles'
import { ActivitiesProps, AttributesData, StarData, Values } from './types'
import { capitalize, generateAttributes, generateStars, pluralize } from './utils'

const _renderMessage = (context: ActivitiesProps['context'], type: string, stars: StarData[] | null, model: string, value: Values) => {
  switch (type) {
    case 'created':
    case 'completed':
    case 'published':
    case 'cancelled':
    case 'added':
    case 'duplicated':
      if (context === 'job' && model === 'deliverable') return `${type} the ${model}${value.name ? `: ${value.name}` : ''}`
      switch (model) {
        case 'job shortlist':
          return `added ${stars?.length} ${pluralize('talent', stars)} to the ${model}:`
        case 'job users':
          return `added ${stars?.length} ${pluralize('user', stars)} to the job:`
        default:
          return `${type} the ${model}`
      }
    case 'updated':
      return `updated the ${model}:`
    case 'assigned':
      if (model === 'deliverable') return `${type} a talent`
      return stars ? `assigned ${stars.length} ${pluralize('talent', stars)} to the job:` : `assigned talents to the job:`
    case 'invite':
      return stars ? `invited ${stars.length} ${pluralize('talent', stars)} to the job:` : `invited talents to the job:`
    case 'apply':
      return stars
        ? `${stars.length} ${pluralize('talent', stars)} have applied for the ${model}:`
        : `talents have applied for the ${model}:`
    case 'live':
      return `${model} changed from Draft to Live`
    case 'booked':
    case 'removed':
      return `${type} a talent`
    case 'done':
      return `marked a talent agreement as done`
    default:
      return ''
  }
}

const Activity = ({
  activities,
  containerStyles,
  dashedLineStyles,
  detailsWrapperStyles,
  activityWrapperStyles,
  bulletWrapperStyles,
  bulletStyles,
  activityDetailsWrapperStyles,
  context = 'job',
}: ActivitiesProps) => {
  const _computeTimeline = (datetime: Date | string) => {
    if (typeof datetime === 'string') datetime = new Date(datetime)
    const interval = getInterval(datetime)
    return <Text style={{ color: GlobalStyles.SLATE_500, fontSize: 14, lineHeight: 24 }}>{interval.message}</Text>
  }

  const _renderAttributes = (attr: AttributesData, key: number) => {
    if (attr.name.includes('_')) {
      attr.name = attr.name.split('_').join(' ')
    }
    // Capitalize first letter
    attr.name = capitalize(attr.name)

    if (attr.type === 'longtext') {
      // skip displaying longtext attributes like description
      return (
        <StackV2 key={key} direction="row" alignItems="flex-start" style={{ flexWrap: 'wrap', height: 'auto' }}>
          <Text>{`updated ${attr.name}`}</Text>
        </StackV2>
      )
    } else {
      if (attr.previousValue && attr.newValue) {
        return (
          <StackV2 key={key} direction="row" alignItems="flex-start" style={{ flexWrap: 'wrap', height: 'auto' }}>
            <Text style={{ fontSize: 14 }}>{`${attr.name} changed from `}</Text>
            <Text
              style={[
                attr.type === 'datetime' && ActivityStyles.datetimeValue,
                attr.type === 'text' && ActivityStyles.stringValue,
                { fontSize: 14 },
              ]}>{` ${attr.previousValue}`}</Text>
            <Text>
              <Icon.ArrowRight size="14" style={{ marginTop: 2 }} />
            </Text>
            <Text
              style={[
                attr.type === 'datetime' && ActivityStyles.datetimeValue,
                attr.type === 'text' && ActivityStyles.stringValue,
                { fontSize: 14 },
              ]}>{` ${attr.newValue}`}</Text>
          </StackV2>
        )
      } else if (!attr.previousValue && attr.newValue) {
        return (
          <StackV2 key={key} direction="row" alignItems="flex-start" style={{ height: 'auto' }}>
            <Text style={{ fontSize: 14 }}>{`added ${attr.name} `}</Text>
            <Text>
              <Icon.ArrowRight size="14" style={{ marginTop: 2 }} />
            </Text>
            <Text
              style={[
                attr.type === 'datetime' && ActivityStyles.datetimeValue,
                attr.type === 'text' && ActivityStyles.stringValue,
                { fontSize: 14 },
              ]}>{` ${attr.newValue}`}</Text>
          </StackV2>
        )
      } else if (attr.previousValue && !attr.newValue) {
        return (
          <StackV2 key={key} direction="row" alignItems="flex-start" style={{ height: 'auto' }}>
            <Text style={{ fontSize: 14 }}>{`removed ${attr.name} `}</Text>
          </StackV2>
        )
      }
    }
  }

  const _renderStars = (stars: StarData[]) => {
    return stars.map((star: StarData, key: number) => {
      return (
        <Text key={key} style={{ color: GlobalStyles.MONO_BLACK, marginRight: 10 }}>
          {`${star.name}${star.requestable_type ? ` (${capitalize(star.requestable_type.toLowerCase())})` : ''}${
            key < stars.length - 1 ? ',' : ''
          }`}
        </Text>
      )
    })
  }

  return (
    <View style={[ActivityStyles.main, containerStyles]}>
      <View style={[ActivityStyles.outline, dashedLineStyles]} />
      <View style={[ActivityStyles.details, detailsWrapperStyles]}>
        {activities.map(({ event, user, date_time, stars = null, old_values, new_values }, key) => {
          let type = ''
          const isCreatedEvent: boolean =
            ['created', 'added'].some((addedEvents) => event.includes(addedEvents)) &&
            !['job-shortlist-added', 'job-users-added'].some((exceptionEvents) => event.includes(exceptionEvents))
          let attributes: Array<AttributesData> = []
          if (['job-talents-assigned', 'job-shortlist-added', 'job-users-added'].some((starEvents) => event.includes(starEvents))) {
            stars = generateStars(new_values)
          } else if (!isCreatedEvent) {
            attributes = generateAttributes(event, old_values, new_values)
          }

          let model = ''
          if (event?.includes('-')) {
            if (event.split('-').length === 3) {
              const [model1, model2, newEvent] = event.split('-')
              type = newEvent
              model = ['talents', 'shortlist', 'users'].some((includedModel2Events) => includedModel2Events === model2)
                ? [model1, model2].join(' ')
                : model1
            } else {
              const [newModel, newEvent] = event.split('-')
              type = newEvent
              model = newModel
            }
          }
          const isStatusUpdateEvent = !!attributes.length && attributes.some(({ name }) => name.toLowerCase() === 'status')

          return stars?.length || attributes.length || isCreatedEvent || isStatusUpdateEvent ? (
            <View style={[ActivityStyles.activityWrapper, activityWrapperStyles]} key={key}>
              <View style={[ActivityStyles.bulletWrapper, bulletWrapperStyles]}>
                <View style={[ActivityStyles.bullet, bulletStyles]}></View>
              </View>
              <View style={[ActivityStyles.activityDetailsWrapper, activityDetailsWrapperStyles]}>
                <View style={ActivityStyles.activity}>
                  <StackV2
                    style={{
                      height: 'auto',
                      flexWrap: 'wrap',
                      width: 'calc(100% - 120px)',
                    }}
                    direction="row"
                    alignItems="flex-start">
                    {type !== 'apply' && (
                      <View>
                        <Text
                          style={{
                            color: GlobalStyles.MONO_BLACK,
                            fontSize: 14,
                            lineHeight: 24,
                          }}>
                          {user?.name}
                        </Text>
                      </View>
                    )}
                    <View>
                      <Text
                        style={{
                          color: GlobalStyles.SLATE_700,
                          fontSize: 14,
                          lineHeight: 24,
                        }}>
                        {_renderMessage(context, type, stars, model, new_values)}
                      </Text>
                    </View>
                  </StackV2>
                  <StackV2 style={ActivityStyles.activityDate} direction="column" alignItems="flex-end">
                    {_computeTimeline(date_time)}
                  </StackV2>
                </View>
                <View style={ActivityStyles.attributesWrapper}>
                  {stars && !!stars?.length && (
                    <StackV2 direction="row" alignItems="flex-start" style={{ flexWrap: 'wrap' }}>
                      {_renderStars(stars)}
                    </StackV2>
                  )}
                  {attributes && !!attributes.length && !isStatusUpdateEvent && (
                    <StackV2 direction="column" alignItems="flex-start" style={{ flexWrap: 'wrap' }}>
                      {attributes.map(_renderAttributes)}
                    </StackV2>
                  )}
                </View>
              </View>
            </View>
          ) : (
            <></>
          )
        })}
      </View>
    </View>
  )
}

export default Activity
