import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { iModalProps } from '@utils/types/modal'
import { forwardRef } from 'react'
import Backdrop from '@mui/material/Backdrop'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
}

const DialogModal = ({ children, onBackdropPress, ...props }: iModalProps, ref?: any) => {
  return (
    <Modal
      open={!!props.show}
      onClose={() => onBackdropPress}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}>
      <Box sx={style}>{children}</Box>
    </Modal>
  )
}

export default forwardRef(DialogModal)
