import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { NavigationContext } from '@utils/context/Navigation.context'
import Link from 'next/link'
import { useContext } from 'react'

export default function JobCreated({ jobId }: { jobId: string }) {
  const { pathEnums } = useContext(NavigationContext)
  const { workspace } = useAppSelector(selectWorkspace) // Get data from the selector

  return (
    <div>
      Job created successfully.{' '}
      <Link target="_blank" href={{ pathname: pathEnums.REQUEST_BY_ID, query: { workspace, requestId: jobId } }}>
        View Job
      </Link>
    </div>
  )
}
