import { GlobalStyles, Icon } from '@andromeda'
import { Button, Divider, Stack, Typography, inputBaseClasses, stackClasses, typographyClasses } from '@mui/material'
import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { useEffect, useMemo, useState } from 'react'
import CustomFieldTextfield from './cf-inputs/cf-textfield'
import CustomFieldDropdown from './cf-inputs/cf-dropdown'
import { iCustomField, iCustomFieldProps, tCustomFields } from './custom-fields.types'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { CUSTOM_FIELD_TYPES } from '../settings/components'
import { breakpoints } from 'src/andromeda/helpers/theme'
import { decodeQoutes } from '@utils/functions/helperFunctions'
import { Types } from '@orbit'

export function decodeCustomFieldsValue(value: Record<string, string>) {
  return Object.entries(value).reduce<Record<string, string>>((acc, [key, value]) => {
    acc[decodeQoutes(key)] = value
    return acc
  }, {})
}

export function getCustomFieldsDefaultValue(
  section: 'job' | 'deliverable' | 'talent',
  customFields: Types.Workspaces.iCustomFieldSettings[],
  currentValues: Record<string, string>
) {
  const sectionCustomFields = customFields.find((setting) => setting.section === section)?.fields_settings

  return sectionCustomFields
    ? Object.entries(sectionCustomFields || {}).reduce<Record<string, string>>((acc, [key, value]) => {
        if (!currentValues[key]) {
          acc[key] = sectionCustomFields[key].default_value || ''
        }

        return acc
      }, structuredClone(currentValues))
    : currentValues
}

const CustomFields = ({
  section,
  name,
  parentForm,
  defaultValues,
  isEdit = false,
  standalone = false,
  columnNo = 2,
  onChange,
  onInputFieldClick,
  handleSubmit,
}: tCustomFields) => {
  const parentFormContext = useFormContext()
  const { settings } = useAppSelector(selectWorkspace)
  const { custom_field_settings } = settings || {}
  const [customFields, setCustomFields] = useState<iCustomField[]>([])
  const [isEditing, setIsEditing] = useState(isEdit || false)
  const [isLoading, setIsLoading] = useState(false)

  const customFieldsForm = useForm<{ [key: string]: string }>({
    defaultValues: {},
    mode: 'onChange',
  })

  const cfFieldName = name || 'custom_fields'
  const _parentForm = parentForm || parentFormContext
  const formValue = (_parentForm || customFieldsForm).watch()
  const _formValue = _parentForm ? formValue[cfFieldName] : formValue

  const CustomFields = useMemo(() => {
    const renderField = (field: iCustomField) => {
      const defaultProps: iCustomFieldProps = {
        key: field._field,
        name: `${cfFieldName}.${field._field!}`,
        field,
        isEdit: isEditing,
        onClick: (fieldVar: iCustomField) => {
          if (standalone && !isEditing) {
            setIsEditing(true)
          }

          if (onInputFieldClick) {
            onInputFieldClick(fieldVar)
          }
        },
      }

      switch (field.type) {
        // Textfield
        case CUSTOM_FIELD_TYPES[0].id:
          return <CustomFieldTextfield {...defaultProps} itemsAlignment={section === 'deliverable' ? 'center' : 'flex-start'} />
        // Dropdown
        case CUSTOM_FIELD_TYPES[1].id:
          return <CustomFieldDropdown {...defaultProps} />
      }
    }

    if (customFields.length) {
      if (columnNo > 1) {
        const groupedFields = []
        const colWidth = `${100 / columnNo}%`

        for (let i = 0; i < customFields.length; i += columnNo) {
          groupedFields.push(
            <Stack
              key={`group-${i}`}
              divider={<Divider light style={{ borderColor: GlobalStyles.SLATE_100 }} />}
              sx={{
                flexDirection: 'column',
                columnGap: '12px',
                [`@media (min-width: ${breakpoints.values.md}px)`]: {
                  flexDirection: 'row',
                  rowGap: '12px',
                  [`> .${stackClasses.root}`]: {
                    flex: `0 1 ${colWidth}`,
                    maxWidth: `calc(${colWidth} - 12px)`,
                    boxSizing: 'border-box',
                  },
                },
              }}>
              {customFields[i] && renderField(customFields[i])}
              {customFields[i + 1] && renderField(customFields[i + 1])}
            </Stack>
          )
        }

        return groupedFields
      }

      return customFields.map(renderField)
    }

    return null
  }, [customFields, isEditing, standalone, onInputFieldClick, columnNo, cfFieldName])

  const currentValues = useMemo(() => {
    return typeof defaultValues === 'string' ? JSON.parse(defaultValues) : defaultValues || {}
  }, [defaultValues])

  useEffect(() => {
    setIsEditing(isEdit)
  }, [isEdit])

  useEffect(() => {
    if (custom_field_settings) {
      const _currentValues = structuredClone(currentValues)
      const sectionCustomFields = custom_field_settings
        .filter((setting) => setting.section === section)
        .flatMap((setting) => {
          const objectFields: Record<string, iCustomField & { key?: string }> =
            typeof setting.fields_settings === 'string' ? JSON.parse(setting.fields_settings) : setting.fields_settings || {}

          return Object.entries(objectFields)
            .map(([key, data]) => {
              if (!_currentValues[key]) _currentValues[key] = data.default_value || ''

              return {
                ...data,
                _field: data.key || key,
                value: _currentValues[key],
              }
            })
            .filter((field) => field.enabled)
        })

      customFieldsForm.reset(_currentValues)
      setCustomFields(sectionCustomFields)
    }
  }, [section, currentValues, custom_field_settings, customFieldsForm])

  useEffect(() => {
    if (onChange) {
      onChange(_formValue)
    }
  }, [onChange, _formValue])

  if (customFields.length === 0) {
    return null
  }

  return (
    <FormProvider {...(_parentForm || customFieldsForm)}>
      <Stack
        direction="column"
        className="paper-border"
        bgcolor="#FFF"
        divider={<Divider light style={{ borderColor: GlobalStyles.SLATE_100 }} />}
        sx={{
          [`input, input.${inputBaseClasses.disabled}`]: {
            fontSize: '14px',
            opacity: 1,
            WebkitTextFillColor: GlobalStyles.SLATE_700,
          },
          [`> .${stackClasses.root} > .${typographyClasses.root}, > .${stackClasses.root} > .${stackClasses.root} > .${typographyClasses.root}`]:
            {
              width: '140px',
              minWidth: '130px',
              wordBreak: 'break-word',
            },
        }}>
        <Stack
          direction="row"
          padding="22px 12px"
          columnGap="12px"
          justifyContent={standalone ? 'space-between' : 'flex-start'}
          color={GlobalStyles.SLATE_500}>
          <Stack direction="row" gap="12px" alignItems="center">
            <Icon.Settings size={20} />
            <Typography fontSize="14px">ADDITIONAL FIELDS</Typography>
          </Stack>

          {standalone && (
            <Stack direction="row" gap="8px" alignItems="center">
              {isEditing && (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: '12px',
                    padding: '4px 9px',
                    minHeight: 'auto',
                  }}
                  onClick={() => {
                    if (handleSubmit) {
                      handleSubmit(_formValue, setIsEditing, setIsLoading)
                    }
                  }}
                  type="button"
                  disabled={!customFieldsForm.formState.isDirty || isLoading}>
                  Save Changes
                </Button>
              )}

              <Button
                type="button"
                size="small"
                aria-label={isEditing ? 'cancel edit additional fields' : 'edit additional fields'}
                onClick={() => {
                  if (isEditing) {
                    customFieldsForm.reset(currentValues)
                    setIsEditing(false)
                  } else {
                    setIsEditing(true)
                  }
                }}
                variant="outlined"
                sx={{
                  border: `1px solid ${GlobalStyles.SLATE_100}`,
                  fontSize: '12px',
                  padding: '3px 9px',
                  minHeight: 'auto',
                }}
                disabled={isLoading}>
                {isEditing ? <Icon.Slash width={12} /> : <Icon.Edit2 width={12} />}
              </Button>
            </Stack>
          )}
        </Stack>

        {CustomFields}
      </Stack>
    </FormProvider>
  )
}

export default CustomFields
