import { ReactNode } from 'react'
import { ViewProps as NativeViewProps, FlexAlignType, FlexStyle, StyleProp, ViewStyle } from 'react-native'
import { iResponsiveStyles } from '../types/common'

export enum StackDefaults {
  SPACE = 'sm',
  DIRECTION = 'row',
  ALIGN = 'center',
  JUSTIFY = 'flex-start',
  WIDTH = '100%',
  HEIGHT = '100%',
}

export type SpaceType = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'none'

export type DirectionType = FlexStyle['flexDirection']

export type ResponsiveValue<T> = T | undefined | Array<T | null>

export interface StackCellProps {
  style?: StyleProp<ViewStyle>
  element: React.ReactElement
}

export interface SpacedChildrenProps {
  children: ReactNode
  space: SpaceType
  direction: ResponsiveValue<DirectionType>
  reversed: undefined | boolean
  border: boolean
  rounded: boolean
  childrenStyle?: StyleProp<ViewStyle>
  divider?: boolean
}

export interface StackProps extends NativeViewProps, iResponsiveStyles {
  /**
   * The divider element to use between other elements.
   */
  divider?: boolean
  /**
   * The space between each item.
   */
  space?: SpaceType
  /**
   * Reverse the direction of items.
   */
  reversed?: boolean
  /**
   * The direction of the items.
   */
  direction?: FlexStyle['flexDirection']
  /**
   * Align the items
   */
  align?: FlexAlignType
  /**
   * Justify Content of items
   */
  justify?: FlexStyle['justifyContent']
  /**
   * Adds a boarder to each child element
   */
  border?: boolean
  /**
   * Adds a rounded edge to the stack
   */
  rounded?: boolean
  /**
   * Add custom style to parent Stack
   */
  style?: StyleProp<ViewStyle>
  /**
   * Add custom style to each element inside
   */
  childrenStyle?: StyleProp<ViewStyle>
  /**
   * Sets the flex value of the parent view
   */
  flex?: number
  /**
   * Sets the width value of the parent view
   */
  width?: number | 'auto' | '100%'
  /**
   * Sets the height value of the parent view
   */
  height?: number | 'auto' | '100%'
}
