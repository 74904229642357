import { Location } from 'slate'
import { Descendant, Range, Editor, Node, Element, NodeEntry, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

export type LinkElement = {
  type: 'link'
  children: Descendant[]
  link?: string
  align?: string
}

const isUrl = (text: string) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol (optional)
      '((([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,})|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(:\\d+)?' + // port (optional)
      '(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*' + // path (optional)
      '(\\?[;&a-zA-Z0-9@:%_\\+.~#?&//=]*)?' + // query string (optional)
      '(#[-a-zA-Z0-9_]*)?$' // fragment locator (optional)
  )
  return urlPattern.test(text)
}

const isLinkElement = (node: Node): node is Element & { type: string } => {
  return Element.isElement(node) && 'type' in node
}

const isLinkActive = (editor: Editor): boolean => {
  const [link]: NodeEntry<Element & { type: string }>[] = Array.from(
    Editor.nodes(editor, { match: (n) => isLinkElement(n) && n.type === 'link' })
  )

  return !!link
}

const unwrapLink = (editor: Editor): void => {
  Transforms.unwrapNodes(editor, { match: (n) => isLinkElement(n) && n.type === 'link' })
}

const wrapLink = (editor: Editor, link: string, text?: string, target?: Location): Promise<void> => {
  return new Promise<void>((resolve, _reject) => {
    if (isLinkActive(editor)) {
      unwrapLink(editor)
    }

    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const URL_LINK = link.match(/^[a-zA-Z]+:\/\//) ? link : `http://${link}`

    const linkObj: LinkElement = {
      type: 'link',
      link: URL_LINK,
      children: [{ text: text || URL_LINK, link: URL_LINK }],
    }

    if (isCollapsed) {
      Transforms.insertNodes(editor, linkObj)
    } else {
      Transforms.wrapNodes(editor, linkObj, { split: true })

      if (target) {
        // Ensure the link is selected and then replace it
        Transforms.select(editor, target)
        Transforms.setNodes(editor, { link: URL_LINK }, { match: (n) => isLinkElement(n) && n.type === 'link' })
      }
    }

    setTimeout(() => {
      // Move the cursor to the end of the link
      Transforms.collapse(editor, { edge: 'end' })

      // Move the cursor one position after the link
      Transforms.move(editor, { distance: 1, unit: 'offset' })

      // Ensure the editor is focused
      ReactEditor.focus(editor)

      resolve()
    })
  })
}

const withLinks = (editor: Editor) => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = (text) => {
    const { selection } = editor

    if (selection) {
      insertText(text)

      if (text === ' ') {
        const [node] = Editor.node(editor, selection.focus.path)
        const textBefore = Node.string(node).slice(0, selection.focus.offset + text.length)

        const words = textBefore.split(' ')
        const lastWord = words[words.length - 2] + words[words.length - 1]

        // Check if the last word is a valid URL and the last character is a space
        if (isUrl(lastWord.trim())) {
          const url = lastWord.trim()
          const linkText = url // Use the URL itself as the link text
          const start = selection.focus.offset - lastWord.length
          const end = selection.focus.offset

          // Ensure the selection is valid before transforming
          if (start >= 0 && end >= start) {
            Transforms.select(editor, {
              anchor: { path: selection.focus.path, offset: start },
              focus: { path: selection.focus.path, offset: end },
            })

            wrapLink(editor, url, linkText).then(() => {
              // Move the cursor back to the position after the space
              Transforms.move(editor, { distance: 1, unit: 'offset' })
            })
          }
        }
      }
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      const url = text
      const linkText = url // Use the URL itself as the link text
      wrapLink(editor, url, linkText)
    } else {
      insertData(data)
    }
  }

  return editor
}

export { withLinks, wrapLink, unwrapLink, isLinkActive }
