import { Orbit, Types } from '@orbit'
import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { ApiResponseError } from '@utils/types/auth'
import { iInviteInitialState } from '@utils/types/invite'
import { AppState } from '../store'

const initialState: iInviteInitialState = {
  invite: null,
  status: 'idle',
  acceptStatus: 'idle',
  rejectStatus: 'idle',
  createStatus: 'idle',
  invites: [],
}

export const createInvite = createAsyncThunk(
  'invite/create',
  async (payload: Types.Invite.iInviteCreatePayload, { rejectWithValue }): Promise<Types.Invite.iCreateInviteResponse | any> => {
    try {
      const response = await Orbit.Services.inviteService.create(payload)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)
export const viewInvite = createAsyncThunk(
  'invite/view',
  async (payload: { token: string; eid: string }, { rejectWithValue }): Promise<Types.Invite.iInvite | any> => {
    try {
      const response = await Orbit.Services.inviteService.get(payload)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)
export const listInvites = createAsyncThunk('invite/list', async (_, { rejectWithValue }) => {
  try {
    const response = await Orbit.Services.inviteService.list()

    return response.data
  } catch (e) {
    return rejectWithValue((e as ApiResponseError).response.data)
  }
})

export const acceptInviteAsNewUser = createAsyncThunk(
  'invite/acceptAsNew',
  async (
    cred: { token: string; eid: string; payload: Types.Invite.iInviteAcceptPayload },
    { rejectWithValue }
  ): Promise<Types.Invite.iInvite | any> => {
    try {
      const response = await Orbit.Services.inviteService.acceptAsNewUser(cred)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const acceptInviteAsExistingUser = createAsyncThunk(
  'invite/acceptAsExisting',
  async (cred: { eid: string }, { rejectWithValue }): Promise<Types.Invite.iInvite | any> => {
    try {
      const response = await Orbit.Services.inviteService.acceptAsExistingUser(cred)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const rejectInvite = createAsyncThunk(
  'invite/reject',
  async (cred: { eid: string }, { rejectWithValue }): Promise<Types.Invite.iInvite | any> => {
    try {
      const response = await Orbit.Services.inviteService.reject(cred)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    resetInviteSlice: () => initialState,
    setCreateInviteFormValue: (state, action: PayloadAction<{ name: string; email: string }>) => {
      state.createInviteDraft = { ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewInvite.fulfilled, (state, action) => {
        state.invite = action.payload
        state.status = 'success'
      })
      .addCase(listInvites.fulfilled, (state, action) => {
        state.invites = action.payload
        state.status = 'success'
      })
      .addCase(viewInvite.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(createInvite.pending, (state) => {
        state.createStatus = 'loading'
      })
      .addCase(createInvite.fulfilled, (state, action) => {
        state.createStatus = 'success'
      })
      .addCase(createInvite.rejected, (state) => {
        state.createStatus = 'failed'
      })
      .addCase(acceptInviteAsExistingUser.fulfilled, (state, action) => {
        state.acceptStatus = 'success'
      })
      .addCase(acceptInviteAsExistingUser.rejected, (state) => {
        state.acceptStatus = 'failed'
      })
      .addCase(acceptInviteAsNewUser.fulfilled, (state, action) => {
        state.acceptStatus = 'success'
      })
      .addCase(acceptInviteAsNewUser.rejected, (state) => {
        state.acceptStatus = 'failed'
      })
      .addCase(rejectInvite.pending, (state) => {
        state.rejectStatus = 'loading'
      })
      .addCase(rejectInvite.fulfilled, (state, action) => {
        state.rejectStatus = 'success'
      })
      .addCase(rejectInvite.rejected, (state) => {
        state.rejectStatus = 'failed'
      })
      .addMatcher(isAnyOf(listInvites.pending, viewInvite.pending), (state) => {
        state.status = 'loading'
      })
      .addMatcher(isAnyOf(acceptInviteAsExistingUser.pending, acceptInviteAsNewUser.pending), (state) => {
        state.acceptStatus = 'loading'
      })
  },
})

export const { resetInviteSlice, setCreateInviteFormValue } = inviteSlice.actions
export const { reducer } = inviteSlice
export const selectInvite = (state: AppState) => state.invite
