import { useContext, useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import BannerMobile from './banner-mobile'
import BannerDesktop from './banner-desktop'
import BannerJobDesktop from './banner-job-destop'
import { WorkspaceTheme, WorkspaceThemeContext } from '@utils/context/Theme.context'

const useBannerImage = ({ workspaceTheme }: { workspaceTheme?: WorkspaceTheme }) => {
  const { primaryColor: backgroundColor, secondaryColor: primary, tertiaryColor: secondary } = useContext(WorkspaceThemeContext)
  const getSvgString = (component: JSX.Element) => {
    return `data:image/svg+xml;base64,${btoa(renderToStaticMarkup(component))}`
  }
  const bannerColors = useMemo(() => {
    if (workspaceTheme) {
      return {
        primary: workspaceTheme.secondaryColor,
        secondary: workspaceTheme.tertiaryColor,
        backgroundColor: workspaceTheme.primaryColor,
      }
    }

    return { primary, secondary, backgroundColor }
  }, [workspaceTheme, primary, secondary, backgroundColor])

  return {
    mobile: getSvgString(
      <BannerMobile primary={bannerColors.primary} secondary={bannerColors.secondary} backgroundColor={bannerColors.backgroundColor} />
    ),
    desktop: getSvgString(
      <BannerDesktop primary={bannerColors.primary} secondary={bannerColors.secondary} backgroundColor={bannerColors.backgroundColor} />
    ),
    jobDesktop: getSvgString(
      <BannerJobDesktop primary={bannerColors.primary} secondary={bannerColors.secondary} backgroundColor={bannerColors.backgroundColor} />
    ),
  }
}

export default useBannerImage
