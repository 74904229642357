import { store } from '@redux/store'
/**
 *
 * @param permissions Additional permissions granted
 * @param allowedPermissions permission criteria
 * @param checkMethod every - check every allowedpermission; some - check atleast 1 permission exists
 * @returns boolean
 */
export const hasPermission = ({
  permissions = [],
  allowedPermissions = [],
  checkMethod = 'every',
  mergePermissions = true,
  escape = false,
}: {
  permissions?: Array<string>
  allowedPermissions: Array<string>
  checkMethod?: 'every' | 'some'
  mergePermissions?: boolean
  escape?: boolean
}) => {
  if (escape) return true
  // const userPermissions = useAppSelector(selectPermissions)
  const userPermissions = store.getState().auth.permissions
  let allPermissions: Array<string> = []
  if (mergePermissions) {
    allPermissions = Array.from(new Set(userPermissions.concat(permissions)))
  } else {
    allPermissions = permissions ?? []
  }
  if (allPermissions == null) allPermissions = []
  let hasPermission = false
  if (checkMethod == 'every') {
    if (allowedPermissions.every((perm) => allPermissions.includes(perm))) hasPermission = true
  } else {
    if (allowedPermissions.some((perm) => allPermissions.includes(perm))) hasPermission = true
  }
  // console.log('perm', hasPermission)
  // console.log('userPermissions', userPermissions)
  // console.log('allowedPermissions', allowedPermissions)
  // console.log('allPermissions', allPermissions)
  // console.log('permissions', permissions)
  return hasPermission
}
