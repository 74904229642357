import { useAppDispatch } from '@redux/hooks'
import { fetchJobById } from '@redux/reducers/jobsReducer'
import { useRouter } from 'next/router'
import { SetStateAction, useEffect } from 'react'

export const useQueryJob = ({
  expandedJob,
  setExpandedIndex,
}: {
  expandedJob: string
  setExpandedIndex: (value: SetStateAction<string>) => void
}) => {
  const router = useRouter()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!expandedJob && router.query.jobEid) {
      setExpandedIndex(router.query.jobEid as string)
      dispatch(fetchJobById({ jobEid: router.query.jobEid as string }))
    }
  }, [dispatch, expandedJob, router.query.jobEid, setExpandedIndex])
}
