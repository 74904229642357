import { Button, Card, GlobalStyles, Icon, Stack, Text, View } from '@andromeda'
import { AppStyles } from '@styles'
import { useScreenDimensions } from '@utils/hooks/dimensions'

const ExternalJobFormExpired = (): JSX.Element => {
  const { screenSize } = useScreenDimensions()

  const responsiveStyles = {
    paddingHorizontal: !screenSize.md ? AppStyles.MODAL_MOBILE_PADDING : 160,
  }

  const _email = () => {
    window.location.href = `mailto:info@pickstar.com.au`
  }

  return (
    <View
      style={{
        maxWidth: 960,
        margin: 'auto',
        marginTop: 180,
        paddingVertical: 0,
        paddingHorizontal: 20,
      }}>
      <Card.Error
        errorIcon={<Icon.Slash size={67} strokeWidth={1} color={GlobalStyles.MONO_BLACK} />}
        heading="Expired"
        subHeading={
          <>
            <Text type="paragraph" size={'base'} style={{ color: GlobalStyles.SLATE_700, marginTop: 20, textAlign: 'center' }}>
              {'This link has expired. Please email us or reach out to your contact to receive a new job form'}
            </Text>
          </>
        }
        containerStyle={{
          paddingTop: 80,
          paddingBottom: 80,
          backgroundColor: GlobalStyles.SLATE_50,
          ...responsiveStyles,
        }}
        actionButtons={
          <Stack flex={1} justify="center" style={{ marginTop: 30 }}>
            <Button type="outlined" onPress={_email} transparent>
              <Text>
                <Icon.Mail strokeWidth={1} color={GlobalStyles.PRIMARY_500} />
              </Text>
              <Text size={'base'} style={{ color: GlobalStyles.PRIMARY_500 }}>
                Email Us
              </Text>
            </Button>
          </Stack>
        }
      />
    </View>
  )
}

export { ExternalJobFormExpired }
