import { Avatar, GlobalStyles, Icon, Tooltip } from '@andromeda'
import { Box, Button, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { getAudits } from '@redux/reducers/auditReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { capitalize, getInitials } from '@utils/functions/helperFunctions'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { useContext, useEffect, useMemo, useState } from 'react'
import { DeliverableFormContext, isEditableContext } from '../'
import { AddTalent, AddTalentProps } from '../AddTalent'
import { labelStyles } from '../Overview'
import { PermissionWrapper } from 'src/components/hocs'
import { selectAuth } from '@redux/reducers/authReducer'
import { DeliverablePermission } from '@utils/enum/permissions'
import Link from 'next/link'
import { PathEnums } from '@utils/context/Navigation.context'
import { Section } from '../components'
import { Users } from './Users'
import { Invited } from './Invited'
import { selectJobs } from '@redux/reducers/jobsReducer'
import { IsDirtyContext } from '@utils/context/isDirty.context'
import useJobQuery from '@utils/hooks/useJobQuery'

export const STATUS_OPTIONS = [
  {
    id: 'ASSIGNED',
    label: 'ASSIGNED',
    value: (
      <Box
        bgcolor={GlobalStyles.SLATE_100}
        borderRadius="50%"
        height="24px"
        width="24px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      />
    ),
  },
  {
    label: 'BOOK',
    id: 'BOOKED',
    value: (
      <Box
        bgcolor={GlobalStyles.STATUS_POSITIVE}
        borderRadius="50%"
        height="24px"
        width="24px"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Icon.Check color="white" height={20} width={20} />
      </Box>
    ),
  },
  {
    label: 'COMPLETE',
    id: 'DONE',
    value: (
      <Box
        bgcolor={GlobalStyles.STATUS_INFO}
        borderRadius="50%"
        height="24px"
        width="24px"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Icon.Check color="white" height={20} width={20} />
      </Box>
    ),
  },
  // {
  //     label: 'DECLINE',
  //     id: 'CANCELLED',
  //     value: (
  //         <Box
  //             bgcolor={GlobalStyles.STATUS_NEGATIVE}
  //             borderRadius="50%"
  //             height="24px"
  //             width="24px"
  //             display="flex"
  //             alignItems="center"
  //             justifyContent="center">
  //             <Icon.X color="white" height={20} width={20} />
  //         </Box>
  //     ),
  // },
  {
    label: 'REMOVE',
    id: 'REMOVED',
    value: (
      <Box
        bgcolor={GlobalStyles.MONO_BLACK}
        borderRadius="50%"
        height="24px"
        width="24px"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Icon.Slash color="white" height={20} width={20} />
      </Box>
    ),
  },
]

export const StatusDropdown = ({
  selectedValue = STATUS_OPTIONS[0],
  onClose,
  disabled,
}: {
  selectedValue?: { id: string; value: JSX.Element; label: string }
  onClose: (id: string) => void
  disabled?: boolean
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (id?: string) => {
    setAnchorEl(null)
    if (id) onClose(id)
  }

  return (
    <>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-label={selectedValue.id}
        disabled={disabled}
        onClick={handleClick}>
        <>
          <Tooltip text={selectedValue.label} showIcon={false}>
            {selectedValue.value}
          </Tooltip>
          {!disabled && <Icon.ChevronDown width={16} color={GlobalStyles.PRIMARY_500} />}
        </>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {STATUS_OPTIONS.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => handleClose(option.id)}
            sx={{ fontSize: GlobalStyles.FONT_SIZES.SMALL, columnGap: '4px' }}>
            {option.value}
            {capitalize(option.label?.toLowerCase())}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

type StatusFormProps = Pick<Types.Agreements.iDeliverableAgreement, 'eid' | 'status'>[]

export const Status = ({
  deliverable_agreements,
  eid,
  onSuccess = () => {},
  jobEid,
  onClickTalent,
  costMode,
  readOnly,
  ...props
}: {
  jobEid: string
  eid: string
  deliverable_agreements: Types.Deliverable.iDeliverable['deliverable_agreements']
  readOnly?: boolean
  onSuccess?: () => void
  onClickTalent: (id: string) => void
  costMode: Types.Job.iJob['cost_mode']
} & Omit<AddTalentProps, 'deliverableEid' | 'context'>) => {
  const dispatch = useAppDispatch()
  const { workspace } = useAppSelector(selectWorkspace)
  const { permissions } = useAppSelector(selectAuth)
  const { viewedDeliverableUsersStatus, viewedDeliverableUsers } = useAppSelector(selectJobs)

  const { toggleModal, closeModal } = useContext(ModalContext)
  const { setDeliverableFormState } = useContext(DeliverableFormContext)
  const { setIsDirty } = useContext(IsDirtyContext)

  const [statuses, setStatuses] = useState<{ [key: string]: string } | undefined>(
    deliverable_agreements?.reduce((acc, { eid, status }) => ({ ...acc, [eid]: status }), {})
  )

  const { details } = useJobQuery({ jobEid, fetchDetails: false, fetchUsers: false })

  const isAddTalentVisible = useMemo(
    () => deliverable_agreements?.some(({ jobAgreement }) => jobAgreement?.talent) && details.cost_mode === 'PER_JOB',
    [deliverable_agreements, details.cost_mode]
  )

  useEffect(() => {
    dispatch(getAudits({ eid: eid, type: 'deliverable' }))
  }, [dispatch, eid])

  useEffect(() => {
    if (deliverable_agreements?.length) {
      setStatuses(deliverable_agreements?.reduce((acc, { eid, status }) => ({ ...acc, [eid]: status }), {}))
      setDeliverableFormState(deliverable_agreements?.reduce((acc, { eid, status }) => [...acc, { eid, status }], [] as StatusFormProps))
    }
  }, [deliverable_agreements, setDeliverableFormState])

  const handleClose = ({ agreementEid, selectedId }: { agreementEid: string; selectedId: string }) => {
    setStatuses((prev) => ({ ...prev, [agreementEid]: selectedId }))
    setDeliverableFormState((prev: StatusFormProps) => {
      return (Array.isArray(prev) ? prev : (Object.values(prev) as StatusFormProps))?.reduce((acc, curr) => {
        if (curr.eid === agreementEid) {
          return [...acc, { ...curr, status: selectedId }]
        }
        return [...acc, curr]
      }, [] as StatusFormProps)
    })
    setIsDirty(true)
  }

  const handleAddTalent = () => {
    toggleModal(ModalPages.ASSIGN_TALENT, {
      modalProps: {
        [ModalPages.ASSIGN_TALENT]: {
          deliverableEid: eid,
          assignedTalentEids: props.assignedTalentEids,
          onSuccess: (data) => {
            onSuccess(data)
            closeModal(ModalPages.ASSIGN_TALENT)
          },
          context: 'focus-panel',
        },
      },
    })
  }

  return (
    <Stack gap="38px">
      <Section
        SectionIcon={(props) => <Icon.Activity {...props} />}
        title="Talent Status"
        aria-label="talent status"
        onClickAdd={handleAddTalent}
        noAdd={isAddTalentVisible || readOnly}>
        {deliverable_agreements?.map(({ eid, jobAgreement }) => (
          <Stack key={eid} border={`1px solid ${GlobalStyles.SLATE_100}`} borderRadius="6px" padding="6px" role="listitem" gap="14px">
            <Grid container display="flex" alignItems="center" gap="16px">
              <Grid display="flex" alignItems="center" gap="10px" xxs={8} xs={9.5}>
                <Box
                  role="button"
                  aria-label={jobAgreement?.talent?.display_name}
                  onClick={() => {
                    trackEvent({
                      event: TrackingEventEnums.Others.FOCUS_PANEL_TOGGLED,
                      eventProperties: { workspace, context: 'talent', open: true },
                    })
                    onClickTalent(jobAgreement?.talent?.eid as string)
                  }}
                  sx={{ cursor: 'pointer' }}>
                  <Avatar
                    image={jobAgreement?.talent?.profile_image?.full_url}
                    placeHolder={
                      <Typography fontSize="18px" color="white" textAlign="center">
                        {getInitials(jobAgreement?.talent?.display_name)}
                      </Typography>
                    }
                  />
                </Box>

                <Link
                  aria-label={jobAgreement?.talent?.display_name}
                  style={{ textDecoration: 'none' }}
                  href={{
                    pathname: PathEnums.TALENT_PAGE,
                    query: { workspace, talentId: jobAgreement?.talent?.eid },
                  }}>
                  <Typography {...labelStyles}>{jobAgreement?.talent?.display_name}</Typography>
                </Link>
              </Grid>
              <Grid xxs={1.5} xs={1}>
                <PermissionWrapper
                  permissions={permissions}
                  allowedPermissions={[
                    DeliverablePermission.AGREEMENT_MARK_DONE,
                    DeliverablePermission.AGREEMENT_MARK_BOOKED,
                    DeliverablePermission.AGREEMENT_MARK_ASSIGNED,
                    DeliverablePermission.AGREEMENT_MARK_REMOVED,
                  ]}>
                  <StatusDropdown
                    selectedValue={statuses && statuses[eid] ? STATUS_OPTIONS.find((option) => option.id === statuses[eid]) : undefined}
                    onClose={(id) => handleClose({ agreementEid: eid, selectedId: id })}
                    disabled={readOnly}
                  />
                </PermissionWrapper>
              </Grid>
              {/* <Grid xxs={1} textAlign="right">
                <Icon.MessageSquare height={15} width={15} />
              </Grid> */}
            </Grid>
          </Stack>
        ))}
      </Section>
      <Stack rowGap="10px">
        <Users eid={eid} noAdd={readOnly} />
        {viewedDeliverableUsersStatus == 'success' && !!viewedDeliverableUsers?.invites.length && <Invited />}
      </Stack>
    </Stack>
  )
}
