import { API } from '../types'
import { Roles } from '../types/roles'
import { HttpClient } from './api.service'

export const rolesService = {
  listRoles: () => {
    return HttpClient.get<null, Roles.iRole[]>({
      endpoint: `${API.RolesEndpoints.LIST_ROLES}`,
      payload: null,
    })
  },
}
