import React, { forwardRef, useCallback, useMemo } from 'react'
import { createEditor, Descendant } from 'slate'
import { Editable, Slate, withReact } from 'slate-react'
import { Element, ElementProps, Leaf, LeafProps } from './tools'
import { markdownToSlate } from '@utils/functions/helperFunctions'
import { GlobalStyles } from '../globalStyles'
import { MentionsProp } from 'src/components/rich-editor-plugin/mention/mention-types'

type iRichTextReader = {
  initialValue: string
  commentStyle?: React.CSSProperties
  mentions?: MentionsProp
}

const RichTextReader = (props: iRichTextReader, ref: any) => {
  const { initialValue = '', mentions } = props
  const renderElement = useCallback((props: ElementProps) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: LeafProps) => <Leaf {...props} />, [])
  const editor = useMemo(() => withReact(createEditor()), [])
  return (
    <Slate editor={editor} initialValue={markdownToSlate(initialValue) as Descendant[]} onChange={() => {}}>
      <Editable
        style={{
          width: '100%',
          fontSize: '14px',
          lineHeight: '24px',
          fontWeight: '400',
          color: GlobalStyles.SLATE_700,
          ...props.commentStyle,
        }}
        renderElement={(props) => renderElement({ ...props, mentions })}
        renderLeaf={renderLeaf}
        readOnly
      />
    </Slate>
  )
}

export default forwardRef(RichTextReader)
