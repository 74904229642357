import { Types } from '@orbit'

type tJobCache = { job: (Types.Job.iJobList | Types.Job.iJob) & { _fromCache: boolean }; date: Date; status: 'added' | 'deleted' }
const JOB_CACHE_KEY = 'job:cache'

function getCachedJobs(status?: string): tJobCache[] {
  const prevData = localStorage.getItem(JOB_CACHE_KEY)
  const collection = prevData ? JSON.parse(prevData) : []
  return status && status !== 'ALL' ? collection.filter((cache: tJobCache) => cache.job.status === status) : collection
}

const JobCacheHelper = {
  saveJobToCache: (job: Types.Job.iJobList | Types.Job.iJob, status: 'added' | 'deleted') => {
    const cacheJobData = {
      job: { ...job, _fromCache: true, numberOfDeliverables: 0, numberOfDeliverablesCompleted: 0 },
      date: new Date(),
      status,
    }
    let cacheCollection = getCachedJobs()
    let cachedIndex = cacheCollection.findIndex((cache: tJobCache) => cache.job.eid === job.eid)

    if (cachedIndex > -1) {
      cacheCollection[cachedIndex] = cacheJobData
    } else {
      cacheCollection.push(cacheJobData)
    }

    localStorage.setItem(JOB_CACHE_KEY, JSON.stringify(cacheCollection))
  },
  checkAndAddDataFromCache: (collection: Types.Job.iJobList[], status: string, listType: string) => {
    const cachedJobs = getCachedJobs(status)
    let reflectedCollection: tJobCache[] = []

    // clear expired cache
    JobCacheHelper.clearExpiredCache()

    if (cachedJobs.length) {
      const sortedCachedJobs = cachedJobs.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      const addedCachedJobs = sortedCachedJobs.filter((cache: tJobCache) => cache.status === 'added')
      const deletedCachedJobs = sortedCachedJobs.filter((cache: tJobCache) => cache.status === 'deleted')
      let newCollection = structuredClone(collection)

      if (status === 'ALL' && listType === 'ALL') {
        for (const CACHE_JOB of addedCachedJobs) {
          if (collection.findIndex((job: Types.Job.iJobList) => job.eid === CACHE_JOB.job.eid) > -1) {
            // remove cached jobs from collection if it's already on the api response data
            reflectedCollection.push(CACHE_JOB)
          } else {
            newCollection = [CACHE_JOB.job as Types.Job.iJobList, ...newCollection]
          }
        }
      }

      for (const CACHE_JOB of deletedCachedJobs) {
        const jobIndex = newCollection.findIndex((job: Types.Job.iJobList) => job.eid === CACHE_JOB.job.eid)

        if (jobIndex > -1) {
          newCollection.splice(jobIndex, 1)
        }
      }

      // remove cache data after reflecting
      if (reflectedCollection.length) {
        const updatedCacheCollection = cachedJobs.filter(
          (cache: tJobCache) => reflectedCollection.findIndex((reflected: tJobCache) => reflected.job.eid === cache.job.eid) === -1
        )
        localStorage.setItem(JOB_CACHE_KEY, JSON.stringify(updatedCacheCollection))
      }

      return newCollection
    }

    return collection
  },
  clearExpiredCache: () => {
    const cachedJobs = getCachedJobs()

    // check and remove cache if is expired by 1hour
    const updatedCacheCollection = cachedJobs.filter((cache: tJobCache) => {
      const cacheDate = new Date(cache.date).getTime()
      const currentDate = new Date().getTime()
      return currentDate - cacheDate < 3600000 // 1hour = 3600000 milliseconds
    })

    localStorage.setItem(JOB_CACHE_KEY, JSON.stringify(updatedCacheCollection))
  },
}

export default JobCacheHelper
