import { Avatar, Button, CardV2, DropDownItemV2, DropdownV2, Icon, StackV2, Text, View } from '@andromeda'
import IconButton from '@mui/material/IconButton'
import { memo, useEffect, useState } from 'react'
import { DropdownOption } from '../Dropdown/types'
import { GlobalStyles } from '../globalStyles'
import { getProfilePic } from '../helpers'
import { useScreenDimensions } from '../hooks/dimensions'
import { CardUserStyles } from './styles'
import { CardUserDefaults, CardUserProps } from './types'

const CardUser = ({
  userData,
  type = CardUserDefaults.TYPE,
  dropdownOptions,
  dropdownOptionLabel,
  buttonStyle,
  selectedItem,
  wrapperStyle,
  buttonSpace = 'sm',
  showMessageButton = true,
  showContactButton = true,
  validateDropdown = false,
  preventSelect = false,
  showAddButton = true,
  ...props
}: CardUserProps) => {
  const [isSelected, setIsSelected] = useState<boolean>(props.selected)
  const { size: screenSize } = useScreenDimensions()
  const [invalid, setInvalid] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<DropdownOption | undefined>(selectedItem)

  const is2XtraSmall = screenSize === 'xxs'
  const isXtraSmall = screenSize === 'xs'
  const isSmall = screenSize === 'sm'

  const _handlePermissionSelect = (item: DropdownOption) => {
    // setInvalid(!item)
    setSelectedOption(item)
    props.onPermissionSelect && props.onPermissionSelect({ item, user: userData })
  }

  const _handleSelect = () => {
    const toSelect = !isSelected
    if (!preventSelect) {
      setIsSelected(toSelect)
      // setInvalid(toSelect && validateDropdown && !selectedOption)
    }
    props.onSelect && props.onSelect({ selected: toSelect, details: userData, setIsSelected })
  }

  const isUser = type === 'user'
  const isManager = type === 'manager'
  const isStar = type === 'star'
  const isOwner = type === 'owner'

  const _renderSubText = () => {
    if (props.subDetails) return props.subDetails

    if (isUser || isOwner) {
      return (
        <StackV2 direction={'row'} width={'auto'} height={'auto'}>
          {userData.company && (
            <Text size="sm" style={CardUserStyles.secondaryText}>
              {`${userData.company}`}
            </Text>
          )}
          {userData.department && (
            <Text size="sm" style={CardUserStyles.secondaryText}>
              {userData.company ? ` / ${userData.department}` : userData.department}
            </Text>
          )}
        </StackV2>
      )
    } else if (isStar) {
      return (
        <StackV2 direction={'row'} width={'auto'} height={'auto'}>
          <Text key={'user-occupation'} size="sm" style={CardUserStyles.secondaryText}>
            {userData.occupation}
          </Text>
          <Text key={'user-followers'} size="sm" style={CardUserStyles.secondaryText}>
            Followers: {userData.followers}
          </Text>
        </StackV2>
      )
    } else if (isManager) {
      return (
        <>
          <Text size="sm" style={CardUserStyles.secondaryText}>
            {userData.department}
          </Text>
        </>
      )
    }
  }

  useEffect(() => {
    setIsSelected(props.selected)
  }, [props.selected])

  useEffect(() => {
    setSelectedOption(selectedItem)
  }, [selectedItem])

  return (
    <CardV2
      key={userData.eid}
      style={{
        ...CardUserStyles.container,
      }}
      sx={{ padding: '6px', alignItems: 'center', display: 'flex' }}>
      <StackV2 direction="row" justifyContent="space-between" alignItems={'center'} flex={1} height={'auto'} columnGap="16px">
        {/* Avatar and User Details */}
        <StackV2
          direction="row"
          flex={1}
          columnGap="16px"
          // style={[
          //     applyResponsiveProp(
          //         {
          //             xxs: { marginBottom: 10 },
          //             xs: { marginBottom: 10 },
          //             default: { marginBottom: 0 },
          //         },
          //         screenSize
          //     ),
          //     { paddingRight: GlobalStyles.PADDING },
          // ]}
        >
          <StackV2 display={{ xxs: 'none', sm: 'flex' }}>
            <Avatar
              size={'lg'}
              image={getProfilePic(userData.profile_image)}
              placeHolder={props.avatarPlaceholder}
              style={{ borderWidth: 2, borderColor: GlobalStyles.MONO_WHITE }}
            />
          </StackV2>
          <StackV2 direction="column" alignItems="flex-start" justifyContent="center" spacing="2" flex={1} style={{ flexWrap: 'wrap' }}>
            <Text key={'user-name'} style={{ color: GlobalStyles.MONO_BLACK, fontWeight: '500' }}>{`${
              props.nameOverride ? props.nameOverride : userData.name
            }`}</Text>
            {_renderSubText()}
          </StackV2>
        </StackV2>

        {/* Action Btns */}
        <StackV2
          // style={[CardUserStyles.actionBtnStackContainer]}
          // width={applyResponsiveProp({ xxs: '100%', xs: '100%', default: 'auto' }, screenSize)}
          direction="row"
          alignItems="center"
          columnGap="16px">
          <StackV2
            direction={'row'}
            flex={1}
            height={'auto'}
            justifyItems={'flex-end'}
            width={props.dropdownLength ?? '130px'}
            // style={[
            //     props.showPermissionSelect && {
            //         paddingRight: GlobalStyles.PADDING_SIZES.sm,
            //         borderRightWidth: !isManager ? 1 : 0,
            //         borderRightColor: GlobalStyles.SLATE_100,
            //     },
            //     !isManager && { marginRight: GlobalStyles.MARGIN_SIZES.sm },
            // ]}
          >
            {props.showHiddenComponent ? props.hiddenComponent : null}
            {props.showPermissionSelect && (
              <DropdownV2
                wrapperStyle={{ flex: 1 }}
                title={selectedOption?.value}
                disabled={!isSelected || props.disabled}
                menuIcon={<Icon.ChevronDown color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />}>
                {dropdownOptions &&
                  dropdownOptions.map((option) => (
                    <DropDownItemV2 key={option.value} onClick={() => _handlePermissionSelect(option)}>
                      {option.label}
                    </DropDownItemV2>
                  ))}
              </DropdownV2>
              // <Dropdown
              //     dropdownWrapperStyle={[(isXtraSmall || is2XtraSmall) && { width: '100%' }]}
              //     dropdownContainerStyle={[
              //         CardUserStyles.dropdownBtn,
              //         GlobalStyles.BUTTON_SHADOWED,
              //         !isSelected && { backgroundColor: GlobalStyles.SLATE_100 },
              //         invalid && { borderColor: GlobalStyles.STATUS_NEGATIVE },
              //     ]}
              //     dropdownStyle={[
              //         {
              //             width: 208,
              //             borderRadius: GlobalStyles.BORDER_RADIUS,
              //             marginTop: GlobalStyles.MARGIN_SIZES['2xs'],
              //             paddingHorizontal: GlobalStyles.PADDING_SIZES.xxs,
              //             ...GlobalStyles.BUTTON_SHADOWED,
              //         },
              //     ]}
              //     labelStyle={[CardUserStyles.dropdownLabel, !isSelected && { color: GlobalStyles.SLATE_300 }]}
              //     options={dropdownOptions}
              //     optionLabel={dropdownOptionLabel || 'value'}
              //     onSelect={_handlePermissionSelect}
              //     selectedItem={selectedOption}
              //     disabled={!isSelected || props.disabled}
              //     // stackProps={{
              //     //     justify: applyResponsiveProp(
              //     //         v,
              //     //         screenSize
              //     //     ),
              //     // }}
              //     dropdownPosition={'right'}
              //     listHoverStyle={{
              //         backgroundColor: GlobalStyles.SLATE_50,
              //         borderRadius: GlobalStyles.BUTTON_BORDER_RADIUS,
              //     }}
              //     placeholder={props.permissionPlaceholder || 'No Access'}
              // />
            )}
          </StackV2>
          <StackV2 direction={'row'} spacing={2} width={'auto'} alignItems={'flex-end'}>
            {isOwner ? (
              <IconButton
                onClick={() => {
                  props.onLeave && props.onLeave()
                }}
                style={{ height: 40, width: 40, borderRadius: 8 }}
                aria-label={`remove ${userData.name}`}>
                <Icon.LogOut color={GlobalStyles.STATUS_NEGATIVE} size={GlobalStyles.FONT_SIZES.SMALL} />
              </IconButton>
            ) : (
              // <Button
              //     type={'shadowed'}
              //     text={isSmall || isXtraSmall || is2XtraSmall ? undefined : props.leaveButtonText || 'Leave Job'}
              //     textStyle={{
              //         color: GlobalStyles.STATUS_NEGATIVE,
              //         fontSize: GlobalStyles.FONT_SIZES.SMALL,
              //         fontWeight: '500',
              //     }}
              //     leftElement={
              //         <View>
              //             <Icon.LogOut color={GlobalStyles.STATUS_NEGATIVE} size={GlobalStyles.FONT_SIZES.SMALL} />
              //         </View>
              //     }
              //     style={[CardUserStyles.button, { width: 'auto' }, GlobalStyles.BUTTON_SHADOWED, props.leaveButtonStyle]}
              //     space={isSmall || isXtraSmall || is2XtraSmall ? 'none' : '2xs'}
              //     onPress={() => props.onLeave && props.onLeave()}
              //     disabled={props.disableLeave}
              // />
              <StackV2 direction={'row'}>
                {showMessageButton && (
                  <Button
                    style={[CardUserStyles.button, GlobalStyles.BUTTON_SHADOWED, props.messageButtonStyle]}
                    type={'shadowed'}
                    onPress={() => props.onMessage && props.onMessage()}
                    disabled={props.disableMessage || props.disabled}>
                    <View>
                      <Icon.MessageSquare size={GlobalStyles.FONT_SIZES.SMALL} color={GlobalStyles.PRIMARY_500} />
                    </View>
                  </Button>
                )}
                {showContactButton && (
                  <Button
                    style={[CardUserStyles.button, GlobalStyles.BUTTON_SHADOWED, props.contactButtonStyle]}
                    type={'shadowed'}
                    onPress={() => props.onContact && props.onContact()}
                    disabled={props.disableContact || props.disabled}>
                    <View>
                      <Icon.Phone size={GlobalStyles.FONT_SIZES.SMALL} color={GlobalStyles.PRIMARY_500} />
                    </View>
                  </Button>
                )}
                {showAddButton && (
                  <IconButton
                    disabled={props.disabled}
                    onClick={() => _handleSelect()}
                    aria-label={`${isSelected ? 'remove' : 'add'} ${userData.name}`}
                    style={{
                      height: 35,
                      width: 35,
                      borderRadius: 8,
                      backgroundColor: isSelected ? GlobalStyles.PRIMARY_500 : GlobalStyles.MONO_WHITE,
                    }}>
                    {isSelected ? (
                      <Icon.Check size={GlobalStyles.FONT_SIZES.SMALL} color={GlobalStyles.MONO_WHITE} />
                    ) : (
                      <Icon.Plus size={GlobalStyles.FONT_SIZES.SMALL} color={GlobalStyles.PRIMARY_500} />
                    )}
                  </IconButton>
                  // <Button
                  //     style={[
                  //         CardUserStyles.button,
                  //         isSelected && CardUserStyles.activeBtn,
                  //         GlobalStyles.BUTTON_SHADOWED,
                  //         buttonStyle,
                  //     ]}
                  //     type="shadowed"
                  //     onPress={_handleSelect}
                  //     disabled={props.disabled}>
                  //     <View>
                  //         {isSelected ? (
                  //             <Icon.Check
                  //                 size={GlobalStyles.FONT_SIZES.SMALL}
                  //                 color={props.disabled ? GlobalStyles.PRIMARY_500 : GlobalStyles.MONO_WHITE}
                  //             />
                  //         ) : (
                  //             <Icon.Plus size={GlobalStyles.FONT_SIZES.SMALL} color={GlobalStyles.PRIMARY_500} />
                  //         )}
                  //     </View>
                  // </Button>
                )}
              </StackV2>
            )}
          </StackV2>
        </StackV2>
      </StackV2>
    </CardV2>
  )
}

export default memo(CardUser)
