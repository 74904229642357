import { GlobalStyles, Text, View } from '@andromeda'
import { DrawerContext, Loader, Meta, usePageMeta } from '@components'
import { Stack, StackProps } from '@mui/material'
import Box from '@mui/material/Box'
import { Orbit, Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { refreshToken, resetAuthSlice, selectAuth } from '@redux/reducers/authReducer'
import { Workspace, fetchAllWorkspaces, resetWorkspaceSlice, selectWorkspace } from '@redux/reducers/workspaceReducer'
import * as Sentry from '@sentry/nextjs'
import { AppStyles } from '@styles'
import JobCacheHelper from '@utils/functions/JobCacheHelper'
import { identifyUser } from '@utils/tracking/helpers'
import { iConsentRes } from '@utils/types/auth'
import { iPageProps } from '@utils/types/page'
import { AxiosResponse } from 'axios'
import { motion } from 'framer-motion'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import PickstarLogo from 'public/pickstar-logo.png'
import { CSSProperties, useCallback, useContext, useEffect, useState } from 'react'

interface iConsentEvent extends Event {
  detail: iConsentRes
}

type MainContainerProps = iPageProps & {
  headerButtons?: Array<JSX.Element>
  footerComponent?: JSX.Element
  drawer?: boolean
  navBar?: boolean
  authed?: boolean
  innerDrawer?: boolean
  paddingTop?: number
  paddingBottom?: number
  styles?: CSSProperties | undefined
  padding?: StackProps['padding']
  showLoader?: boolean
}

const SessionChecker = ({
  children,
  authed,
  maxHeight,
  showLoader = true,
}: {
  children: JSX.Element
  authed: boolean
  maxHeight?: string | number
  showLoader?: boolean
}): JSX.Element => {
  const router = useRouter()

  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [tokenVerifying, setTokenVerifying] = useState<boolean>(true)

  const dispatch = useAppDispatch() // For dispatching Actions
  const doRefreshToken = useCallback((refresh_token: string) => dispatch(refreshToken(refresh_token)), [dispatch]) // For dispatching Actions
  const { token, me: profile } = useAppSelector(selectAuth) // Get data from the selector
  const { workspaceList, workspace, activeWorkspace } = useAppSelector(selectWorkspace) // Get data from the selector

  const handleConsentEvent = (e: Event | null) => {
    if (!e) return
    const event = e as iConsentEvent
    identifyUser({ consent: event.detail })
  }

  const redirectToLogin = () => {
    const { asPath } = router
    router.push({
      pathname: '/login',
      query: asPath !== '/' && !asPath.startsWith('/login') ? { from: asPath } : {},
    })
  }

  useEffect(() => {
    Orbit.Services.AxiosBaseInstance.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
      },
      function (error) {
        if (error.response.status === 401) {
          dispatch(resetWorkspaceSlice())
          dispatch(resetAuthSlice())
          localStorage.setItem('persist:workspace', JSON.stringify({}))
          localStorage.setItem('persist:auth', JSON.stringify({}))
          redirectToLogin()
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error)
      }
    )
  }, [])

  useEffect(() => {
    if (!authed) {
      setTokenVerifying(false)
      return
    }
    const handleStart = (url: string) => {
      setPageLoading(true)
    }
    const handleStop = (url: string) => {
      setPageLoading(false)
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    document.addEventListener('onPreferenceExpressed', handleConsentEvent)
    document.addEventListener('onPreferenceExpressedOrNotNeeded', handleConsentEvent)

    if (token?.access_token) {
      const tokenExpired = token.expires_in < Date.now()
      if (tokenExpired) {
        console.error('Token Expired. Refreshing')
        setTokenVerifying(true)
        doRefreshToken(token?.refresh_token)
      } else {
        if (profile) {
          Sentry.setUser({
            id: profile.eid,
            email: profile.email,
            username: profile.email,
          })
        }
        // console.log(workspace)
        // console.log(token.access_token)
        setTokenVerifying(false)
        if (router.query.workspace) {
          Orbit.Services.AxiosBaseInstance.defaults.headers.common['x-pickstar-workspace'] = `${router.query.workspace}`
        }
        Orbit.Services.AxiosBaseInstance.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`
        Orbit.Services.AxiosBaseInstance.interceptors.response.use(
          function (response: AxiosResponse<any, any>) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response
          },
          function (error: any) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            Sentry.configureScope(function (scope) {
              scope.setExtra('error', JSON.stringify(error.response.data))
              scope.setExtra('status', error.response.status)
              Sentry.captureException(error)
            })
            return Promise.reject(error)
          }
        )

        // TODO - Use workspaces list from redux
        if (!workspaceList) {
          dispatch(fetchAllWorkspaces())
        } else {
          const allowedWorkspaces = workspaceList as [Types.Workspaces.iWorkspace]
          if (allowedWorkspaces?.length > 0) {
            if (
              allowedWorkspaces.some((e) => e.slug === router.query.workspace) ||
              Object.values(Workspace).some((e) => e === router.asPath.split('/')[1])
            ) {
              // if (activeWorkspace?.slug !== router.query.workspace) {
              //     console.log("Valid but different")
              // }
              if (authed && router.query.workspace) {
                // console.log(router.asPath)
                // TODO - move this to app.js or somehow make this a 1 time call on app entry
                // if (!router.asPath.includes('requests')) { // because requests screen already pulls in the requests
                // doFetchAllJobs()
                // }
                // doFetchAllPermissions()
              }
            } else {
              console.error('NOT VALID WORKSPACE')
              router.replace('/404')
            }
          }
        }
      }
    } else {
      console.error('main layout no token')
      setTimeout(() => {
        redirectToLogin()
      }, 100)
    }
    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [dispatch, doRefreshToken, token, router, workspace])

  if (!authed) return <Box maxHeight={maxHeight || `calc(${window.innerHeight}px - 160px - 64px)`}>{children}</Box>
  return (
    // 100vh - banner height - navbar height
    <Box>
      {tokenVerifying ? (
        <Loader />
      ) : (
        <>
          {showLoader && pageLoading && (
            <Stack
              style={{
                position: 'fixed',
                zIndex: 1,
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'transparent',
              }}>
              <motion.div
                transition={{ repeat: Infinity, duration: 2 }}
                animate={{
                  opacity: [1, 0, 0, 1, 1],
                }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <View style={{ height: 50, width: 50 }}>
                    <Image alt="WorkspaceLogo" src={PickstarLogo} layout="responsive" />
                  </View>
                  <Text>Loading...</Text>
                </View>
              </motion.div>
            </Stack>
          )}
          {children}
        </>
      )}
    </Box>
  )
}

const Main = ({ children, styles, footerComponent, padding, authed = true, drawer = true, showLoader = true }: MainContainerProps) => {
  const router = useRouter()
  const pageMeta = usePageMeta(router.pathname)
  const { open } = useContext(DrawerContext)

  useEffect(() => {
    // check & clear job cache every 5mins
    const clearJobCacheInterval = setInterval(() => {
      JobCacheHelper.clearExpiredCache()
    }, 300000)

    // immediately check & clear job cache
    JobCacheHelper.clearExpiredCache()

    return () => {
      clearInterval(clearJobCacheInterval)
    }
  }, [])

  return (
    <Stack width={'100%'} flex={1}>
      <Meta path={router.pathname} />
      <SessionChecker authed={authed} maxHeight={styles?.maxHeight} showLoader={showLoader}>
        <Stack flex={1} padding={padding ?? { xxs: '0', sm: '32px', lg: '64px' }} boxSizing="border-box">
          {children}
          <Box sx={{ paddingX: drawer ? { md: `calc((100% - 1100px) / 2)`, xxs: `10px` } : {} }}>{footerComponent}</Box>
        </Stack>
      </SessionChecker>
    </Stack>
  )
}

export default Main
