import { Dispatch, SetStateAction, createContext, useState } from 'react'

export const EditIconContext = createContext<{
  visibleEditIcon: string | null
  setVisibleEditIcon: Dispatch<SetStateAction<string | null>> | (() => void)
}>({
  visibleEditIcon: null,
  setVisibleEditIcon: () => {},
})

export const EditIconProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [visibleEditIcon, setVisibleEditIcon] = useState<string | null>(null)

  return <EditIconContext.Provider value={{ visibleEditIcon, setVisibleEditIcon }}>{children}</EditIconContext.Provider>
}
