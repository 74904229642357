import { Button, Stack, Text } from '@andromeda'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { AppStyles } from '@styles'
import { ModalContext } from '@utils/context/Modal.context'
import * as React from 'react'
import { useContext } from 'react'

const options = [
  {
    id: 0,
    label: 'Log out of other devices',
  },
  {
    id: 1,
    label: 'Stay logged in',
  },
]

const PasswordChangeConfirmation = (): JSX.Element => {
  const { closeModal } = useContext(ModalContext)
  const [value, setValue] = React.useState('female')

  /**
   * Function invoked when radio button is selected
   * @param value Radio button change value
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
  }

  const doSubmit = () => {
    closeModal('resetPasswordForm')
  } // For dispatching Actions

  return (
    <Stack
      direction="column"
      style={{ paddingHorizontal: AppStyles.MODAL_PADDING_HORIZONTAL, paddingBottom: AppStyles.MODAL_PADDING_HORIZONTAL }}
      align="flex-start">
      <Text type={'paragraph'}>
        If you think your old password has been compromised, it’s a good idea to log out of any other devices and check for recent changes
        to your account.{' '}
      </Text>
      <FormControl>
        <RadioGroup aria-labelledby="reset-pw-login-option" name="reset-pw-option" value={value} onChange={handleChange}>
          <FormControlLabel value="logout" control={<Radio />} label="Log out of other devices" />
          <FormControlLabel value="stay" control={<Radio />} label="Stay logged in" />
        </RadioGroup>
      </FormControl>
      <Stack height={'auto'} justify={'flex-end'}>
        <Button
          onPress={() => {
            doSubmit()
          }}
          style={{ borderRadius: 6 }}
          type="primary"
          text="Continue"
        />
      </Stack>
    </Stack>
  )
}

PasswordChangeConfirmation.displayName = 'PasswordChangeConfirmation'

export { PasswordChangeConfirmation }
