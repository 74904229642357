import { API, Authentication, Invite, User } from '../types'
import { HttpClient } from './api.service'

export const authService = {
  me: () => {
    return HttpClient.get<null, User.iUser>({
      endpoint: API.TokenEndpoints.ME,
      payload: null,
    })
  },
  introCompleted: () => {
    return HttpClient.put<null, User.iUser>({
      endpoint: API.TokenEndpoints.INTRO_COMPLETED,
      payload: null,
    })
  },
  refreshToken: (refresh_token: string) => {
    return HttpClient.auth<{ refresh_token: string }, Authentication.iAuthToken>(
      {
        endpoint: API.TokenEndpoints.OAUTH_TOKEN,
        payload: { refresh_token },
      },
      API.GrantOptions.REFRESH_TOKEN
    )
  },
  login: (authCredentials: Authentication.iAuthCredentials) => {
    return HttpClient.auth<Authentication.iAuthCredentials, Authentication.iAuthToken>({
      endpoint: API.TokenEndpoints.OAUTH_TOKEN,
      payload: authCredentials,
    })
  },
  sendLink: (email: string) => {
    return HttpClient.post<{ email: string }, any>({
      endpoint: API.TokenEndpoints.SEND_LINK,
      payload: { email },
    })
  },
  passwordReset: (passwordResetFields: Authentication.iPasswordResetFields) => {
    return HttpClient.post<Authentication.iPasswordResetFields, any>({
      endpoint: API.TokenEndpoints.PASSWORD_RESET,
      payload: passwordResetFields,
    })
  },
  register: ({ token, eid, payload }: { token: string; eid: string; payload: Invite.iInviteAcceptPayload }) => {
    return HttpClient.post<Invite.iInviteAcceptPayload, Invite.iInvite>({
      endpoint: `${API.TokenEndpoints.REGISTER.concat(eid ? `/${eid}` : '').concat(token ? `?token=${token}` : '')}`,
      payload: payload,
    })
  },
}
