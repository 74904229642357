import { API, User, Workspaces } from '../types'
import { HttpClient } from './api.service'

export const userService = {
  me: () => {
    return HttpClient.get<null, User.iUser>({
      endpoint: API.UserEndpoints.ME,
      payload: null,
    })
  },
  getMyWorkspaces: () => {
    return HttpClient.get<null, Array<Workspaces.iWorkspace>>({
      endpoint: API.UserEndpoints.MY_WORKSPACES,
      payload: null,
    })
  },
  list: (queryParams: User.iUserListParams) => {
    return HttpClient.get<null, Array<User.iUser>, User.iUserListMeta>({
      endpoint: `${API.UserEndpoints.USER_LIST}`.replace(':page', queryParams.page).replace(':query', (queryParams.query ?? '').toString()),
    })
  },
  updateUser: (payload: User.iUserUpdatePayload) => {
    return HttpClient.put<User.iUserUpdatePayload, User.iUser>({
      endpoint: API.UserEndpoints.ME,
      payload: payload,
    })
  },
  updateUserRole: ({ userEid, payload }: { userEid: User.iUser['eid']; payload: User.iUserUpdateRolePayload }) => {
    return HttpClient.put<User.iUserUpdateRolePayload, User.iUser>({
      endpoint: API.UserEndpoints.UPDATE_ROLES.replace(':user', userEid),
      payload: payload,
    })
  },
  getUser: ({ eid }: { eid: string }) => {
    return HttpClient.get<null, Array<User.iUser>>({
      endpoint: `${API.UserEndpoints.USER}/${eid}`,
    })
  },
  generateProfileImageUploadUrl: ({ file_name }: { file_name: string }) => {
    return HttpClient.post<null, User.iSignedImageUploadURLResponse>({
      endpoint: `${API.UserEndpoints.GENERATE_PROFILE_IMAGE_UPLOAD_URL}`.replace(':file_name', file_name),
    })
  },
  changeUserPassword: (payload: User.iUserChangePassword) => {
    return HttpClient.put<User.iUserChangePassword, User.iUser>({
      endpoint: API.UserEndpoints.CHANGE_PASSWORD,
      payload: payload,
    })
  },
  listJobUsers: (queryParams: User.iUserListParams) => {
    return HttpClient.get<null, Array<User.iUser>, User.iUserListMeta>({
      endpoint: `${API.UserEndpoints.JOB_USER_LIST}`
        .replace(':page', queryParams.page)
        .replace(':query', (queryParams.query ?? '').toString())
        .concat(queryParams.per_page ? `&per_page=${queryParams.per_page}` : ''),
    })
  },
  listTalentUsers: (queryParams: User.iUserListParams) => {
    return HttpClient.get<null, Array<User.iUser>, User.iUserListMeta>({
      endpoint: `${API.UserEndpoints.TALENT_USER_LIST}`
        .replace(':page', queryParams.page)
        .replace(':query', (queryParams.query ?? '').toString()),
    })
  },
  setDefaultWorkspace: ({ workspace_id }: { workspace_id: string }) => {
    return HttpClient.put<{ workspace_id: string }, User.iUser>({
      endpoint: `${API.UserEndpoints.SET_DEFAULT_WORKSPACE}`,
      payload: { workspace_id },
    })
  },
  deactivateUser: () => {
    return HttpClient.put<null, User.iUser>({
      endpoint: API.UserEndpoints.DEACTIVATE_USER,
    })
  },
  deleteUser: () => {
    return HttpClient.delete<null, User.iUser>({
      endpoint: API.UserEndpoints.DELETE_USER,
    })
  },
}
