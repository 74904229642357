import { StyleSheet, StatusBar, StyleProp } from 'react-native'
import { GlobalStyles } from '../globalStyles'

const DefaultStyles = {
  padding: GlobalStyles.PADDING,
  marginVertical: 5,
  marginHorizontal: 16,
} as StyleProp<object> as Record<string, number>

const DefaultTextStyles = {
  fontSize: GlobalStyles.FONT_SIZES.SMALL,
} as StyleProp<object> as Record<string, number>

export const ListStyles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: StatusBar.currentHeight || 0,
  },
  primary: {
    ...DefaultStyles,
  },
  secondary: {
    ...DefaultStyles,
    borderWidth: 1,
    borderColor: GlobalStyles.SLATE_100,
  },
})

export const ListHoverStyles = StyleSheet.create({
  primary: {
    backgroundColor: GlobalStyles.SLATE_50,
  },
  secondary: {
    backgroundColor: GlobalStyles.SLATE_200,
  },
})

export const ListTextStyles = StyleSheet.create({
  primary: {
    ...DefaultTextStyles,
  },
  secondary: {
    ...DefaultTextStyles,
  },
})

export const ListHoverTextStyles = StyleSheet.create({
  primary: {
    ...DefaultTextStyles,
    color: GlobalStyles.PRIMARY_500,
  },
  secondary: {
    ...DefaultTextStyles,
    color: GlobalStyles.MONO_WHITE,
  },
})
