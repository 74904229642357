import { GlobalStyles, Icon } from '@andromeda'
import { Stack, SxProps, Theme, Typography } from '@mui/material'
import { OVERFLOW_TEXT } from './job-card'

export const JobCell = ({ eid, sx }: { eid: string; sx?: SxProps<Theme> }) => (
  <Stack direction="row" alignItems="center" gap="6px" sx={sx}>
    <Icon.Layers size={12} color={GlobalStyles.SLATE_400} />
    <Typography
      {...OVERFLOW_TEXT}
      variant="caption"
      fontSize={'10px'}
      letterSpacing="-0.1px"
      color={GlobalStyles.MONO_BLACK}
      fontWeight="400">
      {eid}
    </Typography>
  </Stack>
)
