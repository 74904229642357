import { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'

type Dimension = {
  screen: {
    width: number
    height: number
  }
  window: {
    width: number
    height: number
  }
}
// TODO: Deprecate this
const useDimensions = () => {
  const [screen, setScreen] = useState<Dimension['screen']>(Dimensions.get('screen'))
  const [window, setWindow] = useState<Dimension['window']>(Dimensions.get('window'))
  useEffect(() => {
    const handleChange = ({ screen, window }: Dimension) => {
      setScreen(screen)
      setWindow(window)
    }

    const dimensionsSubscription = Dimensions.addEventListener('change', handleChange)

    return () => {
      dimensionsSubscription.remove()
    }
  }, [setScreen, setWindow])

  return [screen, window] as const
}

export default useDimensions
