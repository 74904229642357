import createWebStorage from 'redux-persist/lib/storage/createWebStorage'

const createNoopStorage = () => {
  return {
    /**
     * @desc Fetches key and returns item in a promise.
     */
    getItem(key: string): Promise<string | null> {
      return Promise.resolve(null)
    },
    /**
     * @desc Sets value for key and returns item in a promise.
     */
    setItem(key: string, item: string) {
      return Promise.resolve(item)
    },
    /**
     * @desc Removes value for key.
     */
    removeItem(key: string) {
      return Promise.resolve()
    },
  }
}

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage()

export default storage
