import { GlobalStyles, Icon, Tooltip } from '@andromeda'
import { Button, Stack, Typography } from '@mui/material'
import { useState } from 'react'

const TILES = [
  {
    value: '89.9M',
    label: 'Cumulative Followers',
  },
  {
    value: '14.5M',
    label: 'Followers',
  },
  {
    value: '2M',
    label: 'Impressions',
  },
  {
    value: '1.9M',
    label: 'Reach',
  },
  {
    value: '361',
    label: 'Posts & Videos',
  },
  {
    value: '36',
    label: 'Creators',
  },
]

export const Campaign = () => {
  const [isCampaignStatsVisible, setIsCampaignStatsVisible] = useState(true)

  return (
    <Stack>
      <Stack
        direction="row"
        sx={{
          '>div': {
            padding: '12px 24px',
          },
        }}>
        <Stack direction="row" flex={1} alignItems="center" columnGap="4px">
          <Typography fontSize="14px" fontWeight={500} letterSpacing="-0.14px" color={GlobalStyles.MONO_BLACK}>
            Campaign # Hashtag
          </Typography>
          <Tooltip text="hashtag" />
          <Typography fontSize="14px" letterSpacing="-0.14px" color={GlobalStyles.SLATE_700}>
            #allyourvasearebelongtous
          </Typography>
        </Stack>
        <Stack direction="row" flex={1}>
          <Button
            variant="text"
            startIcon={<Icon.BarChart color={GlobalStyles.PRIMARY_500} size={20} />}
            onClick={() => setIsCampaignStatsVisible((prev) => !prev)}>
            {`${isCampaignStatsVisible ? 'Hide' : 'View'} Campaign Stats`}
          </Button>
        </Stack>
      </Stack>
      {isCampaignStatsVisible && (
        <Stack padding="12px 24px">
          <Typography fontSize="18px" color={GlobalStyles.SLATE_700} textAlign="center" sx={{ paddingY: '6px' }}>
            Campaign Stats
          </Typography>
          <Stack
            direction="row"
            rowGap="8px"
            flexWrap="wrap"
            justifyContent="center"
            sx={{
              '>div': {
                borderRight: `1px solid ${GlobalStyles.SLATE_100}`,
              },
              '>div:last-child': {
                borderRight: 'none',
              },
            }}>
            {TILES.map(({ value, label }) => (
              <Stack key={label} minWidth="146px" boxSizing="border-box" alignItems="center" padding="12px 24px">
                <Typography color={GlobalStyles.MONO_BLACK} fontSize="16px" fontWeight={500} letterSpacing="-0.16px">
                  {value}
                </Typography>
                <Typography color={GlobalStyles.SLATE_700} fontSize="14px" letterSpacing="-0.14px">
                  {label}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
