/* eslint-disable react/display-name */
// @ts-nocheck

import { ComponentType, useEffect, useState } from 'react'
import { LayoutChangeEvent } from './types'
import { useScreenDimensions } from '../hooks/dimensions'

export function withModal<T>(OriginalComponent: ComponentType<T>) {
  return (hocProps: T) => {
    /**
     * Modal related states
     */
    const [element, setElement] = useState<any>(undefined)
    const [menuPosition, setMenuPosition] = useState<MenuPosition>({ top: 0 })
    const [visible, setVisible] = useState<boolean>(false)

    const { windowHeight, windowWidth } = useScreenDimensions()
    const [yPos, setYPos] = useState<number>(0)
    const [elemHeight, setElemHeight] = useState<number>(0)

    const didEnter = (ref: any) => {
      _computePosition(ref)
    }

    const didExit = () => {
      setVisible(false)
    }

    const _computePosition = async (ref: any) => {
      const toShow = !visible
      if (toShow) {
        const [x, y, width, height]: [number, number, number, number] = await new Promise((resolve) =>
          ref.current?.measureInWindow((...args: any) => resolve(args))
        )
        setYPos(y)
        setElemHeight(height)

        setMenuPosition({ top: y + height, left: x })
        setVisible(toShow)
      } else {
        setVisible(toShow)
      }
    }

    const _onLayout = (e: LayoutChangeEvent) => {
      const height = e.nativeEvent.layout.height
      const willOverlap = windowHeight - (yPos + elemHeight) < height
      if (willOverlap) {
        const newPos = { bottom: 0 }
        if (menuPosition.left) newPos.left = menuPosition.left
        if (menuPosition.right) newPos.right = menuPosition.right
        setMenuPosition(newPos)
      }
    }

    return (
      <OriginalComponent
        element={element}
        menuPosition={menuPosition}
        visible={visible}
        didEnter={didEnter}
        didExit={didExit}
        _onLayout={_onLayout}
        {...hocProps}
      />
    )
  }
}
