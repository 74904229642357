import { StyleProp, StyleSheet } from 'react-native'
import { GlobalStyles } from '../globalStyles'

const DefaultStyles = {
  margin: 0,
  fontWeight: '400',
  lineHeight: null,
  color: GlobalStyles.SLATE_700,
} as StyleProp<object> as Record<string, number>

export const TextSizeStyles = StyleSheet.create({
  xl: {
    ...DefaultStyles,
    fontSize: GlobalStyles.FONT_SIZES.XTRA_LARGE,
  },
  lg: {
    ...DefaultStyles,
    fontSize: GlobalStyles.FONT_SIZES.LARGE,
  },
  md: {
    ...DefaultStyles,
    fontSize: GlobalStyles.FONT_SIZES.MEDIUM,
  },
  base: {
    ...DefaultStyles,
    fontSize: GlobalStyles.FONT_SIZES.BASE,
  },
  sm: {
    ...DefaultStyles,
    fontSize: GlobalStyles.FONT_SIZES.SMALL,
  },
  tiny: {
    ...DefaultStyles,
    fontSize: GlobalStyles.FONT_SIZES.TINY,
  },
})

export const HeaderTextSizeStyles = StyleSheet.create({
  xl: {
    ...DefaultStyles,
    fontSize: GlobalStyles.HEADER_FONT_SIZES.XTRA_LARGE,
  },
  lg: {
    ...DefaultStyles,
    fontSize: GlobalStyles.HEADER_FONT_SIZES.LARGE,
  },
  md: {
    ...DefaultStyles,
    fontSize: GlobalStyles.HEADER_FONT_SIZES.MEDIUM,
  },
  base: {
    ...DefaultStyles,
    fontSize: GlobalStyles.HEADER_FONT_SIZES.BASE,
  },
  sm: {
    ...DefaultStyles,
    fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL,
  },
  tiny: {
    ...DefaultStyles,
    fontSize: GlobalStyles.HEADER_FONT_SIZES.TINY,
  },
})
