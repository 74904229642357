import { StyleProp, TextProps, TextStyle, ViewStyle } from 'react-native'

export enum BadgeDefaults {
  TYPE = 'primary',
}

export type BadgeTypes = 'primary' | 'secondary'

export interface BadgeProps extends TextProps {
  /**
   * The type of Badge
   */
  type?: BadgeTypes
  /**
   * The text to display inside the Badge
   */
  text?: string
  /**
   * Custom styles for the Badge container
   */
  style?: StyleProp<ViewStyle>
  /**
   * Custom styles for the Badge text
   */
  textStyle?: StyleProp<TextStyle>
}
