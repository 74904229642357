import { API, Permission } from '../types'
import { HttpClient } from './api.service'

export const permissionService = {
  createFromResource: ({ type, eid, payload }: { type: string; eid: string; payload: Permission.iPermissionPayload }) => {
    const endpoint = API.PermissionEndpoints.PERMISSION.replace(':permissionable_type', type).replace(':permissionable_eid', eid)
    return HttpClient.post<Permission.iPermissionPayload, Permission.iPermissionResponse>({
      endpoint,
      payload,
    })
  },
  listFromResource: ({ type, eid }: { type: string; eid: string }) => {
    const endpoint = API.PermissionEndpoints.PERMISSION.replace(':permissionable_type', type).replace(':permissionable_eid', eid)
    return HttpClient.get<null, Permission.iPermissionResourceResponse>({
      endpoint,
    })
  },
  listUserPermission: ({ type, eid }: { type: string; eid: string }) => {
    const endpoint = API.PermissionEndpoints.USER_PERMISSION.replace(':permissionable_type', type).replace(':permissionable_eid', eid)
    return HttpClient.get<null, Permission.iPermissionResourceResponse>({
      endpoint,
    })
  },
  deleteFromResource: ({ type, eid, payload }: { type: string; eid: string; payload: Permission.iPermissionResourceDeletePayload }) => {
    const endpoint = API.PermissionEndpoints.PERMISSION.replace(':permissionable_type', type).replace(':permissionable_eid', eid)
    return HttpClient.delete<Permission.iPermissionResourceDeletePayload, null>({
      endpoint,
      payload,
    })
  },
  updateFromResource: ({ type, eid, payload }: { type: string; eid: string; payload: Permission.iPermissionPayload }) => {
    const endpoint = API.PermissionEndpoints.PERMISSION.replace(':permissionable_type', type).replace(':permissionable_eid', eid)
    return HttpClient.put<Permission.iPermissionPayload, Permission.iPermissionResponse>({
      endpoint,
      payload,
    })
  },
  deleteUserPermission: ({ type, eid }: { type: string; eid: string }) => {
    const endpoint = API.PermissionEndpoints.USER_PERMISSION.replace(':permissionable_type', type).replace(':permissionable_eid', eid)
    return HttpClient.delete<null, null>({
      endpoint,
    })
  },
}
