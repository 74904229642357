import { Editor } from 'slate'
import { CustomElement } from 'src/andromeda/RichEditor/custom-types'

const withMentions = (editor: Editor) => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = (element: CustomElement) => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = (element: CustomElement) => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  editor.markableVoid = (element: CustomElement) => {
    return element.type === 'mention' || markableVoid(element)
  }

  return editor
}

export default withMentions
