import { Orbit, Types } from '@orbit'
import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { addDays, dataUrlToFile } from '@utils/functions/helperFunctions'
import { ApiResponseError, Error, iAuthInitialState, Roles } from '@utils/types/auth'
import axios from 'axios'
import mixpanel from 'mixpanel-browser'
import { AppState } from '../store'
import { toast } from 'react-toastify'

const initialState: iAuthInitialState = {
  status: 'idle',
  token: null,
  error: null,
  permissions: [],
  me: null,
  loggedOut: true,
}

export const login = createAsyncThunk(
  'auth/login',
  async (credentials: Types.Authentication.iAuthCredentials, { rejectWithValue }): Promise<Types.Authentication.iAuthToken | any> => {
    try {
      let response = await Orbit.Services.authService.login(credentials)
      Orbit.Services.AxiosBaseInstance.defaults.headers.common['Authorization'] = `Bearer ${response.access_token}`
      return {
        access_token: response.access_token,
        expires_in: addDays(Date.now(), response.expires_in / 60 / 60 / 24),
        refresh_token: response.refresh_token,
        token_type: 'jwt',
      }
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const me = createAsyncThunk('auth/me', async (_, { rejectWithValue }): Promise<Types.User.iUser | any> => {
  try {
    let response = await Orbit.Services.authService.me()
    return response.data
  } catch (e) {
    return rejectWithValue((e as ApiResponseError).response.data)
  }
})

export const introCompleted = createAsyncThunk('auth/introCompleted', async (_, { rejectWithValue }): Promise<Types.User.iUser | any> => {
  try {
    let response = await Orbit.Services.authService.introCompleted()
    return response.data
  } catch (e) {
    return rejectWithValue((e as ApiResponseError).response.data)
  }
})

export const updateUserProfilePicture = createAsyncThunk(
  'user/updateUserProfilePicture',
  async (payload: { fileMeta: File; profilePicture: string }, { rejectWithValue }): Promise<Types.User.iUser | any> => {
    try {
      const response = await Orbit.Services.userService.generateProfileImageUploadUrl({ file_name: payload.fileMeta.name })
      const file = await dataUrlToFile(payload.profilePicture, payload.fileMeta.name)
      await axios.put(response.data.upload_presign_url, file, {
        headers: {
          'Content-Type': payload.fileMeta.type,
        },
      })
      const updateProfileRes = await Orbit.Services.userService.updateUser({ profile_image_eid: response.data.eid })
      return updateProfileRes.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (payload: Types.User.iUserUpdatePayload, { rejectWithValue }): Promise<Types.User.iUser | any> => {
    try {
      const response = await Orbit.Services.userService.updateUser(payload)
      return response
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const refreshToken = createAsyncThunk(
  'auth/refresh-token',
  async (refresh_token: string, { rejectWithValue }): Promise<Types.Authentication.iAuthToken | any> => {
    try {
      let response = await Orbit.Services.authService.refreshToken(refresh_token)
      return {
        access_token: response.access_token,
        expires_in: addDays(Date.now(), response.expires_in / 60 / 60 / 24),
        refresh_token: response.refresh_token,
        token_type: 'jwt',
      }
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const fetchCurrentPermissions = createAsyncThunk(
  'workspace/current',
  async (_, { rejectWithValue }): Promise<Types.Workspaces.iWorkspace | any> => {
    try {
      let response = await Orbit.Services.workspaceService.fetchCurrentWorkspaceSettings()
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const changeUserPassword = createAsyncThunk(
  'users/changePassword',
  async (payload: Types.User.iUserChangePassword, { rejectWithValue }): Promise<Types.User.iUser | any> => {
    try {
      const response = await Orbit.Services.userService.changeUserPassword(payload)
      return response
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const register = createAsyncThunk(
  'users/register',
  async (
    cred: { token: string; eid: string; payload: Types.Invite.iInviteAcceptPayload },
    { rejectWithValue }
  ): Promise<Types.Invite.iInvite | any> => {
    try {
      const response = await Orbit.Services.authService.register(cred)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state: iAuthInitialState, action: PayloadAction<iAuthInitialState['token']>) => {
      state.token = action.payload
      state.loggedOut = false
    },
    logout: (_state: iAuthInitialState) => {
      _state.loggedOut = true
      toast.warn('Logging out...')
      mixpanel.reset()
      setTimeout(() => {
        location.pathname = 'login'
      }, 1000)
    },
    resetAuthSlice: (_state: iAuthInitialState) => initialState,
    /**
     * Temporary measure to be able to switch between to access role specific screens
     *
     * @param state current state of auth
     * @param action specific role to set
     */
    // changeRole: (state: iAuthInitialState, action: PayloadAction<Roles>) => {
    //     state.roles = action.payload
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'success'
        state.loggedOut = false
        state.token = action.payload
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.status = 'success'
        state.token = action.payload
      })
      .addCase(introCompleted.fulfilled, (state) => {
        state.status = 'success'
        if (state.me) state.me.is_intro_completed = true
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as Error
        state.token = null
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as Error
        state.token = null
      })
      .addCase(updateUserProfilePicture.fulfilled, (state: iAuthInitialState, action: PayloadAction<Types.User.iUser>) => {
        state.me = action.payload
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.me = action.payload.data
      })
      .addCase(fetchCurrentPermissions.fulfilled, (state: iAuthInitialState, action: PayloadAction<Types.Workspaces.iWorkspace>) => {
        state.permissions = action.payload.permissions ?? []
      })
      .addMatcher(isAnyOf(login.pending, refreshToken.pending, introCompleted.pending), (state) => {
        state.status = 'loading'
      })
  },
})

export const { logout, resetAuthSlice, setToken } = authSlice.actions
export const { reducer } = authSlice
export const selectAuth = (state: AppState) => state.auth
export const selectPermissions = (state: AppState) => state.auth.permissions
