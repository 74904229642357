import { getCurrencySymbolCode, getDefaultCurrencyCode, numberWithCommas } from '@utils/functions/helperFunctions'
import { useCurrencyCode } from './useCurrencyCode'

export const useCurrencyFormat = () => {
  const currenyCodeOptions = useCurrencyCode()

  const format = (currencyCode?: string, cost?: number | null) =>
    `${getCurrencySymbolCode(getDefaultCurrencyCode(currencyCode, currenyCodeOptions))}  ${numberWithCommas(cost) ?? ''}`

  return format
}
