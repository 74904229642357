import { Types } from '..'
import { API } from '../types'
import { HttpClient } from './api.service'

export const dashboardService = {
  listWidgets: ({ role }: { role: string }) => {
    const endpoint = API.DashboardEndpoints.WIDGET_LIST.replace(':role', role)

    return HttpClient.get<null, any>({
      endpoint: endpoint,
    })
  },
  fetchWidgetData: ({
    role,
    widget,
    page,
    from_datetime,
    deliverable_statuses,
    job_statuses,
    year,
    month,
    view,
    sortBy,
    sortOrder,
  }: Types.Dashboard.iDashboardQueryParms) => {
    const endpoint = API.DashboardEndpoints.WIDGET_DATA.replace(':role', role)
      .replace(':widget', widget)
      // deliverable overview
      .concat(from_datetime ? `&from_datetime=${from_datetime}` : '')
      // calendar
      .concat(year ? `&year=${year}` : '')
      .concat(month ? `&month=${month}` : '')
      .concat(view ? `&view=${view}` : '')
      // common
      .concat(deliverable_statuses ? `&deliverable_statuses[]=${deliverable_statuses}` : '')
      .concat(job_statuses ? `&statuses[]=${job_statuses}` : '')
      .concat(page ? `&page=${page}` : '')
      .concat(sortBy ? `&sortBy=${sortBy}` : '')
      .concat(sortOrder ? `&sortOrder=${sortOrder}` : '')
    return HttpClient.get<null, any>({
      endpoint: endpoint,
    })
  },
}
