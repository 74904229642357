import { Orbit, Types } from '@orbit'
import { createAsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { ApiResponseError } from '@utils/types/auth'
import { iDeliverableErrorMessages, iDeliverableInitialState } from '@utils/types/deliverables'
import { toast } from 'react-toastify'
import { AppState } from '../store'
import { uploadFileToUrl } from '@utils/functions/helperFunctions'

export const deliverableInitialState: Types.Deliverable.iDeliverablePayload = {
  eid: null,
  deliverable_type_eid: '',
  name: null,
  description: null,
  start_datetime: null,
  end_datetime: null,
  job_eid: '',
  number_of_talents_required: null,
  hasChanges: false,
  status: '',
  deliverable_category_eid: '',
}

const deliverableRequestStatus = {
  create: 'idle',
  createMultiple: 'idle',
  read: 'idle',
  update: 'idle',
  delete: 'idle',
  list: 'idle',
  markComplete: 'idle',
  assignTalent: 'idle',
}

const initialState: iDeliverableInitialState = {
  details: deliverableInitialState,
  types: null,
  requestStatus: deliverableRequestStatus,
  errorMessages: null,
  createDeliverablePayload: null,
  viewedDeliverable: null,
}

export const getTypes = createAsyncThunk<Draft<Types.Deliverable.iDeliverableCategory[]>, undefined>(
  'deliverables/types',
  async (_): Promise<Types.Deliverable.iDeliverableCategory[]> => {
    const response = await Orbit.Services.deliverableService.listTypes()
    return response.data
  }
)

export const createDeliverable = createAsyncThunk(
  'deliverables/create',
  async (payload: Types.Deliverable.iDeliverablePayload, { rejectWithValue }): Promise<Types.Deliverable.iDeliverable | any> => {
    try {
      const response = await Orbit.Services.deliverableService.create({ payload })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)
export const assignTalentToDeliverable = createAsyncThunk(
  'deliverables/assign',
  async (
    payload: Types.Deliverable.iAssignTalentToDeliverablePayload,
    { rejectWithValue }
  ): Promise<Types.Deliverable.iDeliverable | any> => {
    try {
      const response = await Orbit.Services.deliverableService.assignTalent({ payload })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const createMultipleDeliverable = createAsyncThunk(
  'deliverables/createMultiple',
  async (payload: Types.Deliverable.iMultipleDeliverablePayload, { rejectWithValue }): Promise<Types.Deliverable.iDeliverable[] | any> => {
    try {
      const response = await Orbit.Services.deliverableService.createMultiple({ payload })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const markCompleteDeliverable = createAsyncThunk<
  Draft<Types.Deliverable.iMarkCompleteDeliverableResponse>,
  { payload: Types.Deliverable.iMarkCompleteDeliverablePayload }
>('deliverables/markComplete', async ({ payload }): Promise<Types.Deliverable.iMarkCompleteDeliverableResponse> => {
  const response = await Orbit.Services.deliverableService.markComplete({ payload })

  return response
})

export const getDeliverable = createAsyncThunk(
  'deliverables/get',
  async (eid: string, { rejectWithValue }): Promise<Types.Deliverable.iDeliverable | any> => {
    try {
      const response = await Orbit.Services.deliverableService.get({ eid })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const updateDeliverable = createAsyncThunk(
  'deliverables/update',
  async (
    payload: { eid: string; payload: Types.Deliverable.iDeliverablePayload },
    { rejectWithValue }
  ): Promise<Types.Deliverable.iDeliverable | any> => {
    try {
      const response = await Orbit.Services.deliverableService.update(payload)
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const publishDeliverable = createAsyncThunk(
  'deliverables/publish',
  async (payload: { eid: string }, { rejectWithValue }): Promise<Types.Deliverable.iDeliverable | any> => {
    try {
      const response = await Orbit.Services.deliverableService.publish(payload)
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const deleteDeliverable = createAsyncThunk(
  'deliverables/delete',
  async (payload: { eid: string }, { rejectWithValue }): Promise<Types.Deliverable.iDeliverable | any> => {
    try {
      const response = await Orbit.Services.deliverableService.delete(payload)
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const archiveDeliverable = createAsyncThunk(
  'deliverables/archive',
  async (payload: { eid: string }, { rejectWithValue }): Promise<Types.Deliverable.iDeliverable | any> => {
    try {
      const response = await Orbit.Services.deliverableService.archive(payload)
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const uploadDeliverableAttachment = createAsyncThunk(
  'deliverables/uploadDeliverableAttachment',
  async ({ eid, files }: { eid: string; files: File[] }, { rejectWithValue }): Promise<Types.User.iUser | any> => {
    try {
      const file_names = files.map(({ name }) => name)
      const generateURLResponse = await Orbit.Services.deliverableService.generateFileUploadUrl({ eid, file_names })

      await Promise.all(
        files.map(async (file, index) => {
          const res = await uploadFileToUrl(file, generateURLResponse.data[index].upload_presign_url)
        })
      )

      const response = await Orbit.Services.deliverableService.attachFiles({
        eid,
        payload: { files: generateURLResponse.data.map(({ eid }) => eid) },
      })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const detachDeliverableAttachment = createAsyncThunk(
  'deliverables/detachDeliverableAttachment',
  async ({ eid, files }: { eid: string; files: string[] }, { rejectWithValue }): Promise<any> => {
    try {
      const response = await Orbit.Services.deliverableService.detachFiles({
        eid,
        payload: { files },
      })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const generateDeliverableFileDownloadUrl = createAsyncThunk(
  'deliverables/generateDeliverableFileDownloadUrl',
  async ({ eid, files }: { eid: string; files: string[] }, { rejectWithValue }): Promise<any> => {
    try {
      const response = await Orbit.Services.deliverableService.generateFileDownloadUrl({
        eid,
        payload: { files },
      })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const deliverableSlice = createSlice({
  name: 'deliverable',
  initialState,
  reducers: {
    resetDeliverableSlice: () => initialState,
    setDeliverable: (state: iDeliverableInitialState, action: PayloadAction<any>) => {
      state.details = action.payload
    },
    resetDeliverable: (state: iDeliverableInitialState) => {
      state.details = deliverableInitialState
      state.requestStatus = deliverableRequestStatus
      state.errorMessages = null
    },
    setErrorMessages: (state: iDeliverableInitialState, action: PayloadAction<iDeliverableErrorMessages | null>) => {
      state.errorMessages = action.payload
    },
    setCreateDeliverablePayload: (
      state: iDeliverableInitialState,
      action: PayloadAction<Types.Deliverable.iDeliverablePayload[] | null>
    ) => {
      state.createDeliverablePayload = action.payload
    },
    setActiveDeliverable: (state: iDeliverableInitialState, action: PayloadAction<Types.Deliverable.iDeliverable | null>) => {
      state.viewedDeliverable = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTypes.fulfilled, (state, action) => {
        state.types = action.payload
      })
      .addCase(createDeliverable.pending, (state, action) => {
        state.requestStatus.create = 'pending'
      })
      .addCase(createDeliverable.fulfilled, (state: iDeliverableInitialState, action: PayloadAction<Types.Deliverable.iDeliverable>) => {
        state.requestStatus.create = 'success'
      })
      .addCase(createMultipleDeliverable.pending, (state, action) => {
        state.requestStatus.createMultiple = 'pending'
      })
      .addCase(
        createMultipleDeliverable.fulfilled,
        (state: iDeliverableInitialState, action: PayloadAction<Types.Deliverable.iDeliverable[]>) => {
          state.requestStatus.createMultiple = 'success'
        }
      )
      .addCase(createDeliverable.rejected, (state, action) => {
        state.errorMessages = (action.payload as { errors: iDeliverableErrorMessages }).errors
        toast.error((action.payload as { message: string }).message)
        state.requestStatus.create = 'failed'
      })
      .addCase(createMultipleDeliverable.rejected, (state, action) => {
        state.requestStatus.createMultiple = 'failed'
      })
      .addCase(markCompleteDeliverable.fulfilled, (state, action) => {
        state.requestStatus.markComplete = 'success'
      })
      .addCase(markCompleteDeliverable.rejected, (state, action) => {
        state.requestStatus.markComplete = 'failed'
      })
      .addCase(markCompleteDeliverable.pending, (state, action) => {
        state.requestStatus.markComplete = 'loading'
      })
      .addCase(publishDeliverable.fulfilled, (state, action) => {
        state.requestStatus.list = 'success'
      })
      .addCase(publishDeliverable.rejected, (state, action) => {
        state.requestStatus.list = 'failed'
      })
      .addCase(publishDeliverable.pending, (state, action) => {
        state.requestStatus.list = 'loading'
      })
      .addCase(assignTalentToDeliverable.fulfilled, (state, action) => {
        state.requestStatus.assignTalent = 'success'
      })
      .addCase(assignTalentToDeliverable.rejected, (state, action) => {
        state.requestStatus.assignTalent = 'failed'
      })
      .addCase(assignTalentToDeliverable.pending, (state, action) => {
        state.requestStatus.assignTalent = 'loading'
      })
    builder.addCase(getDeliverable.fulfilled, (state: iDeliverableInitialState, action: PayloadAction<Types.Deliverable.iDeliverable>) => {
      state.details = {
        eid: action.payload.eid,
        name: action.payload.name,
        description: action.payload.description,
        deliverable_type_eid: action.payload.deliverable_type.eid,
        start_datetime: action.payload.start_datetime,
        end_datetime: action.payload.end_datetime,
        job_eid: action.payload.job?.eid,
        number_of_talents_required: action.payload.number_of_talents_required,
        hasChanges: false,
        status: action.payload.status,
      }
      state.requestStatus.read = 'success'
    }),
      builder.addCase(getDeliverable.pending, (state: iDeliverableInitialState) => {
        state.requestStatus.read = 'pending'
      }),
      builder.addCase(getDeliverable.rejected, (state: iDeliverableInitialState) => {
        state.requestStatus.read = 'failed'
      }),
      builder.addCase(updateDeliverable.rejected, (state, action) => {
        state.errorMessages = (action.payload as { errors: iDeliverableErrorMessages }).errors
        toast.error((action.payload as { message: string }).message)
        state.requestStatus.update = 'failed'
      })
    builder.addCase(deleteDeliverable.fulfilled, (state: iDeliverableInitialState) => {
      state.requestStatus.delete = 'success'
    })
    builder.addCase(deleteDeliverable.rejected, (state: iDeliverableInitialState) => {
      state.requestStatus.delete = 'failed'
    })
    builder.addCase(deleteDeliverable.pending, (state: iDeliverableInitialState) => {
      state.requestStatus.delete = 'pending'
    })
    builder.addCase(archiveDeliverable.fulfilled, (state: iDeliverableInitialState) => {
      state.requestStatus.delete = 'success'
    })
    builder.addCase(archiveDeliverable.rejected, (state: iDeliverableInitialState) => {
      state.requestStatus.delete = 'failed'
    })
    builder.addCase(archiveDeliverable.pending, (state: iDeliverableInitialState) => {
      state.requestStatus.delete = 'pending'
    })
  },
})

export const {
  setCreateDeliverablePayload,
  setDeliverable,
  setErrorMessages,
  resetDeliverable,
  resetDeliverableSlice,
  setActiveDeliverable,
} = deliverableSlice.actions
export const { reducer } = deliverableSlice
export const selectDeliverable = (state: AppState) => state.deliverable
