import { API, User } from '../types'
import { Organisation } from '../types/organisations'
import { HttpClient } from './api.service'

export const organisationsService = {
  listOrganisations: (params?: Organisation.iListOrganisationsParams) => {
    return HttpClient.get<null, Organisation.iOrgListResponse[]>({
      endpoint: API.OrganisationsEndpoints.BASE.concat(`?query=${params?.query ?? ''}`)
        .concat(`&page=${params?.page ?? '1'}`)
        .concat(`&per_page=${params?.per_page ?? '20'}`),
      payload: null,
    })
  },
  create: (payload: Organisation.iOrganisationPayload) => {
    return HttpClient.post<Organisation.iOrganisationPayload, Organisation.iOrganisation>({
      endpoint: API.OrganisationsEndpoints.BASE,
      payload,
    })
  },
  listUsers: (eid: string) => {
    return HttpClient.get<null, User.iUser[]>({
      endpoint: API.OrganisationsEndpoints.USERS.replace(':role', eid),
      payload: null,
    })
  },
  attachUsers: (eid: string, payload: Organisation.iAttachUsersToOrganisationPayload) => {
    return HttpClient.post<Organisation.iAttachUsersToOrganisationPayload, Organisation.iOrganisation>({
      endpoint: API.OrganisationsEndpoints.ATTACH.replace(':role', eid),
      payload,
    })
  },
  update: (eid: string, payload: Organisation.iOrganisationPayload) => {
    return HttpClient.put<Organisation.iOrganisationPayload, Organisation.iOrganisation>({
      endpoint: API.OrganisationsEndpoints.UPDATE.replace(':role', eid),
      payload,
    })
  },
  generateUploadUrl: ({ file_name }: { file_name: string }) => {
    return HttpClient.post<null, User.iSignedImageUploadURLResponse>({
      endpoint: `${API.OrganisationsEndpoints.GENERATE_URL}`.replace(':file_name', file_name),
    })
  },
}
