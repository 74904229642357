import Pages from '@utils/constants/pages.json'
import Head from 'next/head'
import React, { useMemo } from 'react'

const usePageMeta = (path: string) => {
  const page = useMemo(() => {
    let page = Pages.find((page) => page.path === path)
    if (!page) page = Pages[0]
    return page
  }, [path])

  return page
}

const Meta = ({ path }: { path: string }) => {
  const page = usePageMeta(path)

  return (
    <Head>
      <title>{page.title} | Pickstar</title>
      <link rel="icon" href="/pickstar-logo.png" />
      <meta name="description" content={page.meta.description} />
    </Head>
  )
}

export { Meta, usePageMeta }
