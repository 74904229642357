import { GlobalStyles } from '@andromeda'
import { Grid, GridProps, Typography, TypographyProps } from '@mui/material'
import { OVERFLOW_TEXT } from './job-card'

export const JOB_ROW_SIZES = (isPanelOpen = false) => ({
  status: { width: '24px', alignItems: 'baseline' },
  title: { xxs: 12, sm: isPanelOpen ? 4 : 3.5, xl: 3.5, sx: { width: { xxs: '100%' } }, item: true },
  type: { xxs: 12, sm: 3, sx: { display: { sm: 'none', md: isPanelOpen ? 'none' : 'block', xl: 'block' } }, item: true },
  date: { xxs: 12, sm: 3, item: true },
  client: { xxs: 12, sm: 3, item: true },
  talents: { xxs: 4, sm: 3, md: isPanelOpen ? 3 : 1.8, xl: 1.8, item: true },
  progress: { xxs: 8, sm: 3, md: isPanelOpen ? 3 : 1.2, xl: 1.2, item: true },
  action: { width: '50px', sx: { display: { xxs: 'none', sm: 'block' } }, item: true },
})
export const DELIVERABLE_ROW_SIZES = (isPanelOpen = false) => ({
  status: { width: '40px' },
  title: { xxs: 12, sm: 2.5, md: isPanelOpen ? 2 : 2.5, item: true },
  type: { xxs: 0, sm: 2, sx: { display: { xxs: 'none', md: isPanelOpen ? 'none' : 'block', xl: 'block' } }, item: true },
  date: { xxs: 0, sm: 6, md: isPanelOpen ? 3 : 2, sx: { display: { xxs: 'none', sm: 'block' } }, item: true },
  duration: { sm: 1.5, sx: { display: { xxs: 'none', sm: 'block' } }, item: true },
  budget: { sm: 1.8, md: isPanelOpen ? 2 : 1.4, sx: { display: { xxs: 'none', md: 'block' } }, item: true },
  talents: {
    xxs: 2,
    sm: 2,
    md: isPanelOpen ? 1.75 : 1.45,
    item: true,
    sx: { marginLeft: { lg: isPanelOpen ? 0 : '42px' } },
  },
  users: {
    xxs: 2,
    sm: 2,
    md: isPanelOpen ? 1.75 : 1.45,
    item: true,
    sx: { marginLeft: { md: isPanelOpen ? 0 : '42px' }, display: { xxs: 'none', sm: 'block' } },
  },
  action: { sm: 0.5, item: true },
  duplicate: { width: '46px', minWidth: '46px' },
})
export const ColumnHeader = ({ children, sortable = false, ...props }: { children: string; sortable?: boolean } & TypographyProps) => (
  <Typography
    color={GlobalStyles.SLATE_500}
    fontSize="10px"
    sx={{
      ...OVERFLOW_TEXT.sx,
      textDecoration: sortable ? 'underline' : 'none',
    }}
    {...props}>
    {children}
  </Typography>
)

export const TypeColumn = ({ children, expanded }: { children: JSX.Element | false; expanded: boolean }) => (
  <Grid {...JOB_ROW_SIZES(expanded).type}>{children}</Grid>
)
export const RowGrid = ({
  left,
  right,
  expanded,
  gridProps,
}: {
  expanded: boolean
  left: {
    component: JSX.Element
    style?: GridProps
  }
  right: {
    component: JSX.Element
    style?: GridProps
  }
  gridProps?: GridProps
}) => (
  <Grid container {...gridProps}>
    <Grid {...JOB_ROW_SIZES(expanded).title} {...left.style}>
      {left.component}
    </Grid>
    <Grid item xxs={12} sm={12 - JOB_ROW_SIZES(expanded).title.sm} {...right.style}>
      {right.component}
    </Grid>
  </Grid>
)
