import { Button, GlobalStyles, StackV2, Text } from '@andromeda'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { ModalContext } from '@utils/context/Modal.context'
import { useContext } from 'react'
const options = [
  {
    id: 0,
    label: 'Send a password reset link to my email',
  },
  // {
  //     id: 1,
  //     label: 'Send a code to my mobile',
  // },
]

const PasswordResetOption = ({ onChange, onSubmit }: { onChange: (e: any) => void; onSubmit: (e: any) => void }) => {
  const { closeModal } = useContext(ModalContext)
  return (
    <StackV2
      height={'100%'}
      direction="column"
      className="dialog-content"
      spacing={2}
      // style={{ paddingBottom: AppStyles.MODAL_PADDING_HORIZONTAL, }}
      alignItems="flex-start">
      {/* <Text type={'paragraph'}>How would you like to reset your password?</Text> */}
      <FormControl>
        <FormLabel id="reset-options-group-label">How would you like to reset your password?</FormLabel>
        <RadioGroup aria-labelledby="reset-options-group-label" defaultValue="email" name="radio-buttons-group">
          {options.map((option) => (
            <FormControlLabel
              key={option.label}
              value="email"
              control={<Radio onClick={() => onChange(option.id)} />}
              label={option.label}
            />
          ))}

          {/* <FormControlLabel value="phone" control={<Radio />} label="Male" /> */}
        </RadioGroup>
      </FormControl>
      {/* <Radio.Group align="flex-start" onChangeValue={onChange} items={options} height={'auto'} /> */}
      <StackV2 direction={'row'} alignItems={'center'} spacing={2} justifyContent={'space-between'} paddingBottom="24px">
        <Button
          onPress={() => {
            closeModal('resetPasswordForm')
          }}
          style={{ padding: 0 }}
          transparent>
          <Text size="sm" style={{ color: GlobalStyles.PRIMARY_500 }}>
            Cancel
          </Text>
        </Button>
        <Button
          onPress={(e) => {
            onSubmit(e)
          }}
          style={{ borderRadius: 6 }}
          type="primary"
          text="Continue"
        />
      </StackV2>
    </StackV2>
  )
}

export { PasswordResetOption }
