import { CommentThread, GlobalStyles, iCommentThread } from '@andromeda'
import { Types } from '@orbit'
import { forwardRef } from 'react'
import { CommentsProvider, useComments } from 'src/components/comments/useComments'
import { RichEditorValue } from 'src/andromeda/RichEditor/RichEditor'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { MentionsProp } from '../rich-editor-plugin/mention/mention-types'

interface iCommentsList {
  comments: Types.Comment.iComment[]
  profile: Types.User.iUser | null
  mentions?: MentionsProp
  onSubmit?: (content: RichEditorValue, onSuccess: (callback: () => void) => void) => void
  onPressEdit?: (thread: iCommentThread | null) => void
  onPressReply?: (thread: iCommentThread | null) => void
  onPressDelete?: (thread: iCommentThread) => void
  isDeletingComment?: boolean
  actionCommentId?: string | number | null
  isSavingComment?: boolean
  type: 'job' | 'deliverable'
}

const CommentsList = ({
  comments,
  profile,
  mentions,
  onSubmit,
  onPressEdit,
  onPressReply,
  onPressDelete,
  isDeletingComment,
  actionCommentId,
  isSavingComment,
  type,
}: iCommentsList) => {
  const { state, updateState } = useComments()

  const _generateKey = (data: Types.Comment.iComment) => {
    let key = data.eid
    if (data.updated_at) {
      const timestamp = new Date(data.updated_at).getTime()
      key = `${data.eid}-${timestamp}`
    } else if (data.created_at) {
      const timestamp = new Date(data.created_at).getTime()
      key = `${data.eid}-${timestamp}`
    }
    return key
  }

  return (
    <>
      {comments.map((thread) => (
        <CommentThread
          key={_generateKey(thread)}
          eid={thread.eid}
          content={thread.content || ''}
          created_at={thread.created_at}
          creator={thread.creator}
          replies={thread.children}
          onSubmit={onSubmit}
          onPressEdit={onPressEdit}
          onPressReply={onPressReply}
          onPressDelete={onPressDelete}
          isDeleting={isDeletingComment}
          actionCommentId={actionCommentId}
          isSaving={isSavingComment}
          showReply
          editorId={profile?.eid}
          // allDelete={hasUpdatePermissions}
          // allEdit={hasUpdatePermissions}
          showEdit={profile?.eid === thread.creator.eid}
          showDelete={profile?.eid === thread.creator.eid}
          usersList={mentions?.users || []}
          talentsList={mentions?.talents || []}
          type={type}
        />
      ))}

      <Dialog open={state?.showDiscardDialog || false} onClose={() => updateState({ showDiscardDialog: false })}>
        <DialogTitle>Discard message?</DialogTitle>

        <DialogContent>
          <DialogContentText>Are you sure you want to discard your changes?</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            style={{ color: GlobalStyles.ORANGE, opacity: 0.9 }}
            onClick={() => updateState({ showDiscardDialog: false, toDiscardComment: state.activeComment })}>
            Discard
          </Button>
          <Button onClick={() => updateState({ showDiscardDialog: false })}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default forwardRef<unknown, iCommentsList>(function renderComments(props, _ref) {
  return (
    <CommentsProvider>
      <CommentsList {...props} />
    </CommentsProvider>
  )
})
