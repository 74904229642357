import { Avatar, CardV2, GlobalStyles, StackV2, Text } from '@andromeda'
import { getInitials } from '@utils/functions/helperFunctions'
import { StyleProp, View, ViewStyle } from 'react-native'

const ProfileCard = ({
  avatar,
  name,
  department,
  role,
  wrapperStyle,
}: {
  avatar?: string | null
  name: string
  department?: string
  role?: string | null
  wrapperStyle?: StyleProp<ViewStyle>
}) => {
  return (
    <CardV2 className="content-card">
      <StackV2 direction="row" alignItems={'center'} spacing={2} flex={1}>
        <Avatar
          size={'lg'}
          image={avatar || undefined}
          placeHolder={
            <Text
              style={{
                textAlign: 'center',
                fontSize: GlobalStyles.FONT_SIZES.LARGE,
              }}>
              {getInitials(name)}
            </Text>
          }
        />
        <StackV2 width={'auto'} direction="column" alignItems="flex-start" spacing={2}>
          <Text
            size={'base'}
            style={{
              color: GlobalStyles.MONO_BLACK,
              fontWeight: '500',
            }}>
            {name}
          </Text>
          <Text style={{ color: GlobalStyles.SLATE_700 }} size={'sm'}>
            {department}
          </Text>
        </StackV2>
      </StackV2>
      <StackV2 direction="row" alignItems="center" spacing={2}>
        {role && (
          <View>
            <Text type={'paragraph'} bold size={'sm'}>
              {role}
            </Text>
          </View>
        )}
      </StackV2>
    </CardV2>
  )
}

export default ProfileCard
