import { Button, GlobalStyles, Input, Stack, Text } from '@andromeda'
import { useScreenDimensions } from '@utils/hooks/dimensions'
import { iMyAccountsProps } from '@utils/types/my-accounts'
import { memo, useCallback } from 'react'
import { FlexStyle } from 'react-native'

const TalentAccounts = ({ isEditMode, onSubmit, onCancel }: iMyAccountsProps) => {
  const { screenSize } = useScreenDimensions()

  // Styles applied to various sections for responsiveness
  const responsiveStyles = {
    responsiveAvatarSection: {
      direction: !screenSize.md ? 'column' : ('row' as FlexStyle['flexDirection']),
      justify: !screenSize.md ? 'center' : ('flex-start' as FlexStyle['justifyContent']),
      avatarStyle: {
        width: !screenSize.md ? 80 : 96,
        height: !screenSize.md ? 80 : 96,
        borderRadius: !screenSize.md ? 40 : 48,
      },
      avatarImageStyle: {
        width: !screenSize.md ? 80 : 96,
        height: !screenSize.md ? 80 : 96,
      },
      fullNameWidth: !screenSize.md ? '100%' : ('auto' as number | 'auto' | '100%'),
    },
    responsiveProfile: {
      borderTopWidth: !screenSize.md || isEditMode ? 0 : 2,
    },
  }

  const stackProfileStyles = {
    style: {
      borderWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderColor: GlobalStyles.SLATE_50,
    },
    childrenStyles: {
      borderWidth: isEditMode ? 0 : 2,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderColor: GlobalStyles.SLATE_50,
      paddingVertical: 24,
    },
  }

  const _handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  const _handleSubmit = useCallback(() => {
    onSubmit('submitted info')
  }, [onSubmit])

  return (
    <Stack align="flex-start" space="none" direction="column">
      <Stack
        align="center"
        direction={!screenSize.md ? 'column' : 'row'}
        space={!screenSize.md ? 'none' : isEditMode ? 'md' : 'none'}
        style={stackProfileStyles.style}
        childrenStyle={stackProfileStyles.childrenStyles}>
        {/* Full name */}
        <Stack align="flex-start" flex={1} direction="column" space="sm" style={responsiveStyles.responsiveProfile}>
          <Text bold size="md" style={{ color: GlobalStyles.MONO_BLACK }}>
            {isEditMode ? 'Full Name*' : 'Full Name'}
          </Text>
          {isEditMode ? (
            <Input
              style={{ fontSize: 16, borderColor: GlobalStyles.SLATE_100, padding: 24, minWidth: 408 }}
              placeholder="Full Name"
              defaultValue="Hen the Roasted"
            />
          ) : (
            <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
              Hen the Roasted
            </Text>
          )}
        </Stack>

        {/* Location
                <Stack align="flex-start" flex={1} direction="column" space="sm" style={responsiveStyles.responsiveProfile}>
                    <Text bold size="md" style={{ color: GlobalStyles.MONO_BLACK }}>
                        {isEditMode ? 'Based In*' : 'Based In'}
                    </Text>
                    {isEditMode ? (
                        <Input
                            style={{ fontSize: 16, borderColor: GlobalStyles.SLATE_100, padding: 24, minWidth: 408 }}
                            placeholder="Based In"
                            defaultValue="Sydney, Australia"
                        />
                    ) : (
                        <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
                            Sydney, Australia
                        </Text>
                    )}
                </Stack> */}
      </Stack>

      <Stack
        align="center"
        direction={!screenSize.md ? 'column' : 'row'}
        space={!screenSize.md ? 'none' : isEditMode ? 'md' : 'none'}
        style={stackProfileStyles.style}
        childrenStyle={stackProfileStyles.childrenStyles}>
        {/* Email */}
        <Stack align="flex-start" flex={1} direction="column" space="sm">
          <Text bold size="md" style={{ color: GlobalStyles.MONO_BLACK }}>
            {isEditMode ? 'Email*' : 'Email'}
          </Text>
          {isEditMode ? (
            <Input
              style={{ fontSize: 16, borderColor: GlobalStyles.SLATE_100, padding: 24, minWidth: 408 }}
              placeholder="Email"
              defaultValue="friedhen@caa.com"
            />
          ) : (
            <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
              friedhen@caa.com
            </Text>
          )}
        </Stack>

        {/* Mobile */}
        <Stack align="flex-start" flex={1} direction="column" space="sm">
          <Text bold size="md" style={{ color: GlobalStyles.MONO_BLACK }}>
            {isEditMode ? 'Mobile*' : 'Mobile'}
          </Text>
          {isEditMode ? (
            <Input
              style={{ fontSize: 16, borderColor: GlobalStyles.SLATE_100, padding: 24, minWidth: 408 }}
              placeholder="Mobile"
              defaultValue="+61 423 456 789"
            />
          ) : (
            <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
              0423 456 789
            </Text>
          )}
        </Stack>
      </Stack>

      {/* <Stack
                align="center"
                space={'none'}
                direction={isEditMode || !screenSize.md ? 'column' : 'row'}
                style={stackProfileStyles.style}
                childrenStyle={stackProfileStyles.childrenStyles}>
                
                <Stack align="flex-start" flex={1} direction="column" space="none">
                    <Stack align="center" space="sm" justify={!screenSize.md ? 'space-between' : 'flex-start'}>
                        <Text bold size="md" style={{ color: GlobalStyles.MONO_BLACK }}>
                            Address
                        </Text>
                        <Tooltip />
                    </Stack>

                    {!isEditMode ? (
                        <>
                            <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
                                2/65 Evans Street,
                            </Text>
                            <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
                                Freshwater NSW 2096,
                            </Text>
                            <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
                                AUSTRALIA
                            </Text>
                        </>
                    ) : (
                        <>
                            
                            <Stack direction={!screenSize.md ? 'column' : 'row'} align="flex-start" style={{ paddingBottom: 24 }}>
                                <Stack align="flex-start" flex={1} direction="column" space="sm">
                                    <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
                                        Line 1
                                    </Text>

                                    <Input
                                        style={{ fontSize: 16, borderColor: GlobalStyles.SLATE_100, padding: 24, minWidth: 408 }}
                                        placeholder=""
                                        defaultValue="2/65 Evans Street"
                                    />
                                </Stack>

                                <Stack align="flex-start" flex={1} direction="column" space="sm">
                                    <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
                                        Line 2
                                    </Text>

                                    <Input
                                        style={{ fontSize: 16, borderColor: GlobalStyles.SLATE_100, padding: 24, minWidth: 408 }}
                                        placeholder=""
                                        defaultValue=""
                                    />
                                </Stack>
                            </Stack>

                            
                            <Stack direction={!screenSize.md ? 'column' : 'row'} align="flex-start" style={{ paddingBottom: 24 }}>
                                <Stack align="flex-start" flex={1} direction="column" space="sm">
                                    <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
                                        Suburb
                                    </Text>

                                    <Input
                                        style={{ fontSize: 16, borderColor: GlobalStyles.SLATE_100, padding: 24, minWidth: 408 }}
                                        placeholder=""
                                        defaultValue="Freshwater"
                                    />
                                </Stack>

                                <Stack align="flex-start" flex={1} direction="column" space="sm">
                                    <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
                                        State
                                    </Text>

                                    <Input
                                        style={{ fontSize: 16, borderColor: GlobalStyles.SLATE_100, padding: 24, minWidth: 408 }}
                                        placeholder=""
                                        defaultValue="NSW"
                                    />
                                </Stack>
                            </Stack>
                            
                            <Stack direction={!screenSize.md ? 'column' : 'row'} align="flex-start" style={{ paddingBottom: 24 }}>
                                <Stack align="flex-start" flex={1} direction="column" space="sm">
                                    <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
                                        Postcode
                                    </Text>

                                    <Input
                                        style={{ fontSize: 16, borderColor: GlobalStyles.SLATE_100, padding: 24, minWidth: 408 }}
                                        placeholder=""
                                        defaultValue="2096"
                                    />
                                </Stack>

                                <Stack align="flex-start" flex={1} direction="column" space="sm">
                                    <Text size="base" style={{ color: GlobalStyles.SLATE_700 }}>
                                        Country
                                    </Text>

                                    <Input
                                        style={{ fontSize: 16, borderColor: GlobalStyles.SLATE_100, padding: 24, minWidth: 408 }}
                                        placeholder=""
                                        defaultValue="Australia"
                                    />
                                </Stack>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Stack> */}

      {/* Edit mode Button Group */}
      {isEditMode && (
        <Stack align="flex-start" justify="space-between">
          <Button
            type="secondary"
            text="Cancel"
            textStyle={{ color: GlobalStyles.PRIMARY_500 }}
            style={{
              backgroundColor: GlobalStyles.MONO_WHITE,
              borderRadius: 8,
              paddingVertical: 12,
              paddingHorizontal: 16,
            }}
            onPress={_handleCancel}
          />

          <Button
            type="primary"
            text="Confirm"
            style={{ borderRadius: 8, paddingVertical: 12, paddingHorizontal: 16 }}
            onPress={_handleSubmit}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default memo(TalentAccounts)
