import { GlobalStyles, Icon, Input, StackV2, Text, Tooltip } from '@andromeda'
import { AppStyles } from '@styles'
import { useScreenDimensions } from '@utils/hooks/dimensions'
import { KeyboardType, StyleProp, TextStyle, View } from 'react-native'

export const TextInput = ({
  index,
  label,
  stateLabel,
  placeholder,
  value,
  onChangeText,
  helpText,
  keyboardType,
  editable = true,
  errorMessage = '',
}: {
  index: string
  label: string
  stateLabel: string
  value: string
  placeholder: string
  helpText: string
  onChangeText?: (text: string) => void
  keyboardType: KeyboardType
  editable: boolean
  errorMessage?: string
}) => {
  const { screenSize } = useScreenDimensions()

  const screenIsLarge = screenSize.lg
  const screenIsMedium = screenSize.md

  const responsiveStyles = {
    button: {
      size: screenIsLarge ? AppStyles.BUTTON_SIZES.LG : AppStyles.BUTTON_SIZES.MD,
    },
    icon: {
      size: screenIsLarge ? AppStyles.ICON_SIZES.BASE : AppStyles.ICON_SIZES.SM,
    },
    text: {
      size: screenIsLarge ? GlobalStyles.FONT_SIZES.BASE : GlobalStyles.FONT_SIZES.SMALL,
    },
    label: {
      text: {
        size: screenIsLarge ? GlobalStyles.FONT_SIZES.SMALL : GlobalStyles.FONT_SIZES.TINY,
      },
      size: screenIsLarge ? AppStyles.LABEL_SIZES.LG : AppStyles.LABEL_SIZES.MD,
      padding: screenIsLarge ? GlobalStyles.PADDING_SIZES.md : GlobalStyles.PADDING_SIZES.sm,
    },
    padding: {
      horizontal: screenIsMedium ? AppStyles.PADDING_SIZES.LG : AppStyles.PADDING_SIZES.SM,
      vertival: screenIsMedium ? AppStyles.PADDING_SIZES.BASE : AppStyles.PADDING_SIZES.XXS,
    },
  }
  return (
    <StackV2
      key={index}
      // height={'auto'}
      // space="none"
      direction={{ xxs: 'row', sm: 'column' }}
      // divider={screenIsMedium}
      alignItems={'stretch'}
      // border
      // rounded
      // style={[!screenIsMedium && { paddingVertical: GlobalStyles.PADDING }]}
      // childrenStyle={{
      //     borderColor: GlobalStyles.SLATE_100,
      //     paddingHorizontal: responsiveStyles.padding.horizontal,
      //     paddingVertical: responsiveStyles.padding.vertival,
      // }}
    >
      <View style={[screenIsMedium && { flex: 1 }, !screenIsMedium && { width: '100%' }]}>
        <SectionTitle title={label} tooltip={helpText || ''} />
      </View>
      <View style={[{ padding: 0, alignSelf: 'stretch' }, screenIsMedium && { flex: 3 }, !screenIsMedium && { width: '100%' }]}>
        <Input
          name={stateLabel}
          value={value}
          placeholder={placeholder}
          style={{ padding: 0, borderRadius: 0, borderWidth: 0 }}
          wrapperStyle={{ flex: 1 }}
          onChangeText={onChangeText}
          keyboardType={keyboardType}
          errorMessage={errorMessage}
          errorMessageStyle={{
            fontSize: GlobalStyles.FONT_SIZES.TINY,
            paddingTop: AppStyles.PADDING_SIZES['4XS'],
          }}
          editable={editable}
        />
      </View>
    </StackV2>
  )
}

const SectionTitle = ({
  title,
  LeftIcon,
  tooltip,
  spacedTooltip = true,
  showBottomBorder = true,
  ...props
}: {
  title: string
  LeftIcon?: typeof Icon.Activity
  tooltip: string
  spacedTooltip?: boolean
  showBottomBorder?: boolean
  titleTextStyle?: StyleProp<TextStyle>
}) => {
  const { screenSize } = useScreenDimensions()

  const screenIsLarge = screenSize.lg
  const screenIsMedium = screenSize.md

  const responsiveStyles = {
    button: {
      size: screenIsLarge ? AppStyles.BUTTON_SIZES.LG : AppStyles.BUTTON_SIZES.MD,
    },
    icon: {
      size: screenIsLarge ? AppStyles.ICON_SIZES.BASE : AppStyles.ICON_SIZES.SM,
    },
    text: {
      size: screenIsLarge ? GlobalStyles.FONT_SIZES.BASE : GlobalStyles.FONT_SIZES.SMALL,
    },
    label: {
      text: {
        size: screenIsLarge ? GlobalStyles.FONT_SIZES.SMALL : GlobalStyles.FONT_SIZES.TINY,
      },
      size: screenIsLarge ? AppStyles.LABEL_SIZES.LG : AppStyles.LABEL_SIZES.MD,
      padding: screenIsLarge ? GlobalStyles.PADDING_SIZES.md : GlobalStyles.PADDING_SIZES.sm,
    },
    padding: {
      horizontal: screenIsMedium ? AppStyles.PADDING_SIZES.LG : AppStyles.PADDING_SIZES.SM,
      vertival: screenIsMedium ? AppStyles.PADDING_SIZES.BASE : AppStyles.PADDING_SIZES.XXS,
    },
  }

  return (
    <StackV2
      flex={1}
      direction={'row'}
      justifyContent={spacedTooltip ? 'space-between' : 'flex-start'}
      alignItems={'center'}
      // space={'none'}
      // style={[
      //     !screenIsMedium &&
      //     showBottomBorder && {
      //         borderBottomWidth: 1,
      //         borderBottomColor: GlobalStyles.SLATE_50,
      //         paddingBottom: AppStyles.PADDING_SIZES.XS,
      //     },
      // ]}
    >
      <StackV2 direction={'row'} width={'auto'}>
        {LeftIcon ? (
          <Text>
            <LeftIcon color={GlobalStyles.SLATE_500} size={AppStyles.BUTTON_SIZE_SMALL} />
          </Text>
        ) : null}
        <Text bold style={[{ fontSize: responsiveStyles.text.size, color: GlobalStyles.MONO_BLACK }, props.titleTextStyle]}>
          {title}
        </Text>
      </StackV2>
      <Tooltip text={tooltip} />
    </StackV2>
  )
}
