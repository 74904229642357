import { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'

export type SCREEN_SIZE = {
  xxs: boolean
  xs: boolean
  sm: boolean
  md: boolean
  lg: boolean
  xl: boolean
  '2xl': boolean
}

export const useScreenDimensions = () => {
  const [screenData, setScreenData] = useState(Dimensions.get('window'))
  const [screenSize, setScreenSize] = useState<SCREEN_SIZE>({
    '2xl': Dimensions.get('window').width >= 1536,
    xl: Dimensions.get('window').width >= 1280,
    lg: Dimensions.get('window').width >= 1024,
    md: Dimensions.get('window').width >= 768,
    sm: Dimensions.get('window').width >= 640,
    xs: Dimensions.get('window').width >= 400,
    xxs: Dimensions.get('window').width >= 200,
  })

  useEffect(() => {
    const dimensionsListener = Dimensions.addEventListener('change', (result) => {
      setScreenSize({
        '2xl': result.window.width >= 1536,
        xl: result.window.width >= 1280,
        lg: result.window.width >= 1024,
        md: result.window.width >= 768,
        sm: result.window.width >= 640,
        xs: result.window.width >= 400,
        xxs: result.window.width >= 200,
      })
      setScreenData(result.window)
    })

    return () => {
      dimensionsListener.remove()
    }
  })

  return { screenData, screenSize }
}
