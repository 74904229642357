import { Fade, Portal, Stack, Typography } from '@mui/material'
import { MouseEventHandler, useRef } from 'react'
import { useClickOutside } from '@utils/hooks/useClickOutside'
import { getInitials } from '@utils/functions/helperFunctions'
import { Avatar, GlobalStyles } from '@andromeda'
import { MentionItem } from './mention-types'

type DefaultData = {
  profile_image?: { full_url: string } | string | null
  name?: string
}

type tMentionSelect = {
  collection: MentionItem[]
  position: { top: string; left: string }
  selectedIndex: number
  onSelect: (user: MentionItem) => void
  onHover?: (index: number) => void
  onClickOuside?: () => void
}

type tMentionRow<T = DefaultData> = {
  data: T
  selected: boolean
  onClick: MouseEventHandler<HTMLDivElement> | undefined
  onHover?: MouseEventHandler<HTMLDivElement> | undefined
}

const MentionRow = <T extends DefaultData = DefaultData>({ data, onClick, onHover, selected = false }: tMentionRow<T>) => {
  const { profile_image, name } = data
  const profileImage = typeof profile_image === 'string' ? profile_image : profile_image?.full_url
  const dataName = name

  return (
    <div
      onMouseOver={onHover}
      onClick={onClick}
      style={{
        borderLeft: `2px solid ${selected ? GlobalStyles.PRIMARY_500 : 'transparent'}`,
        backgroundColor: selected ? '#f2f3f5' : 'transparent',
      }}>
      <Stack
        className="rounder"
        direction="row"
        alignItems="center"
        padding={`${GlobalStyles.PADDING_SIZES.xxs}px 12px ${GlobalStyles.PADDING_SIZES.xxs}px 10px`}
        columnGap={`${GlobalStyles.MARGIN_SIZES['2xs']}px`}>
        <Avatar
          size={'xs'}
          image={profileImage}
          placeHolder={
            <Typography style={{ textAlign: 'center', fontSize: GlobalStyles.FONT_SIZES.TINY }}>{getInitials(dataName)}</Typography>
          }
        />
        <Typography>{dataName}</Typography>
      </Stack>
    </div>
  )
}

const MentionSelect = ({ collection, selectedIndex, position, onSelect, onHover, onClickOuside = () => {} }: tMentionSelect) => {
  const ref = useRef<HTMLDivElement>(null)

  // handle click outside
  useClickOutside(ref, onClickOuside)

  return (
    <Portal>
      <Fade in appear timeout={150}>
        <Stack
          className="absolute shadow-1"
          ref={ref}
          style={{
            ...position,
            backgroundColor: 'white',
            borderRadius: '6px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            transform: 'translateY(-100%)', // remove if you want to show the list below the input
            maxHeight: '250px',
            overflow: 'auto',
            zIndex: 1250,
          }}
          role="presentation"
          aria-label="select mention"
          data-slot="mentions-portal">
          {collection.map((row, i) => (
            <MentionRow
              key={`${i}_${row.eid}`}
              data={row}
              onClick={() => onSelect(row)}
              onHover={() => onHover && onHover(i)}
              selected={i === selectedIndex}
            />
          ))}
        </Stack>
      </Fade>
    </Portal>
  )
}

export default MentionSelect
