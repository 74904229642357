import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../store'

interface iPasswordRecoveryInitialProps {
  email: string | null
  phoneNumber: string | null
}

const initialState: iPasswordRecoveryInitialProps = {
  email: null,
  phoneNumber: null,
}
export const passwordRecoverySlice = createSlice({
  name: 'passwordRecovery',
  initialState,
  reducers: {
    resetPasswordRecoverySlice: () => initialState,
    setResetEmail: (state: iPasswordRecoveryInitialProps, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setPhoneNumber: (state: iPasswordRecoveryInitialProps, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload
    },
  },
})

export const { setResetEmail, setPhoneNumber, resetPasswordRecoverySlice } = passwordRecoverySlice.actions
export const { reducer } = passwordRecoverySlice
export const selectRecovery = (state: AppState) => state.recovery
