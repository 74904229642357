import { Button, DropdownSearch, GlobalStyles, Stack, Text, View } from '@andromeda'
import { Accordion } from '@components'
import { Checkbox } from '@mui/material'
import { AppStyles } from '@styles'
import { Filter, FilterOptions } from '@utils/types/filter'
import { useEffect, useState } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

interface iFilterProps {
  filters: Filter[]
  value: string
  label: string
  selectedOptions?: FilterOptions[]
  optionsKey?: string
  style?: StyleProp<ViewStyle>
  onCancel?: () => void
  onSubmit?: (e: any) => void
  onSelect?: (e: any) => void
  showActionButtons?: boolean
  disabled?: boolean
}

const FilterAccordion = ({ showActionButtons = true, disabled = false, ...props }: iFilterProps) => {
  const [filters, setFilters] = useState<Filter[]>([])
  const [viewMore, setViewMore] = useState(new Array(props.filters.length).fill(false))

  useEffect(() => {
    // add selected attribute to options
    const formattedFilters = props.filters.map((filter) => {
      // get all option keys
      const optionValues = filter.options.filter((option) => option[props.value] !== 'all').map((option) => option[props.value])
      let optionShouldPreselect = []
      if (props.selectedOptions) {
        optionShouldPreselect = props.selectedOptions.filter((selectedOption) => optionValues.indexOf(selectedOption[props.value]) > -1)
      }
      const allShouldCheck = optionShouldPreselect.length === 0 || optionValues.length === optionShouldPreselect.length
      const newOptions = filter.options.map((option: FilterOptions) => {
        // 'all' option is selected by default if all the options exists in props.selectedOptions OR none
        // others will be based on props.selectedOptions
        const isChecked =
          (option[props.value] === 'all' && allShouldCheck && !filter.options.some((option) => option.selected)) ||
          (props.selectedOptions && props.selectedOptions.find((opt) => opt[props.value] === option[props.value])) ||
          option.selected
        return Object.assign({}, option, { selected: isChecked, group: filter.name })
      })
      return {
        ...filter,
        options: newOptions,
      }
    })

    setFilters(formattedFilters)
    const selectedOptions = getSelectedOptions(formattedFilters)
    props.onSubmit && props.onSubmit(selectedOptions)
  }, [props.filters, props.selectedOptions, props.value])

  const getSelectedOptions = (filters: Filter[]) =>
    filters.reduce((result: FilterOptions[], filter) => {
      const hasAllOption = filter.options.find((option) => option[props.value] === 'all')
      // check if has 'All' option
      if (hasAllOption) {
        // if so, check if not selected
        // (will not return each option if 'All' is selected)
        if (!hasAllOption.selected) {
          filter.options.forEach((option) => {
            if (option[props.value] !== 'all' && option.selected) result.push(option)
          })
        }
      } else {
        filter.options.forEach((option) => {
          if (option[props.value] !== 'all' && option.selected) result.push(option)
        })
      }
      return result
    }, [])

  const _handleSelect = (e: any) => {
    const toSelectValue = e.value.option[props.value]
    const newFitlers = filters.map((filter) => {
      const inOptions = filter.options.find((option) => option[props.value] === toSelectValue && option.group === e.value.option.group)
      if (inOptions) {
        if (toSelectValue === 'all' && e.isChecked) {
          const newOptions = filter.options.map((option) => {
            return {
              ...option,
              selected: option[props.value] === 'all',
              group: filter.name,
            }
          })
          return {
            ...filter,
            options: newOptions,
          }
        } else {
          let optionsSelected = 0
          const newOptions = filter.options.map((option) => {
            if (option[props.value] === toSelectValue) {
              if (option[props.value] !== 'all' && e.isChecked) optionsSelected++
              return {
                ...option,
                selected: e.isChecked,
                group: filter.name,
              }
            } else {
              if (option[props.value] !== 'all' && option.selected) optionsSelected++
              return option
            }
          })

          // check if 'All' option is in options
          const hasAllOption = newOptions.find((newOpt) => newOpt[props.value] === 'all')
          if (hasAllOption) {
            const allOptionIndex = newOptions.indexOf(hasAllOption)
            // if all options are selected OR no options selected
            // set 'All' option to selected/checked
            newOptions[allOptionIndex].selected = newOptions.length - 1 === optionsSelected || optionsSelected === 0
          }
          return {
            ...filter,
            options: newOptions,
          }
        }
      } else {
        return filter
      }
    })
    setFilters(newFitlers)
  }

  const _renderCheckbox = (filter: Filter, viewMore: boolean) => {
    let options = viewMore ? filter[props.optionsKey || 'options'] : filter[props.optionsKey || 'options'].slice(0, 6)
    if (options && Array.isArray(options)) {
      return options.map((option: any, index: number) => {
        return (
          <Button
            key={`${option[props.value]}-${index}-${option.selected}`}
            type="secondary"
            transparent
            hover
            style={{ paddingHorizontal: 0 }}>
            <Stack space="xs">
              <Checkbox defaultChecked={option.selected} value={{ id: index, option: option }} onChange={_handleSelect} />
              <Text size="sm">{option[props.label]}</Text>
            </Stack>
          </Button>
        )
      })
    }
    return <></>
  }

  const _renderDropdown = (filter: Filter) => {
    let options = filter[props.optionsKey || 'options']
    if (options && Array.isArray(options)) {
      return (
        <DropdownSearch
          lazyLoad={filter.onLazyLoad}
          isLazyLoading={filter.isLazyLoading}
          inputName="talent-search"
          onChangeText={() => {}}
          onClose={function noRefCheck() {}}
          onOpen={function noRefCheck() {}}
          onSelect={function noRefCheck() {}}
          optionLabel="value"
          options={options}
          selectedItem={options[0]}
          placeholder={options[0].value}
          renderItem={(option) => (
            <Button
              key={`${option[props.value]}-${option.eid}-${option.selected}`}
              type="secondary"
              transparent
              hover
              style={{ paddingHorizontal: 0 }}>
              <Stack space="xs">
                <Checkbox defaultChecked={option.selected} value={{ id: option.eid, option: option }} onChange={_handleSelect} />
                <Text size="sm">{option[props.label]}</Text>
              </Stack>
            </Button>
          )}
          // selectedItem={{
          //     id: 1,
          //     label: 'All Talent',
          //     value: 'All Talent',
          // }}
          type="primary"
          dropdownWrapperStyle={{ backgroundColor: GlobalStyles.MONO_WHITE }}
        />
      )
    }
    return <></>
  }

  const _renderAccordion = (filter: Filter, index: number) => {
    return (
      <View key={`filter-category-container-${index}`} style={{ width: '100%' }}>
        <Accordion
          titleComponent={
            <Stack justify={'space-between'} style={{ backgroundColor: GlobalStyles.SLATE_50, marginBottom: 14 }}>
              <Stack width={'auto'}>
                <Text size="sm">{filter.name}</Text>
                {filter.type !== 'dropdown' && (
                  <Text
                    size="sm"
                    style={{ color: GlobalStyles.PRIMARY_500 }}
                    onPress={() =>
                      _handleSelect({
                        isChecked: true,
                        value: { id: 0, option: filter[props.optionsKey || 'options'][0] },
                      })
                    }>
                    {filter[props.optionsKey || 'options'][0][props.label]}
                  </Text>
                )}
              </Stack>
            </Stack>
          }
          isOpen={filter.collapsed}>
          {filter.type === 'dropdown' ? _renderDropdown(filter) : _renderCheckbox(filter, viewMore[index])}
          {!!filter.viewMore && (
            <Button
              type={'secondary'}
              text={viewMore[index] ? 'View Less' : 'View More'}
              onPress={() => {
                const newViewMore = [...viewMore]
                newViewMore[index] = !viewMore[index]
                setViewMore(newViewMore)
              }}
            />
          )}
        </Accordion>
      </View>
    )
  }

  const _handleSubmit = () => {
    const selectedOptions = getSelectedOptions(filters)
    props.onSubmit && props.onSubmit(selectedOptions)
  }

  return (
    <Stack direction={'column'} justify={'space-between'} flex={1}>
      <View style={{ flex: 1, width: '100%' }}>
        <div
          style={{
            width: '100%',
            height: '100%',
          }}>
          <Stack
            direction={'column'}
            align={'flex-start'}
            style={[
              {
                paddingHorizontal: AppStyles.PADDING_SIZES.BASE,
                paddingVertical: AppStyles.PADDING_SIZES.SM,
                backgroundColor: GlobalStyles.SLATE_50,
              },
              props.style,
            ]}>
            {filters.map(_renderAccordion)}
          </Stack>
        </div>
      </View>
      {showActionButtons && (
        <Stack
          justify={'space-between'}
          height={'auto'}
          style={{
            paddingHorizontal: AppStyles.PADDING_SIZES.SM,
            paddingBottom: AppStyles.PADDING_SIZES.SM,
          }}>
          <Button type={'secondary'} text={'Cancel'} onPress={() => props.onCancel && props.onCancel()} />
          <Button type={'primary'} text={'Apply Filters'} onPress={_handleSubmit} disabled={disabled} />
        </Stack>
      )}
    </Stack>
  )
}

export { FilterAccordion }
