import { TouchableOpacityProps as NativeTouchableOpacityProps, StyleProp, ViewStyle, TextStyle, FlexStyle } from 'react-native'
import { SpaceType, StackProps } from '../Stack/types'

export enum DropdownDefaults {
  TYPE = 'primary',
  LABEL = 'value',
  POSITION = 'left',
  PLATFORM = 'web',
}

export type DropdownType = 'primary' | 'secondary' | 'shadow'
export type DropdownMode = 'dropdown' | 'menu'
export type DropdownPosition = 'left' | 'right'
export type DropdownOption = {
  [key: string]: any
  id?: number | string
  value?: string
}
export type DropdownPlacement = {
  top?: number
  left?: number
  right?: number
  bottom?: number
}

export interface DropdownProps extends NativeTouchableOpacityProps {
  selectType?: string
  dropdownLabel?: string
  options: DropdownOption[] | undefined
  disabled?: boolean
  hover?: boolean
  placeholder?: string
  optionLabel?: string
  mode?: DropdownMode
  menuText?: string
  onSelect: (e: any) => void
  type?: DropdownType
  selectedItem?: DropdownOption
  testID?: string
  labelStyle?: StyleProp<TextStyle>
  containerStyle?: StyleProp<ViewStyle>
  iconContainerStyle?: StyleProp<TextStyle>
  iconStyle?: any
  hideChevron?: boolean
  dropdownStyle?: StyleProp<ViewStyle>
  dropdownPosition?: DropdownPosition
  dropdownContainerStyle?: StyleProp<ViewStyle>
  dropdownLabelStyle?: StyleProp<TextStyle>
  dropdownWrapperStyle?: StyleProp<ViewStyle>
  leftElement?: JSX.Element | JSX.Element[]
  rightElement?: JSX.Element | JSX.Element[]
  inputStyle?: StyleProp<TextStyle>
  onChangeText?: (e: string) => void
  inputSpace?: SpaceType
  inlineInputWrapperStyle?: StyleProp<ViewStyle>
  inlineInputStyle?: StyleProp<TextStyle>
  /**
   * Extra allowance (pixels) to consider when repositioning dropdown list container
   */
  repositionAllowance?: number
  stackProps?: StackProps
  /**
   * Callback when dropdown opens, no parameters
   */
  onOpen?: () => void
  /**
   * Callback when dropdown closes, no parameters
   */
  onClose?: () => void
  /**
   * List hover style
   */
  listHoverStyle?: StyleProp<ViewStyle>
  disableSelected?: boolean
  listDisabledItemStyle?: StyleProp<ViewStyle>
  selectedItemKey?: string
}

export interface SearchableProps extends DropdownProps {
  inputName: string
  renderItem?: (option: DropdownOption, isHovered: boolean) => JSX.Element | JSX.Element[]
  actionComponent?: (option: DropdownOption[] | undefined) => JSX.Element | JSX.Element[]
  isLoading?: boolean
  loadingText?: string
  multiple?: boolean
  filteredLimit?: number
  /**
   * Clears selection/filter on select or on dropdown close
   */
  unsetOnSelect?: boolean
  lazyLoad?: () => void
  isLazyLoading?: boolean
  controlledOpen?: boolean
}
