import moment from 'moment'
import { iMenuOption } from '../types'

export const getFinancialYearDiff = () => {
  const currDate = new Date(Date.now())
  const financialYearStartDate =
    currDate.getMonth() > 6 ? moment(currDate).add(-1, 'years').set('M', 6).set('D', 1) : moment(currDate).set('M', 6).set('D', 1)
  return Math.abs(moment(financialYearStartDate).diff(moment(currDate), 'months')).toString()
}

export const FILTER_OPTIONS: Array<iMenuOption> = [
  {
    id: 0,
    label: 'Last 1 month',
    value: '1',
  },
  {
    id: 1,
    label: 'Last 3 months',
    value: '3',
  },
  {
    id: 2,
    label: 'Last 6 months',
    value: '6',
  },
  {
    id: 3,
    label: 'Last 12 months',
    value: '12',
  },
  {
    id: 4,
    label: 'Last 2 years',
    value: '24',
  },
  {
    id: 5,
    label: 'FY',
    value: getFinancialYearDiff(),
  },
]
export const STATUS_OPTIONS: Array<iMenuOption> = [
  {
    id: 0,
    label: 'All',
    value: '',
  },
  {
    id: 1,
    label: 'Draft',
    value: 'Draft',
  },
  {
    id: 2,
    label: 'Live',
    value: 'Live',
  },
  {
    id: 3,
    label: 'Completed',
    value: 'Completed',
  },
  {
    id: 4,
    label: 'Cancelled',
    value: 'Cancelled',
  },
  {
    id: 5,
    label: 'Postponed',
    value: 'Postponed',
  },
]
