import { Button, GlobalStyles, Icon, StackV2, Text } from '@andromeda'
import { ModalFooter, ModalHeader } from '@components'
import { AppStyles } from '@styles'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext, useState } from 'react'

export const OwnerChange = () => {
  const { closeModal, options } = useContext(ModalContext)
  const props = options?.modalProps ? options.modalProps[ModalPages.OWNER_CHANGE] : undefined

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const _confirm = () => {
    if (props?.onConfirm) props.onConfirm()
    else closeModal(ModalPages.OWNER_CHANGE)
  }

  const _cancel = () => {
    props?.onCancel && props.onCancel()
    closeModal(ModalPages.OWNER_CHANGE)
  }

  return (
    <StackV2 spacing={2} height="auto" className="dialog-container" style={{ backgroundColor: GlobalStyles.MONO_WHITE }}>
      <ModalHeader
        modalContext={ModalPages.OWNER_CHANGE}
        modalTitle={'Owner Change'}
        textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
      />

      <div className="dialog-content">
        <Text>{'This will replace the current owner of the job with your selection.'}</Text>
        <StackV2
          direction="column"
          alignItems="flex-start"
          spacing={2}
          style={
            {
              // paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
              // paddingVertical: GlobalStyles.PADDING_SIZES.sm,
            }
          }>
          <Text size={'sm'} style={{ color: GlobalStyles.MONO_BLACK }}>
            {'Adding recommended fields will:'}
          </Text>

          <StackV2 direction="row" spacing={2} alignItems="flex-start">
            <Text style={{ lineHeight: 15 }}>
              <Icon.Check color={GlobalStyles.SLATE_300} />
            </Text>
            <Text size="sm">{'Enable automated reminders'}</Text>
          </StackV2>

          <StackV2 direction="row" spacing={2} alignItems="flex-start">
            <Text style={{ lineHeight: 15 }}>
              <Icon.Check color={GlobalStyles.SLATE_300} />
            </Text>
            <Text size="sm">{'Enable prompts to complete this job'}</Text>
          </StackV2>

          <StackV2 direction="row" spacing={2} alignItems="flex-start">
            <Text style={{ lineHeight: 15 }}>
              <Icon.Check color={GlobalStyles.SLATE_300} />
            </Text>
            <Text size="sm">{'Improve job sorting on the listing page'}</Text>
          </StackV2>

          <StackV2 direction="row" spacing={2} alignItems="flex-start">
            <Text style={{ lineHeight: 15 }}>
              <Icon.Check color={GlobalStyles.SLATE_300} />
            </Text>
            <Text size="sm">{'Improve reporting accuracy'}</Text>
          </StackV2>
        </StackV2>
      </div>

      <ModalFooter
        backgroundColor={GlobalStyles.MONO_WHITE}
        wrapperStyle={{
          shadowOpacity: 0,
          justifyContent: 'flex-end',
          paddingBottom: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.sm,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
        }}
        buttonStyles={{
          paddingVertical: 12,
          paddingHorizontal: 18,
          borderRadius: GlobalStyles.BUTTON_BORDER_RADIUS,
          backgroundColor: GlobalStyles.PRIMARY_500,
          height: AppStyles.BUTTON_SIZES.MD,
        }}
        onButtonPress={_confirm}
        buttonText={'Confirm Change'}
        disabled={false}
        leftComponent={
          <Button
            type="secondary"
            style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
            onPress={() => _cancel()}
            textStyle={{ color: GlobalStyles.PRIMARY_500 }}
            text="Cancel"
          />
        }
        loading={isSaving}
        loadingType={'white'}
      />
    </StackV2>
  )
}
